var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sumBy } from "lodash";
import { GET_INVOICES_BY_JOBS, INSERT_BATCH_PAYMENT } from "../graphqls/invoices/invoice.query";
import { ADD_BULK_PAYMENT, GET_PAYMENTS_BY_BATCHID, UPDATE_BATCH_PAYMENT, UPDATE_PAYMENT } from "../graphqls/payments/payment.query";
import { formatAddress } from "./job.service";
import { ADD_PAYROLL, GET_ALL_PAYROLLS, GET_APPRAISER_PAYROLLS, GET_PAYROLL_HISTORY, GET_STATEMENTS } from "../graphqls/accounting/payroll.query";
import { uuid } from "@utils/text";
import { httpGet } from "../helpers/http.helper";
export var getPayrollHistory = function (startDate, endDate, appId) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_PAYROLL_HISTORY,
                    variables: {
                        start: startDate,
                        end: endDate,
                        userId: appId,
                    },
                })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, (((_a = data === null || data === void 0 ? void 0 : data.allTblPayrolls) === null || _a === void 0 ? void 0 : _a.nodes) || []).map(function (item) {
                        var _a, _b, _c, _d, _e;
                        return ({
                            jobid: item.jobId,
                            id: item.id,
                            gross: (item.payment / (1 + item.hst)) - item.expense,
                            hst: item.payment - (item.payment / (1 + item.hst)),
                            payment: item.payment,
                            expense: item.expense,
                            subtotal: item.payment / (1 + item.hst),
                            endDate: item.endDate,
                            startDate: item.startDate,
                            address: (_a = item === null || item === void 0 ? void 0 : item.tblJobByJobId) === null || _a === void 0 ? void 0 : _a.zzFullAddress,
                            filenumber: (_b = item === null || item === void 0 ? void 0 : item.tblJobByJobId) === null || _b === void 0 ? void 0 : _b.fileNumber,
                            jobnumber: (_c = item === null || item === void 0 ? void 0 : item.tblJobByJobId) === null || _c === void 0 ? void 0 : _c.jobNumber,
                            clientname: (_e = (_d = item === null || item === void 0 ? void 0 : item.tblJobByJobId) === null || _d === void 0 ? void 0 : _d.tblClientByClientId) === null || _e === void 0 ? void 0 : _e.name,
                            billed: item.billed,
                            revenue: item.revenue,
                            totalJobExpenses: item.totalJobExpenses,
                            appFee: item.commission + " " + (item.feeType === 0 ? '%' : '$'),
                        });
                    })];
        }
    });
}); }; };
export var createPayroll = function (tableData, jobIds, startDate, endDate, appId) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(tableData.filter(function (t) { return jobIds.includes(+t.jobid); }).map(function (row) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                console.log(row);
                                return [4 /*yield*/, apolloClient.query({
                                        query: ADD_PAYROLL,
                                        variables: {
                                            input: {
                                                endDate: endDate,
                                                startDate: startDate,
                                                expense: parseFloat(row.totalexpenses),
                                                payment: (parseFloat(row.appportion) + parseFloat(row.expportion)) * (1 + parseFloat(row.hst)),
                                                userId: appId === -1 ? +row.appid : appId,
                                                jobId: +row.jobid,
                                                billed: (parseFloat(row.saleprice) / (1 + parseFloat(row.hst))) - parseFloat(row.totalJobExpenses),
                                                revenue: parseFloat(row.payments),
                                                commission: parseFloat(row.appfee),
                                                feeType: row.feetype === '%' ? 0 : 1,
                                                totalJobExpenses: parseFloat(row.totalJobExpenses),
                                                hst: row.hst,
                                            }
                                        },
                                    })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/, true];
                        }
                    });
                }); }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var getPayrollList = function (appId) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_APPRAISER_PAYROLLS,
                    variables: {
                        appId: appId,
                    },
                })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, ((_a = data === null || data === void 0 ? void 0 : data.spPayrollList) === null || _a === void 0 ? void 0 : _a.results) || []];
        }
    });
}); }; };
export var getPayrolls = function (startDate, endDate, appId, alldata) {
    if (alldata === void 0) { alldata = false; }
    return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, apolloClient.query({
                        query: GET_ALL_PAYROLLS,
                        variables: {
                            start: startDate,
                            end: endDate,
                            id: appId,
                            alldata: alldata,
                        },
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, ((_a = data === null || data === void 0 ? void 0 : data.spPayroll) === null || _a === void 0 ? void 0 : _a.results) || []];
            }
        });
    }); };
};
export var getStatements = function (id) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_STATEMENTS,
                    variables: {
                        id: id,
                    },
                })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, ((_a = data === null || data === void 0 ? void 0 : data.spStatement) === null || _a === void 0 ? void 0 : _a.results) || []];
        }
    });
}); }; };
export var updateBatchPayment = function (formData) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: UPDATE_BATCH_PAYMENT,
                    variables: {
                        id: +formData.id,
                        input: {
                            paymentDate: formData.paymentDate,
                            refNo: formData.refNo,
                            description: formData.description,
                            type: formData.type,
                            zzModifiedDate: new Date(),
                        }
                    },
                })];
            case 1:
                _c.sent();
                return [4 /*yield*/, apolloClient.query({
                        query: GET_PAYMENTS_BY_BATCHID,
                        variables: {
                            batchId: formData.batchId,
                        },
                    })];
            case 2:
                data = (_c.sent()).data;
                return [4 /*yield*/, Promise.all((_b = (_a = data === null || data === void 0 ? void 0 : data.allTblPayments) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map(function (p) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, apolloClient.query({
                                        query: UPDATE_PAYMENT,
                                        variables: {
                                            id: +p.id,
                                            input: {
                                                description: formData.description,
                                                paymentDate: formData.paymentDate,
                                                paymentType: formData.type,
                                                refNumber: formData.refNo,
                                            },
                                        },
                                    })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/, true];
                            }
                        });
                    }); }))];
            case 3:
                _c.sent();
                return [4 /*yield*/, httpGet("intuit/updateBatchPayment/" + formData.batchId)];
            case 4:
                _c.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var paymentReceived = function (invoices, save, otherData) {
    if (otherData === void 0) { otherData = null; }
    return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
        var data, result, batchId, payments;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, apolloClient.query({
                        query: GET_INVOICES_BY_JOBS,
                        variables: {
                            id: invoices,
                        },
                    })];
                case 1:
                    data = (_b.sent()).data;
                    result = (_a = data === null || data === void 0 ? void 0 : data.allTblJobs) === null || _a === void 0 ? void 0 : _a.nodes.map(function (i) { return ({
                        id: i.id,
                        zzFullAddress: formatAddress(i),
                        billed: sumBy(i.tblInvoicesByJobId.nodes, function (n) { return +n.rate; }),
                        paid: sumBy(i.tblPaymentsByJobId.nodes, function (n) { return +n.amount; }),
                        due: parseFloat(sumBy(i.tblInvoicesByJobId.nodes, function (n) { return +n.rate; }) + '') - parseFloat(sumBy(i.tblPaymentsByJobId.nodes, function (n) { return +n.amount; }) + ''),
                    }); }).filter(function (i) { return i.due > 0; });
                    if (!save) {
                        return [2 /*return*/, result];
                    }
                    batchId = uuid();
                    payments = result.map(function (id) {
                        return {
                            description: (otherData === null || otherData === void 0 ? void 0 : otherData.description) || '',
                            amount: +id.due || 0,
                            refNumber: (otherData === null || otherData === void 0 ? void 0 : otherData.refNumber) || '',
                            paymentDate: (otherData === null || otherData === void 0 ? void 0 : otherData.paymentDate) || new Date(),
                            paymentType: (otherData === null || otherData === void 0 ? void 0 : otherData.paymentType) || '',
                            jobId: +id.id,
                            batchId: batchId,
                        };
                    });
                    return [4 /*yield*/, Promise.all(payments.filter(function (p) { return p; }).map(function (p) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2 /*return*/, apolloClient.query({
                                        query: ADD_BULK_PAYMENT,
                                        variables: {
                                            input: p,
                                        }
                                    })];
                            });
                        }); }))];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, apolloClient.query({
                            query: INSERT_BATCH_PAYMENT,
                            variables: {
                                input: {
                                    amount: (otherData === null || otherData === void 0 ? void 0 : otherData.amount) || 0,
                                    description: (otherData === null || otherData === void 0 ? void 0 : otherData.description) || '',
                                    paymentDate: (otherData === null || otherData === void 0 ? void 0 : otherData.paymentDate) || new Date(),
                                    refNo: (otherData === null || otherData === void 0 ? void 0 : otherData.refNumber) || '',
                                    type: (otherData === null || otherData === void 0 ? void 0 : otherData.paymentType) || '',
                                    batchId: batchId,
                                }
                            },
                        })];
                case 3:
                    _b.sent();
                    return [4 /*yield*/, httpGet("intuit/createBatchPayment/" + batchId)];
                case 4:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
};
