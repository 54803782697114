var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { ColumnType, SelectorName, DRAWERCOMPONENT } from '@enums';
export var GET_DRIVING_DISTANCE = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation MyMutation($app: BigInt, $start: Datetime, $end: Datetime) {\n  spDrivingDistance(\n    input: { appraiser: $app, enddate: $end, startdate: $start }\n  ) {\n    results {\n      address\n      appraiser\n      distance\n      id\n      title\n    }\n  }\n}"], ["mutation MyMutation($app: BigInt, $start: Datetime, $end: Datetime) {\n  spDrivingDistance(\n    input: { appraiser: $app, enddate: $end, startdate: $start }\n  ) {\n    results {\n      address\n      appraiser\n      distance\n      id\n      title\n    }\n  }\n}"])));
export var GET_PAYMENTS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nmutation getPayments($startDate: Datetime!, $endDate: Datetime!) {\n  spDashboard(input: { startdate: $startDate, enddate: $endDate }) {\n    results {\n      datepart\n      title\n      total\n      refid\n    }\n  }\n}\n"], ["\nmutation getPayments($startDate: Datetime!, $endDate: Datetime!) {\n  spDashboard(input: { startdate: $startDate, enddate: $endDate }) {\n    results {\n      datepart\n      title\n      total\n      refid\n    }\n  }\n}\n"])));
export var GET_INCOME_PAYMENTS = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmutation getincome($appid:BigInt,$start:Date,$end:Date, $clientid:BigInt,$completed:Boolean, $outstand:Boolean) {\n  spIncome(input: {appid: $appid, enddate: $end, startdate: $start, clientid: $clientid, completed: $completed, outstand: $outstand }) {\n    results {\n      clientname\n      id\n      payments\n      portfolioname\n      propertysubtype\n      propertytype\n      statusname\n      zzcreateddate\n      zzcreatedby\n      zzfulladdress\n      jobnumber\n      filenumber\n      paid\n      clientid\n      statusid\n    }\n  }\n}\n"], ["\nmutation getincome($appid:BigInt,$start:Date,$end:Date, $clientid:BigInt,$completed:Boolean, $outstand:Boolean) {\n  spIncome(input: {appid: $appid, enddate: $end, startdate: $start, clientid: $clientid, completed: $completed, outstand: $outstand }) {\n    results {\n      clientname\n      id\n      payments\n      portfolioname\n      propertysubtype\n      propertytype\n      statusname\n      zzcreateddate\n      zzcreatedby\n      zzfulladdress\n      jobnumber\n      filenumber\n      paid\n      clientid\n      statusid\n    }\n  }\n}\n"])));
export var Columns = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Status",
        fieldName: "statusname",
        sortField: "STATUS_ID",
        sortable: false,
        filterable: false,
        upperCase: true,
        columnFilter: true,
        columnFilterField: 'statusid',
        columnType: ColumnType.LIST,
        selectorName: SelectorName.STATUS,
    },
    {
        caption: "File#",
        fieldName: "filenumber",
        sortField: "filenumber",
        sortable: true,
        filterable: false,
        upperCase: true,
        companyFormat: true,
        columnFilter: true,
        columnFilterField: 'filenumber',
    },
    {
        caption: "Reference#",
        fieldName: "jobnumber",
        sortField: "jobnumber",
        sortable: true,
        filterable: false,
        upperCase: true,
        columnFilter: true,
        columnFilterField: 'jobnumber',
    },
    {
        caption: "Client",
        fieldName: "clientname",
        sortField: "clientname",
        sortable: true,
        filterable: false,
        linkTo: DRAWERCOMPONENT.CLIENT,
        openInDrawer: true,
        linkFieldId: 'clientid',
        columnFilter: true,
        columnFilterField: 'clientid',
        selectorName: SelectorName.CLIENT,
        columnType: ColumnType.LIST,
    },
    {
        caption: "Address",
        fieldName: "zzfulladdress",
        sortField: "zzfulladdress",
        sortable: true,
        filterable: false,
        linkTo: DRAWERCOMPONENT.JOB_VIEW,
        openInDrawer: true,
        linkFieldId: 'id',
    },
    {
        caption: "Created Date",
        fieldName: "zzcreateddate",
        sortField: "zzcreateddate",
        sortable: true,
        filterable: false,
        columnType: ColumnType.DATE,
        columnFilter: true,
        columnFilterField: 'zzcreateddate',
    },
    {
        caption: "Created By",
        fieldName: "zzcreatedby",
        sortField: "zzcreatedby",
        sortable: true,
        filterable: false,
        upperCase: true,
    },
    {
        caption: "Portfolio Name",
        fieldName: "portfolioname",
        sortField: "portfolioname",
        sortable: true,
        filterable: false,
        upperCase: true,
    },
    {
        caption: "Appraiser Fee",
        fieldName: "payments",
        sortField: "payments",
        sortable: true,
        filterable: false,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
];
var templateObject_1, templateObject_2, templateObject_3;
