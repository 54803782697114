var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { InputGroup, InputGroupText, InputGroupAddon, Input, } from "reactstrap";
import { useMutation } from "@apollo/client";
import { SEARCH_APP } from "../../../graphqls/jobs/job.query";
import { SmallJobList } from "../../../components/appraisals/Small-Jobs.component";
export var AppSearchBar = function (_a) {
    var _b = _a.onSelect, onSelect = _b === void 0 ? null : _b;
    var _c = useState(null), result = _c[0], setResult = _c[1];
    var searchInApp = useMutation(SEARCH_APP, {
        onCompleted: function (data) {
            var _a;
            setResult((_a = data === null || data === void 0 ? void 0 : data.spSearchInApp) === null || _a === void 0 ? void 0 : _a.results);
        }
    })[0];
    var searchByJob = function (e) {
        var _a;
        var search = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        if (search.length >= 2) {
            searchInApp({
                variables: {
                    criteria: "%" + search + "%",
                }
            });
        }
        else {
            setResult(null);
        }
    };
    return (_jsxs("div", { children: [_jsxs(InputGroup, __assign({ className: "no-border" }, { children: [_jsx(InputGroupAddon, __assign({ addonType: "prepend" }, { children: _jsx(InputGroupText, { children: _jsx("i", { className: "nc-icon nc-zoom-split" }, void 0) }, void 0) }), void 0),
                    _jsx(Input, { placeholder: "Search...", onChange: searchByJob, className: "input-search-bar" }, void 0)] }), void 0),
            (result === null || result === void 0 ? void 0 : result.length) > 0 && (_jsx("div", { children: _jsx(SmallJobList, { jobs: result, onSelect: onSelect }, void 0) }, void 0))] }, void 0));
};
