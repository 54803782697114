var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatTotalAsCurrency } from "@utils/text";
import "./plan.css";
import { orderBy } from "lodash";
export var SubscriptionPlans = function (_a) {
    var _b;
    var plans = _a.plans, _c = _a.onSelect, onSelect = _c === void 0 ? null : _c, _d = _a.licenceid, licenceid = _d === void 0 ? 0 : _d, _e = _a.user, user = _e === void 0 ? 0 : _e;
    var planSelected = function (id) { return function () {
        onSelect && onSelect(id, user, licenceid);
    }; };
    return (_jsx("div", __assign({ className: "pricing-container" }, { children: (_b = orderBy(plans === null || plans === void 0 ? void 0 : plans.filter(function (p) { return +p.yearly !== 0; }), function (i) { return +i.id; })) === null || _b === void 0 ? void 0 : _b.map(function (plan) {
            var _a, _b;
            return (_jsxs("article", __assign({ className: "pricing-card " + ((plans === null || plans === void 0 ? void 0 : plans.length) === 1 ? 'max-w-full' : '') }, { children: [_jsx("h3", { children: plan.typeName }, void 0),
                    _jsxs("div", __assign({ className: "pricing-card__price" }, { children: [formatTotalAsCurrency(plan.price), _jsx("div", __assign({ className: "period" }, { children: "/month" }), void 0)] }), void 0),
                    _jsx("div", __assign({ className: "pt-4 w-100" }, { children: "Includes:" }), void 0),
                    _jsx("ul", { children: (_b = (_a = plan.plan) === null || _a === void 0 ? void 0 : _a.split('\n')) === null || _b === void 0 ? void 0 : _b.map(function (p, i) { return (_jsxs("li", { children: [p, "."] }, i)); }) }, void 0),
                    _jsxs("div", __assign({ className: "period" }, { children: ["Additional Users: ", formatTotalAsCurrency(plan.additionalUser), "/user"] }), void 0),
                    (plans === null || plans === void 0 ? void 0 : plans.length) > 1 && _jsxs("a", __assign({ className: "enroll", href: "#", onClick: planSelected(plan.id) }, { children: ["Choose ", plan.typeName] }), void 0)] }), void 0));
        }) }), void 0));
};
