var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { httpGet, httpPost, httpPostUpload } from "../../helpers/http.helper";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { isAppraiser } from "../../services/user-service";
import { FileUploader } from "../../ui-components/File-Uploader.component";
import { DOCUMENT_TYPE } from "@enums";
import { uuid } from "@utils/text";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
export var UserProfile = function () {
    var _a = useState(null), user = _a[0], setUser = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState([]), attachment = _c[0], setAttachment = _c[1];
    var _d = useState(''), description = _d[0], setDescription = _d[1];
    var _e = useState(''), reload = _e[0], setReload = _e[1];
    var appraiser = isAppraiser();
    var formik = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            retype: '',
        },
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setError('');
                        Spinner.show();
                        result = {};
                        if (!(values.newPassword && values.password && values.retype)) return [3 /*break*/, 2];
                        return [4 /*yield*/, httpPost('user/resetpwd', __assign({}, values))];
                    case 1:
                        result = _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(result === null || result === void 0 ? void 0 : result.error)) return [3 /*break*/, 3];
                        setError(result === null || result === void 0 ? void 0 : result.error);
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, httpPostUpload("document/upload/" + user.id, attachment, { description: description, documentType: DOCUMENT_TYPE.APPRAISERS })];
                    case 4:
                        _a.sent();
                        setReload(uuid());
                        _a.label = 5;
                    case 5:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    });
    var handleAttachments = function (files, description) {
        setAttachment(files);
        setDescription(description);
    };
    var getUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpGet("user/profile")];
                case 1:
                    user = _a.sent();
                    setUser(user);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getUser();
    }, []);
    return (_jsx("div", __assign({ className: "content" }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, __assign({ title: "User Profile", noHome: true }, { children: _jsxs(Button, __assign({ color: "info", type: "submit", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0) }, void 0) }, void 0),
                isMobile &&
                    _jsx(Row, __assign({ className: "mt-16" }, { children: _jsxs(Col, __assign({ className: "flex gap-16", xs: 12 }, { children: [_jsx(Link, __assign({ to: "/admin/subscription", className: "btn btn-xxs btn-link text-blue btn btn-secondary underline" }, { children: "Subscription" }), void 0),
                                _jsx(Link, __assign({ to: "/admin/billing", className: "btn btn-xxs btn-link text-blue btn btn-secondary underline" }, { children: "Billing" }), void 0)] }), void 0) }), void 0),
                _jsxs(Row, __assign({ className: "mt-16" }, { children: [_jsx(Col, __assign({ xs: isMobile ? 12 : 6 }, { children: _jsxs(Card, __assign({ className: "border-0 min-h-90" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Account Details" }), void 0)] }), void 0) }), void 0),
                                    _jsx(CardBody, { children: _jsxs(Grid, __assign({ container: true, className: "grid-view-only" }, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Email" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 8 }, { children: user === null || user === void 0 ? void 0 : user.email }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "User name" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 8 }, { children: user === null || user === void 0 ? void 0 : user.user_name }), void 0)] }), void 0) }, void 0)] }), void 0) }), void 0),
                        _jsx(Col, __assign({ xs: isMobile ? 12 : 6 }, { children: _jsxs(Card, __assign({ className: "border-0 min-h-90" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Reset Password" }), void 0)] }), void 0) }), void 0),
                                    _jsx(CardBody, { children: _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Current password" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 8 }, { children: _jsx(InputText, { helperText: error, type: "password", id: "password", name: "password", onChange: formik.handleChange }, void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "new Password" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 8 }, { children: _jsx(InputText, { type: "password", id: "newPassword", name: "newPassword", onChange: formik.handleChange }, void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Confirm Password" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 8 }, { children: _jsx(InputText, { helperText: formik.values.retype && formik.values.newPassword !== formik.values.retype ? 'Confirm Password does not match.' : '', type: "password", id: "retype", name: "retype", onChange: formik.handleChange }, void 0) }), void 0)] }), void 0) }, void 0)] }), void 0) }), void 0)] }), void 0),
                appraiser && (_jsx(Row, __assign({ className: "mt-8" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsxs(Card, __assign({ className: "border-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Documents" }), void 0)] }), void 0) }), void 0),
                                _jsx(CardBody, { children: _jsx(FileUploader, { reload: reload, id: +(user === null || user === void 0 ? void 0 : user.id), type: DOCUMENT_TYPE.APPRAISERS, onChange: handleAttachments, attachments: attachment || [] }, void 0) }, void 0)] }), void 0) }), void 0) }), void 0))] }), void 0) }), void 0));
};
