var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { httpPost } from "../../helpers/http.helper";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import "./login.css";
import { useParams } from "react-router";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { Typography } from "@material-ui/core";
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import { Button } from "reactstrap";
import { isMobile } from "react-device-detect";
import { SubscriptionPlans } from "../../modules/subscription/subscription-plans.component";
export var RegisterTrial = function () {
    var _a = useState('REGISTER'), step = _a[0], setStep = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState(null), subPlan = _c[0], setSubPlan = _c[1];
    var _d = useState(null), userData = _d[0], setUserData = _d[1];
    var plan = useParams().plan;
    var formik = useFormik({
        initialValues: {
            company: '',
            firstName: '',
            lastName: '',
            email: '',
            code: '',
            password: '',
            retypePassword: '',
            users: 0,
        },
        validate: function (values) {
            var errors = {};
            if (!values.email) {
                errors = __assign(__assign({}, errors), { email: 'Email is required!' });
            }
            if (!values.company) {
                errors = __assign(__assign({}, errors), { company: 'Company is required!' });
            }
            return errors;
        },
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, result, result, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setError('');
                        _a = step;
                        switch (_a) {
                            case 'REGISTER': return [3 /*break*/, 1];
                            case 'CODE': return [3 /*break*/, 3];
                            case 'PAYMENT': return [3 /*break*/, 6];
                        }
                        return [3 /*break*/, 8];
                    case 1:
                        Spinner.show();
                        return [4 /*yield*/, httpPost("user/freeTrial", __assign({}, values))];
                    case 2:
                        result = _b.sent();
                        if ((result === null || result === void 0 ? void 0 : result.severity) === "ERROR") {
                            setError('Email already exists !');
                        }
                        else {
                            setStep('CODE');
                        }
                        return [3 /*break*/, 8];
                    case 3:
                        if (!(values === null || values === void 0 ? void 0 : values.code)) return [3 /*break*/, 5];
                        Spinner.show();
                        return [4 /*yield*/, httpPost("user/freeTrial", __assign(__assign({}, values), { plan: plan }))];
                    case 4:
                        result = _b.sent();
                        if ((result === null || result === void 0 ? void 0 : result.severity) === "ERROR") {
                            setError('Invalid verification code !');
                        }
                        else {
                            setSubPlan(result === null || result === void 0 ? void 0 : result.sub);
                            setStep('PAYMENT');
                            setUserData({
                                userId: result.userId,
                                licenceId: result.licenceId,
                            });
                        }
                        _b.label = 5;
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        Spinner.show();
                        return [4 /*yield*/, httpPost("stripe/continuetopayment", __assign({ plan: plan, email: values.email }, userData))];
                    case 7:
                        result = _b.sent();
                        if (result === null || result === void 0 ? void 0 : result.severity) {
                            setError(result === null || result === void 0 ? void 0 : result.severity);
                        }
                        else {
                            document.location.href = result.url;
                        }
                        return [3 /*break*/, 8];
                    case 8:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    });
    useEffect(function () {
        document.body.style.backgroundColor = 'white';
    }, []);
    return (_jsxs("div", __assign({ className: "login-wrapper" }, { children: [_jsx("div", __assign({ className: "login-side" }, { children: _jsxs("div", __assign({ className: "my-form__wrapper " + (step !== 'PAYMENT' ? 'pt-0' : 'p-0') }, { children: [step !== 'PAYMENT' && _jsx("div", __assign({ className: "db w-full center pt-4 bold black" }, { children: "Create your account \uD83D\uDC4F" }), void 0),
                        _jsxs("form", __assign({ className: "my-form " + (step === 'PAYMENT' ? 'w-full' : ''), onSubmit: formik.handleSubmit }, { children: [error && _jsx("div", __assign({ className: "error db center bold" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: error }), void 0) }), void 0),
                                step === 'PAYMENT' && (_jsx("div", __assign({ className: "db w-full" }, { children: _jsx(SubscriptionPlans, { plans: [subPlan] }, void 0) }), void 0)),
                                step === 'CODE' && (_jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "code" }, { children: "Verification Code:" }), void 0),
                                        _jsx("input", { type: "text", id: "code", name: "code", placeholder: "verification code...", onChange: formik.handleChange }, void 0),
                                        _jsx(LockOpenOutlinedIcon, {}, void 0)] }), void 0)),
                                step === 'REGISTER' && (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "company" }, { children: "Company Name:" }), void 0),
                                                _jsx("input", { type: "text", id: "company", name: "company", placeholder: "Company Name", onChange: formik.handleChange, required: true }, void 0),
                                                _jsx(BusinessCenterOutlinedIcon, {}, void 0)] }), void 0),
                                        _jsxs("div", __assign({ className: "db w-full" }, { children: [_jsx("div", __assign({ className: isMobile ? "db w-full" : "dib w-45" }, { children: _jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "firstName" }, { children: "First Name:" }), void 0),
                                                            _jsx("input", { type: "text", id: "firstName", name: "firstName", placeholder: "First Name", onChange: formik.handleChange, required: true }, void 0),
                                                            _jsx(PersonOutlineOutlinedIcon, {}, void 0)] }), void 0) }), void 0),
                                                _jsx("div", { className: "dib w-10" }, void 0),
                                                _jsx("div", __assign({ className: isMobile ? "db w-full" : "dib w-45" }, { children: _jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "lastName" }, { children: "Last Name:" }), void 0),
                                                            _jsx("input", { type: "text", id: "lastName", name: "lastName", placeholder: "Last Name", onChange: formik.handleChange, required: true }, void 0),
                                                            _jsx(PersonOutlineOutlinedIcon, {}, void 0)] }), void 0) }), void 0)] }), void 0),
                                        _jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Email:" }), void 0),
                                                _jsx("input", { type: "email", id: "email", name: "email", placeholder: "Your Email", onChange: formik.handleChange, required: true }, void 0),
                                                _jsx(AlternateEmailIcon, {}, void 0)] }), void 0),
                                        _jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "password" }, { children: "Password:" }), void 0),
                                                _jsx("input", { id: "password", type: "password", name: "password", placeholder: "Your Password", onChange: formik.handleChange, required: true }, void 0),
                                                _jsx(LockOutlinedIcon, {}, void 0)] }), void 0)] }, void 0)),
                                _jsx("div", __assign({ className: step === "PAYMENT" ? 'payment-button' : '' }, { children: _jsxs(Button, __assign({ type: "submit", color: "info", className: "my-form__button" }, { children: [step === 'PAYMENT' && _jsx(CreditCardOutlinedIcon, { className: "mr-8" }, void 0), " ", step === 'PAYMENT' ? " Continue to Payment" : step === 'REGISTER' ? 'Get Started' : 'Verify Code'] }), void 0) }), void 0),
                                step === 'REGISTER' &&
                                    _jsxs("div", __assign({ className: "my-form__actions" }, { children: [_jsx("div", __assign({ className: "my-form__signup" }, { children: _jsx("span", { children: "Already have an account? " }, void 0) }), void 0),
                                            _jsx("div", __assign({ className: "my-form__signup" }, { children: _jsx("a", __assign({ href: "/login", title: "Sign in", className: "pl-8" }, { children: "Sign in" }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0),
            _jsx("div", { className: "info-side" }, void 0)] }), void 0));
};
