import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from "../../ui-components/table/table.component";
import { useEffect, useState } from "react";
import { BATCHPAYMENT_JOBS_COLUMNS, GET_PAYMENTS_BY_BATCH_ID } from "../../graphqls/payments/payment.query";
import { useLazyQuery } from "@apollo/client";
export var BatchPaymentDetails = function (_a) {
    var _b = _a.batchId, batchId = _b === void 0 ? '' : _b;
    var _c = useState([]), data = _c[0], setData = _c[1];
    var getById = useLazyQuery(GET_PAYMENTS_BY_BATCH_ID, {
        onCompleted: function (data) {
            setData(data.allTblPayments.nodes || []);
        }
    })[0];
    useEffect(function () {
        if (batchId) {
            getById({
                variables: {
                    batch: batchId,
                }
            });
        }
    }, [batchId]);
    return (_jsx(Table, { offlineData: data, headers: BATCHPAYMENT_JOBS_COLUMNS, showFilter: false }, void 0));
};
