var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toDate } from "@utils/date";
import { differenceInMonths } from 'date-fns';
import { getCompanyTax, getCompanyTaxPercent } from "./Storage.util";
export var extractColumsFromFormula = function (header) {
    var _a;
    var regex = /\[[a-zA-Z0-9\\_]+\]/g;
    var found = (_a = header === null || header === void 0 ? void 0 : header.formula) === null || _a === void 0 ? void 0 : _a.match(regex);
    return found === null || found === void 0 ? void 0 : found.map(function (f) { return f.replace('[', '').replace(']', '').replace(/\$/g, '').replace(/%/g, '').replace(/,/g, ''); });
};
export var calculateFormula = function (header, data) {
    if (header.formula) {
        var regex = /\[[a-zA-Z0-9\\_]+\]/g;
        var found = header.formula.match(regex);
        var evaluate_1 = header.formula.replace('equal', '===');
        found === null || found === void 0 ? void 0 : found.forEach(function (col) {
            evaluate_1 = evaluate_1.replace(col, data[col.replace('[', '').replace(']', '').replace(/\$/g, '').replace(/%/g, '').replace(/,/g, '')] || 0);
        });
        window.differenceInMonths = differenceInMonths;
        window.toDate = toDate;
        window.tax = getCompanyTax;
        window.taxPercent = getCompanyTaxPercent;
        var value = eval(evaluate_1);
        return +value.toFixed(2);
    }
    return 0;
};
export var getImageSrc = function (input) {
    if (!input)
        return process.env.REACT_APP_NO_PROPERTY_PHOTO;
    var regex = /<img.*?src=["|'](.*?)["|']/g;
    var result = regex.exec(input);
    return result && result.length && result.length === 2 ? result[1] : process.env.REACT_APP_NO_PROPERTY_PHOTO;
};
export var getImageTags = function (input) {
    if (!input)
        return [];
    var regex = /<img.*?src="(.*?)"[^\>]+>/g;
    return (input || '').match(regex);
};
export var handleFormula = function (field, formik, form, setForm) { return function (e) {
    var _a;
    var value = -1;
    var result = [];
    if (field.formula && field.formula.indexOf("=") !== -1) {
        field.formula.split(',').forEach(function (element) {
            var _a;
            var newColumn = __assign(__assign({}, field), { formula: element.split('=')[1] });
            value = calculateFormula(newColumn, formik ? formik.values : form);
            if (formik) {
                formik.setFieldValue(element.split('=')[0], value);
            }
            else if (setForm && form) {
                setForm && setForm(__assign(__assign({}, form), (_a = {}, _a[element.split('=')[0]] = value, _a)));
            }
            else {
                result.push({
                    key: element.split('=')[0],
                    value: value,
                });
            }
        });
    }
    else if (field.formula) {
        value = calculateFormula(field, formik ? formik.values : form);
        if (formik) {
            formik.setFieldValue(field.fieldName, value);
        }
        else {
            setForm && setForm(__assign(__assign({}, form), (_a = {}, _a[field.fieldName] = value, _a)));
        }
    }
    return result;
}; };
