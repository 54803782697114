var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var GETBYID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query getById($id: BigInt!) {\n        tblAppraiserById(id: $id) {\n        address1\n        address2\n        aicInsuranceNo\n        aicNo\n        country\n        defautFeeSplit\n        designation\n        email\n        firstName\n        id\n        lastName\n        municipality\n        note\n        phoneMobile\n        phonebusiness\n        postalCode\n        province\n        salutation\n        title\n        username\n        commissions\n        feeTypes\n        reviewer\n        officeId\n        }\n    }\n"], ["\n    query getById($id: BigInt!) {\n        tblAppraiserById(id: $id) {\n        address1\n        address2\n        aicInsuranceNo\n        aicNo\n        country\n        defautFeeSplit\n        designation\n        email\n        firstName\n        id\n        lastName\n        municipality\n        note\n        phoneMobile\n        phonebusiness\n        postalCode\n        province\n        salutation\n        title\n        username\n        commissions\n        feeTypes\n        reviewer\n        officeId\n        }\n    }\n"])));
export var UPDATE = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nmutation update($id: BigInt!, $input: TblAppraiserPatch!) {\n    updateTblAppraiserById(input: { tblAppraiserPatch: $input, id: $id }) {\n      clientMutationId\n      tblAppraiser {\n        id\n        firstName\n        lastName\n        title\n        commissions\n        feeTypes\n      }\n    }\n  }  \n"], ["\nmutation update($id: BigInt!, $input: TblAppraiserPatch!) {\n    updateTblAppraiserById(input: { tblAppraiserPatch: $input, id: $id }) {\n      clientMutationId\n      tblAppraiser {\n        id\n        firstName\n        lastName\n        title\n        commissions\n        feeTypes\n      }\n    }\n  }  \n"])));
export var ADD = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation createnew($input: TblAppraiserInput!) {\n        createTblAppraiser(input: {tblAppraiser: $input}) {\n            clientMutationId\n            tblAppraiser {\n                id\n                firstName\n                lastName\n                title\n                commissions\n                feeTypes\n            }\n        }\n    }\n"], ["\n    mutation createnew($input: TblAppraiserInput!) {\n        createTblAppraiser(input: {tblAppraiser: $input}) {\n            clientMutationId\n            tblAppraiser {\n                id\n                firstName\n                lastName\n                title\n                commissions\n                feeTypes\n            }\n        }\n    }\n"])));
export var TBLAPPRAISOR = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nquery getAllTblAppraisers($offset: Int, $first: Int, $orderBy: [TblAppraisersOrderBy!],  $filter: TblAppraiserFilter) {\n    allTblAppraisers(\n      first: $first\n      offset: $offset\n      orderBy: $orderBy\n      filter: $filter\n    ) {\n      totalCount\n      nodes {\n        firstName\n        lastName\n        id\n        email\n        title\n        salutation\n        note\n        municipality\n        province\n        address1\n        address2\n        aicInsuranceNo\n        aicNo\n        country\n        designation\n        phoneMobile\n        phonebusiness\n        postalCode\n        tblOfficeByOfficeId {\n            name\n          }\n      }\n    }\n  } \n"], ["\nquery getAllTblAppraisers($offset: Int, $first: Int, $orderBy: [TblAppraisersOrderBy!],  $filter: TblAppraiserFilter) {\n    allTblAppraisers(\n      first: $first\n      offset: $offset\n      orderBy: $orderBy\n      filter: $filter\n    ) {\n      totalCount\n      nodes {\n        firstName\n        lastName\n        id\n        email\n        title\n        salutation\n        note\n        municipality\n        province\n        address1\n        address2\n        aicInsuranceNo\n        aicNo\n        country\n        designation\n        phoneMobile\n        phonebusiness\n        postalCode\n        tblOfficeByOfficeId {\n            name\n          }\n      }\n    }\n  } \n"])));
export var Columns = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Full Name",
        fieldName: "salutation|firstName|lastName",
        sortable: true,
        sortField: 'FIRST_NAME',
    },
    {
        caption: "Title",
        fieldName: "title",
        sortable: true,
        sortField: 'TITLE',
        columnFilter: true,
        columnFilterField: 'title',
    },
    {
        caption: "Email",
        fieldName: "email",
        columnFilter: true,
        columnFilterField: 'email',
        sortable: true,
        sortField: 'EMAIL',
    },
    {
        caption: "Designation",
        fieldName: "designation",
        sortable: true,
        sortField: 'DESIGNATION',
    },
    {
        caption: "Mobile Phone",
        fieldName: "phoneMobile",
        sortable: true,
        sortField: 'PHONE_MOBILE',
        columnFilter: true,
        columnFilterField: 'phoneMobile',
    },
    {
        caption: "Business Phone",
        fieldName: "phonebusiness",
        sortable: true,
        sortField: 'PHONEBUSINESS',
        columnFilter: true,
        columnFilterField: 'phonebusiness',
        isHidden: true,
    },
    {
        caption: "Office",
        fieldName: "tblOfficeByOfficeId.name",
        isHidden: false,
    },
    {
        caption: "Address",
        fieldName: "address1|address2|municipality|province|postalCode",
        isHidden: true,
        multipleValueSeparator: ',',
    },
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
