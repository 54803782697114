var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useEffect, useRef, useState } from "react";
import { MenuItem, Switch, Tabs, Tab, List, ListItem, ListItemText, Typography, Divider } from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { InputText } from "../../../ui-components/input/Input-Field.component";
import { makeStyles } from '@material-ui/core/styles';
import { getAppraisers, getClients, getJobStatuses, getJobTypes, getOptions } from "../../../stores/client.selector";
import { map } from "lodash";
import { useHistory } from "react-router-dom";
import { Spinner } from "../../../ui-components/loading/Spinner.component";
import { useLazyQuery } from "@apollo/client";
import { GETJOBBYID, GET_DOCUMENTS, GET_ACTIVE_JOBS_BY_CLIENT, GET_ACTIVE_JOBS_BY_ADDRESS, GET_DOCUMENTS_BY_TYPE } from "../../../graphqls/jobs/job.query";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { httpPostUpload } from "../../../helpers/http.helper";
import { ModalForm } from "../../../ui-components/modals/Modal-Form.component";
import { ClientEdit } from "../../clients/edit/Edit.component";
import { PropertyAddress } from "./Property-Address.component";
import { FileExtension } from "../../../ui-components/extens/File-Extension.component";
import { AddressComponent } from "../../../ui-components/Address.component";
import { formatDate } from "@utils/date";
import { canEditRequest, getJwtToken, getUserName } from "../../../services/user-service";
import { useFormik, FormikProvider } from 'formik';
import { APP_PACKAGES, JobTypes, NEGATIVE_AUTO_COMPLETE_OPTION, ORDER_STATUS, SelectorName } from "@enums";
import { QuoteOptionType } from "@enums";
import { Note } from "../../note/Note.component";
import { ListHeader } from "../../../ui-components/lists/Header.component";
import { useApolloQuery } from "../../../hooks/use-appolo.hooks";
import { formatFileNumber, formatUnitSteet, getCountryName, getNextFileNumber, upsertJob } from "../../../services/job.service";
import { getCompanyTaxPercent, readFromStorage, STORAGE_KEY } from "../../../utils/Storage.util";
import { JobExpense } from "../Expense.component";
import { SmallJobList } from "../Small-Jobs.component";
import { PropertySections } from "../Property-Section.component";
import { setAddSections } from "../../../stores/app.reducer";
import { JOB_LIST, JOB_VIEW_URL, ORDER_LIST } from "../../../routes";
import { OptionManagerModal } from "../../options/OptionModal.component";
import { SYSTEM_TABLES } from "@enums";
import { getAllSections, getAppModules } from "../../../stores/app.selector";
import { PopupForm } from "../../../ui-components/forms/Popup-Form";
import { ReactDatePicker } from "../../../ui-components/date-picker/DatePicker.component";
import { toDate, handleFormDateChanged } from "@utils/date";
import { DOCUMENT_TYPE } from "@enums";
import { loadOrderById } from "../../../services/orders.service";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { isMobile } from "react-device-detect";
import { ICAutoComplete } from "../../../ui-components/autoComplete/autoComplete.component";
var useStyles = makeStyles(function (theme) { return ({
    topMargin: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
}); });
export var JobComponent = function (_a) {
    var props = __rest(_a, []);
    var formik = useFormik({
        initialValues: {
            jobExpenses: [],
        },
        onSubmit: function () { },
    });
    var canEdit = canEditRequest();
    var apolloClient = useApolloQuery()[0];
    var classes = useStyles();
    var dispatch = useDispatch();
    var _b = useState(false), validate = _b[0], setValidate = _b[1];
    var _c = useState(-1), oldStatusId = _c[0], setOldStatusId = _c[1];
    var _d = useState(5), activeStep = _d[0], setActiveStep = _d[1];
    var appModule = useSelector(getAppModules);
    var _e = useState(null), multipleAddress = _e[0], setMultipleAddress = _e[1];
    var _f = useState([]), documents = _f[0], setDocuments = _f[1];
    var _g = useState([]), attachment = _g[0], setAttachment = _g[1];
    var _h = useState(false), additionalScope = _h[0], setAdditionalScope = _h[1];
    var _j = useState(0), activeJobs = _j[0], setActiveJobs = _j[1];
    var _k = useState(null), activeJobsAddress = _k[0], setActiveJobsAddress = _k[1];
    var _l = useState(false), orderToJob = _l[0], setOrderToJob = _l[1];
    var _m = useState(0), retainerRequired = _m[0], setRetainerRequired = _m[1];
    var _o = useState(''), open = _o[0], setOpen = _o[1];
    var _p = useState(0), optionType = _p[0], setOptionType = _p[1];
    var clients = useSelector(getClients);
    var jobTypes = useSelector(getJobTypes);
    var jobStatuses = useSelector(getJobStatuses);
    var quoteOptions = useSelector(getOptions);
    var sections = useSelector(getAllSections);
    var decription = useRef();
    var history = useHistory();
    var id = ((props === null || props === void 0 ? void 0 : props.id) ? props : (props.match ? props.match.params : { id: undefined })).id;
    var isModal = ((props === null || props === void 0 ? void 0 : props.isModal) ? props : (props.match ? props.match.params : { isModal: false })).isModal;
    var orderId = ((props === null || props === void 0 ? void 0 : props.orderId) ? props : (props.match ? props.match.params : { orderId: undefined })).orderId;
    var appraisers = useSelector(getAppraisers);
    var company = readFromStorage(STORAGE_KEY.COMPANY);
    var renderTabs = useMemo(function () { return (sections === null || sections === void 0 ? void 0 : sections.filter(function (c) { return c.tableName === SYSTEM_TABLES.JOB; })) || []; }, [sections]);
    var isOrder = useMemo(function () { return (props === null || props === void 0 ? void 0 : props.isOrder) && !orderToJob; }, [props, orderToJob]);
    var tax = getCompanyTaxPercent() * 100;
    var _q = useState({
        jobTypeId: 2,
        streetName: '',
        streetNo: '',
        unit: '',
        province: '',
        postalCode: '',
        zzFullAddress: '',
        city: '',
        clientId: 0,
        multi: false,
        portfolioName: '',
        feeSplitAmount: 0,
        feeSplitPercent: 0,
        statusId: 3,
        zzCreatedBy: getUserName(),
        countryName: '',
        county: '',
        owner: '',
        delivery: '',
        expenses: '',
        scopes: '',
        reportTypeId: -1,
        jobNumber: '',
        appraiserId: -1,
        comission: 0,
        comissionType: 0,
        fileNumber: 0,
        fileNumberFormat: '',
        hst: tax,
    }), jobForm = _q[0], setJobForm = _q[1];
    var onAddressChanged = function (newValue) {
        setJobForm(__assign(__assign({}, jobForm), newValue));
        getActiveJobsByAddress({
            variables: {
                address: "%" + formatUnitSteet(newValue) + "%",
                id: +(id || '0'),
                country: getCountryName(newValue),
            },
        });
    };
    var showActiveJobsAddress = function () {
        ModalForm.show({
            title: "Previously created jobs",
            footer: false,
            small: false,
            md: false,
            large: true,
            body: (_jsx("div", { children: _jsx(SmallJobList, { jobs: activeJobsAddress === null || activeJobsAddress === void 0 ? void 0 : activeJobsAddress.nodes, showAddress: true }, void 0) }, void 0)),
            onSuccessClose: true,
        });
    };
    var getActiveJobsByAddress = useLazyQuery(GET_ACTIVE_JOBS_BY_ADDRESS, {
        onCompleted: function (data) {
            setActiveJobsAddress(data === null || data === void 0 ? void 0 : data.allTblJobs);
        }
    })[0];
    var getActiveJobs = useLazyQuery(GET_ACTIVE_JOBS_BY_CLIENT, {
        onCompleted: function (data) {
            var _a;
            setActiveJobs(((_a = data === null || data === void 0 ? void 0 : data.allTblJobs) === null || _a === void 0 ? void 0 : _a.totalCount) || 0);
        }
    })[0];
    var addNewExpense = function () {
        addOptions(QuoteOptionType.EXPENSES);
    };
    var addOptions = function (optionType) {
        setOptionType(optionType);
        setOpen("" + new Date().getTime());
    };
    var showFiles = function (doc) {
        window.open(process.env.REACT_APP_EXTERNAL_API_URL + "document/document/" + doc.fileKey + "/" + getJwtToken());
    };
    var uploadFiles = function (redirect, jobId) {
        if (jobId === void 0) { jobId = 0; }
        return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Spinner.show();
                        return [4 /*yield*/, httpPostUpload("document/upload/" + (jobId === 0 ? id : jobId), attachment, { description: decription.current.value || "", documentType: isOrder ? DOCUMENT_TYPE.ORDERS : DOCUMENT_TYPE.JOBS })];
                    case 1:
                        result = _a.sent();
                        Spinner.hide();
                        if (redirect) {
                            history.push("/admin/appraisals");
                        }
                        else {
                            setDocuments(documents.concat(result));
                            setAttachment([]);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleUpload = function (files) {
        setAttachment(files);
    };
    useEffect(function () {
        if (!id && +jobForm.appraiserId) {
            var data = appraisers.find(function (a) { return +a.id === +jobForm.appraiserId; });
            if (data) {
                setJobForm(__assign(__assign({}, jobForm), { comission: data.commission, comissionType: data.feeType }));
            }
        }
    }, [jobForm.appraiserId]);
    var handleInputChange = function (key, value) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j;
        if (key === "multi" && id) {
            return;
        }
        var extra = {};
        if (key === 'reportTypeId') {
            var feeSplitAmount = parseFloat(((_e = (_d = (_c = (_b = clients.find(function (c) { return +c.id === jobForm.clientId; })) === null || _b === void 0 ? void 0 : _b.tblClientFeesByClientId) === null || _c === void 0 ? void 0 : _c.nodes) === null || _d === void 0 ? void 0 : _d.find(function (c) { return +c.reportType === value; })) === null || _e === void 0 ? void 0 : _e.fee) || 0);
            extra = {
                feeSplitAmount: feeSplitAmount,
            };
        }
        else if (key === 'clientId') {
            var feeSplitAmount = parseFloat(((_j = (_h = (_g = (_f = clients.find(function (c) { return +c.id === value; })) === null || _f === void 0 ? void 0 : _f.tblClientFeesByClientId) === null || _g === void 0 ? void 0 : _g.nodes) === null || _h === void 0 ? void 0 : _h.find(function (c) { return +c.reportType === jobForm.reportTypeId; })) === null || _j === void 0 ? void 0 : _j.fee) || 0);
            extra = {
                feeSplitAmount: feeSplitAmount,
            };
        }
        setJobForm(__assign(__assign(__assign({}, jobForm), extra), (_a = {}, _a[key] = value, _a)));
    };
    var clientChanged = function (cId) {
        var retainerAmount = jobForm === null || jobForm === void 0 ? void 0 : jobForm.retainerAmount;
        if (cId && !id) {
            var clientValue = clients.find(function (c) { return +c.id === +cId; });
            setRetainerRequired(+((clientValue === null || clientValue === void 0 ? void 0 : clientValue.retainerFee) || '0'));
            if ((clientValue === null || clientValue === void 0 ? void 0 : clientValue.retainerRequired) && +((clientValue === null || clientValue === void 0 ? void 0 : clientValue.retainerFee) || '0')) {
                retainerAmount = (+jobForm.feeSplitAmount * (+(clientValue === null || clientValue === void 0 ? void 0 : clientValue.retainerFee))) / 100;
            }
            else {
                retainerAmount = 0;
            }
        }
        else {
            setRetainerRequired(0);
        }
        setJobForm(__assign(__assign({}, jobForm), { retainerAmount: retainerAmount, clientId: cId }));
    };
    var reportFeeChanged = function (e) {
        var _a, _b;
        if (retainerRequired > 0) {
            setJobForm(__assign(__assign({}, jobForm), { retainerAmount: (+((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.replace(/,/g, '')) * (retainerRequired)) / 100 }));
        }
    };
    var loadOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loadOrderById(orderId, apolloClient)];
                case 1:
                    order = _a.sent();
                    setJobForm(__assign(__assign({}, order), { jobTypeId: 2, comissionType: 0, comission: 0, feeSplitAmount: 0, feeSplitPercent: 0, hst: tax, zzCreatedBy: getUserName() }));
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (orderId) {
            loadOrder();
        }
    }, [orderId]);
    var getById = useLazyQuery(GETJOBBYID, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var jobExpenses, expenseIds;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
            return __generator(this, function (_7) {
                console.log('getById', data);
                jobExpenses = (((_b = (_a = data.tblJobById) === null || _a === void 0 ? void 0 : _a.tblExpensesByJobId) === null || _b === void 0 ? void 0 : _b.nodes) || []).filter(function (c) { return c.tblOptionByOptionId; }).map(function (eps) {
                    var _a;
                    return (_a = {},
                        _a["value" + eps.tblOptionByOptionId.id] = eps.value,
                        _a);
                });
                expenseIds = (((_d = (_c = data.tblJobById) === null || _c === void 0 ? void 0 : _c.tblExpensesByJobId) === null || _d === void 0 ? void 0 : _d.nodes) || []);
                if ((_e = data === null || data === void 0 ? void 0 : data.tblJobById) === null || _e === void 0 ? void 0 : _e.zzFullAddress) {
                    getActiveJobsByAddress({
                        variables: {
                            address: "%" + data.tblJobById.zzFullAddress + "%",
                            id: +(id || '0'),
                            country: getCountryName(data.tblJobById),
                        },
                    });
                }
                if (data.tblJobById.tblPropertiesByJobId.nodes.length > 0) {
                    setJobForm({
                        jobExpenses: jobExpenses,
                        expenseIds: expenseIds,
                        fileNumber: data.tblJobById.fileNumber,
                        fileNumberFormat: formatFileNumber(data.tblJobById.fileNumber, company),
                        jobTypeId: data.tblJobById.jobTypeId,
                        zzFullAddress: data.tblJobById.zzFullAddress,
                        streetName: data.tblJobById.streetName,
                        streetNo: data.tblJobById.streetNo,
                        unit: data.tblJobById.unit,
                        province: data.tblJobById.province,
                        postalCode: data.tblJobById.postalCode,
                        city: data.tblJobById.city,
                        clientId: data.tblJobById.clientId,
                        retainerAmount: data.tblJobById.retainerAmount,
                        multi: true,
                        portfolioName: data.tblJobById.portfolioName,
                        feeSplitAmount: data.tblJobById.feeSplitAmount || 0,
                        feeSplitPercent: data.tblJobById.feeSplitPercent || 0,
                        statusId: data.tblJobById.statusId,
                        pid: +data.tblJobById.tblPropertiesByJobId.nodes[0].id,
                        owner: data.tblJobById.owner,
                        expenses: data.tblJobById.expenses,
                        scopes: data.tblJobById.scopes,
                        reportTypeId: data.tblJobById.reportTypeId,
                        note: data.tblJobById.note || data.tblJobById.tblClientByClientId.note,
                        contactphone: data.tblJobById.contactphone || data.tblJobById.tblClientByClientId.phone,
                        contactname: data.tblJobById.contactname || data.tblJobById.tblClientByClientId.fullName,
                        jobNumber: data.tblJobById.jobNumber,
                        countryName: data.tblJobById.countryName,
                        appraiserId: ((_k = (_j = (_h = (_g = (_f = data.tblJobById) === null || _f === void 0 ? void 0 : _f.tblPropertyAppraisersByJobId) === null || _g === void 0 ? void 0 : _g.nodes) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.tblAppraiserById) === null || _k === void 0 ? void 0 : _k.id) || 0,
                        comission: ((_p = (_o = (_m = (_l = data.tblJobById) === null || _l === void 0 ? void 0 : _l.tblPropertyAppraisersByJobId) === null || _m === void 0 ? void 0 : _m.nodes) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.reportFee) || 0,
                        comissionType: ((_t = (_s = (_r = (_q = data.tblJobById) === null || _q === void 0 ? void 0 : _q.tblPropertyAppraisersByJobId) === null || _r === void 0 ? void 0 : _r.nodes) === null || _s === void 0 ? void 0 : _s[0]) === null || _t === void 0 ? void 0 : _t.feeType) || 0,
                    });
                    setOldStatusId(data.tblJobById.statusId);
                    setMultipleAddress({
                        addresses: data.tblJobById.tblPropertiesByJobId.nodes.map(function (ad) { return ({
                            id: +ad.id,
                            rowId: ad.tblPropertyAppraisersByPropertyId.nodes.length === 0 ? null : +ad.tblPropertyAppraisersByPropertyId.nodes[0].rowId,
                            appraiser: {
                                id: ad.tblPropertyAppraisersByPropertyId.nodes.length === 0 ? null : +ad.tblPropertyAppraisersByPropertyId.nodes[0].tblAppraiserById.id,
                                title: ad.tblPropertyAppraisersByPropertyId.nodes.length === 0 ? null : ad.tblPropertyAppraisersByPropertyId.nodes[0].tblAppraiserById.firstName + " " + ad.tblPropertyAppraisersByPropertyId.nodes[0].tblAppraiserById.lastName
                            },
                            fee: ad.tblPropertyAppraisersByPropertyId.nodes.length === 0 ? 0 : ad.tblPropertyAppraisersByPropertyId.nodes[0].reportFee,
                            address: {
                                label: ad.zctFullAddress,
                                name: ad.zctFullAddress,
                                houseNumber: ad.streetNo,
                                street: ad.streetName,
                                postalCode: ad.postalCode,
                                state: ad.province,
                                city: ad.city,
                                county: ad.county,
                                countryName: ad.countryName,
                                unit: ad.unit,
                            }
                        }); })
                    });
                }
                else {
                    setJobForm({
                        jobExpenses: jobExpenses,
                        expenseIds: expenseIds,
                        hst: data.tblJobById.hst,
                        fileNumber: data.tblJobById.fileNumber,
                        fileNumberFormat: formatFileNumber(data.tblJobById.fileNumber, company),
                        jobTypeId: data.tblJobById.jobTypeId,
                        streetName: data.tblJobById.streetName,
                        streetNo: data.tblJobById.streetNo,
                        unit: data.tblJobById.unit,
                        province: data.tblJobById.province,
                        postalCode: data.tblJobById.postalCode,
                        zzFullAddress: data.tblJobById.zzFullAddress,
                        city: data.tblJobById.city,
                        clientId: data.tblJobById.clientId,
                        county: data.tblJobById.county,
                        multi: false,
                        portfolioName: data.tblJobById.portfolioName,
                        feeSplitAmount: data.tblJobById.feeSplitAmount || 0,
                        feeSplitPercent: data.tblJobById.feeSplitPercent || 0,
                        statusId: data.tblJobById.statusId,
                        propertyTypeId: data.tblJobById.propertyType,
                        propertyType: data.tblJobById.propertyType,
                        propertySubType: data.tblJobById.propertySubType,
                        owner: data.tblJobById.owner,
                        delivery: data.tblJobById.delivery,
                        retainerAmount: data.tblJobById.retainerAmount,
                        function: data.tblJobById.function,
                        interest: data.tblJobById.interest,
                        valueType: data.tblJobById.valueType,
                        expenses: data.tblJobById.expenses,
                        scopes: data.tblJobById.scopes,
                        reportTypeId: data.tblJobById.reportTypeId,
                        note: data.tblJobById.note || data.tblJobById.tblClientByClientId.note,
                        contactphone: data.tblJobById.contactphone || data.tblJobById.tblClientByClientId.phone,
                        contactname: data.tblJobById.contactname || data.tblJobById.tblClientByClientId.fullName,
                        jobNumber: data.tblJobById.jobNumber,
                        countryName: data.tblJobById.countryName,
                        appraiserId: ((_y = (_x = (_w = (_v = (_u = data.tblJobById) === null || _u === void 0 ? void 0 : _u.tblPropertyAppraisersByJobId) === null || _v === void 0 ? void 0 : _v.nodes) === null || _w === void 0 ? void 0 : _w[0]) === null || _x === void 0 ? void 0 : _x.tblAppraiserById) === null || _y === void 0 ? void 0 : _y.id) || 0,
                        comission: ((_2 = (_1 = (_0 = (_z = data.tblJobById) === null || _z === void 0 ? void 0 : _z.tblPropertyAppraisersByJobId) === null || _0 === void 0 ? void 0 : _0.nodes) === null || _1 === void 0 ? void 0 : _1[0]) === null || _2 === void 0 ? void 0 : _2.reportFee) || 0,
                        comissionType: ((_6 = (_5 = (_4 = (_3 = data.tblJobById) === null || _3 === void 0 ? void 0 : _3.tblPropertyAppraisersByJobId) === null || _4 === void 0 ? void 0 : _4.nodes) === null || _5 === void 0 ? void 0 : _5[0]) === null || _6 === void 0 ? void 0 : _6.feeType) || 0,
                        lat: data.tblJobById.lat,
                        lng: data.tblJobById.lng,
                        jobDueDate: data.tblJobById.jobDueDate,
                    });
                }
                setOldStatusId(data.tblJobById.statusId);
                setAdditionalScope(data.tblJobById.scopes && data.tblJobById.scopes.length > 0);
                return [2 /*return*/];
            });
        }); }
    })[0];
    var saveJob = function () { return __awaiter(void 0, void 0, void 0, function () {
        var properties, jobId;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setValidate(true);
                    if (!((props === null || props === void 0 ? void 0 : props.onOrder) && !orderToJob)) return [3 /*break*/, 1];
                    props.onOrder(jobForm, attachment, ((_a = decription === null || decription === void 0 ? void 0 : decription.current) === null || _a === void 0 ? void 0 : _a.value) || "", apolloClient);
                    return [3 /*break*/, 3];
                case 1:
                    if (!jobForm.clientId || !(jobForm === null || jobForm === void 0 ? void 0 : jobForm.city)) {
                        return [2 /*return*/];
                    }
                    properties = (jobForm.multi ? (multipleAddress === null || multipleAddress === void 0 ? void 0 : multipleAddress.addresses) || (multipleAddress === null || multipleAddress === void 0 ? void 0 : multipleAddress.address) || [] : []);
                    Spinner.show();
                    return [4 /*yield*/, upsertJob(__assign(__assign({}, jobForm), { orderId: jobForm.orderId || orderId, jobExpenses: ((_b = formik.values) === null || _b === void 0 ? void 0 : _b.jobExpenses) || [] }), properties, id, additionalScope, attachment, ((_c = decription === null || decription === void 0 ? void 0 : decription.current) === null || _c === void 0 ? void 0 : _c.value) || "")(apolloClient)];
                case 2:
                    jobId = _d.sent();
                    dispatch(setAddSections(jobId));
                    Spinner.hide();
                    if (props.isModal) {
                        props.onDone();
                        PopupForm.close();
                    }
                    else {
                        history.push(JOB_VIEW_URL.replace('{id}', jobId));
                    }
                    _d.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getDocuemtns = useLazyQuery(GET_DOCUMENTS, {
        onCompleted: function (data) {
            setDocuments(data.allTblDocuments.nodes);
        }
    })[0];
    var getDocByRef = useLazyQuery(GET_DOCUMENTS_BY_TYPE, {
        onCompleted: function (data) {
            setDocuments(data.allTblDocuments.nodes);
        }
    })[0];
    var changeTabs = function (tabId) {
        setActiveStep(tabId);
        if (tabId === 3 && id) {
            getDocuemtns({
                variables: {
                    jobId: id,
                }
            });
        }
        if (tabId === 3 && orderId) {
            getDocByRef({
                variables: {
                    refId: orderId,
                    type: DOCUMENT_TYPE.ORDERS,
                }
            });
        }
    };
    var getNewFileNumber = function (force) {
        if (force === void 0) { force = false; }
        return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isOrder && !force)
                            return [2 /*return*/];
                        return [4 /*yield*/, getNextFileNumber(apolloClient)];
                    case 1:
                        data = _a.sent();
                        setJobForm(__assign(__assign({}, jobForm), { fileNumberFormat: formatFileNumber(data, company), fileNumber: +data, id: force ? null : jobForm.id }));
                        return [2 /*return*/];
                }
            });
        });
    };
    var convertToJob = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOrderToJob(true);
                    return [4 /*yield*/, getNewFileNumber(true)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (id) {
            getById({
                variables: {
                    id: id
                }
            });
        }
        else {
            getNewFileNumber();
        }
    }, [id]);
    useEffect(function () {
        if (!id && clients && (jobForm === null || jobForm === void 0 ? void 0 : jobForm.clientId)) {
            var cli = clients.find(function (c) { return c.id === +jobForm.clientId; });
            if (cli) {
                setJobForm(__assign(__assign({}, jobForm), { note: cli.note, contactphone: cli.phone, contactname: cli.fullName }));
            }
        }
    }, [jobForm === null || jobForm === void 0 ? void 0 : jobForm.clientId]);
    var canConvert = useMemo(function () { return !orderToJob && isOrder && canEdit && jobForm.status === ORDER_STATUS.CREATED; }, [orderToJob, isOrder, canEdit, jobForm.status]);
    return (_jsxs("div", __assign({ className: "content" }, { children: [_jsx(FormikProvider, __assign({ value: formik }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [((!isModal && canEdit) || isOrder) && (_jsx(Row, { children: _jsx(Col, { children: _jsxs(ListHeader, __assign({ href: isOrder ? ORDER_LIST : JOB_LIST, title: isOrder ? "Orders" : "Jobs", others: [isOrder ? 'Order Details' : 'Job Details'] }, { children: [_jsxs(Button, __assign({ disabled: (isOrder && orderId && jobForm.status !== ORDER_STATUS.CREATED) || (canEdit && !orderToJob && jobForm.status === ORDER_STATUS.CREATED), onClick: saveJob, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0),
                                        canConvert && _jsxs(Button, __assign({ onClick: convertToJob, color: "info", className: "btn btn-xs ml-16" }, { children: [_jsx("i", { className: "fa fa-share" }, void 0), " Convert to Job"] }), void 0)] }), void 0) }, void 0) }, void 0)),
                        _jsx(Card, __assign({ className: "border-0 mt-16" }, { children: _jsxs(CardBody, { children: [_jsxs("div", { children: [_jsxs(Row, __assign({ style: { display: 'none' } }, { children: [_jsx(Col, __assign({ xs: 4 }, { children: _jsx(InputText, __assign({ id: "outlined-select-currency", select: true, label: "Request Type", value: jobForm.jobTypeId, size: "small", onChange: function (e) { return handleInputChange("jobTypeId", +e.target.value); } }, { children: map(jobTypes, function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0) }), void 0),
                                                    !id ? (_jsxs(Col, __assign({ xs: 8, align: "right" }, { children: ["Single Property", _jsx(Switch, { checked: jobForm.multi, onChange: function (event) { return handleInputChange("multi", event.target.checked); }, color: "primary" }, void 0), " Multiple Properties"] }), void 0)) : (_jsx(Col, __assign({ xs: 8, className: "flex-end" }, { children: _jsx(InputText, __assign({ id: "outlined-select-status", select: true, label: "Status", value: jobForm.statusId, size: "small", onChange: function (e) { return handleInputChange("statusId", +e.target.value); } }, { children: map(jobStatuses, function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0) }), void 0))] }), void 0),
                                            !isOrder && _jsxs(Row, { children: [_jsx(Col, __assign({ xs: isMobile ? 6 : 2 }, { children: _jsx(InputText, { disabled: true, onChange: function (e) { return handleInputChange("fileNumberFormat", e.target.value); }, value: jobForm.fileNumberFormat, label: "File Number" }, void 0) }), void 0),
                                                    _jsx(Col, __assign({ xs: isMobile ? 6 : 2 }, { children: _jsx(InputText, { onChange: function (e) { return handleInputChange("jobNumber", e.target.value); }, value: jobForm.jobNumber, label: "Reference Number" }, void 0) }), void 0),
                                                    !isMobile && _jsx(Col, { xs: 4 }, void 0),
                                                    id && (_jsx(Col, __assign({ xs: 4, className: isMobile ? classes.topMargin : "flex-end" }, { children: _jsx(InputText, __assign({ id: "outlined-select-status", select: true, label: "Status", value: jobForm.statusId, size: "small", className: "min-w-150", onChange: function (e) { return handleInputChange("statusId", +e.target.value); } }, { children: map(jobStatuses, function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0) }), void 0))] }, void 0),
                                            _jsxs(Row, __assign({ className: "mt-24" }, { children: [!isOrder ? _jsxs(Col, __assign({ xs: isMobile ? 12 : 4, className: "flex" }, { children: [_jsx(ICAutoComplete, { options: clients, className: "no-padding-select w-100", value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.clientId, size: "small", error: !(jobForm === null || jobForm === void 0 ? void 0 : jobForm.clientId) && validate, label: "Client " + (activeJobs > 0 ? "(" + activeJobs + " active jobs)" : ''), onChange: function (event) {
                                                                    var _a;
                                                                    clientChanged(event.target.value);
                                                                    getActiveJobs({
                                                                        variables: {
                                                                            clientId: +(((_a = event.target) === null || _a === void 0 ? void 0 : _a.value) || '0'),
                                                                            id: +(id || '0'),
                                                                        }
                                                                    });
                                                                } }, void 0),
                                                            _jsx(Button, __assign({ onClick: function () { return ModalForm.show({ large: true, title: 'Add a new Client', className: 'modal-body-p-0', footer: false, body: (_jsx(ClientEdit, { modal: true }, void 0)) }); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0),
                                                            validate && !jobForm.clientId && (_jsx("div", __assign({ className: "form-error flex form-error-select" }, { children: "required*" }), void 0))] }), void 0) :
                                                        _jsx(Col, __assign({ xs: isMobile ? 12 : 2 }, { children: _jsx(InputText, { onChange: function (e) { return handleInputChange("jobNumber", e.target.value); }, value: jobForm.jobNumber, label: "Reference Number" }, void 0) }), void 0),
                                                    _jsxs(Col, __assign({ xs: isMobile ? 6 : 3, className: isMobile ? classes.topMargin : '' }, { children: [_jsx(ICAutoComplete, { options: (quoteOptions === null || quoteOptions === void 0 ? void 0 : quoteOptions.filter(function (c) { return c.optionType === SelectorName.PROPERTY_TYPE_SALES; })), value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.propertyType, size: "small", label: "Property Type", className: "no-padding-select", onChange: function (event) { handleInputChange("propertyType", event.target.value); } }, void 0), !isOrder && _jsx(Button, __assign({ onClick: function () { return addOptions(QuoteOptionType.PROPERTY_TYPE_SALES); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0)] }), void 0),
                                                    _jsxs(Col, __assign({ xs: isMobile ? 6 : 3, className: isMobile ? classes.topMargin : '' }, { children: [_jsx(ICAutoComplete, { options: (quoteOptions === null || quoteOptions === void 0 ? void 0 : quoteOptions.filter(function (c) { return c.optionType === SelectorName.PROPERTY_SUB_TYPE; })), value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.propertySubType, size: "small", label: "Property Sub Type", className: "no-padding-select", onChange: function (event) { handleInputChange("propertySubType", event.target.value); } }, void 0), !isOrder && _jsx(Button, __assign({ onClick: function () { return addOptions(QuoteOptionType.PROPERTY_SUB_TYPE); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0)] }), void 0),
                                                    _jsxs(Col, __assign({ xs: isMobile ? 12 : 2, className: isMobile ? classes.topMargin : '' }, { children: [_jsx(InputText, __assign({ id: "outlined-select-report", select: true, label: "Report Type", value: jobForm.reportTypeId, size: "small", fullWidth: true, onChange: function (e) { return handleInputChange("reportTypeId", +e.target.value); } }, { children: map(NEGATIVE_AUTO_COMPLETE_OPTION.concat(quoteOptions.filter(function (c) { return c.optionType === QuoteOptionType.REPORT_TYPE; })), function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0),
                                                            !isOrder && _jsx(Button, __assign({ onClick: function () { return addOptions(QuoteOptionType.REPORT_TYPE); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0)] }), void 0)] }), void 0),
                                            !isOrder && !isMobile && _jsxs(Row, __assign({ className: "mt-40 form-row-divider" }, { children: [_jsx(Col, __assign({ xs: 4 }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: " Address" }), void 0) }), void 0),
                                                    _jsx(Col, __assign({ xs: 2 }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: "Fees" }), void 0) }), void 0),
                                                    ((appraisers === null || appraisers === void 0 ? void 0 : appraisers.length) > 0) && (_jsx(Col, __assign({ xs: 4 }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: "Primary Appraiser" }), void 0) }), void 0)),
                                                    _jsx(Col, __assign({ xs: 2 }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: "Expenses" }), void 0) }), void 0)] }), void 0),
                                            !isOrder && _jsxs(Row, __assign({ className: classes.topMargin }, { children: [isMobile && _jsx(Col, __assign({ xs: 12, className: "mt-40 form-row-divider" }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: " Address" }), void 0) }), void 0),
                                                    _jsxs(Col, __assign({ xs: isMobile ? 12 : 4, className: "relative " + (isMobile ? 'mt-16' : '') }, { children: [((activeJobsAddress === null || activeJobsAddress === void 0 ? void 0 : activeJobsAddress.totalCount) > 0) && (_jsxs("div", __assign({ onClick: showActiveJobsAddress, className: "error red underline", style: { position: 'absolute', right: 0, zIndex: 2 } }, { children: ["(", _jsx("b", { children: (activeJobsAddress === null || activeJobsAddress === void 0 ? void 0 : activeJobsAddress.totalCount) || 0 }, void 0), ") active job(s)"] }), void 0)),
                                                            _jsx(AddressComponent, { vertical: true, multipleAddress: false, className: "job-address", form: jobForm, error: validate && !(jobForm === null || jobForm === void 0 ? void 0 : jobForm.city), setForm: setJobForm, onChange: onAddressChanged }, void 0)] }), void 0),
                                                    isMobile && _jsx(Col, __assign({ xs: 12, className: "mt-40 form-row-divider" }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: "Fees" }), void 0) }), void 0),
                                                    _jsx(Col, __assign({ xs: isMobile ? 12 : 2, className: isMobile ? 'mt-16' : '' }, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ xs: 8 }, { children: _jsx(InputText, { prepend: "$", type: "number", onBlur: reportFeeChanged, InputProps: true, onChange: function (e) { return handleInputChange("feeSplitAmount", e.target.value); }, size: "sm", value: jobForm.feeSplitAmount, label: "Report Fee" }, void 0) }), void 0),
                                                                _jsx(Col, __assign({ xs: 8, className: "mt-16" }, { children: _jsx(InputText, { prepend: "%", type: "number", InputProps: true, onChange: function (e) { return handleInputChange("hst", e.target.value); }, size: "sm", value: jobForm.hst, label: "HST" }, void 0) }), void 0),
                                                                _jsx(Col, __assign({ xs: 8, className: "mt-16" }, { children: _jsx(InputText, { prepend: "$", type: "number", step: "0.01", InputProps: true, onChange: function (e) { return handleInputChange("retainerAmount", e.target.value); }, size: "sm", value: jobForm.retainerAmount, label: "Retainer Amount" }, void 0) }), void 0),
                                                                _jsx(Col, __assign({ xs: 12, className: "mt-16" }, { children: _jsx(InputText, { className: "min-h-29", onChange: function (e) { return handleInputChange("delivery", e.target.value); }, size: "small", value: jobForm.delivery, label: "Delivery Timeline" }, void 0) }), void 0),
                                                                _jsx(Col, __assign({ xs: 12, className: "mt-16" }, { children: _jsx(ReactDatePicker, { block: true, label: "Due Date", selected: jobForm.jobDueDate ? toDate(jobForm.jobDueDate) : '', onChange: handleFormDateChanged(setJobForm, jobForm, "jobDueDate") }, void 0) }), void 0)] }, void 0) }), void 0),
                                                    (appraisers === null || appraisers === void 0 ? void 0 : appraisers.length) > 0 && (_jsxs(_Fragment, { children: [isMobile && _jsx(Col, __assign({ xs: 12, className: "mt-40 form-row-divider" }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: "Primary Appraiser" }), void 0) }), void 0),
                                                            _jsx(Col, __assign({ xs: isMobile ? 12 : 4, className: isMobile ? 'mt-16' : '' }, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ xs: 12 }, { children: _jsx(ICAutoComplete, { options: appraisers, className: "no-padding-select", size: "small", disabled: id, label: "Appraiser", value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.appraiserId, onChange: function (event) { var _a; handleInputChange("appraiserId", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value); } }, void 0) }), void 0),
                                                                        _jsx(Col, __assign({ xs: 3, className: "mt-16" }, { children: _jsxs("div", __assign({ className: "align-items-bottom" }, { children: [_jsx(InputText, { onChange: function (e) { var _a; return handleInputChange('comission', (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value); }, type: "number", size: "small", value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.comission, label: "Commission" }, void 0),
                                                                                    _jsxs(InputText, __assign({ select: true, label: "", className: "border-free", value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.comissionType, size: "small", onChange: function (e) {
                                                                                            var _a;
                                                                                            handleInputChange('comissionType', (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
                                                                                        } }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "%" }), void 0),
                                                                                            _jsx(MenuItem, __assign({ value: 1 }, { children: "$" }), void 0)] }), void 0)] }), void 0) }), void 0),
                                                                        _jsx(Col, __assign({ xs: 12, className: "mt-16" }, { children: _jsx(InputText, { type: "text", multiline: true, fullWidth: true, rows: 3, onChange: function (e) { return handleInputChange("expenses", e.target.value); }, size: "small", value: jobForm.expenses, label: "Expenses" }, void 0) }), void 0)] }, void 0) }), void 0)] }, void 0)),
                                                    isMobile && _jsx(Col, __assign({ xs: 12, className: "mt-40 form-row-divider" }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: " Expenses" }), void 0) }), void 0),
                                                    _jsx(Col, __assign({ xs: isMobile ? 12 : 2, className: isMobile ? 'mt-16' : '' }, { children: _jsx(Row, { children: _jsx(JobExpense, { addNew: addNewExpense, xs: 12, mt: "", fieldName: 'jobExpenses', form: jobForm, quoteOptions: quoteOptions, handleChange: formik.handleChange }, void 0) }, void 0) }), void 0)] }), void 0),
                                            !isMobile && _jsxs(Row, __assign({ className: "mt-40 bb-dotte form-row-divider" }, { children: [isOrder && _jsx(Col, __assign({ xs: 4 }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: " Address" }), void 0) }), void 0),
                                                    _jsx(Col, __assign({ xs: isOrder ? 8 : 12 }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: " Property Details" }), void 0) }), void 0)] }), void 0),
                                            _jsxs(Row, __assign({ className: classes.topMargin }, { children: [isOrder &&
                                                        _jsxs(_Fragment, { children: [isMobile && _jsx(Col, __assign({ xs: 12, className: "mt-40 form-row-divider" }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: " Address" }), void 0) }), void 0),
                                                                _jsxs(Col, __assign({ xs: isMobile ? 12 : 4, className: "relative " + (isMobile ? classes.topMargin : '') }, { children: [((activeJobsAddress === null || activeJobsAddress === void 0 ? void 0 : activeJobsAddress.totalCount) > 0) && (_jsxs("div", __assign({ onClick: showActiveJobsAddress, className: "error red underline", style: { position: 'absolute', right: 0, zIndex: 2 } }, { children: ["(", _jsx("b", { children: (activeJobsAddress === null || activeJobsAddress === void 0 ? void 0 : activeJobsAddress.totalCount) || 0 }, void 0), ") active job(s)"] }), void 0)),
                                                                        _jsx(AddressComponent, { vertical: true, multipleAddress: false, className: "job-address", form: jobForm, error: validate && !(jobForm === null || jobForm === void 0 ? void 0 : jobForm.city), setForm: setJobForm, onChange: onAddressChanged }, void 0)] }), void 0)] }, void 0),
                                                    jobForm.multi && (_jsx(Col, __assign({ xs: 4 }, { children: _jsx(InputText, { onChange: function (e) { return handleInputChange("portfolioName", e.target.value); }, value: jobForm.portfolioName, label: "Portfolio/Project Name" }, void 0) }), void 0)),
                                                    isMobile && _jsx(Col, __assign({ xs: 12, className: "mt-40 form-row-divider" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Property Details" }), void 0) }), void 0),
                                                    _jsxs(Col, __assign({ xs: jobForm.multi ? 4 : 8, className: isMobile ? classes.topMargin : '' }, { children: [_jsx(InputText, { type: "text", onChange: function (e) { return handleInputChange("owner", e.target.value); }, fullWidth: true, value: jobForm.owner, label: "Owner" }, void 0),
                                                            isOrder && _jsx("div", __assign({ className: "db mt-16" }, { children: _jsx(ReactDatePicker, { block: true, label: "Due Date", selected: jobForm.jobDueDate ? toDate(jobForm.jobDueDate) : '', onChange: handleFormDateChanged(setJobForm, jobForm, "jobDueDate") }, void 0) }), void 0)] }), void 0)] }), void 0),
                                            (jobForm.jobTypeId === JobTypes.QUOTE || isOrder || orderToJob) && (_jsxs(Row, __assign({ className: "mt-32" }, { children: [_jsxs(Col, __assign({ xs: isMobile ? 6 : 4 }, { children: [_jsx(ICAutoComplete, { options: (quoteOptions.filter(function (c) { return c.optionType === QuoteOptionType.INTEREST; })), value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.interest, size: "small", label: "Property Interest", className: "no-padding-select", onChange: function (event) { handleInputChange("interest", event.target.value); } }, void 0), !isOrder && _jsx(Button, __assign({ onClick: function () { return addOptions(QuoteOptionType.INTEREST); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0)] }), void 0),
                                                    _jsxs(Col, __assign({ xs: isMobile ? 6 : 4 }, { children: [_jsx(ICAutoComplete, { options: (quoteOptions.filter(function (c) { return c.optionType === QuoteOptionType.PURPOS; })), value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.valueType, size: "small", label: "Purpose", className: "no-padding-select", onChange: function (event) { handleInputChange("valueType", event.target.value); } }, void 0), !isOrder && _jsx(Button, __assign({ onClick: function () { return addOptions(QuoteOptionType.PURPOS); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0)] }), void 0),
                                                    _jsxs(Col, __assign({ xs: isMobile ? 12 : 4, className: isMobile ? classes.topMargin : '' }, { children: [_jsx(ICAutoComplete, { options: quoteOptions.filter(function (c) { return c.optionType === QuoteOptionType.FUNCTION; }), value: jobForm === null || jobForm === void 0 ? void 0 : jobForm.function, size: "small", label: "Intended use", className: "no-padding-select", onChange: function (event) { handleInputChange("function", event.target.value); } }, void 0), !isOrder && _jsx(Button, __assign({ onClick: function () { return addOptions(QuoteOptionType.FUNCTION); }, className: "float-button btn btn-xxs btn-link text-blue" }, { children: _jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0) }), void 0)] }), void 0)] }), void 0)),
                                            !isOrder && _jsxs(Row, __assign({ className: "mt-32 mb-32" }, { children: [_jsxs(Col, __assign({ xs: isMobile ? 12 : 3 }, { children: [_jsx("label", __assign({ className: "input-text-label" }, { children: "Additional Scope Required?" }), void 0),
                                                            _jsx(Switch, { checked: additionalScope, onChange: function (e) { return setAdditionalScope(e.target.checked); }, color: "primary", size: "small" }, void 0)] }), void 0),
                                                    _jsx(Col, __assign({ xs: isMobile ? 12 : 9 }, { children: _jsx(InputText, { fullWidth: true, disabled: !additionalScope, type: "text", onChange: function (e) { return handleInputChange("scopes", e.target.value); }, value: jobForm.scopes, label: "Additional Scope" }, void 0) }), void 0)] }), void 0)] }, void 0),
                                    _jsxs(Tabs, __assign({ value: activeStep, onChange: function (e, newValue) { return changeTabs(newValue); }, indicatorColor: "primary", textColor: "primary", variant: isMobile ? "scrollable" : "standard", className: "pt-40 mlr--15" }, { children: [jobForm.multi && (_jsx(Tab, { value: 4, label: "Addresses" }, void 0)),
                                            _jsx(Tab, { value: 5, label: "Inspection Contact Info" }, void 0),
                                            !isOrder && renderTabs.map(function (tab) { return (_jsx(Tab, { value: (+tab.id) * 10, label: tab.title }, "tab_" + tab.id)); }),
                                            !appModule.inactive.includes(APP_PACKAGES.NOTE) && _jsx(Tab, { value: 3, label: "Attachments" }, void 0)] }), void 0),
                                    _jsx("div", __assign({ style: { display: activeStep === 4 ? 'block' : 'none' } }, { children: _jsx(PropertyAddress, { form: multipleAddress, setForm: setMultipleAddress }, void 0) }), void 0),
                                    _jsxs("div", __assign({ className: activeStep === 5 ? 'block pd-32' : 'none' }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsx(InputText, { onChange: function (e) { return handleInputChange("contactname", e.target.value); }, fullWidth: true, value: jobForm.contactname, label: "Contact Name" }, void 0) }, void 0),
                                                    _jsx(Col, { children: _jsx(InputText, { onChange: function (e) { return handleInputChange("contactphone", e.target.value); }, fullWidth: true, value: jobForm.contactphone, label: "Contact Phone" }, void 0) }, void 0)] }, void 0),
                                            _jsx(Row, __assign({ className: classes.topMargin }, { children: _jsx(Col, { children: _jsx(InputText, { onChange: function (e) { return handleInputChange("note", e.target.value); }, fullWidth: true, value: jobForm.note, label: "Note" }, void 0) }, void 0) }), void 0)] }), void 0),
                                    renderTabs.map(function (section) { return (_jsx("div", __assign({ className: activeStep === (+section.id) * 10 ? '' : 'none' }, { children: _jsx(PropertySections, { canEdit: canEdit, table: SYSTEM_TABLES.JOB, sectionId: +section.id, jobId: id }, void 0) }), "prop_section_" + section.id)); }),
                                    activeStep === 3 && (_jsx("div", __assign({ className: "pd-32" }, { children: id ? (_jsx(Note, { jobId: id }, void 0)) : (_jsxs(Row, { children: [_jsxs(Col, __assign({ xs: 8 }, { children: [_jsx(DropzoneArea, { onChange: handleUpload, filesLimit: 5, initialFiles: attachment, showFileNamesInPreview: true, showFileNames: true, showAlerts: ['error'], maxFileSize: +(process.env.REACT_APP_UPLOAD_SIZE || '20') }, void 0),
                                                        _jsx("br", {}, void 0),
                                                        _jsx(InputText, { inputRef: decription, fullWidth: true, size: "small", label: "Description" }, void 0),
                                                        _jsx("br", {}, void 0),
                                                        id && (_jsxs(Button, __assign({ onClick: function () { return uploadFiles(false); }, className: "right-align", color: "primary" }, { children: [_jsx(CloudUploadIcon, {}, void 0), " Save Files"] }), void 0))] }), void 0),
                                                _jsx(Col, __assign({ xs: 4 }, { children: _jsx(List, { children: map(documents, function (doc, index) {
                                                            var _a;
                                                            return (_jsxs(React.Fragment, { children: [_jsx(ListItem, { children: _jsx(ListItemText, { secondary: _jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsxs(Typography, __assign({ variant: "subtitle2", className: "db black" }, { children: [_jsx(AccountCircleIcon, {}, void 0), " ", doc.zzCreatedBy] }), void 0) }, void 0),
                                                                                            _jsx(Col, __assign({ align: "right" }, { children: _jsx(Typography, __assign({ variant: "caption", className: "db" }, { children: formatDate(doc.zzCreatedDate, "MMM dd,yyyy HH:mm:ss") }), void 0) }), void 0)] }, void 0),
                                                                                    _jsx(Typography, __assign({ variant: "subtitle2", className: "flex mt-8" }, { children: _jsxs("span", __assign({ className: "font-normal" }, { children: [" ", doc.description] }), void 0) }), void 0),
                                                                                    _jsx("div", __assign({ className: "db right" }, { children: map(((_a = doc.tblFilesByDocumentId) === null || _a === void 0 ? void 0 : _a.nodes) || [], function (file, index) { return (_jsx(FileExtension, { className: "dib ml-8", fileName: file.fileName, onClick: function () { return showFiles(file); } }, "file_" + index)); }) }), void 0)] }, void 0) }, void 0) }, void 0),
                                                                    _jsx(Divider, {}, void 0)] }, index));
                                                        }) }, void 0) }), void 0)] }, void 0)) }), void 0))] }, void 0) }), void 0),
                        isModal && (_jsx("input", { onClick: saveJob, id: "save-job-details", className: "hidden" }, void 0))] }), void 0) }), void 0),
            _jsx(OptionManagerModal, { open: open, optionType: optionType }, void 0)] }), void 0));
};
