var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useMemo } from "react";
import { Row, Col, Button } from "reactstrap";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { Table } from "../../ui-components/table/table.component";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { CustomDatePicker } from "../../ui-components/date-picker/Custom-Date-Picker";
import { CUSTOMDATEPICKER } from "../../ui-components/date-picker/Date.enum";
import { getThisMonth } from "@utils/date";
import { MenuItem, Tooltip, Typography } from "@material-ui/core";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { useDispatch, useSelector } from "react-redux";
import { getAppraisers } from "../../stores/client.selector";
import { Columns, HistoryColumns } from "../../graphqls/accounting/payroll.query";
import { getTableData } from "../../stores/table.selector";
import { createPayroll, getPayrollHistory, getPayrollList, getPayrolls } from "../../services/payment.service";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { GetUserId, isAppraiser } from "../../services/user-service";
import { clearTableData } from "../../stores/table.reducer";
import { getAppModules } from "../../stores/app.selector";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { isMobile } from "react-device-detect";
export var Payroll = function (_a) {
    var props = __rest(_a, []);
    var apolloClient = useApolloQuery()[0];
    var appraisers = useSelector(getAppraisers);
    var appModule = useSelector(getAppModules);
    var lockAppraiser = props.appId || isAppraiser();
    var id = ((props === null || props === void 0 ? void 0 : props.id) ? props : (props.match ? props.match.params : { id: undefined })).id;
    var tableData = useSelector(getTableData);
    var _b = useState({ start: getThisMonth()[0], end: getThisMonth()[1] }), date = _b[0], setDate = _b[1];
    var _c = useState(0), appId = _c[0], setAppId = _c[1];
    var _d = useState([]), data = _d[0], setData = _d[1];
    var _e = useState([]), payrolls = _e[0], setPayrolls = _e[1];
    var _f = useState([]), selectedJobs = _f[0], setSelectedJobs = _f[1];
    var dispatch = useDispatch();
    var getData = useMemo(function () { return data; }, [data]);
    var goBack = function () {
        props.goBack && props.goBack();
    };
    var appChanged = function (ev) {
        var _a;
        setSelectedJobs([]);
        setAppId(+((_a = ev.target) === null || _a === void 0 ? void 0 : _a.value));
    };
    var onDateChanged = function (ev) {
        setDate({
            start: ev[0],
            end: ev[1]
        });
    };
    var rowSelectionChanged = function (id) {
        setSelectedJobs(id);
    };
    var generatePayroll = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, createPayroll(tableData, selectedJobs, date.start, date.end, appId)(apolloClient)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, loadData()];
                case 2:
                    _a.sent();
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var columns = useMemo(function () { return !lockAppraiser ? Columns : HistoryColumns; }, [lockAppraiser]);
    var loadUserPayroll = function (date) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPayrollHistory(date.start, date.end, props.appId || GetUserId())(apolloClient)];
                case 1:
                    data = _a.sent();
                    setData(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPayrolls(date.start, date.end, appId)(apolloClient)];
                case 1:
                    data = _a.sent();
                    setData(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var periodChanged = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var date;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    date = payrolls.find(function (p) { return p.i === +e.target.value; });
                    return [4 /*yield*/, loadUserPayroll({ start: date.startdate, end: date.enddate })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var allPeriods = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPayrollList(props.appId || GetUserId())(apolloClient)];
                case 1:
                    data = _a.sent();
                    setPayrolls(data.map(function (d, i) { return (__assign(__assign({}, d), { i: i })); }));
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (id) {
            loadUserPayroll({
                start: id.startDate,
                end: id.endDate,
            });
        }
    }, [id]);
    useEffect(function () {
        dispatch(clearTableData());
        if (lockAppraiser) {
            allPeriods();
        }
        else {
            loadData();
        }
    }, [date, appId, lockAppraiser]);
    return (_jsxs("div", __assign({ className: "content" }, { children: [!id && (_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(ListHeader, { title: "Payroll" }, void 0) }), void 0) }, void 0)),
            selectedJobs.length > 0 && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(Button, __assign({ onClick: generatePayroll, className: "btn btn-xs mr-16", color: "info" }, { children: "Create Payroll" }), void 0) }), void 0) }), void 0)),
            isMobile && id && _jsx(Row, __assign({ className: "mt-8" }, { children: _jsxs(Col, __assign({ xs: 12, className: "flex gap-16 flex-start mt-16" }, { children: [_jsx(Tooltip, __assign({ title: "Back to the List" }, { children: _jsx(KeyboardBackspaceIcon, { onClick: goBack, className: "pointer" }, void 0) }), void 0), "  ", _jsxs(Typography, __assign({ variant: "subtitle2" }, { children: [" ", id.startDate, " To ", id.endDate] }), void 0)] }), void 0) }), void 0),
            isMobile && !lockAppraiser &&
                _jsxs(Row, __assign({ className: "mt-8" }, { children: [_jsxs(Col, __assign({ xs: 12, className: "mt-16" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Select" }), void 0),
                                _jsxs(InputText, __assign({ onChange: appChanged, className: "min-w-100 bg-white", select: true, size: "small", defaultValue: 0 }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "-- Select --" }), void 0),
                                        _jsx(MenuItem, __assign({ value: -1 }, { children: "-- ALL --" }), void 0),
                                        appraisers === null || appraisers === void 0 ? void 0 : appraisers.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.title }), void 0)); })] }), void 0)] }), void 0),
                        _jsxs(Col, __assign({ xs: 12, className: "mt-16" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Date" }), void 0),
                                _jsx(CustomDatePicker, { defaultValue: CUSTOMDATEPICKER.THIS_MONTH, onChange: onDateChanged }, void 0)] }), void 0)] }), void 0),
            _jsx(Table, __assign({ offlineData: getData, customAction: null, action: null, headers: columns, showAllData: true, selection: !lockAppraiser && !id, rowSelectionChanged: rowSelectionChanged, pKey: "jobid", exportToExcel: true, groupBy: appId === -1 ? 'appraiser' : '' }, { children: !id ? (_jsx("div", __assign({ className: "flex gap-16 flex-start" }, { children: !lockAppraiser && !isMobile ? (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Select" }), void 0),
                            _jsxs(InputText, __assign({ onChange: appChanged, className: "min-w-100 bg-white", select: true, size: "small", defaultValue: 0 }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "-- Select --" }), void 0),
                                    _jsx(MenuItem, __assign({ value: -1 }, { children: "-- ALL --" }), void 0),
                                    appraisers === null || appraisers === void 0 ? void 0 : appraisers.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.title }), void 0)); })] }), void 0),
                            _jsx(Typography, __assign({ variant: "caption" }, { children: "Date" }), void 0),
                            _jsx(CustomDatePicker, { defaultValue: CUSTOMDATEPICKER.THIS_MONTH, onChange: onDateChanged }, void 0)] }, void 0)) : (isMobile ? null : _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Payroll Period" }), void 0),
                            _jsx(InputText, __assign({ onChange: periodChanged, className: "min-w-300 bg-white", select: true, size: "small" }, { children: payrolls === null || payrolls === void 0 ? void 0 : payrolls.map(function (o, index) { return (_jsxs(MenuItem, __assign({ value: o.i }, { children: [o.startdate, " To ", o.enddate] }), "period_" + index)); }) }), void 0)] }, void 0)) }), void 0)) : (isMobile ? null : _jsxs("div", __assign({ className: "flex gap-16 flex-start" }, { children: [_jsx(Tooltip, __assign({ title: "Back to the List" }, { children: _jsx(KeyboardBackspaceIcon, { onClick: goBack, className: "pointer" }, void 0) }), void 0), "  ", _jsxs(Typography, __assign({ variant: "subtitle2" }, { children: [" ", id.startDate, " To ", id.endDate] }), void 0)] }), void 0)) }), void 0)] }), void 0));
};
