var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createAction, createReducer } from '@reduxjs/toolkit';
export var appCaptureModal = createAction('APP-captureModal');
export var setOpenDrawer = createAction('APP-OPEN-DRAWER');
export var setAddInvoice = createAction('APP-OPEN-INVOICE');
export var setAddPayment = createAction('APP-OPEN-PAYMENT');
export var addPropertyType = createAction('APP-ADD-PROPERTY_TYPE');
export var setFieldsSections = createAction('APP-SET-FIELD-SECTIONS');
export var setAddSections = createAction('APP-OPEN-SECTIONS');
export var addFieldSelect = createAction('APP-ADD-FIELD-SEELCT');
export var updateFieldSelectStore = createAction('APP-UPDATE-FIELD-SEELCT');
export var removeFieldSelectStore = createAction('APP-REMOVE-FIELD-SEELCT');
export var setAdjustmentItems = createAction('APP-SET-ADJUSTMENT-ITEMS');
export var addAdjustmentItems = createAction('APP-SET-ADD-ADJUSTMENT-ITEMS');
export var removeAdjustmentItems = createAction('APP-SET-REMOVE-ADJUSTMENT-ITEMS');
export var editAdjustmentItems = createAction('APP-SET-EDIT-ADJUSTMENT-ITEMS');
export var globalMessage = createAction('APP-SHOW-GLOBAL-MESSAGE');
export var setSidebarLeft = createAction('APP-SET-SIDEBAR-LEFT');
export var setNeighbors = createAction('APP-SET-NEIGHBORS');
export var toggleSidebarWidth = createAction('APP-TOGGLE-SIDEBAR-WIDTH');
export var jobInEditMode = createAction('APP-JOB-EDIT-MODE');
export var addNewSectionField = createAction('addNewSectionField');
export var addNewField = createAction('addNewField');
export var setAppModules = createAction('setAppModules');
export var appReducer = createReducer(null, function (builder) {
    builder
        .addCase(jobInEditMode, function (state, action) {
        return __assign(__assign({}, state), { jobInEditMode: action.payload });
    })
        .addCase(setSidebarLeft, function (state, action) {
        return __assign(__assign({}, state), { sideBarLeft: action.payload });
    })
        .addCase(setAppModules, function (state, action) {
        return __assign(__assign({}, state), { appModules: action.payload });
    })
        .addCase(globalMessage, function (state, action) {
        return __assign(__assign({}, state), { globalMessage: action.payload });
    })
        .addCase(toggleSidebarWidth, function (state, action) {
        return __assign(__assign({}, state), { sidebarWidth: action.payload });
    })
        .addCase(setNeighbors, function (state, action) {
        return __assign(__assign({}, state), { neighbors: action.payload });
    }).addCase(appCaptureModal, function (state, action) {
        return __assign(__assign({}, state), { optionType: action.payload });
    }).addCase(setOpenDrawer, function (state, action) {
        return __assign(__assign({}, state), { drawerStatus: action.payload });
    }).addCase(setAddInvoice, function (state, action) {
        return __assign(__assign({}, state), { triggerInvoice: action.payload });
    }).addCase(setAddPayment, function (state, action) {
        return __assign(__assign({}, state), { triggerPayment: action.payload });
    }).addCase(addPropertyType, function (state, action) {
        return __assign(__assign({}, state), { callAddPropertyType: action.payload });
    }).addCase(setFieldsSections, function (state, action) {
        return __assign(__assign({}, state), { fieldSection: action.payload });
    }).addCase(addNewSectionField, function (state, action) {
        var _a, _b;
        return __assign(__assign({}, state), { fieldSection: __assign(__assign({}, state.fieldSection), { sectionFields: (((_a = state.fieldSection) === null || _a === void 0 ? void 0 : _a.sectionFields) || []).concat(action.payload), fields: (((_b = state.fieldSection) === null || _b === void 0 ? void 0 : _b.fields) || []).concat({
                    defaultValue: null,
                    id: action.payload.fieldId,
                    title: action.payload.customTitle,
                    type: 1,
                    tblFieldSelectsByFieldId: {
                        nodes: [],
                    },
                }) }) });
    }).addCase(addNewField, function (state, action) {
        var _a, _b;
        return __assign(__assign({}, state), { fieldSection: __assign(__assign({}, state.fieldSection), { sectionFields: (((_a = state.fieldSection) === null || _a === void 0 ? void 0 : _a.sectionFields) || []).concat(action.payload), fields: (((_b = state.fieldSection) === null || _b === void 0 ? void 0 : _b.fields) || []).concat(__assign({}, action.payload.tblFieldByFieldId)), fieldOption: (state.fieldSection.fieldOption || []).concat(action.payload.tblFieldByFieldId.tblFieldSelectsByFieldId.nodes) }) });
    }).addCase(setAddSections, function (state, action) {
        return __assign(__assign({}, state), { triggerAddSection: action.payload });
    }).addCase(addFieldSelect, function (state, action) {
        return __assign(__assign({}, state), { fieldSection: __assign(__assign({}, state.fieldSection), { fieldOption: (state.fieldSection.fieldOption || []).concat(action.payload) }) });
    }).addCase(updateFieldSelectStore, function (state, action) {
        return __assign(__assign({}, state), { fieldSection: __assign(__assign({}, state.fieldSection), { fieldOption: (state.fieldSection.fieldOption || []).reduce(function (result, curr) {
                    if (+curr.id === +action.payload.id) {
                        result.push(__assign(__assign({}, curr), { option: action.payload.title }));
                    }
                    else {
                        result.push(curr);
                    }
                    return result;
                }, []) }) });
    }).addCase(removeFieldSelectStore, function (state, action) {
        return __assign(__assign({}, state), { fieldSection: __assign(__assign({}, state.fieldSection), { fieldOption: (state.fieldSection.fieldOption || []).filter(function (c) { return +c.id !== +action.payload.id; }) }) });
    }).addCase(setAdjustmentItems, function (state, action) {
        return __assign(__assign({}, state), { adjustmentItems: ((state === null || state === void 0 ? void 0 : state.adjustmentItems) || []).concat(action.payload) });
    }).addCase(addAdjustmentItems, function (state, action) {
        return __assign(__assign({}, state), { adjustmentItems: (state.adjustmentItems || []).map(function (c) {
                var _a;
                if (+c.id !== +action.payload.headerId) {
                    return __assign({}, c);
                }
                else {
                    return __assign(__assign({}, c), { tblAdjDescriptionsByHeaderId: {
                            nodes: (((_a = c === null || c === void 0 ? void 0 : c.tblAdjDescriptionsByHeaderId) === null || _a === void 0 ? void 0 : _a.nodes) || []).concat(action.payload),
                        } });
                }
            }) });
    }).addCase(removeAdjustmentItems, function (state, action) {
        return __assign(__assign({}, state), { adjustmentItems: (state.adjustmentItems || []).map(function (c) {
                var _a;
                return (__assign(__assign({}, c), { tblAdjDescriptionsByHeaderId: {
                        nodes: (((_a = c === null || c === void 0 ? void 0 : c.tblAdjDescriptionsByHeaderId) === null || _a === void 0 ? void 0 : _a.nodes) || []).filter(function (c) { return +c.id !== +action.payload; }),
                    } }));
            }) });
    }).addCase(editAdjustmentItems, function (state, action) {
        return __assign(__assign({}, state), { adjustmentItems: (state.adjustmentItems || []).map(function (c) {
                var _a;
                return (__assign(__assign({}, c), { tblAdjDescriptionsByHeaderId: {
                        nodes: (((_a = c === null || c === void 0 ? void 0 : c.tblAdjDescriptionsByHeaderId) === null || _a === void 0 ? void 0 : _a.nodes) || []).reduce(function (result, curr) {
                            if (+curr.id === +action.payload.id) {
                                result.push(__assign(__assign({}, curr), { option: action.payload.option }));
                            }
                            else {
                                result.push(__assign({}, curr));
                            }
                            return result;
                        }, [])
                    } }));
            }) });
    });
});
