var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import GoogleMapReact from 'google-map-react';
import { formatDate } from "@utils/date";
import SuperClusterAdapterLoader from 'supercluster-googlemaps-adapter';
import { JOB_VIEW_URL, SALES_DETAILS } from "../../routes";
import { correctMeasure, formatNumbers } from "@utils/text";
import { formatTotalAsCurrency } from "../../helpers/numbers-helper.helper";
import { StatusEnum } from "@enums";
var defaultLat = 59.955413;
export var GoogleMap = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.height, height = _f === void 0 ? '100vh' : _f, _g = _a.width, width = _g === void 0 ? '100%' : _g, _h = _a.data, data = _h === void 0 ? [] : _h, _j = _a.zoom, zoom = _j === void 0 ? 16 : _j, _k = _a.mapTypeControl, mapTypeControl = _k === void 0 ? true : _k, _l = _a.displayInfo, displayInfo = _l === void 0 ? true : _l, _m = _a.showMarkers, showMarkers = _m === void 0 ? true : _m, _o = _a.polygon, polygon = _o === void 0 ? false : _o, _p = _a.mapTypeId, mapTypeId = _p === void 0 ? 'roadmap' : _p, labels = _a.labels, _q = _a.sales, sales = _q === void 0 ? false : _q, _r = _a.searchLocation, searchLocation = _r === void 0 ? false : _r, onClickOnMap = _a.onClickOnMap;
    var _s = useState(null), map = _s[0], setMap = _s[1];
    var info = useRef(null);
    var _t = useState(''), hover = _t[0], setHover = _t[1];
    var polygonLayer = useRef(null);
    var defaultProps = {
        center: {
            lat: ((_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b.lat) || ((_c = data === null || data === void 0 ? void 0 : data[0]) === null || _c === void 0 ? void 0 : _c.latitude) || defaultLat,
            lng: ((_d = data === null || data === void 0 ? void 0 : data[0]) === null || _d === void 0 ? void 0 : _d.lng) || ((_e = data === null || data === void 0 ? void 0 : data[0]) === null || _e === void 0 ? void 0 : _e.longitude) || 30.337844,
        },
        zoom: zoom,
    };
    var createMapOptions = function (maps) {
        return {
            mapTypeId: mapTypeId,
            panControl: false,
            mapTypeControl: mapTypeControl,
            scrollwheel: true,
            styles: [{ stylers: [{ 'saturation': -70 }, { 'gamma': 0.6 }, { 'lightness': 4 }, { 'visibility': 'on' }] }],
        };
    };
    var apiIsLoaded = function (map, maps) {
        setMap(map);
        info.current = (new window.google.maps.InfoWindow());
        if (searchLocation) {
            map.setOptions({
                draggableCursor: "crosshair",
                draggingCursor: "crosshair"
            });
        }
    };
    useEffect(function () {
        if (map && (data === null || data === void 0 ? void 0 : data.length) && polygon) {
            if (polygonLayer.current)
                polygonLayer.current.setMap(null);
            polygonLayer.current = new window.google.maps.Polygon({
                paths: data,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
            });
            polygonLayer.current.setMap(map);
        }
    }, [map, data, polygon]);
    var onMarkerClick = function (marker, event) {
        var _a, _b;
        if (!displayInfo)
            return;
        console.log('marker', marker);
        console.log('event', event);
        info.current.close();
        setCenter({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
        var job = data.find(function (d) { return +d.id === +marker.id; });
        if (sales) {
            info.current.setContent("\n          <div class=\"db min-w-300 text-left v-top\">\n              <div class=\"dib v-top min-w-115\" onclick=\"window.open('" + SALES_DETAILS + "/" + job.id + "')\">\n                  " + (job.image ? job.image : '<img src="https://public-map-icons.s3.ca-central-1.amazonaws.com/No-image-available.png" alt="" />') + "\n              </div>\n              <div class=\"dib v-top\">\n                  <div class=\"db bold color-blue p-4\">" + formatTotalAsCurrency(job.salePrice) + "</div>\n                  <div class=\"db p-4\">" + job.address + "</div>\n                  <div class=\"db p-4\">Building Area: <b>" + formatNumbers(job.buildingArea) + "</b></div>\n                  <div class=\"db p-4\">Site Area: <b>" + formatNumbers(job.siteArea) + "</b></div>\n                  <div class=\"db p-4\">Sale Date: <b>" + job.saleDate + "</b></div>\n                  <div class=\"db p-4\">\n                      <button class=\"btn btn-xxs btn-info mt-2\" data-id=\"" + job.id + "\" onclick=\"document.getElementById('check_sale_" + job.id + "').click()\">Select</button>\n                      <button class=\"btn btn-xxs mt-2 ml-8\" onclick=\"window.open('" + SALES_DETAILS + "/" + job.id + "')\">View Details</button>\n                  </div>\n              </div>\n          </div>");
        }
        else {
            info.current.setContent("<div class=\"db w-100\">\n            <div class=\"dib v-top w-50\"> \n              <img src=\"https://maps.googleapis.com/maps/api/streetview?sensor=false&size=150x100&location=" + job.lat + "," + job.lng + "&fov=80&key=" + process.env.REACT_APP_GOOGLE_API_KEY + "\" alt=\"\" />\n            </div>\n            <div class=\"dib v-top w-50\">\n              <div class=\"db p-4\"><strong>" + ((_a = job.tblStatusByStatusId) === null || _a === void 0 ? void 0 : _a.statusTitle) + "</strong></div>\n              <div class=\"db p-4\"><span>Client: " + ((_b = job.tblClientByClientId) === null || _b === void 0 ? void 0 : _b.name) + "</span></div>\n              <div class=\"db p-4\"><span >" + formatDate(job.zzCreatedDate, 'LLL dd, yyyy') + "</span></div>\n            </div>\n            <div class=\"db p-4 color-light1 underline\"><a href=\"" + JOB_VIEW_URL.replace('{id}', job.id) + "\" target=\"_blank\">" + job.zzFullAddress + "</a></div>\n          </div></div>");
        }
        info.current.open(map, marker);
    };
    var getLatLngOnClick = function (event) {
        if (!searchLocation)
            return;
        onClickOnMap && onClickOnMap(event);
        prepareMarkers([event]);
    };
    var customMarkerIcon = function (feature) {
        var _a, _b, _c, _d;
        return [StatusEnum.COMPLETED, StatusEnum.CLOSED].includes((_b = (_a = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.statusId) ? "/assets/map_green.svg" :
            [StatusEnum.ARCHIVED, StatusEnum.QUOTE_REJECTED].includes((_d = (_c = feature === null || feature === void 0 ? void 0 : feature.properties) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.statusId) ? '/assets/map_yellow.svg"' : "/assets/map_blue.svg";
    };
    var prepareMarkers = function (addedData) {
        var _points = (addedData || data).map(function (item) { return ({
            id: item === null || item === void 0 ? void 0 : item.id,
            type: "Feature",
            properties: { cluster: false, item: item },
            geometry: {
                type: "Point",
                coordinates: [
                    parseFloat(item.lng || item.longitude),
                    parseFloat(item.lat || item.latitude)
                ]
            }
        }); });
        var bounds = new window.google.maps.LatLngBounds();
        (addedData || data || []).map(function (model) {
            var latLng = new window.google.maps.LatLng(model.lat, model.lng);
            bounds.extend(latLng);
            return latLng;
        });
        if (!(addedData === null || addedData === void 0 ? void 0 : addedData.length))
            map.fitBounds(bounds);
        if (showMarkers && !polygon) {
            SuperClusterAdapterLoader.getClusterer().then(function (Clusterer) {
                if (Clusterer) {
                    var clusterer = new Clusterer.Builder(map)
                        .withRadius(80)
                        .withMaxZoom(19)
                        .withMinZoom(0)
                        .withCustomMarkerIcon(customMarkerIcon)
                        .withMarkerClick(onMarkerClick)
                        .build();
                    clusterer.load({
                        type: 'FeatureCollection',
                        features: _points,
                        bbox: [map.getBounds().getSouthWest().lng(), map.getBounds().getSouthWest().lat(), map.getBounds().getNorthEast().lng(), map.getBounds().getNorthEast().lat()],
                    });
                }
            });
        }
        if (!(addedData === null || addedData === void 0 ? void 0 : addedData.length) && (data === null || data === void 0 ? void 0 : data.length) === 1) {
            map.setZoom(zoom);
        }
    };
    useEffect(function () {
        if (map && (data === null || data === void 0 ? void 0 : data.length) > 0) {
            prepareMarkers();
        }
    }, [map, data]);
    var onChildMouseEnter = function (key) {
        setHover(key);
    };
    var onChildMouseLeave = function () {
        setHover('');
    };
    var setCenter = function (item) {
        map.setCenter({ lng: item.lng, lat: item.lat }, true);
    };
    return (_jsx("div", __assign({ style: { height: height, width: width } }, { children: _jsx(GoogleMapReact, __assign({ yesIWantToUseGoogleMapApiInternals: true, onGoogleApiLoaded: function (_a) {
                var map = _a.map, maps = _a.maps;
                return apiIsLoaded(map, maps);
            }, options: createMapOptions, 
            //layerTypes={['TrafficLayer', 'TransitLayer']}
            bootstrapURLKeys: { key: process.env.REACT_APP_GOOGLE_API_KEY }, center: defaultProps.center, defaultZoom: defaultProps.zoom, onClick: getLatLngOnClick }, { children: labels === null || labels === void 0 ? void 0 : labels.map(function (l) { return (_jsx(LabelMarker, { lat: l.labelPosition.latitude, lng: l.labelPosition.longitude, labelAngle: l.labelAngle, text: correctMeasure(parseFloat(l.measurement), l.uom) }, void 0)); }) }), void 0) }), void 0));
};
var LabelMarker = function (props) { return (_jsx("div", __assign({ style: { fontSize: 12, color: 'white', transform: "rotate(" + -1 * props.labelAngle + "deg)" } }, { children: props === null || props === void 0 ? void 0 : props.text }), void 0)); };
