var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var DELETE_OFFICE = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation deleteTblOfficeById($id: BigInt!) {\n  deleteTblOfficeById(input: { id: $id }) {\n    clientMutationId\n    deletedTblOfficeId\n  }\n}\n"], ["mutation deleteTblOfficeById($id: BigInt!) {\n  deleteTblOfficeById(input: { id: $id }) {\n    clientMutationId\n    deletedTblOfficeId\n  }\n}\n"])));
export var CREATE_OFFICE = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["mutation createTblOffice($input: TblOfficeInput!) {\n  createTblOffice(input: { tblOffice: $input }) {\n    clientMutationId\n    tblOffice {\n      id\n    }\n  }\n}\n"], ["mutation createTblOffice($input: TblOfficeInput!) {\n  createTblOffice(input: { tblOffice: $input }) {\n    clientMutationId\n    tblOffice {\n      id\n    }\n  }\n}\n"])));
export var UPDATE_OFFICE = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["mutation updateTblOfficeById($id: BigInt!, $input: TblOfficePatch!) {\n  updateTblOfficeById(input: { tblOfficePatch: $input, id: $id }) {\n    clientMutationId\n  }\n}"], ["mutation updateTblOfficeById($id: BigInt!, $input: TblOfficePatch!) {\n  updateTblOfficeById(input: { tblOfficePatch: $input, id: $id }) {\n    clientMutationId\n  }\n}"])));
export var ADD_CLIENT_FEE = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation addClientFee($input: TblClientFeeInput!) {\n  createTblClientFee(input: { tblClientFee: $input }) {\n    clientMutationId\n  }\n}\n"], ["\nmutation addClientFee($input: TblClientFeeInput!) {\n  createTblClientFee(input: { tblClientFee: $input }) {\n    clientMutationId\n  }\n}\n"])));
export var UPDATE_CLIENT_FEE = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nmutation updateClientFee($id: BigInt!, $fee: BigFloat) {\n  updateTblClientFeeById(input: { tblClientFeePatch: { fee: $fee }, id: $id }) {\n    clientMutationId\n  }\n}\n"], ["\nmutation updateClientFee($id: BigInt!, $fee: BigFloat) {\n  updateTblClientFeeById(input: { tblClientFeePatch: { fee: $fee }, id: $id }) {\n    clientMutationId\n  }\n}\n"])));
export var GET_REPORT_FEE = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\nquery getTblClientFees {\n  allTblClientFees {\n    nodes {\n      clientId\n      fee\n      id\n      reportType\n    }\n  }\n}\n"], ["\nquery getTblClientFees {\n  allTblClientFees {\n    nodes {\n      clientId\n      fee\n      id\n      reportType\n    }\n  }\n}\n"])));
export var GET_COMPANY = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["query getCompany {\n    allTblCompanies {\n        nodes {\n          fax\n          id\n          licenceId\n          logo\n          name\n          phone\n          zzFullAddress\n          email\n          engagementLetter\n          emailTemplate\n          invoiceTemplate\n          fileNumberFormat\n          menuItems\n          lat\n          lng\n          tax\n        }\n      }\n      allTblOffices {\n        nodes {\n          address\n          email\n          engLetter\n          fax\n          id\n          lat\n          lng\n          name\n          phone\n        }\n      }\n  }"], ["query getCompany {\n    allTblCompanies {\n        nodes {\n          fax\n          id\n          licenceId\n          logo\n          name\n          phone\n          zzFullAddress\n          email\n          engagementLetter\n          emailTemplate\n          invoiceTemplate\n          fileNumberFormat\n          menuItems\n          lat\n          lng\n          tax\n        }\n      }\n      allTblOffices {\n        nodes {\n          address\n          email\n          engLetter\n          fax\n          id\n          lat\n          lng\n          name\n          phone\n        }\n      }\n  }"])));
export var ALL_OFFICES = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["query getAllTblOffices{ allTblOffices {\n    nodes {\n      address\n      email\n      engLetter\n      fax\n      id\n      name\n      phone\n    }\n  }}"], ["query getAllTblOffices{ allTblOffices {\n    nodes {\n      address\n      email\n      engLetter\n      fax\n      id\n      name\n      phone\n    }\n  }}"])));
export var ALL_COMPANIES = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query getAllCompanies ($offset: Int,$first: Int,$orderBy:[TblCompaniesOrderBy!],  $filter: TblCompanyFilter) {\n    allTblCompanies(filter:$filter, first: $first, offset: $offset,orderBy:$orderBy) {\n      totalCount\n      nodes {\n        fax\n        id\n        licenceId\n        logo\n        name\n        phone\n        zzFullAddress\n        email\n        engagementLetter\n        emailTemplate\n        invoiceTemplate\n        fileNumberFormat\n      }\n    }\n  }"], ["\n  query getAllCompanies ($offset: Int,$first: Int,$orderBy:[TblCompaniesOrderBy!],  $filter: TblCompanyFilter) {\n    allTblCompanies(filter:$filter, first: $first, offset: $offset,orderBy:$orderBy) {\n      totalCount\n      nodes {\n        fax\n        id\n        licenceId\n        logo\n        name\n        phone\n        zzFullAddress\n        email\n        engagementLetter\n        emailTemplate\n        invoiceTemplate\n        fileNumberFormat\n      }\n    }\n  }"])));
export var UPDATE_COMPANY = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["mutation updateCompany($id: BigInt!, $input: TblCompanyPatch!) {\n  updateTblCompanyById(input: { tblCompanyPatch: $input, id: $id }) {\n    tblCompany {\n      email\n      emailTemplate\n      engagementLetter\n      fax\n      fileNumberFormat\n      id\n      invoiceTemplate\n      logo\n      menuItems\n      name\n      phone\n      zzFullAddress\n      tax\n    }\n  }\n}"], ["mutation updateCompany($id: BigInt!, $input: TblCompanyPatch!) {\n  updateTblCompanyById(input: { tblCompanyPatch: $input, id: $id }) {\n    tblCompany {\n      email\n      emailTemplate\n      engagementLetter\n      fax\n      fileNumberFormat\n      id\n      invoiceTemplate\n      logo\n      menuItems\n      name\n      phone\n      zzFullAddress\n      tax\n    }\n  }\n}"])));
export var COMPANY_COLUMNS = [
    {
        caption: 'Company Name',
        fieldName: 'name',
        columnFilter: true,
        columnFilterField: 'name'
    },
    {
        caption: 'licence #',
        fieldName: 'licenceId',
    },
    {
        caption: 'Email',
        fieldName: 'email',
        columnFilter: true,
        columnFilterField: 'email'
    },
    {
        caption: 'Address',
        fieldName: 'zzFullAddress',
        isHidden: true,
        columnFilter: true,
        columnFilterField: 'zzFullAddress'
    },
    {
        caption: 'Phone',
        fieldName: 'phone',
        isHidden: true,
    },
    {
        caption: 'Fax',
        fieldName: 'fax',
        isHidden: true,
    }
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
