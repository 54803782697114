var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ColumnType } from "@enums";
import { Link, useHistory } from "react-router-dom";
import { formatDate, formatNumbers } from "@utils/text";
import { formatDate as formatDateUtil } from "@utils/date";
import * as _ from "lodash";
import { StatusEnum, DRAWERCOMPONENT } from "@enums";
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatTotalAsCurrency } from "../../helpers/numbers-helper.helper";
import { formatAddress, formatFileNumber } from "../../services/job.service";
import { readFromStorage, STORAGE_KEY } from "../../utils/Storage.util";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDrawer } from "../../stores/app.reducer";
import { calculateFormula, extractColumsFromFormula } from "../../utils/Formula.utility";
import { isAppraiser } from "../../services/user-service";
import { getOptions } from "../../stores/client.selector";
import { InputText } from "../input/Input-Field.component";
import { setUpdatedData } from "../../stores/table.reducer";
import { TableTotalFooter } from "./tableTotalFooter";
import { DrawerComponent } from "../../components/app/drawer-component";
import { ModalForm } from "../modals/Modal-Form.component";
import { Tooltip } from "@material-ui/core";
import { getAppModules } from "../../stores/app.selector";
export var TableBody = function (_a) {
    var _b = _a.headers, headers = _b === void 0 ? [] : _b, _c = _a.data, data = _c === void 0 ? [] : _c, _d = _a.viewRoute, viewRoute = _d === void 0 ? null : _d, _e = _a.editRoute, editRoute = _e === void 0 ? null : _e, _f = _a.onDelete, onDelete = _f === void 0 ? null : _f, _g = _a.deleteAction, deleteAction = _g === void 0 ? null : _g, _h = _a.customAction, customAction = _h === void 0 ? null : _h, _j = _a.refetch, refetch = _j === void 0 ? null : _j, _k = _a.rowClicked, rowClicked = _k === void 0 ? null : _k, _l = _a.action, action = _l === void 0 ? null : _l, _m = _a.selection, selection = _m === void 0 ? false : _m, _o = _a.selectionChanged, selectionChanged = _o === void 0 ? null : _o, _p = _a.rowSelected, rowSelected = _p === void 0 ? [] : _p, _q = _a.pKey, pKey = _q === void 0 ? 'id' : _q, _r = _a.totalInFooter, totalInFooter = _r === void 0 ? true : _r;
    var history = useHistory();
    var dispatch = useDispatch();
    var appraiser = isAppraiser();
    var options = useSelector(getOptions);
    var _s = useState([]), dataRow = _s[0], setDataRow = _s[1];
    var appModule = useSelector(getAppModules);
    var formatDateTime = function (date) {
        return formatDate(date);
    };
    var handleCloseDeleteAction = function (id) {
        deleteAction(id);
    };
    var handleView = function (selectedId) {
        if (typeof viewRoute === 'function') {
            viewRoute(selectedId);
        }
        else {
            history.push("" + viewRoute + selectedId);
        }
    };
    var handleEdit = function (selectedId, rowData) {
        if (typeof editRoute === 'function') {
            editRoute(selectedId, rowData);
        }
        else {
            history.push("" + editRoute + selectedId);
        }
    };
    var rowClicks = function (e, id, index) {
        if (!selection && rowClicked && e.target.localName !== "i") {
            rowClicked(e, id, index);
        }
    };
    var getNestedProperty = function (data, header) {
        var _a, _b, _c, _d, _e, _f, _g;
        var output = '';
        if (header.fieldName === 'zzFullAddress') {
            if (!data[header.fieldName]) {
                return formatAddress(data);
            }
        }
        if (header.fieldName.indexOf(".") !== -1) {
            var p = header.fieldName.split('.');
            if (header.fieldName.indexOf(".nodes.") !== -1) {
                output = ((_c = (_b = (_a = data[p[0]]) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c[p[2]]) || '';
            }
            else {
                var nestedValue_1 = __assign({}, data);
                p.forEach(function (pChild) {
                    nestedValue_1 = nestedValue_1 === null || nestedValue_1 === void 0 ? void 0 : nestedValue_1[pChild];
                });
                output = nestedValue_1 || '';
            }
            if (header.selectorName && header.isFromOptions) {
                return output ? (_d = options === null || options === void 0 ? void 0 : options.find(function (c) { return +c.id === +output; })) === null || _d === void 0 ? void 0 : _d.title : '';
            }
            return header.upperCase ? output.toUpperCase() : output;
        }
        if (header.fieldName.indexOf("|") !== -1) {
            var fields = header.fieldName.split('|');
            return _.reduce(fields, function (result, n) {
                if (data[n]) {
                    result.push("" + data[n] + (header.multipleValueSeparator || '') + " ");
                }
                return result;
            }, []);
        }
        if (header.columnType === ColumnType.DATE_ONLY) {
            return !data[header.fieldName] ? '' : formatDateUtil((data[header.fieldName]), 'MMM dd, yyyy');
        }
        else if (header.fieldName.toLowerCase().startsWith("zzcreateddate") || header.fieldName.toLowerCase().startsWith("zzcreattiondate")) {
            return formatDateUtil(data[header.fieldName], 'MMM dd, yyyy');
        }
        else if (header.columnType === ColumnType.CURRENCY) {
            if (header.formula) {
                return formatTotalAsCurrency(calculateFormula(header, data));
            }
            return formatTotalAsCurrency((data === null || data === void 0 ? void 0 : data[header.fieldName]) || header.defaultValue || '0');
        }
        else if (header.formula) {
            return calculateFormula(header, data);
        }
        output = data[header.fieldName];
        if (header.columnType === ColumnType.BIT) {
            return output ? (_jsx(CheckIcon, { color: "secondary" }, void 0)) : '';
        }
        if (header.columnType === ColumnType.LIST && ((_e = header.listItems) === null || _e === void 0 ? void 0 : _e.length)) {
            return (_f = header.listItems) === null || _f === void 0 ? void 0 : _f[+data[header.fieldName]];
        }
        if (header.companyFormat) {
            return formatFileNumber(data[header.fieldName], readFromStorage(STORAGE_KEY.COMPANY));
        }
        if (header.columnType === ColumnType.NUMBER && header.separator) {
            return output ? formatNumbers(+output) : '';
        }
        if (header.selectorName && header.isFromOptions) {
            return output ? (_g = options === null || options === void 0 ? void 0 : options.find(function (c) { return +c.id === +output; })) === null || _g === void 0 ? void 0 : _g.title : '';
        }
        return header.upperCase ? output === null || output === void 0 ? void 0 : output.toUpperCase() : output;
    };
    var getRowClass = function (rowData) {
        if (rowData["statusId"] && [StatusEnum.ARCHIVED, StatusEnum.QUOTE_REJECTED].includes(rowData["statusId"])) {
            return "bg-light-yellow";
        }
        return "";
    };
    var checkBoxChecked = function (e, rowData) {
        selectionChanged && selectionChanged(e, rowData[pKey]);
    };
    var linkTo = function (header, rowData) {
        var _a, _b, _c;
        if (header.openInDrawer) {
            if (header.linkTo === DRAWERCOMPONENT.CLIENT && appraiser)
                return;
            if (((_a = header.linkFieldId) === null || _a === void 0 ? void 0 : _a.indexOf('|')) !== -1) {
                var value_1 = {};
                (_b = header.linkFieldId) === null || _b === void 0 ? void 0 : _b.split('|').forEach(function (field) {
                    var _a;
                    value_1 = __assign(__assign({}, value_1), (_a = {}, _a[field] = rowData[field], _a));
                });
                dispatch(setOpenDrawer({ status: 'OPEN', id: value_1, component: header.linkTo }));
            }
            else {
                var id = rowData[header.linkFieldId || 'id'];
                if ((_c = document.getElementsByClassName('drawer-big')) === null || _c === void 0 ? void 0 : _c.length) {
                    ModalForm.show({
                        title: '',
                        large: true,
                        footer: false,
                        body: (_jsx(DrawerComponent, { id: id, component: header.linkTo }, void 0))
                    });
                }
                else {
                    dispatch(setOpenDrawer({ status: 'OPEN', id: id, component: header.linkTo }));
                }
            }
        }
    };
    var inlineEdit = function (header, rowIndex) { return function (e) {
        var otherColumns = header.formula ? extractColumsFromFormula(header) : [];
        var newData = dataRow.reduce(function (result, curr, index) {
            var _a, _b;
            var _c, _d, _e, _f;
            if (index === rowIndex) {
                var updated = __assign(__assign({}, curr), (_a = {}, _a[header.fieldName] = parseFloat((_d = (_c = e.target) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.replace(/,/g, '')), _a));
                if (otherColumns === null || otherColumns === void 0 ? void 0 : otherColumns.length) {
                    updated = __assign(__assign({}, updated), (_b = {}, _b[otherColumns[0]] = parseFloat((_f = (_e = e.target) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.replace(/,/g, '')), _b));
                }
                result.push(updated);
            }
            else {
                result.push(curr);
            }
            return result;
        }, []);
        dispatch(setUpdatedData(newData));
        setDataRow(newData);
    }; };
    useEffect(function () {
        setDataRow(data);
        dispatch(setUpdatedData(data));
    }, [data]);
    var renderComponent = function (col, rowData) {
        var Component = col.component;
        return _jsx(Component, __assign({}, rowData), void 0);
    };
    return (_jsxs(_Fragment, { children: [_jsx("tbody", { children: dataRow.map(function (rowData, key) {
                    return (_jsxs("tr", __assign({ className: getRowClass(rowData) + (rowClicked ? ' pointer' : ''), onClick: function (e) { return rowClicks(e, rowData[pKey], key); } }, { children: [selection && (_jsx("td", __assign({ className: "p-8" }, { children: _jsx("input", { id: "input_type_" + rowData[pKey], "data-id": rowData[pKey], className: "select-row-checkbox", type: "checkbox", checked: rowSelected.includes(+rowData[pKey]), onChange: function (e) { return checkBoxChecked(e, rowData); } }, void 0) }), void 0)),
                            headers.map(function (header, colKey) {
                                return (_jsxs("td", { children: [header.editable ? (header.component ?
                                            renderComponent(header, rowData)
                                            : _jsx(InputText, { size: "sm", className: "inline-table-edit", label: "", separator: ",", defaultValue: header.formula ? calculateFormula(header, rowData) : rowData[header.fieldName], value: rowData[header.fieldName], onBlur: inlineEdit(header, key), type: "number" }, void 0)) : header.linkTo ? (_jsx(Link, __assign({ onClick: function () { return linkTo(header, rowData); }, className: "table-row-link", to: header.openInDrawer ? '#' : header.linkTo.replace(':id', rowData[pKey]) }, { children: getNestedProperty(rowData, header) }), void 0)) : (_jsx(_Fragment, { children: getNestedProperty(rowData, header) }, void 0)),
                                        header.childColumns && (_jsxs("span", { children: ["->", (header.childColumns || []).map(function (col) { return getNestedProperty(rowData, col); }).join("->")] }, void 0))] }, colKey));
                            }),
                            (viewRoute || editRoute || onDelete || customAction) ? (_jsxs("td", __assign({ className: "flex table-action-column", "data-id": rowData[pKey] }, { children: [!(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && editRoute && (_jsx(Tooltip, __assign({ title: "Edit" }, { children: _jsx(EditIcon, { className: "pointer", color: "primary", fontSize: "small", onClick: function () { return handleEdit(rowData[pKey], rowData); } }, void 0) }), void 0)),
                                    viewRoute && (_jsx(Tooltip, __assign({ title: "View details" }, { children: _jsx(VisibilityIcon, { className: "pointer ml-8", color: "primary", fontSize: "small", onClick: function () { return handleView(rowData[pKey]); } }, void 0) }), void 0)),
                                    !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && onDelete && (_jsx(Tooltip, __assign({ title: "Delete" }, { children: _jsx(DeleteIcon, { className: "pointer ml-8", color: "primary", fontSize: "small", onClick: function () { return onDelete(rowData[pKey], key, rowData); } }, void 0) }), void 0)),
                                    !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && deleteAction && (_jsx(Tooltip, __assign({ title: "Delete" }, { children: _jsx(DeleteIcon, { className: "pointer ml-8", color: "primary", fontSize: "small", onClick: function () { return handleCloseDeleteAction(rowData[pKey]); } }, void 0) }), void 0)),
                                    customAction && (_jsx("div", { children: customAction }, void 0))] }), void 0)) : (_jsx("td", __assign({ "data-id": rowData[pKey] }, { children: _jsx(_Fragment, { children: action }, void 0) }), "action-row-" + key))] }), key));
                }) }, void 0),
            ((dataRow === null || dataRow === void 0 ? void 0 : dataRow.length) > 0 && headers.some(function (d) { return d.totalInFooter; })) && (_jsx(TableTotalFooter, { headers: headers, dataRow: dataRow, selection: selection, totalInFooter: totalInFooter }, void 0))] }, void 0));
};
