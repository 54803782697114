var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import { Col, Row } from "reactstrap";
import { GET_DOCUMENTS_BY_TYPE } from '../graphqls/jobs/job.query';
import { fileSizeToString } from '../helpers/numbers-helper.helper';
import { formatDate } from "@utils/date";
import { useApolloQuery } from '../hooks/use-appolo.hooks';
import { InputText } from "./input/Input-Field.component";
import { FileExtension } from "./extens/File-Extension.component";
import { getJwtToken } from '../services/user-service';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import { httpPost } from '../helpers/http.helper';
import { reduce } from 'lodash';
import { isMobile } from 'react-device-detect';
export var FileUploader = function (_a) {
    var _b = _a.id, id = _b === void 0 ? null : _b, _c = _a.type, type = _c === void 0 ? '' : _c, _d = _a.onChange, onChange = _d === void 0 ? null : _d, _e = _a.attachments, attachments = _e === void 0 ? [] : _e, _f = _a.showDiscription, showDiscription = _f === void 0 ? true : _f, _g = _a.filesLimit, filesLimit = _g === void 0 ? 5 : _g, _h = _a.reload, reload = _h === void 0 ? '' : _h;
    var _j = useState(attachments), attachment = _j[0], setAttachment = _j[1];
    var _k = useState([]), documents = _k[0], setDocuments = _k[1];
    var apolloClient = useApolloQuery()[0];
    var decription = useRef();
    var handleUpload = function (files) {
        var _a;
        setAttachment(files);
        onChange && onChange(files, ((_a = decription === null || decription === void 0 ? void 0 : decription.current) === null || _a === void 0 ? void 0 : _a.value) || "");
    };
    var showFiles = function (doc) {
        window.open(process.env.REACT_APP_EXTERNAL_API_URL + "document/document/" + doc.fileKey + "/" + getJwtToken());
    };
    var deleteFile = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var updated;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("document/remove", { key: file.fileKey, docId: file.documentId })];
                case 1:
                    _a.sent();
                    updated = reduce(documents, function (result, doc) {
                        if (doc.tblFilesByDocumentId.nodes.filter(function (f) { return f.fileKey !== file.fileKey; }).length > 0) {
                            result = result.concat(__assign(__assign({}, doc), { tblFilesByDocumentId: {
                                    nodes: doc.tblFilesByDocumentId.nodes.filter(function (f) { return f.fileKey !== file.fileKey; }),
                                } }));
                        }
                        return result;
                    }, []);
                    setDocuments(updated);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadDocuments = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, apolloClient.query({
                        query: GET_DOCUMENTS_BY_TYPE,
                        variables: {
                            refId: "" + id,
                            type: type,
                        }
                    })];
                case 1:
                    data = (_b.sent()).data;
                    setDocuments(((_a = data === null || data === void 0 ? void 0 : data.allTblDocuments) === null || _a === void 0 ? void 0 : _a.nodes) || []);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (id && type) {
            //setAttachment(attachments)
            loadDocuments();
        }
    }, [id, type, reload]);
    return (_jsx("div", __assign({ className: !showDiscription ? "" : "pd-32" }, { children: _jsxs(Row, { children: [_jsxs(Col, __assign({ xs: !showDiscription || isMobile ? 12 : 6 }, { children: [_jsx(DropzoneArea, { onChange: handleUpload, filesLimit: filesLimit, initialFiles: attachment, showFileNamesInPreview: true, showFileNames: true, showAlerts: ['error'], maxFileSize: +(process.env.REACT_APP_UPLOAD_SIZE || '20') }, void 0),
                        showDiscription && (_jsxs(_Fragment, { children: [_jsx("br", {}, void 0),
                                _jsx(InputText, { inputRef: decription, fullWidth: true, size: "small", label: "Description" }, void 0),
                                _jsx("br", {}, void 0)] }, void 0))] }), void 0),
                _jsx(Col, __assign({ xs: isMobile ? 12 : 6 }, { children: _jsx(List, __assign({ className: 'list-no-pad' }, { children: documents.map(function (doc, index) { return (_jsxs("div", { children: [doc.description, _jsx(Divider, {}, void 0),
                                doc.tblFilesByDocumentId.nodes.map(function (file, index) { return (_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(FileExtension, { fileName: file.fileName, onClick: function () { return showFiles(file); } }, void 0) }, void 0),
                                        _jsx(ListItemText, { primary: _jsxs("div", __assign({ className: 'db w-100' }, { children: [_jsx("div", __assign({ className: 'dib w-80' }, { children: _jsx(Typography, __assign({ className: "pointer", onClick: function () { return showFiles(file); }, variant: "caption" }, { children: file.fileName }), void 0) }), void 0),
                                                    _jsx("div", __assign({ className: 'dib w-20 right' }, { children: _jsx(Typography, __assign({ variant: "caption", className: "db color-gray" }, { children: formatDate(doc.zzCreatedDate, "MMM dd, yyyy") }), void 0) }), void 0)] }), void 0), secondary: _jsxs(Typography, __assign({ variant: "caption", className: "db" }, { children: [fileSizeToString(file.fileSize), " ", _jsx(DeleteIcon, { style: { fontSize: 20, color: 'red', float: 'right' }, className: "pointer", onClick: function () { return deleteFile(file); } }, void 0)] }), void 0) }, void 0)] }, "file_row_" + index)); })] }, "doc_row_" + index)); }) }), void 0) }), void 0)] }, void 0) }), void 0));
};
