var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { getAppraiserStatuses, getAppraisers } from "../../stores/client.selector";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { Table } from "../../ui-components/table/table.component";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Columns, GET_INCOME_PAYMENTS } from "../../graphqls/dashboard/payments.query";
import { getThisMonth } from "@utils/date";
import { GetUserId, isAppraiser } from "../../services/user-service";
import { ColumnType, DRAWERCOMPONENT, StatusEnum } from '@enums';
import { CustomDatePicker } from "../../ui-components/date-picker/Custom-Date-Picker";
import { CUSTOMDATEPICKER } from "../../ui-components/date-picker/Date.enum";
import { Payroll } from "./payroll";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { batchJobStatusUpdate } from "../../services/job.service";
import { map } from "lodash";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { getAppModules } from "../../stores/app.selector";
import { isMobile } from "react-device-detect";
export var Income = function (_a) {
    var _b = _a.header, header = _b === void 0 ? true : _b, _c = _a.userId, userId = _c === void 0 ? 0 : _c, _d = _a.clientId, clientId = _d === void 0 ? -1 : _d, _e = _a.duePayments, duePayments = _e === void 0 ? false : _e, _f = _a.rowSelectionChanged, rowSelectionChanged = _f === void 0 ? null : _f, _g = _a.completed, completed = _g === void 0 ? false : _g, _h = _a.reload, reload = _h === void 0 ? '' : _h, _j = _a.updateStatus, updateStatus = _j === void 0 ? false : _j, _k = _a.classContent, classContent = _k === void 0 ? 'content' : _k, _l = _a.modal, modal = _l === void 0 ? false : _l, props = __rest(_a, ["header", "userId", "clientId", "duePayments", "rowSelectionChanged", "completed", "reload", "updateStatus", "classContent", "modal"]);
    var appraisers = useSelector(getAppraisers);
    var lockAppraiser = isAppraiser();
    var jobStatuses = useSelector(getAppraiserStatuses);
    var apolloClient = useApolloQuery()[0];
    var appModule = useSelector(getAppModules);
    var _m = useState({ start: getThisMonth()[0], end: getThisMonth()[1] }), date = _m[0], setDate = _m[1];
    var _o = useState(userId), appId = _o[0], setAppId = _o[1];
    var _p = useState([]), data = _p[0], setData = _p[1];
    var _q = useState(StatusEnum.NONE), statusId = _q[0], setStatusId = _q[1];
    var _r = useState([]), selectedIds = _r[0], setSelectedIds = _r[1];
    var _s = useState(''), reloadContent = _s[0], setReloadContent = _s[1];
    var getPayments = useMutation(GET_INCOME_PAYMENTS, {
        onCompleted: function (data) {
            var _a, _b, _c;
            if (duePayments) {
                setData((_b = (_a = data === null || data === void 0 ? void 0 : data.spIncome) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.filter(function (d) { return (d.payments || 0) - (d.paid || 0) > 0; }));
            }
            else {
                setData((_c = data === null || data === void 0 ? void 0 : data.spIncome) === null || _c === void 0 ? void 0 : _c.results);
            }
        }
    })[0];
    useEffect(function () {
        if (duePayments) {
            getPayments({
                variables: {
                    appid: -1,
                    start: date.start,
                    end: date.end,
                    clientid: clientId,
                    outstand: true,
                }
            });
        }
        else if (clientId) {
            getPayments({
                variables: {
                    appid: appId,
                    start: date.start,
                    end: date.end,
                    clientid: clientId,
                    completed: completed,
                    outstand: false,
                }
            });
        }
    }, [date, appId, clientId, duePayments, reload, reloadContent]);
    useEffect(function () {
        if (lockAppraiser) {
            setAppId(GetUserId());
        }
        ;
    }, []);
    var appChanged = function (ev) {
        var _a;
        setAppId(+((_a = ev.target) === null || _a === void 0 ? void 0 : _a.value));
    };
    var onDateChanged = function (ev) {
        setDate({
            start: ev[0],
            end: ev[1]
        });
    };
    var getColumns = useMemo(function () {
        return clientId > 0 ? Columns.filter(function (c) { return !["payments", "clientname", 'zzfulladdress'].includes(c.fieldName); }).concat([
            {
                caption: "Address",
                fieldName: "zzfulladdress",
                sortField: "zzfulladdress",
                sortable: true,
                filterable: false,
                linkTo: modal ? '' : DRAWERCOMPONENT.JOB_VIEW,
                openInDrawer: true,
                linkFieldId: 'id',
            },
            {
                caption: "Billed",
                fieldName: "payments",
                sortField: "PAYMENTS",
                sortable: false,
                filterable: false,
                columnType: ColumnType.CURRENCY,
                totalInFooter: true,
            },
            {
                caption: "Revenue",
                fieldName: "paid",
                sortField: "PAID",
                sortable: false,
                filterable: false,
                columnType: ColumnType.CURRENCY,
                totalInFooter: true,
            }, {
                caption: "Due",
                fieldName: "due",
                sortField: "STATUS_ID",
                sortable: false,
                filterable: false,
                columnType: ColumnType.CURRENCY,
                totalInFooter: true,
                formula: '[payments] - [paid]'
            }
        ]) :
            (duePayments ? Columns.filter(function (c) { return !["portfolioname", "zzcreatedby", "statusname", "payments"].includes(c.fieldName); }).concat([
                {
                    caption: "Billed",
                    fieldName: "payments",
                    sortField: "PAYMENTS",
                    sortable: false,
                    filterable: false,
                    columnType: ColumnType.CURRENCY,
                    totalInFooter: true,
                },
                {
                    caption: "Revenue",
                    fieldName: "paid",
                    sortField: "PAID",
                    sortable: false,
                    filterable: false,
                    columnType: ColumnType.CURRENCY,
                    totalInFooter: true,
                },
                {
                    caption: "Due",
                    fieldName: "due",
                    sortField: "STATUS_ID",
                    sortable: false,
                    filterable: false,
                    columnType: ColumnType.CURRENCY,
                    totalInFooter: true,
                    formula: '[payments] - [paid]',
                }
            ]) : Columns.filter(function (c) { return !["zzcreatedby", "payments"].includes(c.fieldName); }).concat([
                {
                    caption: "Revenue",
                    fieldName: "paid",
                    sortField: "PAID",
                    sortable: false,
                    filterable: false,
                    columnType: ColumnType.CURRENCY,
                    totalInFooter: true,
                },
                {
                    caption: "Appraiser Fee",
                    fieldName: "due",
                    sortable: false,
                    filterable: false,
                    columnType: ColumnType.CURRENCY,
                    totalInFooter: true,
                    formula: '[paid] * [payments]',
                }
            ]));
    }, [appId, clientId, Columns]);
    var getData = useMemo(function () { return data; }, [data]);
    var updateBulkStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.length))
                        return [2 /*return*/];
                    Spinner.show();
                    return [4 /*yield*/, batchJobStatusUpdate(selectedIds, statusId, apolloClient)];
                case 1:
                    _a.sent();
                    setReloadContent("" + new Date().getTime());
                    Spinner.hide();
                    setSelectedIds([]);
                    return [2 /*return*/];
            }
        });
    }); };
    var rowSelected = function (ids) {
        setSelectedIds(ids);
        rowSelectionChanged && rowSelectionChanged(ids);
    };
    if (!duePayments && clientId === -1 && userId === 0) {
        return _jsx(Payroll, {}, void 0);
    }
    return (_jsxs("div", __assign({ className: classContent }, { children: [header && (_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(ListHeader, { title: duePayments ? "Accounts Receivable" : "Earnings" }, void 0) }), void 0) }, void 0)),
            (!duePayments && clientId === -1) && (_jsxs(Grid, __assign({ container: true, spacing: 0, className: "mt-16" }, { children: [(!lockAppraiser && header && !duePayments) && (_jsxs(Grid, __assign({ item: true, xs: 12, className: "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Select" }), void 0),
                            _jsx(InputText, __assign({ onChange: appChanged, className: "min-w-100 bg-white", select: true, size: "small" }, { children: appraisers === null || appraisers === void 0 ? void 0 : appraisers.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.title }), void 0)); }) }), void 0)] }), void 0)),
                    _jsxs(Grid, __assign({ item: true, xs: isMobile ? 12 : 8, className: "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Date" }), void 0),
                            _jsx(CustomDatePicker, { defaultValue: CUSTOMDATEPICKER.THIS_MONTH, onChange: onDateChanged }, void 0)] }), void 0),
                    updateStatus && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsxs(Grid, __assign({ item: true, xs: isMobile ? 12 : 4, className: "flex gap-8 " + (isMobile ? "mt-4" : "") }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Status" }), void 0),
                            _jsx(InputText, __assign({ className: "min-w-200", select: true, label: "", size: "small", onChange: function (e) { return setStatusId(+e.target.value); } }, { children: map(jobStatuses, function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0),
                            _jsx(Button, __assign({ onClick: updateBulkStatus, className: "btn btn-xxs" }, { children: "Update" }), void 0)] }), void 0))] }), void 0)),
            _jsx(Table, __assign({ offlineData: getData, customAction: null, action: null, headers: getColumns, selection: (clientId > -1 || updateStatus) && !modal, showAllData: appId > 0, exportToExcel: modal, rowSelectionChanged: rowSelected }, { children: props.children && (_jsx("div", __assign({ className: "flex gap-16 flex-start mb-16" }, { children: props.children }), void 0)) }), void 0)] }), void 0));
};
