var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BarChartIcon from '@material-ui/icons/BarChart';
import { Typography } from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
Highcharts.setOptions({ lang: { thousandsSep: ',' } });
export var AppChart = function (_a) {
    var _b, _c;
    var _d = _a.chartData, chartData = _d === void 0 ? null : _d, _e = _a.title, title = _e === void 0 ? '' : _e, _f = _a.legend, legend = _f === void 0 ? false : _f, _g = _a.legendPosition, legendPosition = _g === void 0 ? 'top' : _g, _h = _a.datalabel, datalabel = _h === void 0 ? true : _h, _j = _a.label, label = _j === void 0 ? [] : _j, _k = _a.chartType, chartType = _k === void 0 ? 'column' : _k, _l = _a.defaultColor, defaultColor = _l === void 0 ? [] : _l, _m = _a.onClick, onClick = _m === void 0 ? null : _m, _o = _a.datalabelPrefix, datalabelPrefix = _o === void 0 ? '' : _o, _p = _a.datalabelPostfix, datalabelPostfix = _p === void 0 ? '' : _p, _q = _a.height, height = _q === void 0 ? null : _q, _r = _a.datalabelsAlign, datalabelsAlign = _r === void 0 ? 'end' : _r, _s = _a.paddingTop, paddingTop = _s === void 0 ? 30 : _s;
    var highchartOptions = {
        title: {
            text: title,
        },
        chart: {
            type: chartType,
        },
        xAxis: {
            categories: (_b = chartData === null || chartData === void 0 ? void 0 : chartData[0]) === null || _b === void 0 ? void 0 : _b.map(function (c) { return c.title; }),
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        tooltip: {
            valueDecimals: datalabelPrefix ? 2 : 0,
            valueSuffix: datalabelPrefix,
        },
        legend: {
            enabled: legend,
        },
        series: (chartData || []).map(function (d, index) { return ({
            data: chartType === 'pie' ? (d || []).map(function (c) { return ({
                name: c.title,
                y: +c.total,
            }); }) : (d || []).map(function (c) { return c.total; }),
            name: label[index] || '',
        }); }),
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: datalabel
                },
                enableMouseTracking: true,
            },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: datalabel
                },
                showInLegend: legend
            },
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                innerSize: chartType === 'pie' ? '50%' : '',
                borderWidth: 0,
                dataLabels: {
                    enabled: datalabel,
                    format: ['$', 'KM'].includes(datalabelPrefix) ? "{point.y:.2f}" + datalabelPrefix : '{point.y:.1f}',
                },
                point: {
                    events: {
                        click: function (e) { onClick && onClick(e.point.index, e.point.name); }
                    }
                }
            },
        },
    };
    if (!(chartData === null || chartData === void 0 ? void 0 : chartData.length) || ((_c = chartData === null || chartData === void 0 ? void 0 : chartData[0]) === null || _c === void 0 ? void 0 : _c.every(function (c) { return +c.total === 0; }))) {
        return (_jsxs("div", __assign({ className: "db w-100 center empty-chart" }, { children: [_jsxs("div", __assign({ className: "db w-100 center" }, { children: [" ", _jsx(BarChartIcon, { style: { fontSize: 64 } }, void 0)] }), void 0),
                _jsxs("div", __assign({ className: "db w-100 center" }, { children: [" ", _jsx(Typography, __assign({ variant: "caption" }, { children: "No data" }), void 0)] }), void 0)] }), void 0));
    }
    return _jsx(HighchartsReact, { highcharts: Highcharts, options: highchartOptions }, void 0);
};
