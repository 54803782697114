export var StatusEnum;
(function (StatusEnum) {
    StatusEnum[StatusEnum["INVOICE_SENT"] = 1] = "INVOICE_SENT";
    StatusEnum[StatusEnum["COMPLETED"] = 2] = "COMPLETED";
    StatusEnum[StatusEnum["IN_PROGRESS"] = 3] = "IN_PROGRESS";
    StatusEnum[StatusEnum["CLOSED"] = 4] = "CLOSED";
    StatusEnum[StatusEnum["QUOTE_PENDING"] = 5] = "QUOTE_PENDING";
    StatusEnum[StatusEnum["QUOTE_SUBMITTED"] = 6] = "QUOTE_SUBMITTED";
    StatusEnum[StatusEnum["QUOTE_APPROVED"] = 7] = "QUOTE_APPROVED";
    StatusEnum[StatusEnum["QUOTE_REJECTED"] = 8] = "QUOTE_REJECTED";
    StatusEnum[StatusEnum["RETAINER_REQUIRED"] = 9] = "RETAINER_REQUIRED";
    StatusEnum[StatusEnum["PAYMENT_RECEIVED"] = 11] = "PAYMENT_RECEIVED";
    StatusEnum[StatusEnum["CREATE_WORK_ORDER"] = 13] = "CREATE_WORK_ORDER";
    StatusEnum[StatusEnum["RELIANCE"] = 12] = "RELIANCE";
    StatusEnum[StatusEnum["ARCHIVED"] = 14] = "ARCHIVED";
    StatusEnum[StatusEnum["NONE"] = -1] = "NONE";
    StatusEnum[StatusEnum["APPROVED"] = 100] = "APPROVED";
    StatusEnum[StatusEnum["WORK_IN_PROGRESS"] = 15] = "WORK_IN_PROGRESS";
    StatusEnum[StatusEnum["DRAFT_SUBMITTED"] = 16] = "DRAFT_SUBMITTED";
    StatusEnum[StatusEnum["FINAL_REPORT_SUBMITTED"] = 17] = "FINAL_REPORT_SUBMITTED";
})(StatusEnum || (StatusEnum = {}));
