import { InvoiceStatus, FeeTypeEnum } from "@enums";
export var convertFeeType = function (feeType) {
    return feeType === FeeTypeEnum.PERCENTAGE ? '%' : '$';
};
export var convertInvoiceStatus = function (status) {
    switch (status) {
        case InvoiceStatus.PAID: return "Paid";
        case InvoiceStatus.OVER_DUE: return "Overdue";
        case InvoiceStatus.SENT: return "Sent";
        default: return "New";
    }
};
