var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { getAllFields, getAllFieldSelectOption, getAllSectionFields, getAllSections, getTriggerSections } from "../../stores/app.selector";
import { FormDynamic } from '../../ui-components/forms/Form-Dynamic';
import { TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Table } from 'reactstrap';
import { addNewSectionField, setAddSections } from '../../stores/app.reducer';
import { useApolloQuery } from '../../hooks/use-appolo.hooks';
import { getSectionValuesByJob, upsertCustomField, upsertSections } from '../../services/job.service';
import { DeleteButton } from '../../ui-components/button/Delete-Button';
import { COLUMN_TYPE_NUMBER, FIELD_TYPE } from "@enums";
import { ModalForm } from '../../ui-components/modals/Modal-Form.component';
import { formatNumbers } from "@utils/text";
import { InputText } from '../../ui-components/input/Input-Field.component';
import { httpPost } from '../../helpers/http.helper';
import CustomFieldList from '../../ui-components/custom-field/customFieldList';
import { ReactDatePicker } from "../../ui-components/date-picker/DatePicker.component";
import { formatDate } from '@utils/date';
import { toDate } from '@utils/date';
import { loadCustomFields } from '../../services/custom-field.service';
var types = ['', 'number', 'text', 'select', 'number', 'date', 'boolean', 'select', 'currency', 'area'];
var PREFIX = 'FIELD_';
export var PropertySections = function (_a) {
    var sectionId = _a.sectionId, jobId = _a.jobId, table = _a.table, _b = _a.canEdit, canEdit = _b === void 0 ? true : _b, _c = _a.displayForm, displayForm = _c === void 0 ? false : _c;
    var apolloClient = useApolloQuery()[0];
    var dispatch = useDispatch();
    var sections = useSelector(getAllSections);
    var fields = useSelector(getAllFields);
    var sectionFields = useSelector(getAllSectionFields);
    var triggerSections = useSelector(getTriggerSections);
    var fieldSelectOption = useSelector(getAllFieldSelectOption);
    var _d = useState([]), rows = _d[0], setRows = _d[1];
    var _e = useState({}), formValue = _e[0], setFormValue = _e[1];
    var _f = useState(null), newField = _f[0], setNewField = _f[1];
    var getFormRows = useMemo(function () {
        var sf = sectionFields === null || sectionFields === void 0 ? void 0 : sectionFields.filter(function (s) { return +s.sectionId === sectionId; });
        return sf.map(function (f) {
            var _a;
            var field = fields === null || fields === void 0 ? void 0 : fields.find(function (d) { return +d.id === +f.fieldId; });
            return {
                fieldId: +f.fieldId,
                fieldName: "" + PREFIX + f.id,
                title: f.customTitle || field.title,
                type: types[field.type],
                defaultValue: field.defaultValue,
                options: ((_a = field === null || field === void 0 ? void 0 : field.tblFieldSelectsByFieldId) === null || _a === void 0 ? void 0 : _a.nodes) || [],
                group: f.groupTitle || '',
                noPrepend: true,
                formula: f.formula,
                prefix: PREFIX,
                dataType: field.type,
            };
        });
    }, [sectionFields, fields, sectionId]);
    var addNewRow = function (row, index) {
        var section = sections === null || sections === void 0 ? void 0 : sections.find(function (c) { return +c.id === sectionId; });
        var sf = sectionFields === null || sectionFields === void 0 ? void 0 : sectionFields.filter(function (s) { return +s.sectionId === sectionId; });
        var formFields = sf.map(function (f) {
            var field = fields === null || fields === void 0 ? void 0 : fields.find(function (d) { return +d.id === +f.fieldId; });
            return {
                fieldId: +f.fieldId,
                fieldName: "" + PREFIX + f.id,
                title: f.customTitle || field.title,
                type: types[field.type],
                defaultValue: (row === null || row === void 0 ? void 0 : row["" + PREFIX + f.id]) || field.defaultValue,
                options: [],
                group: f.groupTitle || '',
                noPrepend: true,
                formula: f.formula,
                prefix: PREFIX,
                dataType: field.type,
            };
        });
        formFields.push({
            fieldId: 0,
            fieldName: "id",
            title: 'id',
            type: 'number',
            dataType: 1,
            defaultValue: (row === null || row === void 0 ? void 0 : row.id) || 0,
            options: [],
            hidden: true,
            group: '',
        });
        ModalForm.show({
            title: (section === null || section === void 0 ? void 0 : section.title) + " Details",
            md: true,
            footer: false,
            body: _jsx(FormDynamic, { inline: true, formFields: formFields, fullWidth: true, onSubmit: function (data) {
                    if (!data.id) {
                        setRows(rows.concat(data));
                    }
                    else {
                        setRows(rows.reduce(function (result, curr) {
                            if (+data.id === +curr.id) {
                                result.push(__assign(__assign({}, data), { edit: true }));
                            }
                            else {
                                result.push(curr);
                            }
                            return result;
                        }, []));
                    }
                    ModalForm.close();
                } }, void 0)
        });
    };
    var columns = useMemo(function () {
        var sf = sectionFields === null || sectionFields === void 0 ? void 0 : sectionFields.filter(function (s) { return +s.sectionId === sectionId && s.showInGrid; });
        return sf.map(function (f) {
            var field = fields === null || fields === void 0 ? void 0 : fields.find(function (d) { return +d.id === +f.fieldId; });
            return {
                title: f.customTitle || field.title,
                id: f.id,
                type: field.type,
                fieldId: field.id,
            };
        });
    }, [sectionFields, sectionId]);
    var removeRow = function (index) {
        setRows(rows.reduce(function (result, curr, ind) {
            if (+index === +ind) {
                result.push(__assign(__assign({}, curr), { deleted: true }));
            }
            else {
                result.push(curr);
            }
            return result;
        }, []));
    };
    var rowClicked = function (row, index) { return function (e) {
        if (e.target.localName === 'path')
            return;
        addNewRow(row, index);
    }; };
    var onCustomFieldClosed = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loadCustomFields(apolloClient, dispatch)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getValues = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, r_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSectionValuesByJob(jobId, sectionId, PREFIX, table)(apolloClient)];
                case 1:
                    data = _a.sent();
                    setRows(data);
                    if (displayForm && (data === null || data === void 0 ? void 0 : data.length)) {
                        r_1 = {};
                        Object.keys(data === null || data === void 0 ? void 0 : data[0]).map(function (key) {
                            var _a;
                            r_1 = __assign(__assign({}, r_1), (_a = {}, _a[key] = data === null || data === void 0 ? void 0 : data[0][key], _a));
                        });
                        setFormValue(r_1);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (jobId) {
            getValues();
        }
    }, [jobId]);
    var saveChanges = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, upsertSections(rows, +triggerSections, sectionId, PREFIX, table)(apolloClient)];
                case 1:
                    _a.sent();
                    dispatch(setAddSections(''));
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (triggerSections) {
            saveChanges();
        }
    }, [triggerSections]);
    var getRowValue = function (col, row) {
        if (+col.type === COLUMN_TYPE_NUMBER.SELECT) {
            var option = fieldSelectOption.find(function (c) { return +c.fieldId === +col.fieldId && +c.id === +row["" + PREFIX + col.id]; });
            return (option === null || option === void 0 ? void 0 : option.option) || '';
        }
        else if (+col.type === COLUMN_TYPE_NUMBER.NUMBER) {
            return formatNumbers(row["" + PREFIX + col.id]);
        }
        return row["" + PREFIX + col.id];
    };
    var handleDateChanged = function (key) { return function (date) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, upsertCustomSection(key)({
                        target: {
                            value: formatDate(date, 'yyyy-MM-dd')
                        },
                    })];
                case 1:
                    _b.sent();
                    setFormValue(__assign(__assign({}, formValue), (_a = {}, _a[key] = formatDate(date, 'yyyy-MM-dd'), _a)));
                    return [2 /*return*/];
            }
        });
    }); }; };
    var upsertCustomSection = function (key) { return function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var id;
        var _a, _b;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0: return [4 /*yield*/, upsertCustomField(((_c = rows === null || rows === void 0 ? void 0 : rows[0]) === null || _c === void 0 ? void 0 : _c.id) || 0, +key.replace(PREFIX, ''), e.target.value, +jobId, sectionId, table, (_d = rows === null || rows === void 0 ? void 0 : rows[0]) === null || _d === void 0 ? void 0 : _d[key])(apolloClient)];
                case 1:
                    id = _e.sent();
                    if (rows === null || rows === void 0 ? void 0 : rows.length) {
                        setRows([
                            __assign(__assign({}, rows[0]), (_a = {}, _a[key] = e.target.value, _a))
                        ]);
                    }
                    else {
                        setRows([
                            (_b = {
                                    id: id
                                },
                                _b[key] = e.target.value,
                                _b)
                        ]);
                    }
                    return [2 /*return*/];
            }
        });
    }); }; };
    var createField = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("job/addCustomFieldSectionToJob", {
                        title: newField,
                    })];
                case 1:
                    data = _a.sent();
                    if (data) {
                        dispatch(addNewSectionField({
                            customTitle: data.title,
                            fieldId: data.fieldId,
                            formula: null,
                            groupTitle: "",
                            id: data.id,
                            sectionId: sectionId,
                            showInGrid: null,
                        }));
                    }
                    setNewField(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleFormValue = function (key) { return function (e) {
        var _a;
        setFormValue(__assign(__assign({}, formValue), (_a = {}, _a[key] = e.target.value, _a)));
    }; };
    return (_jsxs("div", { children: [displayForm ? (_jsxs(Card, { children: [_jsxs(CardHeader, __assign({ className: "p-2" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Custom Fields" }), void 0)] }), void 0),
                            canEdit &&
                                _jsx("div", __assign({ className: "dib right w-50" }, { children: _jsxs(Button, __assign({ onClick: function () { return ModalForm.show({ onClose: function () { return onCustomFieldClosed(); }, title: 'Custom Fields', footer: false, large: true, body: (_jsx(CustomFieldList, { sectionId: sectionId }, void 0)) }); }, color: "default", className: "btn-xss" }, { children: [_jsx("i", { className: "fa fa-cog" }, void 0), " Manage"] }), void 0) }), void 0)] }), void 0),
                    _jsxs(CardBody, { children: [getFormRows.map(function (row) { return (_jsxs("div", __assign({ className: 'db w-100 pt-2 pb-2 bb-dotted' }, { children: [_jsx("div", __assign({ className: 'dib w-50' }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: row.title }), void 0) }), void 0),
                                    _jsx("div", __assign({ className: 'dib w-50 right' }, { children: canEdit ? ([FIELD_TYPE.SINGLE_SELECT, FIELD_TYPE.Multi_SELECT].includes(row.dataType) ?
                                            _jsx(InputText, { select: true, className: "min-w-180", onChange: function (e) {
                                                    upsertCustomSection(row.fieldName)(e);
                                                    handleFormValue(row.fieldName)(e);
                                                }, menuItems: row.options, displayText: 'option', extendMenu: false, value: formValue[row.fieldName] || '' }, void 0)
                                            :
                                                row.dataType === FIELD_TYPE.DATE ?
                                                    _jsx(ReactDatePicker, { inputWidth: "max-w-180", selected: formValue[row.fieldName] ? toDate(formValue[row.fieldName]) : '', onChange: handleDateChanged(row.fieldName) }, void 0)
                                                    :
                                                        _jsx(InputText, { placeholder: 'Click to set the value', onBlur: upsertCustomSection(row.fieldName), onChange: handleFormValue(row.fieldName), value: formValue[row.fieldName] || '' }, void 0)) : (_jsx(Typography, __assign({ variant: "subtitle2" }, { children: formValue[row.fieldName] || '' }), void 0)) }), void 0)] }), "row_mylist-" + row.fieldId)); }),
                            newField && (_jsx("div", __assign({ className: 'db w-100 pt-2' }, { children: _jsx("div", __assign({ className: 'dib w-50' }, { children: _jsx(InputText, { onBlur: createField, onChange: function (e) { return setNewField(e.target.value); }, value: newField }, void 0) }), void 0) }), void 0))] }, void 0)] }, void 0)) : (_jsxs(Table, __assign({ className: "mt-24 " }, { children: [_jsx(TableHead, __assign({ className: 'text-primary' }, { children: _jsxs(TableRow, { children: [columns.map(function (col) { return (_jsx(TableCell, { children: _jsx("span", { children: col.title }, void 0) }, "column_" + col.id)); }),
                                _jsx(TableCell, { children: "Remove" }, void 0)] }, void 0) }), void 0),
                    _jsx(TableBody, { children: rows.map(function (row, index) { return (_jsxs(TableRow, __assign({ className: row.deleted ? "none" : "pointer", onClick: rowClicked(row, index) }, { children: [columns.map(function (col) { return (_jsx(TableCell, { children: getRowValue(col, row) }, void 0)); }),
                                _jsx(TableCell, { children: canEdit && (_jsx(DeleteButton, { index: index, onClick: removeRow }, void 0)) }, void 0)] }), "section_" + index)); }) }, void 0)] }), void 0)),
            (canEdit && !displayForm) && (_jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue btn btn-secondary", onClick: addNewRow }, { children: [_jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0), " Add"] }), void 0))] }, void 0));
};
