var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useEffect, useRef } from "react";
import { Avatar, Tooltip } from "@material-ui/core";
import { Collapse, Navbar, NavbarToggler, Nav, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, InputGroup, InputGroupText, InputGroupAddon, Input, } from "reactstrap";
import { getUserName, getUserRole, logOut } from "../services/user-service";
import { SEARCH_APP } from "../graphqls/jobs/job.query";
import { useMutation } from "@apollo/client";
import useOnClickOutside from "../hooks/useOnClickOutside.hooks";
import { SmallJobList } from "../components/appraisals/Small-Jobs.component";
import { Link, useHistory } from "react-router-dom";
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import { getCompanyName } from "../utils/Storage.util";
import { Roles } from "@enums";
import { isMobile } from 'react-device-detect';
import { useDispatch } from "react-redux";
import { setSidebarLeft } from "../stores/app.reducer";
import SearchIcon from '@material-ui/icons/Search';
import { ModalForm } from "../ui-components/modals/Modal-Form.component";
import { AppSearchBar } from "../components/app/mobile/App-Search.component";
import { JOB_VIEW_URL } from "../routes";
import RoomIcon from '@material-ui/icons/Room';
var urls = [
    { id: 'LOGOUT', url: '/login' },
    { id: 'PROFILE', url: '/admin/profile' },
    { id: 'SUBSCRIPTION', url: '/admin/subscription' },
    { id: 'BILLING', url: '/admin/billing' },
];
export var NavBar = function (_a) {
    var _b = _a.location, location = _b === void 0 ? { pathname: '' } : _b;
    var history = useHistory();
    var role = getUserRole();
    var company = getCompanyName();
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useState(''), search = _d[0], setSearch = _d[1];
    var _e = useState(null), result = _e[0], setResult = _e[1];
    var _f = useState(false), dropdownOpen = _f[0], setDropdownOpen = _f[1];
    var userName = getUserName();
    var dispatch = useDispatch();
    var ref = useRef(null);
    var displaySearchArea = function () {
        ModalForm.show({
            md: true,
            footer: false,
            title: '',
            top: true,
            body: (_jsx(AppSearchBar, { onSelect: function (id) { history.push(JOB_VIEW_URL.replace('{id}', id)); ModalForm.close(); } }, void 0))
        });
    };
    useOnClickOutside(ref, function () {
        setResult(null);
        setSearch('');
    });
    var searchInApp = useMutation(SEARCH_APP, {
        onCompleted: function (data) {
            var _a;
            setResult((_a = data === null || data === void 0 ? void 0 : data.spSearchInApp) === null || _a === void 0 ? void 0 : _a.results);
        }
    })[0];
    var openSidebar = useCallback(function () {
        setIsOpen(!isOpen);
        if (isMobile) {
            dispatch(setSidebarLeft('260'));
        }
    }, [isOpen]);
    var toggle = useCallback(function () {
    }, []);
    var dropdownToggle = useCallback(function (e) {
        var _a, _b;
        if ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.id) {
            if (e.target.id === "LOGOUT") {
                logOut();
                document.location.href = "/login";
                return;
            }
            else {
                history.push("" + ((_b = urls.find(function (u) { return u.id === e.target.id; })) === null || _b === void 0 ? void 0 : _b.url));
            }
        }
        setDropdownOpen(!dropdownOpen);
    }, [dropdownOpen]);
    var getBrand = function () {
        return "";
    };
    var searchByJob = function (e) {
        var _a;
        setSearch((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
    };
    useEffect(function () {
        if (search.length >= 2) {
            searchInApp({
                variables: {
                    criteria: "%" + search + "%",
                }
            });
        }
        else {
            setResult(null);
        }
    }, [search]);
    return (_jsx(Navbar, __assign({ color: "dark", expand: "lg", className: "navbar-absolute fixed-top navbar-transparent" }, { children: _jsxs(Container, __assign({ fluid: true }, { children: [_jsxs("div", __assign({ className: "navbar-wrapper " + (isMobile ? 'flex-1' : '') }, { children: [_jsx("div", __assign({ className: "navbar-toggle" }, { children: _jsxs("button", __assign({ type: "button", className: "navbar-toggler", onClick: function () { return openSidebar(); } }, { children: [_jsx("span", { className: "navbar-toggler-bar bar1" }, void 0),
                                    _jsx("span", { className: "navbar-toggler-bar bar2" }, void 0),
                                    _jsx("span", { className: "navbar-toggler-bar bar3" }, void 0)] }), void 0) }), void 0),
                        isMobile &&
                            _jsxs("div", __assign({ className: "flex flex-1" }, { children: [_jsx("div", __assign({ className: "logo" }, { children: _jsx("div", __assign({ title: company, className: "simple-text logo-normal company-name" }, { children: company || userName }), void 0) }), void 0),
                                    _jsxs("div", __assign({ className: "flex-end flex-1" }, { children: [_jsx("div", __assign({ className: "calendar-icon-header" }, { children: _jsx("div", __assign({ className: "flex gap-4 uppercase f-noto" }, { children: _jsx(SearchIcon, { className: "pointer", onClick: displaySearchArea }, void 0) }), void 0) }), void 0),
                                            _jsx("div", __assign({ className: "calendar-icon-header" }, { children: _jsx(Link, __assign({ to: "/admin/map", className: "flex gap-4 uppercase f-noto" }, { children: _jsx(Tooltip, __assign({ title: "Appraisal Map", arrow: true }, { children: _jsx(RoomIcon, {}, void 0) }), void 0) }), void 0) }), void 0),
                                            _jsx("div", __assign({ className: "calendar-icon-header" }, { children: _jsx(Link, __assign({ to: "/admin/calendar", className: "flex gap-4 uppercase f-noto" }, { children: _jsx(Tooltip, __assign({ title: "Calendar", arrow: true }, { children: _jsx(EventNoteOutlinedIcon, {}, void 0) }), void 0) }), void 0) }), void 0),
                                            _jsx("div", __assign({ className: "calendar-icon-header" }, { children: _jsx(Link, __assign({ to: "/admin/profile", className: "flex gap-4 uppercase f-noto" }, { children: _jsx(Avatar, __assign({ className: "user-avatar" }, { children: userName.charAt(0).toUpperCase() }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0),
                !isMobile &&
                    _jsxs(NavbarToggler, __assign({ onClick: function () { return toggle(); } }, { children: [_jsx("span", { className: "navbar-toggler-bar navbar-kebab" }, void 0),
                            _jsx("span", { className: "navbar-toggler-bar navbar-kebab" }, void 0),
                            _jsx("span", { className: "navbar-toggler-bar navbar-kebab" }, void 0)] }), void 0),
                !isMobile && _jsx("form", { children: _jsx("div", __assign({ className: "logo" }, { children: _jsx("div", __assign({ title: company, className: "simple-text logo-normal company-name" }, { children: company || userName }), void 0) }), void 0) }, void 0),
                !isMobile && _jsxs(Collapse, __assign({ isOpen: isOpen, navbar: true, className: "justify-content-end" }, { children: [_jsxs("div", __assign({ className: "mr-16 search-area-header" }, { children: [_jsxs(InputGroup, __assign({ className: "no-border" }, { children: [_jsx(InputGroupAddon, __assign({ addonType: "prepend" }, { children: _jsx(InputGroupText, { children: _jsx("i", { className: "nc-icon nc-zoom-split" }, void 0) }, void 0) }), void 0),
                                        _jsx(Input, { placeholder: "Search...", onChange: searchByJob, className: "input-search-bar", value: search }, void 0)] }), void 0),
                                ((result === null || result === void 0 ? void 0 : result.length) || search.length >= 2) && (_jsx("div", __assign({ className: "search-bar-menu", ref: ref }, { children: _jsx(SmallJobList, { jobs: result, className: '' }, void 0) }), void 0))] }), void 0),
                        _jsx("div", __assign({ className: "calendar-icon-header" }, { children: _jsx(Link, __assign({ to: "/admin/map", className: "flex gap-4 uppercase f-noto" }, { children: _jsx(Tooltip, __assign({ title: "Appraisal Map", arrow: true }, { children: _jsx(RoomIcon, {}, void 0) }), void 0) }), void 0) }), void 0),
                        _jsx("div", __assign({ className: "calendar-icon-header" }, { children: _jsx(Link, __assign({ to: "/admin/calendar", className: "flex gap-4 uppercase f-noto" }, { children: _jsx(Tooltip, __assign({ title: "Calendar", arrow: true }, { children: _jsx(EventNoteOutlinedIcon, {}, void 0) }), void 0) }), void 0) }), void 0),
                        _jsx(Nav, __assign({ navbar: true }, { children: _jsxs(Dropdown, __assign({ nav: true, isOpen: dropdownOpen, toggle: function (e) { return dropdownToggle(e); } }, { children: [_jsx(DropdownToggle, __assign({ nav: true }, { children: _jsx(Avatar, __assign({ className: "user-avatar" }, { children: userName.charAt(0).toUpperCase() }), void 0) }), void 0),
                                    _jsxs(DropdownMenu, __assign({ bottom: "true" }, { children: [_jsx(DropdownItem, __assign({ tag: "a", id: "PROFILE" }, { children: "My Profile" }), void 0),
                                            role === Roles.ADMIN && _jsx(DropdownItem, __assign({ tag: "a", id: "SUBSCRIPTION" }, { children: "Subscription" }), void 0),
                                            role === Roles.ADMIN && _jsx(DropdownItem, __assign({ tag: "a", id: "BILLING" }, { children: "Billing" }), void 0),
                                            _jsx(DropdownItem, { divider: true }, void 0),
                                            _jsx(DropdownItem, __assign({ tag: "a", id: "LOGOUT" }, { children: "Log out" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
};
