var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var UPDATE_LAT_LNG_JOB = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation updateTblJobById($id: BigInt!, $input: TblJobPatch!) {\n  updateTblJobById(input: { tblJobPatch: $input, id: $id }) {\n    clientMutationId\n  }\n}\n"], ["mutation updateTblJobById($id: BigInt!, $input: TblJobPatch!) {\n  updateTblJobById(input: { tblJobPatch: $input, id: $id }) {\n    clientMutationId\n  }\n}\n"])));
export var ALL_JOBS_BY_LICENCE = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["query allTblJobsByLicence($licenceId: BigInt) {\n  allTblJobs(condition: {licenceId: $licenceId}, orderBy: ID_ASC) {\n    nodes {\n      unit\n      streetNo\n      streetName\n      province\n      postalCode\n      zzFullAddress\n      city\n      county\n      countryName\n      id\n      lat\n      lng\n    }\n  }\n}\n"], ["query allTblJobsByLicence($licenceId: BigInt) {\n  allTblJobs(condition: {licenceId: $licenceId}, orderBy: ID_ASC) {\n    nodes {\n      unit\n      streetNo\n      streetName\n      province\n      postalCode\n      zzFullAddress\n      city\n      county\n      countryName\n      id\n      lat\n      lng\n    }\n  }\n}\n"])));
export var GET_CURRENT_APPRAISER = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nquery getCurrentJobAppaiser($id: BigInt, $appid: BigInt!) {\n  allTblPropertyAppraisers(filter: { jobId: { equalTo: $id } }) {\n    nodes {\n      rowId\n      id\n      jobId\n    }\n  }\n  tblAppraiserById(id: $appid) {\n    reviewer\n  }\n}\n\n"], ["\nquery getCurrentJobAppaiser($id: BigInt, $appid: BigInt!) {\n  allTblPropertyAppraisers(filter: { jobId: { equalTo: $id } }) {\n    nodes {\n      rowId\n      id\n      jobId\n    }\n  }\n  tblAppraiserById(id: $appid) {\n    reviewer\n  }\n}\n\n"])));
export var ADD_IMIDIATE_APPRAISER = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation addAppraisor($jobId: BigInt!, $appid: BigInt!, $reportFee: BigFloat, $feeType:Int, $role:Int) {\n  createTblPropertyAppraiser(\n    input: {tblPropertyAppraiser: { feeType: $feeType, jobId: $jobId, reportFee: $reportFee, id: $appid, role: $role}}\n  ) {\n    clientMutationId\n  }\n}\n"], ["\nmutation addAppraisor($jobId: BigInt!, $appid: BigInt!, $reportFee: BigFloat, $feeType:Int, $role:Int) {\n  createTblPropertyAppraiser(\n    input: {tblPropertyAppraiser: { feeType: $feeType, jobId: $jobId, reportFee: $reportFee, id: $appid, role: $role}}\n  ) {\n    clientMutationId\n  }\n}\n"])));
export var UPDATE_REVIEWER = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["mutation updateReviewer($role: Int!, $id: BigInt!) {\n  updateTblPropertyAppraiserByRowId(\n    input: { tblPropertyAppraiserPatch: { role: $role }, rowId: $id }\n  ) {\n    clientMutationId\n  }\n}\n"], ["mutation updateReviewer($role: Int!, $id: BigInt!) {\n  updateTblPropertyAppraiserByRowId(\n    input: { tblPropertyAppraiserPatch: { role: $role }, rowId: $id }\n  ) {\n    clientMutationId\n  }\n}\n"])));
export var UPDATE_APPRAISER_FEE = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\nmutation updateReportFee(\n  $feeType: Int!\n  $reportFee: BigFloat!\n  $id: BigInt!\n) {\n  updateTblPropertyAppraiserByRowId(\n    input: {\n      tblPropertyAppraiserPatch: { feeType: $feeType, reportFee: $reportFee }\n      rowId: $id\n    }\n  ) {\n    clientMutationId\n  }\n}\n"], ["\nmutation updateReportFee(\n  $feeType: Int!\n  $reportFee: BigFloat!\n  $id: BigInt!\n) {\n  updateTblPropertyAppraiserByRowId(\n    input: {\n      tblPropertyAppraiserPatch: { feeType: $feeType, reportFee: $reportFee }\n      rowId: $id\n    }\n  ) {\n    clientMutationId\n  }\n}\n"])));
export var DELETE_FROM_JOB_APPRAISER = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\nmutation deleteAppariserByRowId($id: BigInt!) {\n  deleteTblPropertyAppraiserByRowId(input: {rowId: $id}) {\n    tblPropertyAppraiser {\n      rowId\n    }\n  }\n}\n"], ["\nmutation deleteAppariserByRowId($id: BigInt!) {\n  deleteTblPropertyAppraiserByRowId(input: {rowId: $id}) {\n    tblPropertyAppraiser {\n      rowId\n    }\n  }\n}\n"])));
export var ADDSINGLEJOBAPPRAISER = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\nmutation addAppraisor($jobId: BigInt!, $appid: BigInt!, $reportFee: BigFloat) {\n  createTblPropertyAppraiser(\n    input: {tblPropertyAppraiser: { feeType:0, jobId: $jobId, reportFee: $reportFee, id: $appid}}\n  ) {\n    tblAppraiserById {\n      lastName\n      firstName\n      id\n      title\n      salutation\n    }\n    tblPropertyAppraiser {\n      rowId\n      reportFee\n      feeType\n      role\n    }\n  }\n}\n"], ["\nmutation addAppraisor($jobId: BigInt!, $appid: BigInt!, $reportFee: BigFloat) {\n  createTblPropertyAppraiser(\n    input: {tblPropertyAppraiser: { feeType:0, jobId: $jobId, reportFee: $reportFee, id: $appid}}\n  ) {\n    tblAppraiserById {\n      lastName\n      firstName\n      id\n      title\n      salutation\n    }\n    tblPropertyAppraiser {\n      rowId\n      reportFee\n      feeType\n      role\n    }\n  }\n}\n"])));
export var ADDAPPRAISOR = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\nmutation addAppraisor($propertyId: BigInt!, $id: BigInt!) {\n  createTblPropertyAppraiser(\n    input: {\n      tblPropertyAppraiser: {\n        propertyId: $propertyId\n        id: $id\n      }\n    }\n  ) {\n    tblPropertyAppraiser {\n      tblAppraiserById {\n        firstName\n        lastName\n        title\n        id\n      }\n    }\n  }\n}\n"], ["\nmutation addAppraisor($propertyId: BigInt!, $id: BigInt!) {\n  createTblPropertyAppraiser(\n    input: {\n      tblPropertyAppraiser: {\n        propertyId: $propertyId\n        id: $id\n      }\n    }\n  ) {\n    tblPropertyAppraiser {\n      tblAppraiserById {\n        firstName\n        lastName\n        title\n        id\n      }\n    }\n  }\n}\n"])));
export var TBLPROPERTY = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\nquery getAllProperties($offset: Int,$first: Int,$orderBy:[TblPropertiesOrderBy!]) {\n\n  allTblProperties(first: $first, offset: $offset,orderBy:$orderBy) {\n    totalCount\n    nodes {\n      id\n      municipalityId\n      nodeId\n      owner\n      postalCode\n      propertyTypeId\n      province\n      streetName\n      streetNo\n      zctFullAddress\n      unit\n      zzCreatedBy\n      zzCreationDate\n      zzModifiedBy\n      zzModifiedDate\n      zzAssignDate\n      zzDueDate\n      tblMunicipalityByMunicipalityId {\n        region\n        municipality\n      }\n      tblPropertyTypeByPropertyTypeId {\n        propertyType\n        propertySubType\n      }\n      tblStatusByStatusId {\n        statusTitle\n      }\n      tblTypeByTypeId {\n        typeTitle\n      }\n      tblClientByClientId {\n        name\n      }\n    }\n  }\n\n}\n"], ["\nquery getAllProperties($offset: Int,$first: Int,$orderBy:[TblPropertiesOrderBy!]) {\n\n  allTblProperties(first: $first, offset: $offset,orderBy:$orderBy) {\n    totalCount\n    nodes {\n      id\n      municipalityId\n      nodeId\n      owner\n      postalCode\n      propertyTypeId\n      province\n      streetName\n      streetNo\n      zctFullAddress\n      unit\n      zzCreatedBy\n      zzCreationDate\n      zzModifiedBy\n      zzModifiedDate\n      zzAssignDate\n      zzDueDate\n      tblMunicipalityByMunicipalityId {\n        region\n        municipality\n      }\n      tblPropertyTypeByPropertyTypeId {\n        propertyType\n        propertySubType\n      }\n      tblStatusByStatusId {\n        statusTitle\n      }\n      tblTypeByTypeId {\n        typeTitle\n      }\n      tblClientByClientId {\n        name\n      }\n    }\n  }\n\n}\n"])));
export var Columns = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
    },
    {
        caption: "Status",
        fieldName: "tblStatusByStatusId.statusTitle",
        sortField: "STATUS_ID",
        sortable: true,
    },
    {
        caption: "Type",
        fieldName: "tblTypeByTypeId.typeTitle",
        sortField: "TYPE_ID",
        sortable: true,
    },
    {
        caption: "Address",
        fieldName: "zctFullAddress",
        sortField: "",
        childColumns: [
            {
                caption: "",
                fieldName: "tblMunicipalityByMunicipalityId.municipality",
            },
            {
                caption: "",
                fieldName: "province",
            },
        ]
    },
    {
        caption: "Client",
        fieldName: "tblClientByClientId.name",
        sortField: "NAME",
        sortable: true,
    },
    {
        caption: "Owner",
        fieldName: "owner",
        sortField: "",
    },
    {
        caption: "Property Type",
        fieldName: "tblPropertyTypeByPropertyTypeId.propertyType",
        sortField: "PROPERTY_TYPE_ID",
        sortable: true,
    },
    {
        caption: "Created",
        fieldName: "zzCreationDate",
        sortField: "ZZ_CREATION_DATE",
        sortable: true,
    }
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
