var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Row, Button as ReactButton, CardHeader, Button } from "reactstrap";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GETJOBBYID, UPDATE_JOB_STATUS } from "../../../graphqls/jobs/job.query";
import { GetUserId, canEditRequest, isAppraiser, isClient } from "../../../services/user-service";
import { formatTotalAsCurrency, includeHst } from "../../../helpers/numbers-helper.helper";
import { useDispatch, useSelector } from "react-redux";
import { getAppraiserStatuses, getOptions } from "../../../stores/client.selector";
import { map } from "lodash";
import { StatusEnum, APPRAISER_ROLE, DOCUMENT_TYPE } from "@enums";
import EditIcon from '@material-ui/icons/Edit';
import { JobComponent } from "../edit/Edit.component";
import { InputText } from "../../../ui-components/input/Input-Field.component";
import { PopupForm } from "../../../ui-components/forms/Popup-Form";
import { jobInEditMode } from "../../../stores/app.reducer";
import { formatDate, toDate } from "@utils/date";
import { useApolloQuery } from "../../../hooks/use-appolo.hooks";
import { formatAddress, updateReviewDate } from "../../../services/job.service";
import { Note } from "../../../components/note/Note.component";
import { ReactDatePicker } from "../../../ui-components/date-picker/DatePicker.component";
import { httpGet, httpPost } from "../../../helpers/http.helper";
import { GoogleMap } from "../../../ui-components/google-map/googleMap";
import { getAppModules } from "../../../stores/app.selector";
import { isMobile } from "react-device-detect";
export var RequestDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = _a.jobId, jobId = _l === void 0 ? "" : _l, _m = _a.setJobAppr, setJobAppr = _m === void 0 ? null : _m, _o = _a.setParentJob, setParentJob = _o === void 0 ? null : _o, _p = _a.modal, modal = _p === void 0 ? false : _p, _q = _a.drawer, drawer = _q === void 0 ? false : _q;
    var _r = useState(null), job = _r[0], setJob = _r[1];
    var _s = useState(false), showAppDate = _s[0], setShowAppDate = _s[1];
    var _t = useState(false), showDueDate = _t[0], setShowDueDate = _t[1];
    var _isAppraiser = isAppraiser();
    var _isClient = isClient();
    var editable = canEditRequest();
    var jobStatuses = useSelector(getAppraiserStatuses);
    var options = useSelector(getOptions);
    var dispatch = useDispatch();
    var apolloClient = useApolloQuery()[0];
    var appModule = useSelector(getAppModules);
    var updateJobStatus = useMutation(UPDATE_JOB_STATUS, {
        onCompleted: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, logJob()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var logJob = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpGet("job/logJob/" + (job === null || job === void 0 ? void 0 : job.id))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var _u = useLazyQuery(GETJOBBYID, {
        onCompleted: function (data) {
            mountData(data);
            window.scrollTo(0, 0);
        }
    }), getJobById = _u[0], refetch = _u[1].refetch;
    var updateReviewDates = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setJob(__assign(__assign({}, job), { reviewDate: formatDate(new Date(), 'yyyy-MM-dd') }));
                    return [4 /*yield*/, updateReviewDate(job.id, new Date(), apolloClient)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var isReviewer = useMemo(function () {
        var _a, _b, _c;
        return _isAppraiser
            && ((_c = (_b = (_a = job === null || job === void 0 ? void 0 : job.tblPropertyAppraisersByJobId) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.find(function (pa) { return +pa.tblAppraiserById.id === +GetUserId(); })) === null || _c === void 0 ? void 0 : _c.role) === APPRAISER_ROLE.REVIEWER;
    }, [job]);
    var mountData = function (data) {
        var _a, _b;
        setJob(data.tblJobById);
        if (setJobAppr) {
            setJobAppr(((_b = (_a = data.tblJobById) === null || _a === void 0 ? void 0 : _a.tblPropertyAppraisersByJobId) === null || _b === void 0 ? void 0 : _b.nodes) || []);
        }
        if (setParentJob) {
            setParentJob(data.tblJobById);
        }
    };
    var updateStatus = function (e) {
        var _a, _b;
        var newStatus = +((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
        if (newStatus === StatusEnum.APPROVED) {
            return;
        }
        updateJobStatus({
            variables: {
                id: job === null || job === void 0 ? void 0 : job.id,
                status: newStatus,
            },
        });
        setJob(__assign(__assign({}, job), { statusId: newStatus, tblStatusByStatusId: __assign(__assign({}, job === null || job === void 0 ? void 0 : job.tblStatusByStatusId), { statusTitle: (_b = jobStatuses.find(function (c) { return +c.id === newStatus; })) === null || _b === void 0 ? void 0 : _b.title }) }));
    };
    var refetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!refetch) return [3 /*break*/, 2];
                    return [4 /*yield*/, refetch()];
                case 1:
                    data = (_a.sent()).data;
                    mountData(data);
                    dispatch(jobInEditMode(false));
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var editJob = function () {
        dispatch(jobInEditMode(true));
        PopupForm.show({
            title: "",
            footer: !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only),
            fullWidth: true,
            save: 'Save Changes',
            className: 'modal-body-p-0',
            scope: 'save-job-details',
            onClose: function () { dispatch(jobInEditMode(false)); },
            body: (_jsx(JobComponent, { id: jobId, isModal: true, onDone: refetchData }, void 0))
        });
    };
    var updateDueDate = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateJobDetails(job === null || job === void 0 ? void 0 : job.jobAppDate, e)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateAppDate = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateJobDetails(e, job === null || job === void 0 ? void 0 : job.jobDueDate)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateJobDetails = function (appDate, dueDate) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("job/update/appointment/" + jobId, { jobId: jobId, date: appDate, dueDate: dueDate })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refetchData()];
                case 2:
                    _a.sent();
                    setShowAppDate(false);
                    setShowDueDate(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getJobById({
            variables: {
                id: +jobId
            }
        });
    }, [getJobById, jobId]);
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, __assign({ className: isMobile ? '' : "no-pad-right", xs: 12 }, { children: _jsxs(Card, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "db" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: ["  ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Job Details" }), void 0)] }), void 0),
                                        (editable && !drawer && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only)) && (_jsx("div", __assign({ className: "dib right w-50" }, { children: _jsxs(ReactButton, __assign({ onClick: editJob, color: "default", className: "btn-xss" }, { children: [_jsx(EditIcon, { style: { fontSize: 14 } }, void 0), "  Edit"] }), void 0) }), void 0))] }), void 0) }), void 0),
                            _jsx(CardBody, { children: _jsxs(Grid, __assign({ container: true, className: "grid-view-only" }, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Created Date" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isReviewer ? 3 : 8 }, { children: formatDate(job === null || job === void 0 ? void 0 : job.zzCreatedDate, 'MMM dd, yyyy') }), void 0),
                                        isReviewer && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 2 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Review Date" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 3, className: "mt-0" }, { children: (job === null || job === void 0 ? void 0 : job.reviewDate) ? (_jsxs("span", { children: [" ", formatDate(job === null || job === void 0 ? void 0 : job.reviewDate, 'MMM dd, yyyy')] }, void 0)) : (_jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue " + (!(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) ? '' : 'none'), onClick: updateReviewDates }, { children: [_jsx("i", { className: "fa fa-check", "aria-hidden": "true" }, void 0), " Click to Update"] }), void 0)) }), void 0)] }, void 0)),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Status" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: (_b = job === null || job === void 0 ? void 0 : job.tblStatusByStatusId) === null || _b === void 0 ? void 0 : _b.statusTitle.toUpperCase() }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Reference #" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: job === null || job === void 0 ? void 0 : job.jobNumber }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 2 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "File #" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 2 }, { children: job === null || job === void 0 ? void 0 : job.fileNumber }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Addresses" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: _jsxs("ul", __assign({ className: "no-pad-marg" }, { children: [_jsx("li", { children: formatAddress(job) }, void 0),
                                                    (((_c = job === null || job === void 0 ? void 0 : job.tblPropertiesByJobId) === null || _c === void 0 ? void 0 : _c.nodes) || []).map(function (p) { return (_jsx("li", { children: p === null || p === void 0 ? void 0 : p.zctFullAddress }, void 0)); })] }), void 0) }), void 0),
                                        !_isClient && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Client Name" }), void 0) }), void 0),
                                                _jsxs(Grid, __assign({ item: true, xs: 8 }, { children: [job && ((_d = job === null || job === void 0 ? void 0 : job.tblClientByClientId) === null || _d === void 0 ? void 0 : _d.name),
                                                        _jsx("span", __assign({ className: "caption" }, { children: ((_e = job === null || job === void 0 ? void 0 : job.tblClientByClientId) === null || _e === void 0 ? void 0 : _e.retainerRequired) ? ' (Retainer Required)' : '' }), void 0)] }), void 0)] }, void 0)),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Owner" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: job && (job === null || job === void 0 ? void 0 : job.owner) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Due Date" }), void 0) }), void 0),
                                        _jsxs(Grid, __assign({ item: true, xs: isMobile ? 8 : 3 }, { children: [(job === null || job === void 0 ? void 0 : job.jobDueDate) && !showDueDate && _jsxs("div", __assign({ className: "flex gap-8 pointer" }, { children: [job === null || job === void 0 ? void 0 : job.jobDueDate, " ", !_isClient && _jsx(EditIcon, { className: "text-blue", onClick: function () { return setShowDueDate(true); }, style: { fontSize: 14 } }, void 0), " "] }), void 0),
                                                !(job === null || job === void 0 ? void 0 : job.jobDueDate) && !showDueDate && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && !_isClient && _jsx(Button, __assign({ onClick: function () { return setShowDueDate(true); }, className: "btn btn-xxs btn-link text-blue" }, { children: "Click to set" }), void 0),
                                                showDueDate && _jsx(ReactDatePicker, { className: "max-w-110", selected: (job === null || job === void 0 ? void 0 : job.jobDueDate) ? toDate(job === null || job === void 0 ? void 0 : job.jobDueDate) : '', onChange: updateDueDate }, void 0)] }), void 0),
                                        !_isClient ? (_jsxs(Grid, __assign({ item: true, xs: isMobile ? 12 : 5, className: "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "body2" }, { children: "Appointment Date" }), void 0),
                                                (job === null || job === void 0 ? void 0 : job.jobAppDate) && !showAppDate && _jsxs("div", __assign({ className: "flex gap-8  pointer" }, { children: [job === null || job === void 0 ? void 0 : job.jobAppDate, " ", _jsx(EditIcon, { className: "text-blue", onClick: function () { return setShowAppDate(true); }, style: { fontSize: 14 } }, void 0)] }), void 0),
                                                !(job === null || job === void 0 ? void 0 : job.jobAppDate) && !showAppDate && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && _jsx(Button, __assign({ onClick: function () { return setShowAppDate(true); }, className: "btn btn-xxs btn-link text-blue" }, { children: "Click to set" }), void 0),
                                                showAppDate && _jsx(ReactDatePicker, { className: "max-w-110", selected: (job === null || job === void 0 ? void 0 : job.jobAppDate) ? toDate(job === null || job === void 0 ? void 0 : job.jobAppDate) : '', onChange: updateAppDate }, void 0)] }), void 0)) : (isMobile ? null : _jsx(Grid, { item: true, xs: 5 }, void 0)),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Delivery Timeline" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: job && (job === null || job === void 0 ? void 0 : job.delivery) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Property Type" }), void 0) }), void 0),
                                        _jsxs(Grid, __assign({ item: true, xs: 8 }, { children: [((_f = options === null || options === void 0 ? void 0 : options.find(function (c) { return +c.id === (job === null || job === void 0 ? void 0 : job.propertyType); })) === null || _f === void 0 ? void 0 : _f.title) || '',
                                                (job === null || job === void 0 ? void 0 : job.propertySubType) ? ' -> ' : '',
                                                ((_g = options === null || options === void 0 ? void 0 : options.find(function (c) { return +c.id === (job === null || job === void 0 ? void 0 : job.propertySubType); })) === null || _g === void 0 ? void 0 : _g.title) || ''] }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Intended use" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: ((_h = job === null || job === void 0 ? void 0 : job.tblOptionByFunction) === null || _h === void 0 ? void 0 : _h.title) || '' }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Interest" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: ((_j = job === null || job === void 0 ? void 0 : job.tblOptionByInterest) === null || _j === void 0 ? void 0 : _j.title) || '' }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Purpose" }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 8 }, { children: ((_k = job === null || job === void 0 ? void 0 : job.tblOptionByValueType) === null || _k === void 0 ? void 0 : _k.title) || '' }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Report Fee" }), void 0) }), void 0),
                                        _jsxs(Grid, __assign({ item: true, xs: 8 }, { children: [formatTotalAsCurrency(includeHst(+(job === null || job === void 0 ? void 0 : job.feeSplitAmount) || 0, +(job === null || job === void 0 ? void 0 : job.hst) || 0)), " (", formatTotalAsCurrency(job === null || job === void 0 ? void 0 : job.feeSplitAmount), " + ", job === null || job === void 0 ? void 0 : job.hst, "% HST)"] }), void 0),
                                        !_isClient && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "Update Status" }), void 0) }), void 0),
                                                _jsx(Grid, __assign({ item: true, xs: 8 }, { children: _jsx(InputText, __assign({ className: "pb-6 min-w-200", select: true, label: "", defaultValue: "" + (job === null || job === void 0 ? void 0 : job.statusId), value: "" + (job === null || job === void 0 ? void 0 : job.statusId), size: "small", onChange: updateStatus }, { children: map(jobStatuses, function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0) }), void 0)] }, void 0))] }), void 0) }, void 0)] }, void 0) }), void 0) }, void 0),
            _jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(Note, { header: 'Photo Gallery', documentType: DOCUMENT_TYPE.JOB_PHOTOS, jobId: job === null || job === void 0 ? void 0 : job.id, btn: 'Select Photos' }, void 0) }), void 0) }, void 0),
            (job === null || job === void 0 ? void 0 : job.lat) && (_jsx(Row, { children: _jsx(Col, __assign({ xs: 12, className: "mt-8" }, { children: _jsx(GoogleMap, { displayInfo: false, mapTypeControl: false, width: "100%", height: "400px", data: [job], zoom: 18 }, void 0) }), void 0) }, void 0))] }, void 0));
};
