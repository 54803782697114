var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from "reactstrap";
import { Table } from "../../ui-components/table/table.component";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { ALL_TBL_BATCH_PAYMENTS, BATCHPAYMENT_COLUMNS } from "../../graphqls/payments/payment.query";
import { tableRowClicked } from "../../helpers/form.helper";
import { ModalForm } from "../../ui-components/modals/Modal-Form.component";
import { BatchPaymentDetails } from "./batchPaymentDetails";
import { FormDynamic } from "../../ui-components/forms/Form-Dynamic";
import { PAYMENT_TYPES } from "@enums";
import { formatDate } from "@utils/date";
import { updateBatchPayment } from "../../services/payment.service";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { useState } from "react";
export var BatchPayment = function () {
    var _a = useState(false), reload = _a[0], setReload = _a[1];
    var apolloClient = useApolloQuery()[0];
    var rowClicked = function (e, id) {
        if (tableRowClicked(e)) {
            ModalForm.show({
                title: 'Batch Payment Details',
                footer: false,
                large: true,
                body: (_jsx(BatchPaymentDetails, { batchId: id }, void 0)),
            });
        }
    };
    var editPayments = function (id, rowData) {
        ModalForm.show({
            title: 'Batch Payment Edit',
            md: true,
            footer: false,
            body: _jsx(FormDynamic, { formFields: [
                    { fieldName: 'type', title: 'Type', defaultValue: rowData.type, type: 'string', options: PAYMENT_TYPES.map(function (c) { return ({
                            id: c,
                            title: c,
                        }); }) },
                    { fieldName: 'paymentDate', title: 'Payment Date', type: 'date', defaultValue: formatDate(rowData.paymentDate, 'yyyy-MM-dd'), required: true },
                    { fieldName: 'refNo', title: 'Ref#', defaultValue: rowData.refNo },
                    { fieldName: 'description', title: 'Description', defaultValue: rowData.description },
                    { fieldName: 'batchId', title: 'batchId', defaultValue: rowData.batchId, hidden: true },
                    { fieldName: 'id', title: 'id', defaultValue: rowData.id, hidden: true },
                ], onSubmit: function (data) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, updateBatchPayment(data)(apolloClient)];
                            case 1:
                                _a.sent();
                                ModalForm.close();
                                setReload(!reload);
                                return [2 /*return*/];
                        }
                    });
                }); } }, void 0)
        });
    };
    return (_jsxs("div", __assign({ className: "content" }, { children: [_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(ListHeader, { title: "Batch Payments" }, void 0) }), void 0) }, void 0),
            _jsx(Table, { tableName: "allTblBatchPayments", rowClicked: rowClicked, editRoute: editPayments, pKey: "batchId", reload: reload, query: ALL_TBL_BATCH_PAYMENTS, headers: BATCHPAYMENT_COLUMNS }, void 0)] }), void 0));
};
