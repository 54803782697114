var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { Table as Tablex, Row, Col, Button } from "reactstrap";
import { TableBody } from "./table-body.component";
import { TableHeader } from "./table-header.component";
import { TableFooter } from "./table-footer.component";
import { useLazyQuery } from "@apollo/client";
import { ColumnType, SelectorName } from "@enums";
import { Typography, MenuItem, Menu, Divider, Switch } from "@material-ui/core";
import { map, orderBy as sortOrder, groupBy as groupDataBy } from "lodash";
import { InputText } from "../input/Input-Field.component";
import { setFormData } from "../../helpers/form.helper";
import { useSelector } from "react-redux";
import { getAppraisers, getClients, getOptions, getStatuses } from "../../stores/client.selector";
import { Link } from "react-router-dom";
import { getTableColumnOrderViews, getTableViews, updateTableColumnOrders, updateTableViews } from "../../utils/Storage.util";
import { ReactDatePicker } from "../date-picker/DatePicker.component";
import { detectSameDates, formatDate, toDate } from "@utils/date";
import { prepareDataForExport, toExcel, toPdf } from "../../helpers/doc.helper";
import { Spinner as Loader } from "../../ui-components/loading/Spinner.component";
import { TableTotalFooter } from "./tableTotalFooter";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { uuid } from "@utils/text";
import { DataLoader } from "./dataLoader";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { isMobile } from 'react-device-detect';
import { getAppModules } from "../../stores/app.selector";
import CloseIcon from '@material-ui/icons/Close';
import { EmptyDataError } from './EmptyDataError';
import { ICAutoComplete } from "../autoComplete/autoComplete.component";
export var Table = function (_a) {
    var _b, _c, _d;
    var _e = _a.headers, headers = _e === void 0 ? [] : _e, _f = _a.offlineData, offlineData = _f === void 0 ? null : _f, _g = _a.query, query = _g === void 0 ? null : _g, _h = _a.tableName, tableName = _h === void 0 ? '' : _h, _j = _a.viewRoute, viewRoute = _j === void 0 ? null : _j, _k = _a.editRoute, editRoute = _k === void 0 ? null : _k, _l = _a.onDelete, onDelete = _l === void 0 ? null : _l, _m = _a.deleteAction, deleteAction = _m === void 0 ? null : _m, _o = _a.customAction, customAction = _o === void 0 ? null : _o, _p = _a.rowClicked, rowClicked = _p === void 0 ? null : _p, _q = _a.filterQuery, filterQuery = _q === void 0 ? null : _q, _r = _a.showFilter, showFilter = _r === void 0 ? true : _r, _s = _a.action, action = _s === void 0 ? null : _s, _t = _a.reload, reload = _t === void 0 ? false : _t, _u = _a.selection, selection = _u === void 0 ? false : _u, _v = _a.rowSelectionChanged, rowSelectionChanged = _v === void 0 ? null : _v, _w = _a.defaultSort, defaultSort = _w === void 0 ? 'NATURAL' : _w, _x = _a.addLink, addLink = _x === void 0 ? '' : _x, _y = _a.addLinkTitle, addLinkTitle = _y === void 0 ? '' : _y, _z = _a.children, children = _z === void 0 ? null : _z, _0 = _a.footer, footer = _0 === void 0 ? true : _0, _1 = _a.showAllData, showAllData = _1 === void 0 ? false : _1, _2 = _a.pKey, pKey = _2 === void 0 ? 'id' : _2, _3 = _a.exportToExcel, exportToExcel = _3 === void 0 ? false : _3, _4 = _a.exportToPdf, exportToPdf = _4 === void 0 ? false : _4, _5 = _a.groupBy, groupBy = _5 === void 0 ? '' : _5, _6 = _a.customHeaderButton, customHeaderButton = _6 === void 0 ? null : _6;
    var _7 = useState(null), customFilter = _7[0], setCustomFilter = _7[1];
    var _8 = useState(false), resetFilter = _8[0], setResetFilter = _8[1];
    var _9 = useState(offlineData), tableData = _9[0], setTableData = _9[1];
    var _10 = useState(0), pageIndex = _10[0], setPageIndex = _10[1];
    var _11 = useState(25), pageSize = _11[0], setPageSize = _11[1];
    var _12 = useState(false), loading = _12[0], setLoading = _12[1];
    var _13 = useState(false), displayFilterForm = _13[0], setDisplayFilterForm = _13[1];
    var _14 = useState(false), displayColumnForm = _14[0], setDisplayColumnForm = _14[1];
    var _15 = useState(defaultSort), sortBy = _15[0], setSortBy = _15[1];
    var _16 = useState([]), rowSelected = _16[0], setRowSelected = _16[1];
    var _17 = useState(null), anchorEl = _17[0], setAnchorEl = _17[1];
    var _18 = useState(''), columnOrderChanges = _18[0], setColumnOrderChanges = _18[1];
    var _19 = useState([]), hideShowColumns = _19[0], setHideShowColumns = _19[1];
    var apolloClient = useApolloQuery()[0];
    var jobStatuses = useSelector(getStatuses);
    var clients = useSelector(getClients);
    var options = useSelector(getOptions);
    var appModule = useSelector(getAppModules);
    var appraisers = useSelector(getAppraisers);
    var hasFilter = useMemo(function () { return Object.values(customFilter || {}).filter(function (c) { return c; }).length; }, [customFilter]);
    var reorder = function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    };
    var viewHeaders = function () {
        var columns = getTableColumnOrderViews(document.location.pathname);
        return columns || headers || [];
    };
    var onDragEnd = function (result) {
        if (!result.destination) {
            return;
        }
        var items = reorder(viewHeaders(), result.source.index, result.destination.index);
        updateTableColumnOrders(document.location.pathname, items);
        setColumnOrderChanges(uuid());
    };
    var exportPDF = function () {
        var _a;
        toPdf((_a = document.getElementById(tableName || 'table-export')) === null || _a === void 0 ? void 0 : _a.innerHTML, true, tableName, "l");
    };
    var exportExcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    Loader.show();
                    _a = toExcel;
                    _b = [headers.filter(function (c) { return !c.isHidden; })];
                    _c = offlineData;
                    if (_c) return [3 /*break*/, 2];
                    return [4 /*yield*/, prepareDataForExport(tableName, headers.filter(function (c) { return !c.isHidden; }), apolloClient)];
                case 1:
                    _c = (_d.sent());
                    _d.label = 2;
                case 2: return [4 /*yield*/, _a.apply(void 0, _b.concat([_c, "export", groupBy]))];
                case 3:
                    _d.sent();
                    Loader.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (resetFilter && !customFilter) {
            setResetFilter(false);
            applyFilters();
        }
        else if (resetFilter) {
            setCustomFilter(null);
        }
    }, [customFilter, resetFilter]);
    var _20 = useLazyQuery(query, {
        onCompleted: function (data) {
            if (data) {
                setTableData(data[tableName]);
            }
            else {
                setTableData({ nodes: [], totalCount: 0 });
            }
            setLoading(false);
        }
    }), loadData = _20[0], refetch = _20[1].refetch;
    var getFilters = function (_filter) {
        var filter = [];
        var _customFilter = _filter || customFilter;
        if (filterQuery) {
            filter.push(JSON.stringify(filterQuery).substring(1).replace('}}', '}'));
        }
        var keys = Object.keys(_customFilter || {});
        map(keys, function (key) {
            var _a, _b, _c, _d, _e, _f;
            if ((_customFilter || {})[key]) {
                var header = headers.find(function (h) { return (h.columnFilter && h.columnFilterField === key.replace('__to', '')) || (!h.columnFilter && h.fieldName === key.replace('__to', '')); });
                switch (header === null || header === void 0 ? void 0 : header.columnType) {
                    case ColumnType.DATE:
                    case ColumnType.DATE_ONLY:
                        if ("" + (_customFilter || {})[key]) {
                            var dt = '';
                            if (key.endsWith('__to')) {
                                dt = ("\"" + key.replace('__to', '') + "\":{\"lessThanOrEqualTo\":\"" + (_customFilter || {})[key] + "\"}");
                            }
                            else {
                                dt = ("\"" + key + "\":{\"greaterThanOrEqualTo\":\"" + (_customFilter || {})[key] + "\"}");
                            }
                            var indx = filter.findIndex(function (c) { return c.indexOf(key.replace('__to', '')) !== -1; });
                            if (indx !== -1) {
                                filter[indx] = filter[indx].replace("\"}", "\"," + dt.replace("\"" + key.replace('__to', '') + "\":{", ""));
                                if (detectSameDates(filter[indx])) {
                                    filter[indx] = "\"" + key.replace('__to', '') + "\":{\"greaterThanOrEqualTo\":\"" + (_customFilter || {})[key] + "T00:00:00\", \"lessThanOrEqualTo\":\"" + (_customFilter || {})[key] + "T23:59:59\"}";
                                }
                            }
                            else {
                                filter.push(dt);
                            }
                        }
                        break;
                    case ColumnType.NUMBER:
                    case ColumnType.CURRENCY:
                        {
                            if (header === null || header === void 0 ? void 0 : header.filterRange) {
                                var dt = '';
                                if (key.endsWith('__to')) {
                                    dt = ("\"" + key.replace('__to', '') + "\":{\"lessThanOrEqualTo\":\"" + (_customFilter || {})[key] + "\"}");
                                }
                                else {
                                    dt = ("\"" + key + "\":{\"greaterThanOrEqualTo\":\"" + (_customFilter || {})[key] + "\"}");
                                }
                                var indx = filter.findIndex(function (c) { return c.indexOf(key.replace('__to', '')) !== -1; });
                                if (indx !== -1) {
                                    filter[indx] = filter[indx].replace("\"}", "\"," + dt.replace("\"" + key.replace('__to', '') + "\":{", ""));
                                }
                                else {
                                    filter.push(dt);
                                }
                            }
                            else {
                                if (((_a = header === null || header === void 0 ? void 0 : header.columnFilterField) === null || _a === void 0 ? void 0 : _a.indexOf("###")) !== -1) {
                                    filter.push((_b = header === null || header === void 0 ? void 0 : header.columnFilterField) === null || _b === void 0 ? void 0 : _b.replace("###", "" + (_customFilter || {})[key]));
                                }
                                else {
                                    filter.push("\"" + key + "\":{\"equalTo\":" + (_customFilter || {})[key] + "}");
                                }
                            }
                            break;
                        }
                    case ColumnType.LIST:
                        if ("" + (_customFilter || {})[key] !== "-1") {
                            if (((_c = header.columnFilterField) === null || _c === void 0 ? void 0 : _c.indexOf('%0%')) === -1) {
                                filter.push("\"" + key + "\":{\"equalTo\":" + (_customFilter || {})[key] + "}");
                            }
                            else {
                                filter.push((_d = header.columnFilterField) === null || _d === void 0 ? void 0 : _d.replace('%0%', _customFilter === null || _customFilter === void 0 ? void 0 : _customFilter[key]));
                            }
                        }
                        break;
                    default:
                        if (((_e = header === null || header === void 0 ? void 0 : header.columnFilterField) === null || _e === void 0 ? void 0 : _e.indexOf("###")) !== -1) {
                            filter.push((_f = header === null || header === void 0 ? void 0 : header.columnFilterField) === null || _f === void 0 ? void 0 : _f.replace("###", "\"%" + (_customFilter || {})[key] + "%\""));
                        }
                        else {
                            filter.push("\"" + key + "\":{\"likeInsensitive\":\"%" + (_customFilter || {})[key] + "%\"}");
                        }
                        break;
                }
            }
        });
        console.log("{" + filter.join(',') + "}");
        return filter.length ? JSON.parse("{" + filter.join(',') + "}") : null;
    };
    var setSort = function (header) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    handleClose();
                    if (!header.sortable || !header.sortField) {
                        return [2 /*return*/];
                    }
                    if (!(sortBy && sortBy.replace("_DESC", "").replace("_ASC", "") === header.sortField)) return [3 /*break*/, 5];
                    if (!sortBy.endsWith("_DESC")) return [3 /*break*/, 2];
                    return [4 /*yield*/, orderBy(header.sortField, "ASC")];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, orderBy(header.sortField, "DESC")];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, orderBy(header.sortField, "ASC")];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var rows = useMemo(function () {
        if (offlineData) {
            if (groupBy) {
                return Object.entries(groupDataBy(tableData === null || tableData === void 0 ? void 0 : tableData.nodes, groupBy));
            }
            else if (sortBy === 'NATURAL') {
                return showAllData ? sortOrder(tableData === null || tableData === void 0 ? void 0 : tableData.nodes, 'id', ['asc']) : sortOrder(tableData === null || tableData === void 0 ? void 0 : tableData.nodes, 'id', ['asc']).slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
            }
            else {
                if (pageIndex === -1) {
                    setPageIndex(0);
                }
                return showAllData ? sortOrder(tableData === null || tableData === void 0 ? void 0 : tableData.nodes, sortBy.split('_')[0], [sortBy.split('_')[1] === 'ASC' ? 'asc' : 'desc']) : sortOrder(tableData === null || tableData === void 0 ? void 0 : tableData.nodes, sortBy.split('_')[0], [sortBy.split('_')[1] === 'ASC' ? 'asc' : 'desc']).slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
            }
        }
        return tableData === null || tableData === void 0 ? void 0 : tableData.nodes;
    }, [tableData, pageIndex, groupBy]);
    var orderBy = function (column, sortType) { return __awaiter(void 0, void 0, void 0, function () {
        var variables, filter;
        return __generator(this, function (_a) {
            if (offlineData) {
                setSortBy(column + "_" + sortType);
                setPageIndex(-1);
                return [2 /*return*/];
            }
            variables = {
                offset: 0,
                first: pageSize,
                orderBy: column + "_" + sortType,
            };
            filter = getFilters();
            if (filter) {
                variables = __assign(__assign({}, variables), { filter: filter });
            }
            loadData({
                variables: variables,
            });
            setSortBy(column + "_" + sortType);
            setPageIndex(-1);
            return [2 /*return*/];
        });
    }); };
    var loadMoreData = function (pageNumber, size) {
        if (size === void 0) { size = 25; }
        return __awaiter(void 0, void 0, void 0, function () {
            var filter, variables;
            return __generator(this, function (_a) {
                setPageSize(size);
                if (offlineData) {
                    setPageIndex(pageNumber);
                }
                else {
                    filter = getFilters();
                    variables = {
                        offset: (pageNumber) * size,
                        first: size,
                        orderBy: sortBy,
                    };
                    if (filter) {
                        variables = __assign(__assign({}, variables), { filter: filter });
                    }
                    loadData({
                        variables: variables,
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    var applyFilters = function (_filter) { return __awaiter(void 0, void 0, void 0, function () {
        var filter, f_1, variables;
        return __generator(this, function (_a) {
            filter = getFilters(_filter);
            if (offlineData) {
                f_1 = __spreadArray([], offlineData);
                Object.keys(filter || {}).forEach(function (key) {
                    var _a;
                    if ((customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) && "" + (customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) !== '-1') {
                        if (((_a = headers.find(function (c) { return c.fieldName === key || c.columnFilterField === key; })) === null || _a === void 0 ? void 0 : _a.columnType) === ColumnType.LIST) {
                            f_1 = f_1.filter(function (p) { return p[key] === ((customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) || 0); });
                        }
                        else {
                            f_1 = f_1.filter(function (p) { return p[key].indexOf((customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) || '') !== -1; });
                        }
                    }
                });
                setTableData({ nodes: f_1, totalCount: (f_1 === null || f_1 === void 0 ? void 0 : f_1.length) || 0 });
            }
            else {
                variables = {
                    offset: 0,
                    first: pageSize,
                    orderBy: sortBy,
                };
                if (filter) {
                    variables = __assign(__assign({}, variables), { filter: filter });
                }
                setLoading(true);
                loadData({
                    variables: variables,
                });
            }
            return [2 /*return*/];
        });
    }); };
    var refetchData = function () {
        if (refetch) {
            refetch()
                .then(function (data) {
                setTableData(data.data[tableName]);
                setLoading(false);
            }).catch(function () {
                setTableData({ nodes: [], totalCount: 0 });
                setLoading(false);
            });
        }
    };
    useEffect(function () {
        rowSelectionChanged && rowSelectionChanged(rowSelected);
    }, [rowSelected]);
    useEffect(function () {
        if (offlineData) {
            onSelectAll(false);
            if (selection) {
                document.getElementById('chk_select_all_table').checked = false;
            }
            setTableData({ nodes: offlineData, totalCount: (offlineData === null || offlineData === void 0 ? void 0 : offlineData.length) || 0 });
        }
    }, [offlineData]);
    useEffect(function () {
        setHideShowColumns(getTableViews(document.location.pathname));
        if (!offlineData) {
            setLoading(true);
            var variables = {
                offset: 0,
                first: pageSize,
                orderBy: sortBy,
            };
            if (filterQuery) {
                variables = __assign(__assign({}, variables), { filter: filterQuery });
            }
            loadData({
                variables: variables,
            });
        }
    }, [tableName, reload, filterQuery]);
    var TranslateFilter = function () {
        var keys = Object.keys(customFilter || {});
        if (!customFilter || keys.length === 0) {
            return null;
        }
        var result = [];
        keys.forEach(function (key) {
            var _a, _b, _c;
            if ("" + (customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) !== '-1' && "" + (customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) !== '') {
                var header = headers.find(function (h) { return (h.columnFilter && h.columnFilterField === key) || (!h.columnFilter && h.fieldName === key); });
                if (header) {
                    var criteria = (header === null || header === void 0 ? void 0 : header.caption) || '';
                    criteria += header.columnType === ColumnType.LIST ? ' = ' : ' contains ';
                    if (header.columnType === ColumnType.LIST) {
                        switch (header.selectorName) {
                            case SelectorName.CLIENT: {
                                criteria += "`" + ((_a = clients.find(function (c) { return +c.id === +((customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) || -1); })) === null || _a === void 0 ? void 0 : _a.title) + "`";
                                break;
                            }
                            case SelectorName.STATUS: {
                                criteria += "`" + ((_b = jobStatuses.find(function (c) { return +c.id === +((customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) || -1); })) === null || _b === void 0 ? void 0 : _b.title) + "`";
                                break;
                            }
                            case SelectorName.PROPERTY_TYPE_SALES:
                            case SelectorName.PROPERTY_SUB_TYPE: {
                                criteria += "`" + ((_c = options.find(function (c) { return +c.id === +((customFilter === null || customFilter === void 0 ? void 0 : customFilter[key]) || -1); })) === null || _c === void 0 ? void 0 : _c.title) + "`";
                                break;
                            }
                        }
                    }
                    else {
                        criteria += customFilter === null || customFilter === void 0 ? void 0 : customFilter[key];
                    }
                    result.push(criteria);
                }
            }
        });
        if (result.length === 0) {
            return null;
        }
        return (_jsx("tr", { children: _jsx("th", __assign({ className: "no-border filter-row", colSpan: headers.filter(function (h) { return !h.isHidden; }).length + 1 }, { children: _jsxs(Typography, __assign({ variant: "caption" }, { children: [_jsx("b", { children: "Filter :" }, void 0), " ", result.join(' And ')] }), void 0) }), void 0) }, void 0));
    };
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var getListData = function (selectorName) {
        switch (selectorName) {
            case SelectorName.STATUS:
                return jobStatuses;
            case SelectorName.CLIENT:
                return clients;
            case SelectorName.APPRAISERS:
                return appraisers;
            case SelectorName.PROPERTY_TYPE_SALES:
            case SelectorName.PROPERTY_SUB_TYPE:
            case SelectorName.SALE_TYPE:
            case SelectorName.RIGHTS_TRADED:
            case SelectorName.TITLE_TYPE:
                return options.filter(function (c) { return +c.optionType === selectorName; });
            default:
                return [];
        }
    };
    var updateHideShowColumns = function (col) { return function (e) {
        var colIndex = (hideShowColumns || []).findIndex(function (c) { return c.fieldName === col.fieldName; });
        var newColumns = [];
        if (colIndex === -1) {
            newColumns = (hideShowColumns || []).concat(__assign(__assign({}, col), { isHidden: !e.target.checked }));
        }
        else {
            newColumns =
                hideShowColumns.reduce(function (result, curr) {
                    if (curr.fieldName !== col.fieldName) {
                        result.push(curr);
                    }
                    else {
                        result.push(__assign(__assign({}, curr), { isHidden: !e.target.checked }));
                    }
                    return result;
                }, []);
        }
        setHideShowColumns(newColumns);
    }; };
    var saveTableView = function () {
        updateTableViews(document.location.pathname, hideShowColumns);
    };
    var handleDateChange = function (header) { return function (e) {
        var _a;
        var date = formatDate(e, 'yyyy-MM-dd');
        setCustomFilter(__assign(__assign({}, customFilter), (_a = {}, _a[header.columnFilterField || header.fieldName] = date, _a)));
    }; };
    var selectionChanged = function (e, id) {
        if (e.target.checked === true) {
            setRowSelected(rowSelected.concat(+id));
        }
        else {
            if (document.getElementById('chk_select_all_table')) {
                document.getElementById('chk_select_all_table').checked = false;
            }
            setRowSelected(rowSelected.filter(function (r) { return +r !== +id; }));
        }
    };
    var onSelectAll = function (all) {
        var ids = [];
        document.querySelectorAll(".select-row-checkbox").forEach(function (elm) {
            elm.checked = all;
            ids.push(+(elm.getAttribute("data-id") || 0));
        });
        if (!all) {
            setRowSelected([]);
        }
        else {
            setRowSelected(ids);
        }
    };
    var getHeaders = useMemo(function () {
        return viewHeaders().reduce(function (result, curr) {
            if (!(hideShowColumns === null || hideShowColumns === void 0 ? void 0 : hideShowColumns.length)) {
                if (!curr.isHidden) {
                    result.push(curr);
                }
            }
            else if ((hideShowColumns === null || hideShowColumns === void 0 ? void 0 : hideShowColumns.findIndex(function (h) { return h.fieldName === curr.fieldName && h.isHidden; })) === -1 && !curr.isHidden) {
                result.push(curr);
            }
            else if ((hideShowColumns === null || hideShowColumns === void 0 ? void 0 : hideShowColumns.findIndex(function (h) { return h.fieldName === curr.fieldName && !h.isHidden; })) !== -1) {
                result.push(curr);
            }
            return result;
        }, []);
    }, [headers, hideShowColumns, columnOrderChanges]);
    return (_jsxs("div", __assign({ className: "mt-16 relative" }, { children: [_jsxs(Row, __assign({ className: "p-t-b-10 table-action-header " + (isMobile ? 'pb-24' : '') }, { children: [children && (_jsx(Col, __assign({ xs: 8 }, { children: children }), void 0)),
                    _jsxs(Col, __assign({ xs: !children || (appModule === null || appModule === void 0 ? void 0 : appModule.read_only) ? 12 : children ? 4 : 6, className: "right flex-end gap-16 table-filter-panel" }, { children: [showFilter && ((_b = headers.filter(function (h) { return h.columnFilter; })) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
                                _jsx("div", __assign({ className: isMobile ? '' : "relative" }, { children: _jsx(Button, __assign({ disabled: !(rows === null || rows === void 0 ? void 0 : rows.length) && !hasFilter, color: "default", className: "navigation", onClick: function () {
                                            setDisplayFilterForm(true);
                                            setDisplayColumnForm(false);
                                        } }, { children: _jsx("i", { className: "fa fa-filter " + (hasFilter ? 'color-red' : '') }, void 0) }), void 0) }), void 0),
                            showFilter && ((_c = headers.filter(function (h) { return !h.isHidden && h.sortable; })) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                                _jsx(Button, __assign({ color: "default", onClick: handleClick, className: "sort-button" }, { children: _jsx("i", { className: "fa fa-sort-alpha-down" }, void 0) }), void 0),
                            showFilter && ((_d = headers.filter(function (h) { return !h.isHidden && h.sortable; })) === null || _d === void 0 ? void 0 : _d.length) > 0 &&
                                _jsx(Menu, __assign({ id: "simple-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose }, { children: headers.filter(function (h) { return !h.isHidden && h.sortable; }).map(function (col) { return (_jsxs(MenuItem, __assign({ onClick: function () { return setSort(col); } }, { children: [sortBy && sortBy.replace("_DESC", "").replace("_ASC", "") === col.sortField ? (_jsx("i", { className: "fa fa-check" }, void 0)) : (_jsx("i", { className: "fa fa-check", style: { color: 'white' } }, void 0)),
                                            _jsx("span", __assign({ className: "pl-10" }, { children: col.caption }), void 0)] }), "col-row-" + col.fieldName)); }) }), void 0),
                            showFilter && _jsx("div", __assign({ className: isMobile ? "" : "relative`" }, { children: _jsxs(Button, __assign({ color: "default", className: "navigation", onClick: function () {
                                        setDisplayColumnForm(true);
                                        setDisplayFilterForm(false);
                                    } }, { children: [_jsx("i", { className: "fa fa-columns" }, void 0), " "] }), void 0) }), void 0),
                            exportToExcel && (_jsx(Button, __assign({ color: "default", onClick: exportExcel }, { children: _jsx("i", { className: "fa fa-file-excel" }, void 0) }), void 0)),
                            exportToPdf && (_jsx(Button, __assign({ color: "default", onClick: exportPDF }, { children: _jsx("i", { className: "fa fa-file-pdf" }, void 0) }), void 0)),
                            customHeaderButton && _jsx("div", { children: customHeaderButton }, void 0),
                            !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && addLink && (typeof addLink === 'function' ? (_jsxs(Button, __assign({ color: "info", className: "btn btn-xs", onClick: addLink }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " New"] }), void 0)) : (_jsxs(Button, __assign({ tag: Link, to: addLink, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " New"] }), void 0)))] }), void 0)] }), void 0),
            _jsxs(Tablex, __assign({ responsive: true, id: tableName || "table-export" }, { children: [_jsx("thead", __assign({ className: "text-primary" }, { children: _jsx(TableHeader, { selection: selection, hasAction: deleteAction || customAction || viewRoute || editRoute || action || onDelete, headers: getHeaders, onSelectAll: onSelectAll }, void 0) }), void 0),
                    !loading && (groupBy ? (rows.map(function (_a) {
                        var key = _a[0], value = _a[1];
                        return (_jsxs(React.Fragment, { children: [_jsx("tr", { children: _jsx("td", __assign({ className: "total-footer-td table-grouped-row bg-navy", colSpan: getHeaders.length + (selection ? 1 : 0) }, { children: key }), void 0) }, void 0),
                                _jsx(TableBody, { rowClicked: rowClicked, selection: selection, refetch: refetchData, customAction: customAction, deleteAction: deleteAction, onDelete: onDelete, viewRoute: viewRoute, editRoute: editRoute, action: action, selectionChanged: selectionChanged, headers: getHeaders, pKey: pKey, rowSelected: rowSelected, totalInFooter: false, data: value }, void 0)] }, "row_grouped_" + key));
                    })) : (_jsx(TableBody, { rowClicked: rowClicked, selection: selection, refetch: refetchData, customAction: customAction, deleteAction: deleteAction, onDelete: onDelete, viewRoute: viewRoute, editRoute: editRoute, action: action, selectionChanged: selectionChanged, headers: getHeaders, pKey: pKey, totalInFooter: !(offlineData === null || offlineData === void 0 ? void 0 : offlineData.length), rowSelected: rowSelected, data: rows }, void 0))),
                    ((offlineData === null || offlineData === void 0 ? void 0 : offlineData.length) > 0 && getHeaders.some(function (d) { return d.totalInFooter; })) && Math.ceil((offlineData === null || offlineData === void 0 ? void 0 : offlineData.length) / pageSize) > 1 && (_jsx(TableTotalFooter, { headers: getHeaders, dataRow: offlineData, selection: selection, totalInFooter: true }, void 0))] }), void 0),
            !loading && footer && (_jsx(TableFooter, { pageSize: pageSize, totalCount: tableData === null || tableData === void 0 ? void 0 : tableData.totalCount, pageIndex: pageIndex, fetchMore: loadMoreData }, void 0)),
            loading && (_jsx("div", __assign({ className: "table-data-loader" }, { children: _jsx(DataLoader, {}, void 0) }), void 0)),
            !loading && !(rows === null || rows === void 0 ? void 0 : rows.length) &&
                _jsx(EmptyDataError, {}, void 0),
            _jsxs("div", __assign({ className: "table-filter-drawer " + (isMobile ? 'w-100 border-left-0 top-0' : '') + " " + (displayColumnForm ? '' : 'none') }, { children: [_jsx("div", __assign({ className: "db w-100 right pb-8" }, { children: _jsx(CloseIcon, { className: "pointer", onClick: function () { setDisplayColumnForm(false); } }, void 0) }), void 0),
                    _jsx(DragDropContext, __assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "droppable" }, { children: function (provided, snapshot) { return (_jsxs("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, className: "drag-drop-columns" }, { children: [viewHeaders().map(function (col, index) { return (_jsx(Draggable, __assign({ draggableId: col.fieldName, index: index }, { children: function (provided, snapshot) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { className: "drag-drop-row" }, { children: [_jsx("div", __assign({ className: "dib w-80" }, { children: _jsxs("div", __assign({ className: " flex gap-8" }, { children: [" ", _jsx(Typography, __assign({ variant: "body2" }, { children: col.caption }), void 0)] }), void 0) }), void 0),
                                                _jsx("div", __assign({ className: "dib w-20 right" }, { children: _jsx(Switch, { checked: (!col.isHidden && (hideShowColumns || []).findIndex(function (c) { return c.fieldName === col.fieldName; }) === -1) || (hideShowColumns || []).findIndex(function (c) { return c.fieldName === col.fieldName && !c.isHidden; }) > -1, onClick: updateHideShowColumns(col), color: "primary", size: "small", inputProps: { 'aria-label': 'primary checkbox' } }, void 0) }), void 0)] }), void 0)); } }), col.fieldName)); }), provided.placeholder] }), void 0)); } }), void 0) }), void 0),
                    _jsx(Divider, { className: "popup-divider" }, void 0),
                    _jsx("div", __assign({ className: "mt-8 right db" }, { children: _jsx(Button, __assign({ color: "info", onClick: saveTableView, className: "btn btn-md" }, { children: "Save to this view" }), void 0) }), void 0)] }), void 0),
            _jsxs("div", __assign({ className: "table-filter-drawer " + (isMobile ? 'w-100 border-left-0 top-0' : '') + " " + (displayFilterForm ? '' : 'none') }, { children: [_jsx("div", __assign({ className: "db w-100 right pb-8" }, { children: _jsx(CloseIcon, { className: "pointer", onClick: function () { setDisplayFilterForm(false); } }, void 0) }), void 0),
                    headers.filter(function (h) { return h.columnFilter; }).map(function (header, index) { return (_jsx("div", __assign({ className: "mb-8" }, { children: _jsxs("div", __assign({ className: isMobile ? "db w-100" : "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "caption", className: "min-w-120 p-t-4 " + (isMobile ? 'db w-100' : 'text-right') }, { children: header.caption }), void 0),
                                header.columnType === ColumnType.LIST ? (_jsx(ICAutoComplete, { options: getListData(header.selectorName), className: "no-padding-select", size: "small", label: "", flex: true, value: !customFilter ? '-1' : customFilter[header.columnFilterField || header.fieldName] || '-1', onChange: function (e) { setFormData(e, header.columnFilterField || header.fieldName, customFilter, setCustomFilter); } }, void 0)) : ([ColumnType.DATE, ColumnType.DATE_ONLY].includes(header.columnType || ColumnType.NUMBER) ?
                                    _jsxs("div", __assign({ className: "flex gap-4" }, { children: [_jsx(ReactDatePicker, { id: header.fieldName, className: "max-w-120", placeholderText: "from...", selected: !customFilter || !customFilter[header.columnFilterField || header.fieldName] ? '' : toDate(customFilter[header.columnFilterField || header.fieldName]), onChange: handleDateChange(header) }, void 0),
                                            _jsx(ReactDatePicker, { id: header.fieldName + '__to', className: "max-w-120", placeholderText: "to...", selected: !customFilter || !customFilter[(header.columnFilterField || header.fieldName) + '__to'] ? '' : toDate(customFilter[(header.columnFilterField || header.fieldName) + '__to']), onChange: handleDateChange(__assign(__assign({}, header), { columnFilterField: (header === null || header === void 0 ? void 0 : header.columnFilterField) + '__to', fieldName: header.fieldName + '__to' })) }, void 0)] }), void 0)
                                    :
                                        _jsxs("div", __assign({ className: "flex gap-4" }, { children: [_jsx(InputText, { value: !customFilter ? '' : customFilter[header.columnFilterField || header.fieldName], onChange: function (e) { return setFormData(e, header.columnFilterField || header.fieldName, customFilter, setCustomFilter); }, size: "small" }, void 0),
                                                header.filterRange &&
                                                    _jsx(InputText, { value: !customFilter ? '' : customFilter[(header.columnFilterField || header.fieldName) + '__to'], onChange: function (e) { return setFormData(e, (header.columnFilterField || header.fieldName) + '__to', customFilter, setCustomFilter); }, size: "small" }, void 0)] }), void 0))] }), void 0) }), "filter-item-" + index)); }),
                    _jsx(Divider, { className: "popup-divider" }, void 0),
                    _jsxs("div", __assign({ className: "mt-8 right db" }, { children: [_jsx(Button, __assign({ color: "info", onClick: function () { applyFilters(); setDisplayFilterForm(false); }, className: "btn btn-md" }, { children: "Apply" }), void 0),
                            _jsx(Button, __assign({ className: "btn btn-md", color: "default", onClick: function () { setResetFilter(true); } }, { children: "Clear" }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
