var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { createAction, createReducer } from '@reduxjs/toolkit';
import { getUserLicence } from '../services/user-service';
export var setClient = createAction('clients-add-clients');
export var setJobType = createAction('clients-add-job-types');
export var setPropertyType = createAction('clients-SET-ADD-property-types');
export var upsertPropertyType = createAction('clients-UPSERT-property-types');
export var removePropertyType = createAction('clients-REMOVE-property-types');
export var setStatus = createAction('clients-add-status-types');
export var setMunicipalicity = createAction('clients-add-Municipalicity');
export var addNewClient = createAction('clients-add-New-Client');
export var setAppraisers = createAction('clients-set-apptaisers');
export var addNewAppraiser = createAction('clients-add-new-apptaisers');
export var updateAppraiser = createAction('clients-update-apptaisers');
export var setOptions = createAction('clients-set-options');
export var addNewOption = createAction('clients-add-New-Option');
export var updateOption = createAction('clients-UPDATE-New-Option');
export var removeOption = createAction('clients-REMOVE-New-Option');
export var setCompany = createAction('clients-set-company');
export var clientReducer = createReducer(null, function (builder) {
    builder
        .addCase(setCompany, function (state, action) {
        return __assign(__assign({}, state), { company: action.payload });
    })
        .addCase(addNewClient, function (state, action) {
        return __assign(__assign({}, state), { clients: __spreadArray(__spreadArray([], state.clients), [
                action.payload
            ]) });
    })
        .addCase(addNewOption, function (state, action) {
        var op = __assign(__assign({}, action.payload), { licenceId: getUserLicence() });
        return __assign(__assign({}, state), { options: (state.options || []).concat(op) });
    })
        .addCase(removeOption, function (state, action) {
        return __assign(__assign({}, state), { options: state.options.filter(function (o) { return o.id !== action.payload; }) });
    })
        .addCase(updateOption, function (state, action) {
        return __assign(__assign({}, state), { options: state.options.filter(function (o) { return o.id !== action.payload.id; }).concat(action.payload) });
    })
        .addCase(addNewAppraiser, function (state, action) {
        return __assign(__assign({}, state), { appraisers: __spreadArray(__spreadArray([], state.appraisers), [
                action.payload
            ]) });
    })
        .addCase(updateAppraiser, function (state, action) {
        return __assign(__assign({}, state), { appraisers: (state.appraisers || []).reduce(function (res, curr) {
                if (+curr.id === +action.payload.id) {
                    res.push(action.payload);
                }
                else {
                    res.push(curr);
                }
                return res;
            }, []) });
    })
        .addCase(setClient, function (state, action) {
        return __assign(__assign({}, state), { clients: action.payload });
    })
        .addCase(setJobType, function (state, action) {
        return __assign(__assign({}, state), { jobTypes: action.payload });
    }).addCase(upsertPropertyType, function (state, action) {
        return __assign(__assign({}, state), { propertyTypes: (state.propertyTypes || []).filter(function (p) { return p.id !== action.payload.id; }).concat(action.payload) });
    })
        .addCase(setPropertyType, function (state, action) {
        return __assign(__assign({}, state), { propertyTypes: action.payload });
    })
        .addCase(removePropertyType, function (state, action) {
        return __assign(__assign({}, state), { propertyTypes: state.propertyTypes.filter(function (p) { return p.id !== action.payload; }) });
    })
        .addCase(setStatus, function (state, action) {
        return __assign(__assign({}, state), { status: action.payload });
    })
        .addCase(setOptions, function (state, action) {
        return __assign(__assign({}, state), { options: action.payload });
    })
        .addCase(setMunicipalicity, function (state, action) {
        return __assign(__assign({}, state), { municipalicity: action.payload });
    })
        .addCase(setAppraisers, function (state, action) {
        return __assign(__assign({}, state), { appraisers: action.payload });
    });
});
