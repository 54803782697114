var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { APPRAISER_ROLE, DOCUMENT_TYPE, CALENDAR_TYPE } from "@enums";
import { ADD_IMIDIATE_APPRAISER, GET_CURRENT_APPRAISER, UPDATE_APPRAISER_FEE, UPDATE_LAT_LNG_JOB, UPDATE_REVIEWER } from "../graphqls/appraisal/appraisal.query";
import { ADDNEWJOB, ADDNEWJOB_MULTIPLE_PROPERTY, ADD_APPOINTMENT, ADD_JOB_EXPENSES, APPRAISER_EXPENSE_PORTION, APPRAISER_EXPENSE_PORTION_EXISTS, DELETE_APPRAISER_EXPENSE_PORTION, GET_APPOINTMENT, GET_APPOINTMENT_DUE_JOBS, GET_JOB_ADDRESS, GET_NEXT_FILE_NUMBER, GET_OVER_DUE_JOBS, INSERT_APPRAISER_EXPENSE_PORTION, UPDATE, UPDATE_APPOINTMENT, UPDATE_APPRAISER_EXPENSE_PORTION, UPDATE_BATCH_PROPERTY, UPDATE_JOB_EXPENSE, UPDATE_REVIEW_DATE } from "../graphqls/jobs/job.query";
import { ADD_SECTION_FIELD_VALUE, ADD_SECTION_VALUE, DELETE_SECTION_VALUE, GET_JOBS_BY_APPRAISER_FOR_MAP, GET_JOBS_FOR_MAP, GET_VALUES_BY_JOB } from "../graphqls/section-fields/section-fields.query";
import { httpPost, httpPostUpload } from "../helpers/http.helper";
import { uuid } from "@utils/text";
import { readFromStorage, STORAGE_KEY } from "../utils/Storage.util";
import { getValuationHisotry } from "./valuation.service";
import { getCurrentMonth, getThisMonth, addDays, toDate } from "@utils/date";
export var getAppointment = function (apolloClient, id) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_APPOINTMENT,
                    variables: {
                        id: id,
                    },
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data === null || data === void 0 ? void 0 : data.tblAppointmentById];
        }
    });
}); };
export var addAppointment = function (apolloClient, formData) { return __awaiter(void 0, void 0, void 0, function () {
    var data_1, data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!formData.id) return [3 /*break*/, 2];
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_APPOINTMENT,
                        variables: {
                            input: formData,
                            id: formData.id,
                        },
                    })];
            case 1:
                data_1 = (_c.sent()).data;
                return [2 /*return*/, (_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.updateTblAppointmentById) === null || _a === void 0 ? void 0 : _a.tblAppointment];
            case 2: return [4 /*yield*/, apolloClient.query({
                    query: ADD_APPOINTMENT,
                    variables: {
                        input: formData,
                    },
                })];
            case 3:
                data = (_c.sent()).data;
                return [2 /*return*/, (_b = data === null || data === void 0 ? void 0 : data.createTblAppointment) === null || _b === void 0 ? void 0 : _b.tblAppointment];
        }
    });
}); };
export var getOverdueJobs = function (apolloClient, date) { return __awaiter(void 0, void 0, void 0, function () {
    var start, end, data, jobAppDate, all;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                start = getThisMonth()[0];
                end = getThisMonth()[1];
                if (date) {
                    start = getCurrentMonth(date)[0];
                    end = getCurrentMonth(date)[1];
                }
                start = addDays(new Date(start), -6, "yyyy-MM-dd");
                end = addDays(new Date(end), 6, "yyyy-MM-dd");
                return [4 /*yield*/, apolloClient.query({
                        query: GET_OVER_DUE_JOBS,
                        variables: {
                            start: start,
                            end: end,
                        },
                    })];
            case 1:
                data = (_f.sent()).data;
                return [4 /*yield*/, apolloClient.query({
                        query: GET_APPOINTMENT_DUE_JOBS,
                        variables: {
                            start: start,
                            end: end,
                        },
                    })];
            case 2:
                jobAppDate = _f.sent();
                all = (((_b = (_a = jobAppDate === null || jobAppDate === void 0 ? void 0 : jobAppDate.data) === null || _a === void 0 ? void 0 : _a.allTblJobs) === null || _b === void 0 ? void 0 : _b.nodes) || []).concat(((_c = data === null || data === void 0 ? void 0 : data.allTblJobs) === null || _c === void 0 ? void 0 : _c.nodes) || []).concat(((_e = (_d = jobAppDate === null || jobAppDate === void 0 ? void 0 : jobAppDate.data) === null || _d === void 0 ? void 0 : _d.allTblAppointments) === null || _e === void 0 ? void 0 : _e.nodes) || []);
                return [2 /*return*/, all.map(function (j) { return ({
                        id: j.id,
                        type: j.dueDate ? CALENDAR_TYPE.EVENTS : CALENDAR_TYPE.JOBS,
                        title: (j.jobAppDate || j.dueDate ? '[Appointment] ' : '') + (j.zzFullAddress || j.description),
                        // start: formatDate(j.jobDueDate || j.jobAppDate, 'yyyy-MM-dd') + 'T09:00:00',
                        // end: formatDate(j.jobDueDate || j.jobAppDate, 'yyyy-MM-dd') + 'T23:59:59',
                        start: toDate(j.jobDueDate || j.jobAppDate || j.dueDate),
                        end: toDate(j.jobDueDate || j.jobAppDate || j.dueDate),
                    }); })];
        }
    });
}); };
export var updateReviewDate = function (id, date, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: UPDATE_REVIEW_DATE,
                    variables: {
                        id: id,
                        review: date,
                    },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var batchJobStatusUpdate = function (jobId, statusId, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(jobId.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, apolloClient.query({
                                    query: UPDATE_LAT_LNG_JOB,
                                    variables: {
                                        id: id,
                                        input: {
                                            statusId: statusId,
                                        },
                                    },
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/, true];
                        }
                    });
                }); }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var upsertAppraiser = function (jobId, appid, reportFee, feeType) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_CURRENT_APPRAISER,
                    variables: {
                        id: jobId,
                        appid: appid,
                    },
                })];
            case 1:
                data = (_f.sent()).data;
                if (!((_b = (_a = data === null || data === void 0 ? void 0 : data.allTblPropertyAppraisers) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.length)) return [3 /*break*/, 3];
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_APPRAISER_FEE,
                        variables: {
                            feeType: feeType,
                            reportFee: reportFee,
                            id: (_c = data === null || data === void 0 ? void 0 : data.allTblPropertyAppraisers) === null || _c === void 0 ? void 0 : _c.nodes[0].rowId,
                        },
                    })];
            case 2:
                _f.sent();
                return [2 /*return*/, false];
            case 3: return [4 /*yield*/, apolloClient.query({
                    query: ADD_IMIDIATE_APPRAISER,
                    variables: {
                        jobId: jobId,
                        appid: appid,
                        reportFee: reportFee,
                        feeType: feeType,
                        role: APPRAISER_ROLE.OWNER,
                    },
                })];
            case 4:
                _f.sent();
                if (!((_d = data === null || data === void 0 ? void 0 : data.tblAppraiserById) === null || _d === void 0 ? void 0 : _d.reviewer)) return [3 /*break*/, 6];
                return [4 /*yield*/, apolloClient.query({
                        query: ADD_IMIDIATE_APPRAISER,
                        variables: {
                            jobId: jobId,
                            appid: (_e = data === null || data === void 0 ? void 0 : data.tblAppraiserById) === null || _e === void 0 ? void 0 : _e.reviewer,
                            reportFee: 0,
                            feeType: 0,
                            role: APPRAISER_ROLE.REVIEWER,
                        },
                    })];
            case 5:
                _f.sent();
                _f.label = 6;
            case 6: return [2 /*return*/, true];
        }
    });
}); }; };
export var getJobsForMap = function (appraiserId, status) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data, data;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                if (!appraiserId) return [3 /*break*/, 2];
                return [4 /*yield*/, apolloClient.query({
                        query: GET_JOBS_BY_APPRAISER_FOR_MAP(status),
                        variables: {
                            id: appraiserId,
                        }
                    })];
            case 1:
                data = (_f.sent()).data;
                return [2 /*return*/, (((_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.allTblAppraisers) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.tblPropertyAppraisersById) === null || _d === void 0 ? void 0 : _d.nodes) || []).map(function (d) { return d.tblJobByJobId; })];
            case 2: return [4 /*yield*/, apolloClient.query({
                    query: GET_JOBS_FOR_MAP(status),
                    variables: {}
                })];
            case 3:
                data = (_f.sent()).data;
                return [2 /*return*/, ((_e = data === null || data === void 0 ? void 0 : data.allTblJobs) === null || _e === void 0 ? void 0 : _e.nodes) || []];
        }
    });
}); }; };
export var getSectionValuesByJob = function (jobId, sectionId, prefix, table) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_VALUES_BY_JOB,
                    variables: {
                        id: jobId,
                        sectionId: sectionId,
                        table: table,
                    }
                })];
            case 1:
                data = (_c.sent()).data;
                return [2 /*return*/, (_b = (_a = data === null || data === void 0 ? void 0 : data.allTblSectionFieldHeaders) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map(function (row) {
                        var _a;
                        var _b;
                        var result = (_a = {
                                id: row.id
                            },
                            _a[prefix + "0"] = row.title,
                            _a);
                        (_b = row === null || row === void 0 ? void 0 : row.tblSectionFieldValuesByHeaderId) === null || _b === void 0 ? void 0 : _b.nodes.forEach(function (element) {
                            var _a;
                            result = __assign(__assign({}, result), (_a = {}, _a["" + prefix + element.fieldSectionId] = element.value, _a));
                        });
                        return result;
                    })];
        }
    });
}); }; };
export var upsertCustomField = function (headerId, fieldSectionId, value, jobId, sectionId, table, edit) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!headerId) return [3 /*break*/, 5];
                if (!edit) return [3 /*break*/, 2];
                return [4 /*yield*/, httpPost("job/updateCustomFieldSection", {
                        headerId: headerId,
                        fieldSectionId: fieldSectionId,
                        value: value,
                    })];
            case 1:
                _c.sent();
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, apolloClient.query({
                    query: ADD_SECTION_FIELD_VALUE,
                    variables: {
                        input: {
                            fieldSectionId: fieldSectionId,
                            headerId: headerId,
                            value: value,
                        },
                    },
                })];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, apolloClient.query({
                    query: ADD_SECTION_VALUE,
                    variables: {
                        input: {
                            referenceId: jobId,
                            title: '',
                            sectionId: sectionId,
                            tableName: table,
                            tblSectionFieldValuesUsingId: {
                                create: {
                                    fieldSectionId: fieldSectionId,
                                    value: value,
                                },
                            }
                        },
                    },
                })];
            case 6:
                data = (_c.sent()).data;
                return [2 /*return*/, (_b = (_a = data === null || data === void 0 ? void 0 : data.createTblSectionFieldHeader) === null || _a === void 0 ? void 0 : _a.tblSectionFieldHeader) === null || _b === void 0 ? void 0 : _b.id];
            case 7: return [2 /*return*/, headerId];
        }
    });
}); }; };
export var upsertSections = function (rows, jobId, sectionId, prefix, table) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(rows.map(function (element) { return __awaiter(void 0, void 0, void 0, function () {
                    var header;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(element.id && (element.edit || element.deleted))) return [3 /*break*/, 2];
                                return [4 /*yield*/, apolloClient.query({
                                        query: DELETE_SECTION_VALUE,
                                        variables: {
                                            id: +element.id,
                                        },
                                    })];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                if (!((!element.id || (element.id && element.edit)) && !element.deleted)) return [3 /*break*/, 4];
                                header = {
                                    referenceId: jobId,
                                    title: '',
                                    sectionId: sectionId,
                                    tableName: table,
                                    tblSectionFieldValuesUsingId: {
                                        create: Object.keys(element).filter(function (key) { return !['id', prefix + "0", 'edit'].includes(key); }).map(function (key) { return ({
                                            fieldSectionId: +key.replace(prefix, ''),
                                            value: "" + element[key],
                                        }); }).filter(function (item) { return item.value && item.value !== 'null'; }),
                                    }
                                };
                                return [4 /*yield*/, apolloClient.query({
                                        query: ADD_SECTION_VALUE,
                                        variables: {
                                            input: header,
                                        },
                                    })];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4: return [2 /*return*/, null];
                        }
                    });
                }); }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var upsertJob = function (jobForm, properties, id, additionalScope, attachment, decription) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data, inputData, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!jobForm.multi) return [3 /*break*/, 6];
                if (!id) return [3 /*break*/, 2];
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_BATCH_PROPERTY,
                        variables: {
                            id: id,
                            input: {
                                jobTypeId: +jobForm.jobTypeId,
                                zzFullAddress: formatAddressEntry(jobForm),
                                clientId: +jobForm.clientId,
                                portfolioName: jobForm.portfolioName,
                                feeSplitAmount: jobForm.feeSplitAmount,
                                feeSplitPercent: 0,
                                statusId: jobForm.statusId,
                                owner: jobForm.owner,
                                delivery: jobForm.delivery,
                                retainerAmount: jobForm.retainerAmount,
                                function: jobForm.function,
                                interest: jobForm.interest,
                                valueType: jobForm.valueType,
                                expenses: jobForm.expenses,
                                scopes: additionalScope ? jobForm.scopes : '',
                                streetName: jobForm.streetName,
                                streetNo: jobForm.streetNo,
                                province: jobForm.province,
                                postalCode: jobForm.postalCode,
                                city: jobForm.city,
                                unit: jobForm.unit,
                                county: jobForm.county,
                                countryName: jobForm.countryName,
                                reportTypeId: +jobForm.reportTypeId,
                                jobNumber: jobForm.jobNumber,
                                tblPropertiesUsingId: {
                                    create: properties.filter(function (p) { return +p.id === 0 && !p.deleted; }).map(function (c) { return ({
                                        streetNo: c.address.houseNumber,
                                        streetName: c.address.street,
                                        zctFullAddress: c.address.label,
                                        postalCode: c.address.postalCode,
                                        province: c.address.state,
                                        county: c.address.county,
                                        city: c.address.city,
                                        countryName: c.address.countryName,
                                        propertyTypeId: jobForm.propertyTypeId,
                                        unit: c.address.unit,
                                    }); }),
                                    updateById: properties.filter(function (p) { return +p.id !== 0 && !p.deleted; }).map(function (c) { return ({
                                        id: +c.id,
                                        tblPropertyPatch: {
                                            id: +c.id,
                                            streetNo: c.address.houseNumber,
                                            streetName: c.address.street,
                                            zctFullAddress: c.address.label,
                                            postalCode: c.address.postalCode,
                                            province: c.address.state,
                                            county: c.address.county,
                                            city: c.address.city,
                                            countryName: c.address.countryName,
                                            propertyTypeId: jobForm.propertyTypeId,
                                            unit: c.address.unit,
                                        },
                                    }); }),
                                    deleteById: properties.filter(function (p) { return p.deleted; }).map(function (c) { return ({ id: c.id }); }),
                                }
                            },
                        },
                    })];
            case 1:
                _a.sent();
                return [3 /*break*/, 5];
            case 2: return [4 /*yield*/, apolloClient.query({
                    query: ADDNEWJOB_MULTIPLE_PROPERTY,
                    variables: __assign(__assign({}, jobForm), { scopes: additionalScope ? jobForm.scopes : '', statusId: +jobForm.jobTypeId === 1 ? 3 : 5, zctFullAddress: formatAddressEntry(jobForm), streetName: jobForm.streetName, streetNo: jobForm.streetNo, province: jobForm.province, postalCode: jobForm.postalCode, city: jobForm.city, county: jobForm.county, countryName: jobForm.countryName, unit: jobForm.unit, properties: properties.map(function (c) { return ({
                            streetNo: c.address.houseNumber,
                            streetName: c.address.street,
                            zctFullAddress: c.address.label,
                            postalCode: c.address.postalCode,
                            province: c.address.state,
                            county: c.address.county,
                            city: c.address.city,
                            countryName: c.address.countryName,
                            unit: c.address.unit,
                            propertyTypeId: jobForm.propertyType,
                            // tblPropertyAppraisersUsingId: {
                            //     create: {
                            //         id: +(c.appraiser.id || 0),
                            //         reportFee: c.fee,
                            //         feeType: c.feeType || 0,
                            //     }
                            // }
                        }); }) }),
                })];
            case 3:
                data = (_a.sent()).data;
                return [4 /*yield*/, afterJobCreated(jobForm, +data.createTblJob.tblJob.id, attachment, decription, apolloClient)];
            case 4:
                _a.sent();
                return [2 /*return*/, data.createTblJob.tblJob.id];
            case 5: return [3 /*break*/, 11];
            case 6:
                if (!id) return [3 /*break*/, 8];
                inputData = {
                    fileNumber: jobForm.fileNumber,
                    jobTypeId: +jobForm.jobTypeId,
                    zzFullAddress: formatAddressEntry(jobForm),
                    clientId: +jobForm.clientId,
                    portfolioName: jobForm.portfolioName,
                    feeSplitAmount: jobForm.feeSplitAmount,
                    feeSplitPercent: 0,
                    statusId: jobForm.statusId,
                    propertyType: jobForm.propertyType,
                    propertySubType: jobForm.propertySubType,
                    streetName: jobForm.streetName,
                    streetNo: jobForm.streetNo,
                    province: jobForm.province,
                    postalCode: jobForm.postalCode,
                    city: jobForm.city,
                    county: jobForm.county,
                    countryName: jobForm.countryName,
                    owner: jobForm.owner,
                    delivery: jobForm.delivery,
                    retainerAmount: jobForm.retainerAmount,
                    function: jobForm.function,
                    interest: jobForm.interest,
                    valueType: jobForm.valueType,
                    expenses: jobForm.expenses,
                    scopes: additionalScope ? jobForm.scopes : '',
                    reportTypeId: +jobForm.reportTypeId,
                    note: jobForm.note,
                    contactphone: jobForm.contactphone,
                    contactname: jobForm.contactname,
                    jobNumber: jobForm.jobNumber,
                    unit: jobForm.unit,
                    hst: +jobForm.hst,
                    lat: jobForm.lat,
                    lng: jobForm.lng,
                    jobDueDate: jobForm.jobDueDate,
                    orderId: jobForm.orderId,
                };
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE,
                        variables: {
                            id: id,
                            input: inputData,
                        },
                    })];
            case 7:
                _a.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, apolloClient.query({
                    query: ADDNEWJOB,
                    variables: __assign(__assign({}, jobForm), { hst: +jobForm.hst, zzFullAddress: formatAddressEntry(jobForm), external_id: uuid(), statusId: +jobForm.jobTypeId === 1 ? 3 : 5, zctFullAddress: formatAddressEntry(jobForm) }),
                })];
            case 9:
                data = (_a.sent()).data;
                return [4 /*yield*/, afterJobCreated(jobForm, +data.createTblJob.tblJob.id, attachment, decription, apolloClient)];
            case 10:
                _a.sent();
                return [2 /*return*/, data.createTblJob.tblJob.id];
            case 11:
                if (!id) return [3 /*break*/, 13];
                return [4 /*yield*/, afterJobCreated(jobForm, id, attachment, decription, apolloClient, true)];
            case 12:
                _a.sent();
                return [2 /*return*/, id];
            case 13: return [2 /*return*/];
        }
    });
}); }; };
export var afterJobCreated = function (jobForm, jobId, attachment, decription, apolloClient, updated) {
    if (updated === void 0) { updated = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var newAppraiser;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newAppraiser = false;
                    if (!((jobForm === null || jobForm === void 0 ? void 0 : jobForm.appraiserId) && +(jobForm === null || jobForm === void 0 ? void 0 : jobForm.appraiserId) !== -1)) return [3 /*break*/, 2];
                    return [4 /*yield*/, upsertAppraiser(jobId, +(jobForm === null || jobForm === void 0 ? void 0 : jobForm.appraiserId), +(jobForm === null || jobForm === void 0 ? void 0 : jobForm.comission), +(jobForm === null || jobForm === void 0 ? void 0 : jobForm.comissionType))(apolloClient)];
                case 1:
                    newAppraiser = _b.sent();
                    _b.label = 2;
                case 2:
                    if (!((_a = jobForm.jobExpenses) === null || _a === void 0 ? void 0 : _a.length)) return [3 /*break*/, 4];
                    return [4 /*yield*/, Promise.all(jobForm.jobExpenses.map(function (eps) { return __awaiter(void 0, void 0, void 0, function () {
                            var id, values, data;
                            var _a, _b, _c, _d, _e;
                            return __generator(this, function (_f) {
                                switch (_f.label) {
                                    case 0:
                                        id = (_b = (_a = Object.keys(eps || {})) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.replace('value', '');
                                        values = +("" + (((_c = Object.values(eps || {})) === null || _c === void 0 ? void 0 : _c[0]) || 0));
                                        if (!(jobForm.expenseIds || []).some(function (p) { var _a; return ((_a = p.tblOptionByOptionId) === null || _a === void 0 ? void 0 : _a.id) === +id; })) return [3 /*break*/, 1];
                                        return [2 /*return*/, apolloClient.query({
                                                query: UPDATE_JOB_EXPENSE,
                                                variables: {
                                                    id: jobForm.expenseIds.find(function (p) { var _a; return ((_a = p.tblOptionByOptionId) === null || _a === void 0 ? void 0 : _a.id) === +id; }).id,
                                                    input: {
                                                        value: values,
                                                    },
                                                },
                                            })];
                                    case 1:
                                        if (!(values && values > 0)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, apolloClient.query({
                                                query: ADD_JOB_EXPENSES,
                                                variables: {
                                                    input: {
                                                        jobId: jobId,
                                                        value: values,
                                                        optionId: +id,
                                                    },
                                                },
                                            })];
                                    case 2:
                                        data = (_f.sent()).data;
                                        return [4 /*yield*/, upsertAppExpensePortion(jobId, +(jobForm === null || jobForm === void 0 ? void 0 : jobForm.appraiserId), +((_e = (_d = data === null || data === void 0 ? void 0 : data.createTblExpense) === null || _d === void 0 ? void 0 : _d.tblExpense) === null || _e === void 0 ? void 0 : _e.id), 100)(apolloClient)];
                                    case 3: return [2 /*return*/, _f.sent()];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }))];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    if (!updated) return [3 /*break*/, 6];
                    return [4 /*yield*/, httpPost("job/updated/" + jobId, {
                            jobId: jobId,
                            newAppraiser: newAppraiser,
                        })];
                case 5:
                    _b.sent();
                    return [3 /*break*/, 9];
                case 6: return [4 /*yield*/, httpPost("job/created/" + jobId, {
                        jobId: jobId,
                        newAppraiser: newAppraiser,
                    })];
                case 7:
                    _b.sent();
                    if (!(attachment && attachment.length)) return [3 /*break*/, 9];
                    return [4 /*yield*/, httpPostUpload("document/upload/" + jobId, attachment, { documentType: DOCUMENT_TYPE.JOBS, description: decription })];
                case 8:
                    _b.sent();
                    _b.label = 9;
                case 9: return [2 /*return*/];
            }
        });
    });
};
export var getAppExpensePortion = function (jobId) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: APPRAISER_EXPENSE_PORTION,
                    variables: {
                        jobId: jobId
                    },
                })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, ((_a = data === null || data === void 0 ? void 0 : data.allTblExpAppraiserPortions) === null || _a === void 0 ? void 0 : _a.nodes) || []];
        }
    });
}); }; };
export var removeAppExpByAppId = function (id) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(id === null || id === void 0 ? void 0 : id.length))
                    return [2 /*return*/];
                return [4 /*yield*/, Promise.all(id.map(function (i) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, apolloClient.query({
                                        query: DELETE_APPRAISER_EXPENSE_PORTION,
                                        variables: {
                                            id: i,
                                        },
                                    })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/, true];
                            }
                        });
                    }); }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var updateReviewer = function (appId, role) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: UPDATE_REVIEWER,
                    variables: {
                        id: appId,
                        role: role,
                    },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var upsertAppExpensePortion = function (jobId, appId, expId, rate) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!appId || appId === -1)
                    return [2 /*return*/];
                return [4 /*yield*/, apolloClient.query({
                        query: APPRAISER_EXPENSE_PORTION_EXISTS,
                        variables: {
                            appId: appId,
                            expId: expId
                        },
                    })];
            case 1:
                data = (_e.sent()).data;
                if (!(rate > 0 && ((_a = data === null || data === void 0 ? void 0 : data.allTblExpAppraiserPortions) === null || _a === void 0 ? void 0 : _a.totalCount) === 0)) return [3 /*break*/, 3];
                return [4 /*yield*/, apolloClient.query({
                        query: INSERT_APPRAISER_EXPENSE_PORTION,
                        variables: {
                            input: {
                                appId: appId,
                                expId: expId,
                                rate: rate,
                                jobId: jobId,
                            },
                        },
                    })];
            case 2:
                _e.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, apolloClient.query({
                    query: UPDATE_APPRAISER_EXPENSE_PORTION,
                    variables: {
                        id: (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.allTblExpAppraiserPortions) === null || _b === void 0 ? void 0 : _b.nodes) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.id,
                        input: {
                            appId: appId,
                            expId: expId,
                            rate: rate,
                            jobId: jobId,
                        },
                    },
                })];
            case 4:
                _e.sent();
                _e.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}); }; };
export var appraiserEmail = function (id, add) {
    if (add === void 0) { add = true; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("job/assignment/appraiser/" + id, {
                        jobId: id,
                        newAppraiser: add,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export var formatFileNumber = function (fileNumber, formatter) {
    var _a;
    var company = !formatter ? readFromStorage(STORAGE_KEY.COMPANY) : formatter;
    return ((_a = company === null || company === void 0 ? void 0 : company.fileNumberFormat) === null || _a === void 0 ? void 0 : _a.replace('{0}', fileNumber)) || fileNumber;
};
export var getNextFileNumber = function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_NEXT_FILE_NUMBER,
                    variables: {}
                })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, (_a = data === null || data === void 0 ? void 0 : data.spGetFileNumber) === null || _a === void 0 ? void 0 : _a.bigFloat];
        }
    });
}); };
export var getJobAddress = function (id, apolloClient, valuationHistory) {
    if (valuationHistory === void 0) { valuationHistory = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var data, _a;
        var _b;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, apolloClient.query({
                        query: GET_JOB_ADDRESS,
                        variables: {
                            id: id,
                        }
                    })];
                case 1:
                    data = (_d.sent()).data;
                    _b = {
                        address: data === null || data === void 0 ? void 0 : data.tblJobById,
                        clientId: (_c = data === null || data === void 0 ? void 0 : data.tblJobById) === null || _c === void 0 ? void 0 : _c.clientId
                    };
                    if (!!valuationHistory) return [3 /*break*/, 2];
                    _a = [];
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, getValuationHisotry(id, apolloClient)];
                case 3:
                    _a = _d.sent();
                    _d.label = 4;
                case 4: return [2 /*return*/, (_b.history = _a,
                        _b)];
            }
        });
    });
};
export var formatGoogleAddress = function (address) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    var add = {
        city: ((_b = (_a = address.address_components) === null || _a === void 0 ? void 0 : _a.find(function (c) { return c.types.includes('sublocality'); })) === null || _b === void 0 ? void 0 : _b.long_name) || ((_d = (_c = address.address_components) === null || _c === void 0 ? void 0 : _c.find(function (c) { return c.types.includes('locality'); })) === null || _d === void 0 ? void 0 : _d.long_name) || '',
        houseNumber: ((_f = (_e = address.address_components) === null || _e === void 0 ? void 0 : _e.find(function (c) { return c.types.includes('street_number'); })) === null || _f === void 0 ? void 0 : _f.long_name) || '',
        label: address.formatted_address,
        name: address.formatted_address,
        postalCode: ((_h = (_g = address.address_components) === null || _g === void 0 ? void 0 : _g.find(function (c) { return c.types.includes('postal_code'); })) === null || _h === void 0 ? void 0 : _h.long_name) || '',
        street: ((_k = (_j = address.address_components) === null || _j === void 0 ? void 0 : _j.find(function (c) { return c.types.includes('route'); })) === null || _k === void 0 ? void 0 : _k.short_name) || '',
        countryCode: ((_m = (_l = address.address_components) === null || _l === void 0 ? void 0 : _l.find(function (c) { return c.types.includes('country'); })) === null || _m === void 0 ? void 0 : _m.short_name) || '',
        countryName: ((_p = (_o = address.address_components) === null || _o === void 0 ? void 0 : _o.find(function (c) { return c.types.includes('country'); })) === null || _p === void 0 ? void 0 : _p.long_name) || '',
        county: ((_r = (_q = address.address_components) === null || _q === void 0 ? void 0 : _q.find(function (c) { return c.types.includes('administrative_area_level_2'); })) === null || _r === void 0 ? void 0 : _r.long_name) || '',
        state: ((_t = (_s = address.address_components) === null || _s === void 0 ? void 0 : _s.find(function (c) { return c.types.includes('administrative_area_level_1'); })) === null || _t === void 0 ? void 0 : _t.long_name) || '',
        district: '',
        stateCode: ((_v = (_u = address.address_components) === null || _u === void 0 ? void 0 : _u.find(function (c) { return c.types.includes('administrative_area_level_1'); })) === null || _v === void 0 ? void 0 : _v.short_name) || '',
        lat: ((_x = (_w = address === null || address === void 0 ? void 0 : address.geometry) === null || _w === void 0 ? void 0 : _w.location) === null || _x === void 0 ? void 0 : _x.lat()) || null,
        lng: ((_z = (_y = address === null || address === void 0 ? void 0 : address.geometry) === null || _y === void 0 ? void 0 : _y.location) === null || _z === void 0 ? void 0 : _z.lng()) || null,
        unit: ((_1 = (_0 = address.address_components) === null || _0 === void 0 ? void 0 : _0.find(function (c) { return c.types.includes('subpremise'); })) === null || _1 === void 0 ? void 0 : _1.long_name) || '',
    };
    return add;
};
export var calculateDistance = function (start, end) {
    var google = window.google;
    var distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(start, end);
    return parseFloat((distanceInMeters / 1000).toFixed(2));
};
export var formatUnitSteet = function (job) { var _a; return (_a = (!job ? '' : (job.unit || '') + " " + (job.streetNo || '') + " " + ((job === null || job === void 0 ? void 0 : job.streetName) || ''))) === null || _a === void 0 ? void 0 : _a.trim(); };
export var formatAddress = function (job) { var _a; return (_a = ((job === null || job === void 0 ? void 0 : job.zzFullAddress) || ((job === null || job === void 0 ? void 0 : job.unit) || '') + " " + ((job === null || job === void 0 ? void 0 : job.streetNo) || '') + " " + ((job === null || job === void 0 ? void 0 : job.streetName) || '') + ", " + ((job === null || job === void 0 ? void 0 : job.city) || '') + ", " + ((job === null || job === void 0 ? void 0 : job.province) || '') + ", " + ((job === null || job === void 0 ? void 0 : job.postalCode) || ''))) === null || _a === void 0 ? void 0 : _a.trim(); };
export var formatAddressEntry = function (job) { var _a; return (_a = ((job === null || job === void 0 ? void 0 : job.label) ? job.label : ((job === null || job === void 0 ? void 0 : job.unit) || '') + " " + ((job === null || job === void 0 ? void 0 : job.streetNo) || '') + " " + ((job === null || job === void 0 ? void 0 : job.streetName) || '') + ", " + ((job === null || job === void 0 ? void 0 : job.city) || '') + ", " + ((job === null || job === void 0 ? void 0 : job.province) || '') + ", " + ((job === null || job === void 0 ? void 0 : job.postalCode) || ''))) === null || _a === void 0 ? void 0 : _a.trim(); };
export var getCountryName = function (job) { return (job === null || job === void 0 ? void 0 : job.countryName) || ''; };
