var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Tooltip } from '@material-ui/core';
import { ModalForm } from '../modals/Modal-Form.component';
export var DeleteButton = function (_a) {
    var _b = _a.onClick, onClick = _b === void 0 ? null : _b, _c = _a.onDelete, onDelete = _c === void 0 ? null : _c, _d = _a.index, index = _d === void 0 ? 0 : _d, _e = _a.id, id = _e === void 0 ? null : _e, _f = _a.size, size = _f === void 0 ? 20 : _f, _g = _a.color, color = _g === void 0 ? '#6b6c72' : _g, _h = _a.title, title = _h === void 0 ? 'Delete Confirmation' : _h, _j = _a.body, body = _j === void 0 ? 'Are you sure ?' : _j, _k = _a.toolTip, toolTip = _k === void 0 ? 'Delete' : _k;
    var removeRow = function (e) {
        e.preventDefault();
        ModalForm.confirm({
            body: (_jsx("div", { children: body }, void 0)),
            title: title,
            remove: true,
            onSuccess: function () {
                onClick && onClick(index);
                onDelete && onDelete(id);
                ModalForm.close();
            }
        });
    };
    return (_jsx(IconButton, __assign({ className: "btn-xs-no-padd", onClick: removeRow, "aria-label": toolTip }, { children: _jsx(Tooltip, __assign({ title: toolTip }, { children: _jsx(DeleteIcon, { style: { fontSize: size, color: color } }, void 0) }), void 0) }), void 0));
};
