var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FIELD_TYPE } from "@enums";
import { httpPost } from "../helpers/http.helper";
import { CUSTOMFIELDS_TYPETABLE } from "../graphqls/type-tables/type-table.query";
import { setFieldsSections } from "../stores/app.reducer";
export var getDataType = function () { return [
    { id: FIELD_TYPE.TEXT, title: 'Text' },
    { id: FIELD_TYPE.TEXT_AREA, title: 'Multi-line Text' },
    { id: FIELD_TYPE.NUMBER, title: 'Number' },
    { id: FIELD_TYPE.SINGLE_SELECT, title: 'Dropdown' },
    { id: FIELD_TYPE.Multi_SELECT, title: 'Multi-Select' },
    { id: FIELD_TYPE.CURRENCY, title: 'Amount' },
    { id: FIELD_TYPE.PERCENT, title: 'Percentage' },
    { id: FIELD_TYPE.DATE, title: 'Date' },
    { id: FIELD_TYPE.YES_NO, title: 'CheckBox' },
]; };
export var mapDataType = function (type) {
    switch (type) {
        case FIELD_TYPE.TEXT: return "Text";
        case FIELD_TYPE.TEXT_AREA: return "Text";
        case FIELD_TYPE.NUMBER: return "Number";
        case FIELD_TYPE.SINGLE_SELECT: return "Dropdown";
        case FIELD_TYPE.Multi_SELECT: return "Multi-Select";
        case FIELD_TYPE.CURRENCY: return "Amount";
        case FIELD_TYPE.PERCENT: return "Percentage";
        case FIELD_TYPE.DATE: return "Date";
        case FIELD_TYPE.YES_NO: return "CheckBox";
    }
};
export var loadCustomFields = function (apolloClient, dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: CUSTOMFIELDS_TYPETABLE,
                    variables: {},
                })];
            case 1:
                data = (_e.sent()).data;
                dispatch(setFieldsSections({
                    fields: (_a = data === null || data === void 0 ? void 0 : data.allTblFields) === null || _a === void 0 ? void 0 : _a.nodes,
                    sections: (_b = data === null || data === void 0 ? void 0 : data.allTblSections) === null || _b === void 0 ? void 0 : _b.nodes,
                    sectionFields: (_c = data === null || data === void 0 ? void 0 : data.allTblSectionFields) === null || _c === void 0 ? void 0 : _c.nodes,
                    fieldOption: (_d = data === null || data === void 0 ? void 0 : data.allTblFieldSelects) === null || _d === void 0 ? void 0 : _d.nodes
                }));
                return [2 /*return*/];
        }
    });
}); };
export var upsertField = function (data, section) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, httpPost("dbSchema/addField/" + section, __assign({}, data))];
            case 1:
                result = _a.sent();
                return [2 /*return*/, result];
        }
    });
}); };
export var removeFieldSection = function (id, section) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, httpPost("dbSchema/removeField", {
                    id: id,
                    section: section,
                })];
            case 1:
                result = _a.sent();
                return [2 /*return*/, result];
        }
    });
}); };
