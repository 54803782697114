var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { getAppModules, getSidebarWidth } from "../stores/app.selector";
import { isMobile } from "react-device-detect";
export var Footer = function () {
    var appModule = useSelector(getAppModules);
    var sidebarLeft = useSelector(getSidebarWidth);
    if (isMobile) {
        return null;
    }
    return (_jsx("footer", __assign({ className: "footer footer-default " + (appModule.read_only ? 'subscription-ends' : ''), style: { left: sidebarLeft ? sidebarLeft : '200px' } }, { children: _jsxs("div", __assign({ className: "db w-100" }, { children: [_jsx("div", __assign({ className: "dib w-50 pl-10" }, { children: appModule.read_only ? 'Your subscription has been expired !' : appModule.title }), void 0),
                _jsxs("div", __assign({ className: "dib w-50 right pr-30" }, { children: ["ICISIGMA \u00A9 ", new Date().getFullYear()] }), void 0)] }), void 0) }), void 0));
};
