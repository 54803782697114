export var QuoteOptionType;
(function (QuoteOptionType) {
    QuoteOptionType[QuoteOptionType["PURPOS"] = 1] = "PURPOS";
    QuoteOptionType[QuoteOptionType["FUNCTION"] = 2] = "FUNCTION";
    QuoteOptionType[QuoteOptionType["INTEREST"] = 3] = "INTEREST";
    QuoteOptionType[QuoteOptionType["REPORT_TYPE"] = 4] = "REPORT_TYPE";
    QuoteOptionType[QuoteOptionType["EXPENSES"] = 5] = "EXPENSES";
    QuoteOptionType[QuoteOptionType["PROPERTY_TYPE"] = 0] = "PROPERTY_TYPE";
    QuoteOptionType[QuoteOptionType["SALE_TYPE"] = 6] = "SALE_TYPE";
    QuoteOptionType[QuoteOptionType["RIGHTS_TRADED"] = 7] = "RIGHTS_TRADED";
    QuoteOptionType[QuoteOptionType["TENANCY_TYPE"] = 8] = "TENANCY_TYPE";
    QuoteOptionType[QuoteOptionType["TIMELINE"] = 9] = "TIMELINE";
    QuoteOptionType[QuoteOptionType["DEV_STATUS"] = 10] = "DEV_STATUS";
    QuoteOptionType[QuoteOptionType["PROPERTY_TYPE_SALES"] = 20] = "PROPERTY_TYPE_SALES";
    QuoteOptionType[QuoteOptionType["PROPERTY_SUB_TYPE"] = 19] = "PROPERTY_SUB_TYPE";
    QuoteOptionType[QuoteOptionType["NOTE_REPORT_TYPE"] = 33] = "NOTE_REPORT_TYPE";
    QuoteOptionType[QuoteOptionType["DCA_ADJUSTMENT_DESCRIPTION"] = 100] = "DCA_ADJUSTMENT_DESCRIPTION";
})(QuoteOptionType || (QuoteOptionType = {}));
