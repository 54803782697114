var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import WarningIcon from '@material-ui/icons/Warning';
import DoneAllIcon from '@material-ui/icons/DoneAll';
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClose = function () {
            _this.setState({ open: false });
        };
        _this.state = {
            open: false,
            onSuccess: props.onSuccess,
        };
        Alert.Instance = _this;
        return _this;
    }
    Alert.close = function () {
        Alert.Instance.setState({
            open: false,
        });
    };
    Alert.success = function (_a) {
        var _b = _a.title, title = _b === void 0 ? 'Success' : _b, _c = _a.message, message = _c === void 0 ? '' : _c;
        if (Alert.Instance) {
            Alert.Instance.setState({
                open: true,
                body: message,
                title: title,
                footer: false,
                small: true,
                large: false,
                save: 'Save',
                onSuccessClose: false,
                backdrop: true,
                backgroundColor: 'green',
                icon: (_jsx(DoneAllIcon, { style: { color: 'white' } }, void 0))
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    Alert.show = function (_a) {
        var _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.message, message = _c === void 0 ? '' : _c;
        if (Alert.Instance) {
            Alert.Instance.setState({
                open: true,
                body: message,
                title: title,
                footer: false,
                small: true,
                large: false,
                save: 'Save',
                onSuccessClose: false,
                backdrop: true,
                backgroundColor: 'red',
                icon: (_jsx(WarningIcon, { style: { color: 'white' } }, void 0))
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    Alert.prototype.render = function () {
        var _a = this.state, open = _a.open, body = _a.body, title = _a.title, small = _a.small, large = _a.large, backdrop = _a.backdrop, backgroundColor = _a.backgroundColor, icon = _a.icon;
        return (_jsx("div", { children: _jsxs(Modal, __assign({ backdrop: backdrop, size: large ? "lg" : (small ? "md" : "xl"), isOpen: open, toggle: this.handleClose, className: "" }, { children: [_jsxs(ModalHeader, __assign({ style: { color: 'white', backgroundColor: backgroundColor }, toggle: this.handleClose }, { children: [icon, "  ", title] }), void 0),
                    _jsx(ModalBody, { children: _jsx("div", __assign({ style: { paddingTop: 8, paddingBottom: 16 } }, { children: body }), void 0) }, void 0),
                    _jsx(ModalFooter, { children: _jsx(Button, __assign({ onClick: this.handleClose }, { children: "Close" }), void 0) }, void 0)] }), void 0) }, void 0));
    };
    return Alert;
}(React.Component));
export { Alert };
