var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { ALL_TBLCLIENTS } from "../../graphqls/clients/client.query";
import { GET_REPORT_FEE, UPDATE_CLIENT_FEE, ADD_CLIENT_FEE } from "../../graphqls/settings/company.query";
import { useEffect, useState, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { Col, Row, Button } from "reactstrap";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { getOptions } from "../../stores/client.selector";
import { useSelector } from "react-redux";
import { QuoteOptionType } from "@enums";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { useFormik } from 'formik';
import Grid from "@material-ui/core/Grid";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { TableFooter } from "../../ui-components/table/table-footer.component";
import { getAppModules } from "../../stores/app.selector";
import { isMobile } from "react-device-detect";
export var ClientFee = function (_a) {
    var clientId = _a.clientId;
    var apolloClient = useApolloQuery()[0];
    var _b = useState([]), clients = _b[0], setClients = _b[1];
    var _c = useState([]), fees = _c[0], setFees = _c[1];
    var _d = useState(0), pageIndex = _d[0], setPageIndex = _d[1];
    var _e = useState(25), pageSize = _e[0], setPageSize = _e[1];
    var options = useSelector(getOptions);
    var appModule = useSelector(getAppModules);
    var formik = useFormik({
        initialValues: {},
        onSubmit: function (values, e) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Spinner.show();
                        return [4 /*yield*/, Promise.all(Object.keys(values).map(function (key) { return __awaiter(void 0, void 0, void 0, function () {
                                var clientId, reportTypeId, currentFee;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            clientId = +key.replace('fee', '').split('-').slice(1)[0];
                                            reportTypeId = +key.replace('fee', '').split('-').slice(1)[1];
                                            currentFee = fees === null || fees === void 0 ? void 0 : fees.find(function (f) { return +f.clientId === clientId && +f.reportType === reportTypeId; });
                                            if (!currentFee) return [3 /*break*/, 2];
                                            return [4 /*yield*/, apolloClient.query({
                                                    query: UPDATE_CLIENT_FEE,
                                                    variables: {
                                                        id: currentFee.id,
                                                        fee: +values[key],
                                                    },
                                                })];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 4];
                                        case 2:
                                            if (!values[key]) return [3 /*break*/, 4];
                                            return [4 /*yield*/, apolloClient.query({
                                                    query: ADD_CLIENT_FEE,
                                                    variables: {
                                                        input: {
                                                            fee: +values[key],
                                                            clientId: clientId,
                                                            reportType: reportTypeId,
                                                        },
                                                    },
                                                })];
                                        case 3:
                                            _a.sent();
                                            _a.label = 4;
                                        case 4: return [2 /*return*/, null];
                                    }
                                });
                            }); }))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, loadData(true)];
                    case 2:
                        _a.sent();
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); },
    });
    var submitData = function () {
        formik.submitForm();
    };
    var loadData = function (feeOnly) {
        if (feeOnly === void 0) { feeOnly = false; }
        return __awaiter(void 0, void 0, void 0, function () {
            var fees, clients_1;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        Spinner.show();
                        return [4 /*yield*/, apolloClient.query({
                                query: GET_REPORT_FEE,
                            })];
                    case 1:
                        fees = _e.sent();
                        setFees(((_b = (_a = fees === null || fees === void 0 ? void 0 : fees.data) === null || _a === void 0 ? void 0 : _a.allTblClientFees) === null || _b === void 0 ? void 0 : _b.nodes) || []);
                        if (!!feeOnly) return [3 /*break*/, 3];
                        return [4 /*yield*/, apolloClient.query({
                                query: ALL_TBLCLIENTS,
                            })];
                    case 2:
                        clients_1 = _e.sent();
                        setClients(((_d = (_c = clients_1 === null || clients_1 === void 0 ? void 0 : clients_1.data) === null || _c === void 0 ? void 0 : _c.allTblClients) === null || _d === void 0 ? void 0 : _d.nodes) || []);
                        _e.label = 3;
                    case 3:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    var fetchMore = function (page, size) {
        if (size === void 0) { size = 25; }
        setPageIndex(page);
        setPageSize(size);
    };
    var showHeaderFooter = useMemo(function () { return !clientId; }, [clientId]);
    useEffect(function () {
        loadData();
    }, []);
    return (_jsx("div", __assign({ className: "content" }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit, id: "form-report-fee" }, { children: [showHeaderFooter && (_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, __assign({ title: "Report Type Fees" }, { children: !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) &&
                                _jsxs(Button, __assign({ type: "submit", color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0) }, void 0) }, void 0)),
                _jsx(Row, __assign({ className: showHeaderFooter ? "bg-white mt-16" : "pl-20 bg-white mt-16" }, { children: clients.filter(function (c) { return !clientId || clientId === +c.id; }).slice(pageIndex * pageSize, pageIndex * pageSize + pageSize).map(function (client) { return (_jsxs("div", __assign({ className: "db w-100" }, { children: [showHeaderFooter && (_jsx("div", __assign({ className: "db w-100 mt-32 bb-dotte p-all-4" }, { children: _jsxs(Typography, __assign({ className: "card-header-h5" }, { children: [" ", client.name] }), void 0) }), void 0)),
                            _jsx(Grid, __assign({ container: true, spacing: 1, className: "p-all-4" }, { children: (options || []).filter(function (c) { return c.optionType === QuoteOptionType.REPORT_TYPE; })
                                    .map(function (type) {
                                    var _a;
                                    return (_jsx(Grid, __assign({ item: true, xs: showHeaderFooter ? (isMobile ? 4 : 2) : 12 }, { children: _jsxs("div", __assign({ className: clientId ? "flex gap-8" : "" }, { children: [_jsx(Typography, __assign({ variant: "caption", className: (clientId ? "min-w-200 right" : "") + (isMobile ? ' ellipsis db' : '') }, { children: type.title }), void 0),
                                                _jsx(InputText, { prepend: "$", type: "number", InputProps: true, size: "sm", step: "0.01", className: "max-w-150", name: "fee-" + client.id + "-" + type.id, id: "fee-" + client.id + "-" + type.id, onChange: formik.handleChange, defaultValue: ((_a = fees === null || fees === void 0 ? void 0 : fees.find(function (f) { return +f.clientId === +client.id && +f.reportType === +type.id; })) === null || _a === void 0 ? void 0 : _a.fee) || '' }, void 0)] }), void 0) }), "row-" + client.id + "-" + type.id));
                                }) }), void 0)] }), "client-" + client.id)); }) }), void 0),
                showHeaderFooter ? (_jsx(Row, __assign({ className: "bg-white" }, { children: _jsx(TableFooter, { pageIndex: pageIndex, pageSize: pageSize, totalCount: (clients === null || clients === void 0 ? void 0 : clients.length) || 0, fetchMore: fetchMore }, void 0) }), void 0)) : (_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsxs(Button, __assign({ onClick: submitData, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Report Fees"] }), void 0) }), void 0) }), void 0))] }), void 0) }), void 0));
};
