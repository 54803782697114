var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createAction, createReducer } from '@reduxjs/toolkit';
export var setSelectedJob = createAction('set-Selected-Job');
export var appendSelectedJob = createAction('append-Selected-Job');
export var jobReducer = createReducer(null, function (builder) {
    builder
        .addCase(setSelectedJob, function (state, action) {
        return __assign(__assign({}, state), { job: __assign({}, action.payload) });
    }).addCase(appendSelectedJob, function (state, action) {
        return __assign(__assign({}, state), { job: __assign(__assign({}, state === null || state === void 0 ? void 0 : state.job), action.payload) });
    });
});
