var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setAddInvoice, setAddPayment } from "../../stores/app.reducer";
var PopupForm = /** @class */ (function (_super) {
    __extends(PopupForm, _super);
    function PopupForm(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClose = function () {
            PopupForm.Instance.state.dispatch && PopupForm.Instance.state.dispatch(setAddInvoice('CLOSE'));
            PopupForm.Instance.state.dispatch && PopupForm.Instance.state.dispatch(setAddPayment('CLOSE'));
            _this.setState({ open: false });
            if (PopupForm.Instance.state.onClose) {
                PopupForm.Instance.state.onClose();
            }
        };
        _this.handleSuccess = function () {
            if (PopupForm.Instance.state.scope) {
                document.getElementById(PopupForm.Instance.state.scope).click();
                return;
            }
            else if (PopupForm.Instance.state.onSuccessClose) {
                _this.setState({ open: false });
            }
            if (PopupForm.Instance.state.onSuccess) {
                PopupForm.Instance.state.onSuccess();
            }
            else {
                _this.setState({ open: false });
            }
        };
        _this.state = {
            open: false,
            onSuccess: props.onSuccess,
        };
        PopupForm.Instance = _this;
        return _this;
    }
    PopupForm.close = function () {
        PopupForm.Instance.state.dispatch && PopupForm.Instance.state.dispatch(setAddInvoice('CLOSE'));
        PopupForm.Instance.state.dispatch && PopupForm.Instance.state.dispatch(setAddPayment('CLOSE'));
        PopupForm.Instance.setState({
            open: false,
            onSuccess: function () { },
        });
    };
    PopupForm.confirm = function (_a) {
        var _b = _a.onSuccess, onSuccess = _b === void 0 ? null : _b, _c = _a.body, body = _c === void 0 ? {} : _c, _d = _a.title, title = _d === void 0 ? 'Confirmation' : _d, _e = _a.scope, scope = _e === void 0 ? '' : _e, _f = _a.className, className = _f === void 0 ? '' : _f;
        if (PopupForm.Instance) {
            PopupForm.Instance.setState({
                open: true,
                onSuccess: onSuccess,
                body: body,
                title: title,
                footer: true,
                small: false,
                large: false,
                md: true,
                save: 'Confirm',
                scope: scope,
                className: className,
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    PopupForm.hide = function (_a) {
        var _b = _a.id, id = _b === void 0 ? null : _b;
        if (id) {
            PopupForm.Instance.state.onSuccess(id);
        }
        PopupForm.Instance.setState({
            open: false,
            onSuccess: function () { },
        });
    };
    PopupForm.show = function (_a) {
        var _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.onSuccess, onSuccess = _c === void 0 ? null : _c, _d = _a.body, body = _d === void 0 ? {} : _d, _e = _a.footer, footer = _e === void 0 ? true : _e, _f = _a.small, small = _f === void 0 ? false : _f, _g = _a.large, large = _g === void 0 ? false : _g, _h = _a.backdrop, backdrop = _h === void 0 ? true : _h, _j = _a.onSuccessClose, onSuccessClose = _j === void 0 ? false : _j, _k = _a.save, save = _k === void 0 ? 'Save' : _k, _l = _a.md, md = _l === void 0 ? false : _l, _m = _a.fullWidth, fullWidth = _m === void 0 ? false : _m, _o = _a.download, download = _o === void 0 ? false : _o, _p = _a.onDownload, onDownload = _p === void 0 ? null : _p, _q = _a.dispatch, dispatch = _q === void 0 ? null : _q, _r = _a.className, className = _r === void 0 ? '' : _r, _s = _a.scope, scope = _s === void 0 ? '' : _s, _t = _a.onClose, onClose = _t === void 0 ? null : _t;
        if (PopupForm.Instance) {
            PopupForm.Instance.setState({
                open: true,
                onSuccess: onSuccess,
                body: body,
                title: title,
                footer: footer,
                small: small,
                large: large,
                save: save,
                onSuccessClose: onSuccessClose,
                backdrop: backdrop,
                md: md,
                download: download,
                onDownload: onDownload,
                dispatch: dispatch,
                className: className,
                scope: scope,
                fullWidth: fullWidth,
                onClose: onClose,
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    PopupForm.prototype.render = function () {
        var _a = this.state, open = _a.open, body = _a.body, title = _a.title, footer = _a.footer, small = _a.small, large = _a.large, save = _a.save, onSuccessClose = _a.onSuccessClose, backdrop = _a.backdrop, fullWidth = _a.fullWidth, md = _a.md, className = _a.className;
        return (_jsx("div", { children: _jsxs(Modal, __assign({ backdrop: backdrop, size: fullWidth ? 'xl' : md ? "md" : large ? "lg" : (small ? "sm" : "xl"), isOpen: open, toggle: this.handleClose, className: fullWidth ? "full-with-modal" : "" }, { children: [_jsx(ModalHeader, __assign({ toggle: this.handleClose }, { children: title }), void 0),
                    _jsx(ModalBody, __assign({ className: className }, { children: body }), void 0),
                    footer && (_jsxs(ModalFooter, { children: [_jsx(Button, __assign({ color: "info", className: "btn btn-md", onClick: this.handleSuccess }, { children: save }), void 0), ' ', _jsx(Button, __assign({ color: "secondary", onClick: this.handleClose, className: "btn btn-md" }, { children: "Cancel" }), void 0)] }, void 0))] }), void 0) }, void 0));
    };
    return PopupForm;
}(React.Component));
export { PopupForm };
