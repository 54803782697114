var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, useCallback } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import { Col, Row } from "reactstrap";
import { useApolloQuery } from "../hooks/use-appolo.hooks";
import { getOverdueJobs } from "../services/job.service";
import { useDispatch } from "react-redux";
import { setOpenDrawer } from "../stores/app.reducer";
import { CALENDAR_TYPE, DRAWERCOMPONENT } from "@enums";
import { isMobile } from "react-device-detect";
import { ModalForm } from "../ui-components/modals/Modal-Form.component";
import Appointment from "./Appointment";
import { toDate } from "@utils/date";
var locales = {
    'en-US': enUS,
};
var localizer = dateFnsLocalizer({
    format: format,
    parse: parse,
    startOfWeek: startOfWeek,
    getDay: getDay,
    locales: locales,
});
export var MyCalendar = function (_a) {
    var _b = _a.withWrapper, withWrapper = _b === void 0 ? true : _b;
    var _c = useState([]), events = _c[0], setEvents = _c[1];
    var apolloClient = useApolloQuery()[0];
    var dispatch = useDispatch();
    var onNavigate = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loadData(new Date(e))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var loadData = function (date) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getOverdueJobs(apolloClient, date)];
                case 1:
                    data = _a.sent();
                    setEvents(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSelectEvent = useCallback(function (event) {
        switch (event.type) {
            case CALENDAR_TYPE.EVENTS: {
                ModalForm.show({
                    title: 'Modify Appointment',
                    md: true,
                    footer: false,
                    body: (_jsx(Appointment, { id: event.id, onUpdated: onEventAdded }, void 0))
                });
                break;
            }
            default:
                dispatch(setOpenDrawer({ status: 'OPEN', id: event.id, component: DRAWERCOMPONENT.JOB_VIEW }));
        }
    }, []);
    var onEventAdded = useCallback(function (data) {
        setEvents(events.concat({
            id: data.id,
            title: data.description,
            type: CALENDAR_TYPE.EVENTS,
            start: toDate(data.dueDate),
            end: toDate(data.dueDate),
        }));
        ModalForm.close();
    }, [events, setEvents]);
    var handleSelectSlot = useCallback(function (_a) {
        var start = _a.start, end = _a.end;
        ModalForm.show({
            title: 'Add Appointment',
            md: true,
            footer: false,
            body: (_jsx(Appointment, { date: start, onUpdated: onEventAdded }, void 0))
        });
    }, []);
    useEffect(function () {
        loadData();
    }, []);
    if (!withWrapper) {
        return (_jsx(Calendar, { onSelectEvent: handleSelectEvent, onNavigate: onNavigate, localizer: localizer, events: events, selectable: true, startAccessor: "start", onSelectSlot: handleSelectSlot, endAccessor: "end", defaultView: Views.AGENDA, Views: { month: false, week: false, day: false, agenda: false, } }, void 0));
    }
    return (_jsx("div", __assign({ className: "content pb-0 pr-0 bg-white pt-0" }, { children: _jsx(Row, __assign({ className: "bg-white pr-90 pl-20" }, { children: _jsx(Col, __assign({ xs: 12, className: "mt-16" }, { children: _jsx(Calendar, { onSelectEvent: handleSelectEvent, onNavigate: onNavigate, localizer: localizer, events: events, selectable: true, startAccessor: "start", onSelectSlot: handleSelectSlot, endAccessor: "end", style: { height: isMobile ? '80vh' : '85vh' } }, void 0) }), void 0) }), void 0) }), void 0));
};
