var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from '@material-ui/core';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import xls from "../../assets/images/xls.svg";
import doc from "../../assets/images/doc.svg";
import pdf from "../../assets/images/pdf.svg";
import img from "../../assets/images/img.svg";
export var FileExtension = function (_a) {
    var _b = _a.fileName, fileName = _b === void 0 ? '' : _b, _c = _a.onClick, onClick = _c === void 0 ? null : _c, _d = _a.className, className = _d === void 0 ? "" : _d;
    var ext = fileName.split('.');
    switch (ext[ext.length - 1].toLowerCase()) {
        case "xls":
        case "xlsx":
        case "csv":
            return (_jsx("div", __assign({ title: fileName, className: (onClick ? "pointer" : "") + " " + className, onClick: onClick }, { children: _jsx(Avatar, { src: xls }, void 0) }), void 0));
        case "doc":
        case "docx":
            return (_jsx("div", __assign({ title: fileName, className: (onClick ? "pointer" : "") + " " + className, onClick: onClick }, { children: _jsx(Avatar, { src: doc }, void 0) }), void 0));
        case "pdf":
            return (_jsx("div", __assign({ title: fileName, className: (onClick ? "pointer" : "") + " " + className, onClick: onClick }, { children: _jsx(Avatar, { src: pdf }, void 0) }), void 0));
        case "txt":
        case "sql":
            return (_jsx("div", __assign({ title: fileName, className: (onClick ? "pointer" : "") + " " + className, onClick: onClick }, { children: _jsx(Avatar, { children: _jsx(TextFieldsIcon, {}, void 0) }, void 0) }), void 0));
        case "jpg":
        case "jfif":
        case "png":
        case "jpeg":
        case "gif":
        case "tiff":
            return (_jsx("div", __assign({ title: fileName, className: (onClick ? "pointer" : "") + " " + className, onClick: onClick }, { children: _jsx(Avatar, { src: img }, void 0) }), void 0));
    }
    return null;
};
