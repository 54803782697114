import { jsx as _jsx } from "react/jsx-runtime";
import { DRAWERCOMPONENT } from '@enums';
import { ClientEdit } from '../clients/edit/Edit.component';
import { ViewComponent } from '../appraisals/view/View.component';
import { EditAppraiser } from '../appraisers/edit/Edit.component';
import { JobComponent } from '../appraisals/edit/Edit.component';
import { Payroll } from '../accounting/payroll';
export var DrawerComponent = function (_a) {
    var component = _a.component, id = _a.id, _b = _a.drawer, drawer = _b === void 0 ? true : _b;
    switch (component) {
        case DRAWERCOMPONENT.CLIENT: return (_jsx(ClientEdit, { id: id, drawer: drawer }, void 0));
        case DRAWERCOMPONENT.JOB_VIEW: return (_jsx(ViewComponent, { drawer: drawer, id: id }, void 0));
        case DRAWERCOMPONENT.APPRAISER: return (_jsx(EditAppraiser, { drawer: drawer, id: id }, void 0));
        case DRAWERCOMPONENT.JOB_EDIT: return (_jsx(JobComponent, { id: id }, void 0));
        case DRAWERCOMPONENT.PAYROLL: return (_jsx(Payroll, { id: id }, void 0));
    }
    return null;
};
