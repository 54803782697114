var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Button, Row, Col, ModalFooter } from "reactstrap";
import { Grid, Typography, Tab, Tabs } from "@material-ui/core";
import { InputText } from "../../../ui-components/input/Input-Field.component";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATECLIENT, GETBYID, UPDATE } from "../../../graphqls/clients/client.query";
import clsx from "clsx";
import { ModalForm } from "../../../ui-components/modals/Modal-Form.component";
import { addNewClient } from "../../../stores/client.reducer";
import { Field, FieldArray, Form, Formik } from "formik";
import { map } from "lodash";
import { getRemovedItem, setFormData } from "../../../helpers/form.helper";
import { ListHeader } from "../../../ui-components/lists/Header.component";
import { Income } from "../../accounting/Income";
import { useApolloQuery } from "../../../hooks/use-appolo.hooks";
import { paymentReceived } from "../../../services/payment.service";
import { Spinner } from "../../../ui-components/loading/Spinner.component";
import { InvoiceList } from "../../invoices/Invoice-List.component";
import { ClientFee } from "../../settings/Client-Fee.component";
import { httpGet, httpPost } from "../../../helpers/http.helper";
import { APP_PACKAGES, DOCUMENT_TYPE, SALUTATIONS, StatusEnum } from "@enums";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { Note } from "../../note/Note.component";
import { getAppraiserStatuses } from "../../../stores/client.selector";
import { batchJobStatusUpdate } from "../../../services/job.service";
import { AddPayment } from "../../../components/payments/edit/Add-Payment.component";
import { getAppModules } from "../../../stores/app.selector";
import { isMobile } from "react-device-detect";
export var ClientEdit = function (_a) {
    var props = __rest(_a, []);
    var history = useHistory();
    var _b = useState([]), contacts = _b[0], setContacts = _b[1];
    var _c = useState(false), submit = _c[0], setSubmit = _c[1];
    var _d = useState(1), activeStep = _d[0], setActiveStep = _d[1];
    var _e = useState([]), selectedIds = _e[0], setSelectedIds = _e[1];
    var _f = useState(''), error = _f[0], setError = _f[1];
    var _g = useState(StatusEnum.NONE), statusId = _g[0], setStatusId = _g[1];
    var _h = useState(''), reload = _h[0], setReload = _h[1];
    var apolloClient = useApolloQuery()[0];
    var dispatch = useDispatch();
    var jobStatuses = useSelector(getAppraiserStatuses);
    var appModule = useSelector(getAppModules);
    var id = ((props === null || props === void 0 ? void 0 : props.id) ? props : (props.match ? props.match.params : { id: undefined })).id;
    var drawer = ((props === null || props === void 0 ? void 0 : props.drawer) ? props : (props.match ? props.match.params : { drawer: false })).drawer;
    var changeTabs = function (tabId) {
        setActiveStep(tabId);
    };
    var _j = useState({
        name: '',
        address1: '',
        address2: '',
        province: '',
        postalCode: '',
        country: '.',
        email: '',
        website: '',
        phone: '',
        fax: '',
        note: '',
        municipality: '',
        zzCreatedBy: 'test',
        salutation: '',
        fullName: '',
        retainerRequired: false,
        retainerFee: 0,
        designation: '',
        title: '',
    }), form = _j[0], setForm = _j[1];
    var _k = useState({
        email: '',
        id: 0,
        password: '',
        retPassword: '',
        edit: true,
        error: '',
    }), userForm = _k[0], setUserForm = _k[1];
    var handleInputChange = function (key, value) {
        var _a;
        setForm(__assign(__assign({}, form), (_a = {}, _a[key] = key === 'retainerRequired' ? value === 'true' : value, _a)));
    };
    var createUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSubmit(true);
                    setError('');
                    if (!userForm.password || !userForm.retPassword || userForm.password !== userForm.retPassword)
                        return [2 /*return*/];
                    Spinner.show();
                    return [4 /*yield*/, httpPost("user/createClientUser/" + id, __assign({}, userForm))];
                case 1:
                    user = _a.sent();
                    Spinner.hide();
                    if (user.error) {
                        setError(user.error);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var clearForm = function () {
        setSubmit(false);
        setForm({
            name: '',
            address1: '',
            address2: '',
            province: '',
            postalCode: '',
            country: '',
            email: '',
            website: '',
            phone: '',
            fax: '',
            note: '',
            municipality: '',
            zzCreatedBy: 'test',
            salutation: '',
            fullName: '',
            retainerRequired: false,
            retainerFee: 0,
            designation: '',
            title: '',
        });
        Spinner.hide();
    };
    var addClient = useMutation(CREATECLIENT, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dispatch(addNewClient(data.createTblClient.tblClient));
                        return [4 /*yield*/, httpGet("intuit/createCustomers/" + data.createTblClient.tblClient.id)];
                    case 1:
                        _a.sent();
                        if (props.modal) {
                            Spinner.hide();
                            ModalForm.close();
                            return [2 /*return*/];
                        }
                        clearForm();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var updateClient = useMutation(UPDATE, {
        onCompleted: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, httpGet("intuit/createCustomers/" + id)];
                    case 1:
                        _a.sent();
                        Spinner.hide();
                        history.push("/admin/clients");
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var saveClient = function (otherContacts) {
        setSubmit(true);
        if (!form.name || !form.email) {
            return;
        }
        Spinner.show();
        if (id) {
            delete form.tblClientContactsByClientId;
            updateClient({
                variables: {
                    input: __assign(__assign({}, form), { tblClientContactsUsingId: {
                            updateById: (otherContacts || []).filter(function (c) { return c.id; }).map(function (c) { return ({
                                id: +c.id,
                                tblClientContactPatch: c,
                            }); }),
                            create: (otherContacts || []).filter(function (c) { return !c.id; }),
                            deleteById: getRemovedItem(contacts, otherContacts || []).map(function (c) { return ({ id: +c.id }); }),
                        } }),
                    id: id,
                }
            });
        }
        else {
            addClient({
                variables: {
                    input: __assign(__assign({}, form), { tblClientContactsUsingId: {
                            create: otherContacts,
                        } }),
                },
            });
        }
    };
    var getById = useLazyQuery(GETBYID, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var user;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setForm(data.tblClientById);
                        setContacts((_b = (_a = data.tblClientById) === null || _a === void 0 ? void 0 : _a.tblClientContactsByClientId) === null || _b === void 0 ? void 0 : _b.nodes);
                        return [4 /*yield*/, httpGet("user/clientUser/" + id)];
                    case 1:
                        user = _c.sent();
                        setUserForm({
                            email: (user === null || user === void 0 ? void 0 : user.email) || '',
                            id: (user === null || user === void 0 ? void 0 : user.id) || 0,
                            password: '',
                            retPassword: '',
                            edit: !(user === null || user === void 0 ? void 0 : user.id),
                        });
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var rowSelectionChanged = function (id) {
        setSelectedIds(id);
    };
    var updateStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, batchJobStatusUpdate(selectedIds, statusId, apolloClient)];
                case 1:
                    _a.sent();
                    setReload("" + new Date().getTime());
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var addPayments = function () { return __awaiter(void 0, void 0, void 0, function () {
        var invoices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, paymentReceived(selectedIds, false)(apolloClient)];
                case 1:
                    invoices = _a.sent();
                    Spinner.hide();
                    ModalForm.show({
                        title: "Due Payments",
                        footer: false,
                        small: false,
                        md: true,
                        body: (_jsx(InvoiceList, { invoices: invoices, selectedIds: selectedIds, actionText: "Create Payments" }, void 0)),
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (id) {
            getById({
                variables: {
                    id: id
                }
            });
        }
    }, [id, getById]);
    var getPageTitle = function () {
        return !id ? 'Client Details' : form === null || form === void 0 ? void 0 : form.name;
    };
    return (_jsx("div", __assign({ className: "content " + (drawer ? 'pb-32' : '') }, { children: _jsx(Formik, { initialValues: { contacts: contacts }, enableReinitialize: true, onSubmit: function (values) {
                saveClient(values.contacts);
            }, render: function (_a) {
                var values = _a.values;
                return (_jsxs(Form, { children: [(!props.modal && !drawer) && (_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, __assign({ href: "/admin/clients", title: "Clients", others: [getPageTitle()] }, { children: _jsxs(Button, __assign({ type: "submit", color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0) }, void 0) }, void 0)),
                        _jsx(Card, __assign({ className: "border-0 " + (id ? '' : 'mt-16') }, { children: _jsxs(CardBody, { children: [_jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 6 }, { children: _jsx(InputText, { helperText: !form.name && submit ? 'required' : '', value: form.name, fullWidth: true, onChange: function (e) { return handleInputChange("name", e.target.value); }, label: "Client Name *" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: isMobile ? 6 : 3 }, { children: _jsxs(InputText, __assign({ value: "" + form.retainerRequired, select: true, onChange: function (e) { return handleInputChange("retainerRequired", e.target.value); }, size: "small", label: "Retainer Required?" }, { children: [_jsx(MenuItem, __assign({ value: "true" }, { children: "Yes" }), void 0),
                                                        _jsx(MenuItem, __assign({ value: "false" }, { children: "No" }), void 0)] }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: isMobile ? 6 : 3 }, { children: (form === null || form === void 0 ? void 0 : form.retainerRequired) && (_jsx(InputText, { value: form.retainerFee, prepend: "%", type: "number", step: "0.01", InputProps: true, onChange: function (e) { return handleInputChange("retainerFee", e.target.value); }, size: "sm", className: "max-w-150", label: "Retainer Amount" }, void 0)) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 6 }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, className: clsx("bb-dotte", "form-row-divider", "ml--8") }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Primary Contact Information" }), void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 3, className: "mt-16" }, { children: _jsx(InputText, __assign({ value: form.salutation, select: true, onChange: function (e) { return handleInputChange("salutation", e.target.value); }, size: "small", label: "Salutation" }, { children: SALUTATIONS.map(function (s) { return (_jsx(MenuItem, __assign({ value: s }, { children: s }), "sal_" + s)); }) }), void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 9, className: "mt-16" }, { children: _jsx(InputText, { helperText: !form.title && submit ? 'required' : '', value: form.title, fullWidth: true, onChange: function (e) { return handleInputChange("title", e.target.value); }, label: "Title *" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { helperText: !form.fullName && submit ? 'required' : '', value: form.fullName, fullWidth: true, onChange: function (e) { return handleInputChange("fullName", e.target.value); }, label: "Contact Name *" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.designation, fullWidth: true, onChange: function (e) { return handleInputChange("designation", e.target.value); }, size: "small", label: "Designation" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-16" }, { children: _jsx(InputText, { helperText: !form.email && submit ? 'required' : '', value: form.email, onChange: function (e) { return handleInputChange("email", e.target.value); }, fullWidth: true, label: "Email *" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.phone, onChange: function (e) { return handleInputChange("phone", e.target.value); }, fullWidth: true, size: "small", label: "Phone" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.fax, onChange: function (e) { return handleInputChange("fax", e.target.value); }, fullWidth: true, size: "small", label: "Fax" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-16" }, { children: _jsx(InputText, { value: form.website, onChange: function (e) { return handleInputChange("website", e.target.value); }, fullWidth: true, size: "small", label: "Site" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-16" }, { children: _jsx(InputText, { value: form.note, onChange: function (e) { return handleInputChange("note", e.target.value); }, fullWidth: true, size: "small", label: "Note" }, void 0) }), void 0)] }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 6, className: "pl-0" }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, className: clsx("bb-dotte", "form-row-divider", "ml--8") }, { children: _jsx(Typography, __assign({ className: "card-header-h5 pl-8" }, { children: "Address" }), void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 12, className: "mt-16" }, { children: _jsx(InputText, { value: form.address1, onChange: function (e) { return handleInputChange("address1", e.target.value); }, fullWidth: true, size: "small", label: "Address 1" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-16" }, { children: _jsx(InputText, { value: form.address2, onChange: function (e) { return handleInputChange("address2", e.target.value); }, fullWidth: true, size: "small", label: "Address 2" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.municipality, onChange: function (e) { return handleInputChange("municipality", e.target.value); }, size: "small", label: "Municipality" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.province, onChange: function (e) { return handleInputChange("province", e.target.value); }, fullWidth: true, size: "small", label: "Province" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.postalCode, onChange: function (e) { return handleInputChange("postalCode", e.target.value); }, fullWidth: true, size: "small", label: "Postal Code" }, void 0) }), void 0),
                                                        _jsx(Grid, __assign({ item: true, xs: 6, className: "pt-16" }, { children: _jsx(InputText, { value: form.country, onChange: function (e) { return handleInputChange("country", e.target.value); }, fullWidth: true, size: "small", label: "Country" }, void 0) }), void 0)] }), void 0) }), void 0),
                                            _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(FieldArray, { name: "contacts", render: function (arrayHelpers) { return (_jsxs("div", { children: [_jsx(Grid, __assign({ container: true, spacing: 2 }, { children: map(values.contacts, function (_, index) { return (_jsx(Grid, __assign({ item: true, xs: drawer ? 6 : (isMobile ? 12 : 4) }, { children: _jsxs(Card, { children: [_jsxs(Button, __assign({ onClick: function () { return arrayHelpers.remove(index); }, color: "default", className: "btn-xs absolute right-0" }, { children: [_jsx("i", { className: "fa fa-trash" }, void 0), " "] }), void 0),
                                                                                _jsxs(Row, __assign({ className: "p-8" }, { children: [_jsx(Col, __assign({ xs: 6 }, { children: _jsx(Field, __assign({ name: "contacts." + index + ".firstName" }, { children: function (_a) {
                                                                                                    var field = _a.field;
                                                                                                    return (_jsx(InputText, __assign({}, field, { size: "small", fullWidth: true, label: "First Name" }), void 0));
                                                                                                } }), void 0) }), void 0),
                                                                                        _jsx(Col, __assign({ xs: 6 }, { children: _jsx(Field, __assign({ name: "contacts." + index + ".lastName" }, { children: function (_a) {
                                                                                                    var field = _a.field;
                                                                                                    return (_jsx(InputText, __assign({}, field, { size: "small", fullWidth: true, label: "Last Name" }), void 0));
                                                                                                } }), void 0) }), void 0)] }), void 0),
                                                                                _jsxs(Row, __assign({ className: "p-8" }, { children: [_jsx(Col, __assign({ xs: 6 }, { children: _jsx(Field, __assign({ name: "contacts." + index + ".email" }, { children: function (_a) {
                                                                                                    var field = _a.field;
                                                                                                    return (_jsx(InputText, __assign({}, field, { size: "small", fullWidth: true, label: "Email" }), void 0));
                                                                                                } }), void 0) }), void 0),
                                                                                        _jsx(Col, __assign({ xs: 6 }, { children: _jsx(Field, __assign({ name: "contacts." + index + ".title" }, { children: function (_a) {
                                                                                                    var field = _a.field;
                                                                                                    return (_jsx(InputText, __assign({}, field, { size: "small", fullWidth: true, label: "Title" }), void 0));
                                                                                                } }), void 0) }), void 0)] }), void 0),
                                                                                _jsxs(Row, __assign({ className: "p-8" }, { children: [_jsx(Col, __assign({ xs: 6 }, { children: _jsx(Field, __assign({ name: "contacts." + index + ".phoneMobile" }, { children: function (_a) {
                                                                                                    var field = _a.field;
                                                                                                    return (_jsx(InputText, __assign({}, field, { size: "small", fullWidth: true, label: "Phone" }), void 0));
                                                                                                } }), void 0) }), void 0),
                                                                                        _jsx(Col, __assign({ xs: 6 }, { children: _jsx(Field, __assign({ name: "contacts." + index + ".phoneBusiness" }, { children: function (_a) {
                                                                                                    var field = _a.field;
                                                                                                    return (_jsx(InputText, __assign({}, field, { size: "small", fullWidth: true, label: "Fax" }), void 0));
                                                                                                } }), void 0) }), void 0)] }), void 0)] }, void 0) }), index)); }) }), void 0),
                                                                _jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue btn btn-secondary", onClick: function () { return arrayHelpers.push({}); } }, { children: [_jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0), " Add a new contact"] }), void 0)] }, void 0)); } }, void 0),
                                                    (!props.modal && drawer && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only)) && (_jsx("div", __assign({ className: drawer ? "fixed-bottom-drawer" : "absolute save-formik " + (id ? " top--120" : "") }, { children: _jsxs(Button, __assign({ type: "submit", color: drawer ? "info" : "default", className: drawer ? "btn btn-xs" : "btn btn-outline-default btn-round" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0))] }), void 0)] }), void 0),
                                    id && (_jsxs(Tabs, __assign({ value: activeStep, onChange: function (e, newValue) { return changeTabs(newValue); }, indicatorColor: "primary", textColor: "primary", variant: isMobile ? "scrollable" : "standard", className: "pt-40 mlr--15" }, { children: [_jsx(Tab, { value: 1, label: "Active Jobs" }, void 0),
                                            _jsx(Tab, { value: 4, label: "Completed Jobs" }, void 0),
                                            _jsx(Tab, { value: 5, label: "Accounts Receivable" }, void 0),
                                            _jsx(Tab, { value: 2, label: "Report Type Fees" }, void 0),
                                            !appModule.inactive.includes(APP_PACKAGES.CLIENTJOBS) && _jsx(Tab, { value: 3, label: "Credentials" }, void 0),
                                            !appModule.inactive.includes(APP_PACKAGES.NOTE) && _jsx(Tab, { value: 6, label: "Documents" }, void 0),
                                            _jsx(Tab, { value: 7, label: "Payments" }, void 0)] }), void 0)),
                                    !props.modal && id && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { display: activeStep === 1 ? 'block' : 'none' } }, { children: _jsx(Income, __assign({ header: false, userId: -1, clientId: id, reload: reload, classContent: '', rowSelectionChanged: rowSelectionChanged }, { children: (selectedIds.length > 0) && (_jsxs("div", __assign({ className: "db w-100" }, { children: [_jsx("div", __assign({ className: "dib w-30" }, { children: !appModule.inactive.includes(APP_PACKAGES.BATCHPAYMENTS) && _jsxs(Button, __assign({ onClick: addPayments, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Payment Received"] }), void 0) }), void 0),
                                                            _jsx("div", __assign({ className: "dib right w-70" }, { children: _jsxs("div", __assign({ className: "flex-end items-center gap-8" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "New Status" }), void 0),
                                                                        _jsx(InputText, __assign({ className: "min-w-200", select: true, label: "", size: "small", onChange: function (e) { return setStatusId(+e.target.value); } }, { children: map(jobStatuses, function (type) { return (_jsxs(MenuItem, __assign({ value: type.id }, { children: [type.title, " \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"] }), type.id)); }) }), void 0),
                                                                        _jsx(Button, __assign({ onClick: updateStatus, className: "btn btn-xxs" }, { children: "Update Status" }), void 0)] }), void 0) }), void 0)] }), void 0)) }), void 0) }), void 0),
                                            _jsx("div", __assign({ style: { display: activeStep === 4 ? 'block' : 'none' } }, { children: _jsx(Income, __assign({ header: false, userId: -1, clientId: id, completed: true, classContent: '', rowSelectionChanged: rowSelectionChanged }, { children: (selectedIds.length > 0) && !appModule.inactive.includes(APP_PACKAGES.BATCHPAYMENTS) && (_jsxs(Button, __assign({ onClick: addPayments, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Payment Received"] }), void 0)) }), void 0) }), void 0),
                                            _jsx("div", __assign({ style: { display: activeStep === 5 ? 'block' : 'none' } }, { children: _jsx(Income, __assign({ header: false, userId: -1, clientId: id, duePayments: true, classContent: '', rowSelectionChanged: rowSelectionChanged }, { children: (selectedIds.length > 0) && !appModule.inactive.includes(APP_PACKAGES.BATCHPAYMENTS) && (_jsxs(Button, __assign({ onClick: addPayments, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Payment Received"] }), void 0)) }), void 0) }), void 0),
                                            activeStep === 2 && _jsx(ClientFee, { clientId: +id }, void 0)] }, void 0)),
                                    activeStep === 7 && (_jsx("div", { children: _jsx(AddPayment, { clientId: id }, void 0) }, void 0)),
                                    activeStep === 6 && (_jsx("div", { children: _jsx(Note, { jobId: id, documentType: DOCUMENT_TYPE.CLIENTS, header: '' }, void 0) }, void 0)),
                                    _jsx("div", __assign({ style: { display: activeStep === 3 && !appModule.inactive.includes(APP_PACKAGES.CLIENTJOBS) ? 'block' : 'none' } }, { children: userForm.edit ? (_jsxs(_Fragment, { children: [_jsx(Row, __assign({ className: "mt-16" }, { children: _jsxs(Col, __assign({ xs: isMobile ? 12 : 4 }, { children: [_jsx(InputText, { value: userForm.email, fullWidth: true, onChange: function (e) { return setFormData(e, "email", userForm, setUserForm); }, label: "Email *" }, void 0),
                                                            error && (_jsx("div", __assign({ className: "error" }, { children: error }), void 0))] }), void 0) }), void 0),
                                                _jsx(Row, __assign({ className: "mt-24" }, { children: _jsx(Col, __assign({ xs: isMobile ? 12 : 4 }, { children: _jsx(InputText, { helperText: !userForm.password && submit ? 'required' : '', value: userForm.password, fullWidth: true, onChange: function (e) { return setFormData(e, "password", userForm, setUserForm); }, type: "password", label: "Password*" }, void 0) }), void 0) }), void 0),
                                                _jsx(Row, __assign({ className: "mt-24" }, { children: _jsx(Col, __assign({ xs: isMobile ? 12 : 4 }, { children: _jsx(InputText, { helperText: !userForm.retPassword && submit ? 'required' : '', value: userForm.retPassword, fullWidth: true, onChange: function (e) { return setFormData(e, "retPassword", userForm, setUserForm); }, type: "password", label: "Confirm Password*" }, void 0) }), void 0) }), void 0),
                                                _jsx(Row, __assign({ className: "mt-24" }, { children: _jsx(Col, __assign({ xs: isMobile ? 12 : 4 }, { children: _jsxs(Button, __assign({ onClick: createUser, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " ", userForm.id ? 'Save Changes' : 'Create User'] }), void 0) }), void 0) }), void 0)] }, void 0)) : (_jsxs("div", __assign({ className: "mb-16 flex gap-16 mt-24" }, { children: [_jsxs(Typography, __assign({ variant: "body2" }, { children: [_jsx("b", { children: "Email :" }, void 0), " ", userForm.email] }), void 0),
                                                _jsx("i", { className: "fa fa-edit pointer", onClick: function () { return setUserForm(__assign(__assign({}, userForm), { edit: true })); } }, void 0)] }), void 0)) }), void 0)] }, void 0) }), void 0),
                        props.modal && (_jsxs(ModalFooter, __assign({ className: "sticky-bottom" }, { children: [_jsx(Button, __assign({ color: "info", className: "btn btn-md", onClick: function () { return saveClient(); } }, { children: "Save Changes" }), void 0), ' ', _jsx(Button, __assign({ color: "secondary", className: "btn btn-md", onClick: function () { return ModalForm.close(); } }, { children: "Cancel" }), void 0)] }), void 0))] }, void 0));
            } }, void 0) }), void 0));
};
