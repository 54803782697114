var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Row, Col } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { InputText } from "../../ui-components/input/Input-Field.component";
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getAdjustmentItems } from '../../stores/app.selector';
import { ADD_ADJUSTMENT_DESCRIPTION, DELETE_ADJUSTMENT_DESCRIPTION, UPDATE_ADJUSTMENT_DESCRIPTION } from '../../graphqls/type-tables/type-table.query';
import { useMutation } from '@apollo/client';
import { addAdjustmentItems, editAdjustmentItems, removeAdjustmentItems } from '../../stores/app.reducer';
export var PropertyType = function (_a) {
    var _b;
    var _c = _a.id, id = _c === void 0 ? 0 : _c;
    var dispatch = useDispatch();
    var _d = useState({
        option: '',
        headerId: id,
    }), form = _d[0], setForm = _d[1];
    var _e = useState(null), edit = _e[0], setEdit = _e[1];
    var adjustmentItems = useSelector(getAdjustmentItems);
    var addItem = useMutation(ADD_ADJUSTMENT_DESCRIPTION, {
        onCompleted: function (data) {
            dispatch(addAdjustmentItems(data.createTblAdjDescription.tblAdjDescription));
            setForm({
                option: '',
                headerId: id,
            });
        }
    })[0];
    var deleteItem = useMutation(DELETE_ADJUSTMENT_DESCRIPTION, {
        onCompleted: function (data) {
            dispatch(removeAdjustmentItems(data.deleteTblAdjDescriptionById.tblAdjDescription.id));
        }
    })[0];
    var updateItem = useMutation(UPDATE_ADJUSTMENT_DESCRIPTION, {
        onCompleted: function () {
            dispatch(editAdjustmentItems({ id: edit.id, option: edit.option, headerId: form.headerId }));
            setEdit(null);
        }
    })[0];
    var deleteRow = function (op) {
        deleteItem({
            variables: {
                id: op.id,
            }
        });
    };
    var saveChanges = function () {
        if (!edit) {
            addItem({
                variables: {
                    input: __assign({}, form),
                },
            });
        }
        else {
            updateItem({
                variables: {
                    input: {
                        option: edit.option,
                    },
                    id: edit.id,
                }
            });
        }
    };
    return (_jsxs("div", { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(InputText, { size: "sm", label: "Option", onChange: function (e) { return setForm(__assign(__assign({}, form), { option: e.target.value })); }, value: form === null || form === void 0 ? void 0 : form.option }, void 0) }, void 0) }, void 0),
            _jsx(Row, { children: _jsx(Col, { children: _jsx(List, __assign({ dense: true, className: "max-h-350" }, { children: (_b = adjustmentItems === null || adjustmentItems === void 0 ? void 0 : adjustmentItems.find(function (c) { return +c.id === id; })) === null || _b === void 0 ? void 0 : _b.options.map(function (op) { return (_jsx(ListItem, { children: (edit && op.id === (edit === null || edit === void 0 ? void 0 : edit.id)) ? (_jsxs("div", { children: [_jsx(InputText, { size: "sm", onChange: function (e) { return setEdit(__assign(__assign({}, edit), { option: e.target.value })); }, value: edit.option, defaultValue: edit.option }, void 0),
                                    _jsxs(ListItemSecondaryAction, __assign({ className: "pointer" }, { children: [_jsx(SaveIcon, { color: "primary", onClick: saveChanges }, void 0), " ", _jsx(CloseIcon, { onClick: function () { return setEdit(null); } }, void 0)] }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(ListItemText, { children: op.option }, void 0),
                                    _jsxs(ListItemSecondaryAction, __assign({ className: "pointer" }, { children: [_jsx(EditIcon, { fontSize: "small", color: "primary", onClick: function () { return setEdit(op); } }, void 0), " ", _jsx(DeleteIcon, { color: "primary", onClick: function () { return deleteRow(op); }, fontSize: "small" }, void 0)] }), void 0)] }, void 0)) }, "item-option-" + op.id)); }) }), void 0) }, void 0) }, void 0),
            _jsx("input", { type: "button", onClick: saveChanges, id: "save-adjustment-button", className: 'hidden' }, void 0)] }, void 0));
};
