var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import clsx from "clsx";
import { InputText } from "../../../ui-components/input/Input-Field.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD, UPDATE, GETBYID } from "../../../graphqls/appraiser/appraisor.query";
import { httpPost, httpPostUpload } from "../../../helpers/http.helper";
import { Spinner } from "../../../ui-components/loading/Spinner.component";
import { APP_PACKAGES, DOCUMENT_TYPE, Roles, SALUTATIONS } from "@enums";
import { canEditAppraisers, getCompanyId, getOffices, getUserLicence } from "../../../services/user-service";
import { ListHeader } from "../../../ui-components/lists/Header.component";
import { Income } from "../../accounting/Income";
import { FileUploader } from "../../../ui-components/File-Uploader.component";
import { ModalForm } from "../../../ui-components/modals/Modal-Form.component";
import { FormDynamic } from "../../../ui-components/forms/Form-Dynamic";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalError } from "../../../stores/error.reducer";
import MenuItem from "@material-ui/core/MenuItem";
import { getAppraisers } from "../../../stores/client.selector";
import { PayrollStatement } from "../../../components/accounting/statements";
import { getAppModules } from "../../../stores/app.selector";
import { addNewAppraiser, updateAppraiser } from "../../../stores/client.reducer";
import { isMobile } from "react-device-detect";
import { ICAutoComplete } from "../../../ui-components/autoComplete/autoComplete.component";
export var EditAppraiser = function (_a) {
    var props = __rest(_a, []);
    var _b = useState(0), activeStep = _b[0], setActiveStep = _b[1];
    var _c = useState(false), submit = _c[0], setSubmit = _c[1];
    var _d = useState([]), attachment = _d[0], setAttachment = _d[1];
    var _e = useState(''), description = _e[0], setDescription = _e[1];
    var _f = useState(''), error = _f[0], setError = _f[1];
    var documentType = DOCUMENT_TYPE.APPRAISERS;
    var dispatch = useDispatch();
    var canEdit = canEditAppraisers();
    var offices = useSelector(getOffices);
    var appraisers = useSelector(getAppraisers);
    var appModule = useSelector(getAppModules);
    var companyId = getCompanyId();
    var _g = useState({
        email: '',
        repassword: '',
        password: '',
        lastName: '',
        firstName: '',
        salutation: '',
        title: '',
        designation: '',
        address2: '',
        address1: '',
        municipality: '',
        province: '',
        postalCode: '',
        country: '',
        phonebusiness: '',
        phoneMobile: '',
        aicNo: '',
        aicInsuranceNo: '',
        id: 0,
        note: '',
        username: '',
        commissions: 0,
        feeTypes: 0,
        reviewer: null,
        officeId: null,
    }), form = _g[0], setForm = _g[1];
    var id = ((props === null || props === void 0 ? void 0 : props.id) ? props : (props.match ? props.match.params : { id: undefined })).id;
    var drawer = ((props === null || props === void 0 ? void 0 : props.drawer) ? props : (props.match ? props.match.params : { drawer: false })).drawer;
    var handleAttachments = function (files, description) {
        setAttachment(files);
        setDescription(description);
    };
    var uploadFiles = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPostUpload("document/upload/" + id, attachment, { description: description, documentType: documentType })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateCredential = function () {
        ModalForm.show({
            title: 'Update Login Details',
            md: true,
            footer: false,
            body: _jsx(FormDynamic, { formFields: [
                    { fieldName: 'email', title: 'Email', defaultValue: form === null || form === void 0 ? void 0 : form.email, required: true },
                    { fieldName: 'username', title: 'User Name', defaultValue: form === null || form === void 0 ? void 0 : form.username, required: true },
                    { fieldName: 'password', title: 'Password', type: 'password', defaultValue: '', required: true },
                    { fieldName: 'retype', title: 'Confirm Password', type: 'password', defaultValue: '', required: true },
                    { fieldName: 'id', title: 'id', hidden: true, defaultValue: form === null || form === void 0 ? void 0 : form.id },
                ], onSubmit: function (data) { return __awaiter(void 0, void 0, void 0, function () {
                    var result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                Spinner.show();
                                return [4 /*yield*/, httpPost('user/updateLoginDetail', __assign({}, data))];
                            case 1:
                                result = _a.sent();
                                if (result === null || result === void 0 ? void 0 : result.error) {
                                    dispatch(setGlobalError(result));
                                }
                                else {
                                    ModalForm.close();
                                }
                                Spinner.hide();
                                return [2 /*return*/];
                        }
                    });
                }); } }, void 0)
        });
    };
    var cleanForm = function () {
        setForm({
            email: '',
            repassword: '',
            password: '',
            lastName: '',
            firstName: '',
            salutation: '',
            title: '',
            designation: '',
            address2: '',
            address1: '',
            municipality: '',
            province: '',
            postalCode: '',
            country: '',
            phonebusiness: '',
            phoneMobile: '',
            aicNo: '',
            aicInsuranceNo: '',
            id: 0,
            note: '',
            username: '',
            commissions: 0,
            feeTypes: 0,
            reviewer: null,
            officeId: null,
        });
        setAttachment([]);
        setDescription('');
        setActiveStep(0);
        setSubmit(false);
        setError('');
    };
    var addUser = useMutation(ADD, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!attachment.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFiles((_b = (_a = data === null || data === void 0 ? void 0 : data.createTblAppraiser) === null || _a === void 0 ? void 0 : _a.tblAppraiser) === null || _b === void 0 ? void 0 : _b.id)];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        dispatch(addNewAppraiser((_c = data === null || data === void 0 ? void 0 : data.createTblAppraiser) === null || _c === void 0 ? void 0 : _c.tblAppraiser));
                        Spinner.hide();
                        cleanForm();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var updateUser = useMutation(UPDATE, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!attachment.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, uploadFiles(id)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        dispatch(updateAppraiser((_a = data === null || data === void 0 ? void 0 : data.updateTblAppraiserById) === null || _a === void 0 ? void 0 : _a.tblAppraiser));
                        Spinner.hide();
                        setActiveStep(0);
                        setAttachment([]);
                        getById({
                            variables: {
                                id: id,
                            }
                        });
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var getById = useLazyQuery(GETBYID, {
        onCompleted: function (data) {
            setForm(__assign(__assign({}, data.tblAppraiserById), { officeId: !data.tblAppraiserById.officeId ? companyId : +data.tblAppraiserById.officeId }));
        }
    })[0];
    var handleInputChange = function (key, value) {
        var _a;
        setForm(__assign(__assign({}, form), (_a = {}, _a[key] = value, _a)));
    };
    var saveData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var input, user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSubmit(true);
                    setError('');
                    if (!form.email || !form.username || (!id && !form.password) || (!id && !form.repassword))
                        return [2 /*return*/];
                    Spinner.show();
                    input = __assign(__assign({}, form), { officeId: !form.officeId || +form.officeId <= 0 ? null : +form.officeId });
                    delete input.password;
                    delete input.repassword;
                    if (!id) return [3 /*break*/, 1];
                    updateUser({
                        variables: {
                            input: input,
                            id: id,
                        }
                    });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, httpPost("user/adduser", {
                        email: form.email,
                        password: form.password,
                        username: form.username,
                        licenceid: getUserLicence(),
                        role: Roles.APPRAISER,
                    })];
                case 2:
                    user = _a.sent();
                    if (user.error) {
                        setError(user.error);
                        Spinner.hide();
                        return [2 /*return*/];
                    }
                    addUser({
                        variables: {
                            input: __assign(__assign({}, input), { id: +user.id })
                        }
                    });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (appModule.inactive.includes(APP_PACKAGES.NOTE) && activeStep === 0) {
            setActiveStep(1);
        }
    }, [appModule, activeStep]);
    var changeTabs = function (tabId) {
        setActiveStep(tabId);
    };
    var getPageTitle = function () {
        return !id ? 'Appraiser Details' : (form === null || form === void 0 ? void 0 : form.salutation) + " " + (form === null || form === void 0 ? void 0 : form.firstName) + " " + (form === null || form === void 0 ? void 0 : form.lastName);
    };
    useEffect(function () {
        if (id) {
            getById({
                variables: {
                    id: id,
                }
            });
        }
    }, [id, getById]);
    return (_jsxs("div", __assign({ className: "content" }, { children: [!drawer ? (_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, __assign({ href: '/admin/appraisers', title: "Appraisers", others: [getPageTitle()] }, { children: _jsxs(Button, __assign({ onClick: saveData, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0) }, void 0) }, void 0)) : (_jsx("div", __assign({ className: "fixed-bottom-drawer " + ((appModule === null || appModule === void 0 ? void 0 : appModule.read_only) ? 'none' : '') }, { children: _jsxs(Button, __assign({ onClick: saveData, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0)),
            _jsx(Card, __assign({ className: "border-0 mt-16 " + (drawer ? 'pb-40' : '') }, { children: _jsxs(CardBody, { children: [_jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsxs(Grid, __assign({ item: true, xs: id ? (isMobile ? 6 : 4) : (isMobile ? 12 : 6) }, { children: [_jsx(InputText, { disabled: id, helperText: !form.email && submit ? 'required' : '', value: form.email, onChange: function (e) { return handleInputChange("email", e.target.value); }, fullWidth: true, label: "Email *" }, void 0),
                                        error && (_jsx("div", __assign({ className: "error" }, { children: error }), void 0))] }), void 0),
                                !id ? (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 3 }, { children: _jsx(InputText, { helperText: !form.username && submit ? 'User Name is required' : '', value: form.username, onChange: function (e) { return handleInputChange("username", e.target.value); }, fullWidth: true, label: "User Name *" }, void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 3 }, { children: _jsx(ICAutoComplete, { options: appraisers, className: "no-padding-select", size: "small", label: "Reviewer", value: form.reviewer, onChange: function (event) { var _a; handleInputChange("reviewer", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value); } }, void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isMobile ? 6 : 3 }, { children: _jsx(InputText, { helperText: !id && !form.password && submit ? 'Password is required' : '', type: "password", value: form.password, onChange: function (e) { return handleInputChange("password", e.target.value); }, fullWidth: true, label: "Password *" }, void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isMobile ? 6 : 3 }, { children: _jsx(InputText, { helperText: !id && !form.repassword && submit ? 'Retype Password is required' : '', type: "password", value: form.repassword, onChange: function (e) { return handleInputChange("repassword", e.target.value); }, fullWidth: true, label: "Retype Password *" }, void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 6 }, { children: _jsxs("div", __assign({ className: "align-items-bottom" }, { children: [_jsx(InputText, { onChange: function (e) { return handleInputChange('commissions', +e.target.value); }, type: "number", size: "sm", className: "max-w-100", value: form.commissions, label: "Commission" }, void 0),
                                                    _jsxs(InputText, __assign({ select: true, label: "", className: "border-free", value: form.feeTypes, size: "small", onChange: function (e) { return handleInputChange('feeTypes', +e.target.value); } }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "%" }), void 0),
                                                            _jsx(MenuItem, __assign({ value: 1 }, { children: "$" }), void 0)] }), void 0)] }), void 0) }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: isMobile ? 6 : 2, className: "flex" }, { children: _jsxs(Button, __assign({ onClick: updateCredential, className: "btn btn-xxs btn-link text-blue btn btn-secondary btn btn-secondary", color: "secondary" }, { children: [_jsx("i", { className: "fa fa-user" }, void 0), " Update Login Details"] }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 2 }, { children: _jsxs("div", __assign({ className: "align-items-bottom" }, { children: [_jsx(InputText, { onChange: function (e) { return handleInputChange('commissions', +e.target.value); }, type: "number", size: "sm", className: "max-w-100", value: form.commissions, label: "Commission" }, void 0),
                                                    _jsxs(InputText, __assign({ select: true, label: "", className: "border-free", value: form.feeTypes, size: "small", onChange: function (e) { return handleInputChange('feeTypes', +e.target.value); } }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "%" }), void 0),
                                                            _jsx(MenuItem, __assign({ value: 1 }, { children: "$" }), void 0)] }), void 0)] }), void 0) }), void 0),
                                        _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 3 }, { children: ((form.id && form.reviewer) || (form.id && !form.reviewer)) && (_jsx(ICAutoComplete, { options: appraisers, className: "no-padding-select", size: "small", value: form.reviewer, label: "Reviewer", onChange: function (event) { var _a; handleInputChange("reviewer", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value); } }, void 0)) }), void 0)] }, void 0)),
                                _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 4, className: "mt-24" }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, className: clsx("bb-dotte", "form-row-divider", "ml--8") }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: " User Information" }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 12, className: "mt-16" }, { children: _jsx(InputText, __assign({ value: form.salutation, select: true, onChange: function (e) { return handleInputChange("salutation", e.target.value); }, size: "small", label: "Salutation" }, { children: SALUTATIONS.map(function (s) { return (_jsx(MenuItem, __assign({ value: s }, { children: s }), "sal_" + s)); }) }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { helperText: !form.firstName && submit ? 'required' : '', value: form.firstName, onChange: function (e) { return handleInputChange("firstName", e.target.value); }, fullWidth: true, size: "small", label: "First Name *" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { helperText: !form.lastName && submit ? 'required' : '', value: form.lastName, onChange: function (e) { return handleInputChange("lastName", e.target.value); }, fullWidth: true, size: "small", label: "Last Name *" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.title, onChange: function (e) { return handleInputChange("title", e.target.value); }, fullWidth: true, size: "small", label: "Title" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.designation, onChange: function (e) { return handleInputChange("designation", e.target.value); }, fullWidth: true, size: "small", label: "Designation" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.aicNo, onChange: function (e) { return handleInputChange("aicNo", e.target.value); }, fullWidth: true, size: "small", label: "aic#" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.aicInsuranceNo, onChange: function (e) { return handleInputChange("aicInsuranceNo", e.target.value); }, fullWidth: true, size: "small", label: "aic Insurance#" }, void 0) }), void 0)] }), void 0) }), void 0),
                                _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 4, className: "mt-24 pl-0" }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, className: clsx("bb-dotte", "form-row-divider", "ml--8") }, { children: _jsx(Typography, __assign({ className: "card-header-h5 pl-8" }, { children: " Address" }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 12, className: "mt-16" }, { children: _jsx(InputText, { value: form.address1, onChange: function (e) { return handleInputChange("address1", e.target.value); }, fullWidth: true, size: "small", label: "Address1" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(InputText, { value: form.address2, onChange: function (e) { return handleInputChange("address2", e.target.value); }, fullWidth: true, size: "small", label: "Address2" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.postalCode, onChange: function (e) { return handleInputChange("postalCode", e.target.value); }, fullWidth: true, size: "small", label: "Postal Code" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.province, onChange: function (e) { return handleInputChange("province", e.target.value); }, fullWidth: true, size: "small", label: "Province" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.country, onChange: function (e) { return handleInputChange("country", e.target.value); }, fullWidth: true, size: "small", label: "Country" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.municipality, onChange: function (e) { return handleInputChange("municipality", e.target.value); }, fullWidth: true, size: "small", label: "Municipality" }, void 0) }), void 0)] }), void 0) }), void 0),
                                _jsx(Grid, __assign({ item: true, xs: isMobile ? 12 : 4, className: "mt-24 pl-0" }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, className: clsx("bb-dotte", "form-row-divider", "ml--8") }, { children: _jsx(Typography, __assign({ className: "card-header-h5 pl-8" }, { children: "Contact Information" }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 12, className: "mt-16" }, { children: _jsx(ICAutoComplete, { options: offices, className: "no-padding-select", size: "small", label: "Office", id: "officeId", value: +form.officeId, onChange: function (event) { var _a; handleInputChange("officeId", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value); } }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(InputText, { value: form.phoneMobile, onChange: function (e) { return handleInputChange("phoneMobile", e.target.value); }, fullWidth: true, size: "small", label: "Mobile" }, void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(InputText, { value: form.phonebusiness, onChange: function (e) { return handleInputChange("phonebusiness", e.target.value); }, fullWidth: true, size: "small", label: "Businesss Phone" }, void 0) }), void 0)] }), void 0) }), void 0),
                                _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(InputText, { value: form.note, onChange: function (e) { return handleInputChange("note", e.target.value); }, fullWidth: true, size: "small", label: "Note" }, void 0) }), void 0),
                                _jsx(Grid, __assign({ item: true, xs: 12 }, { children: "\u00A0" }), void 0)] }), void 0),
                        _jsxs(Tabs, __assign({ value: activeStep, onChange: function (e, newValue) { return changeTabs(newValue); }, indicatorColor: "primary", textColor: "primary", variant: isMobile ? "scrollable" : "standard", className: "pt-40 mlr--15" }, { children: [!appModule.inactive.includes(APP_PACKAGES.NOTE) && _jsx(Tab, { value: 0, label: "Documents" }, void 0),
                                id && _jsx(Tab, { value: 1, label: "Orders" }, void 0),
                                !appModule.inactive.includes(APP_PACKAGES.ACCOUNTING) && id && _jsx(Tab, { value: 3, label: "Statements" }, void 0)] }), void 0),
                        _jsx("div", __assign({ style: { display: activeStep === 1 && id ? 'block' : 'none' } }, { children: _jsx(Income, { classContent: "", header: false, userId: +id, updateStatus: canEdit }, void 0) }), void 0),
                        activeStep === 3 && !appModule.inactive.includes(APP_PACKAGES.ACCOUNTING) && (_jsx("div", { children: _jsx(PayrollStatement, { appId: +id, breadcrumb: false }, void 0) }, void 0)),
                        !appModule.inactive.includes(APP_PACKAGES.NOTE) && activeStep === 0 && (_jsx(FileUploader, { id: id, type: documentType, onChange: handleAttachments, attachments: attachment || [] }, void 0))] }, void 0) }), void 0)] }), void 0));
};
