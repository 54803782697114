var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
export var ALL_TBLCLIENTS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery getAllClients {\n  allTblClients(orderBy: NAME_ASC) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n\n"], ["\nquery getAllClients {\n  allTblClients(orderBy: NAME_ASC) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n\n"])));
export var TBLCLIENTS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery getAllClients ($offset: Int,$first: Int,$orderBy:[TblClientsOrderBy!], $filter: TblClientFilter) {\n    allTblClients(first: $first, offset: $offset,orderBy:$orderBy, filter: $filter) {\n      totalCount\n      nodes {\n        address1\n        address2\n        email\n        fax\n        id\n        name\n        phone\n        postalCode\n        province\n        website\n        zzCreatedBy\n        zzCreatedDate\n        municipality\n        salutation\n        fullName\n        title\n      }\n    }\n  }\n"], ["\nquery getAllClients ($offset: Int,$first: Int,$orderBy:[TblClientsOrderBy!], $filter: TblClientFilter) {\n    allTblClients(first: $first, offset: $offset,orderBy:$orderBy, filter: $filter) {\n      totalCount\n      nodes {\n        address1\n        address2\n        email\n        fax\n        id\n        name\n        phone\n        postalCode\n        province\n        website\n        zzCreatedBy\n        zzCreatedDate\n        municipality\n        salutation\n        fullName\n        title\n      }\n    }\n  }\n"])));
export var UPDATE = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation updateById($id: BigInt!, $input: TblClientPatch!) {\n    updateTblClientById(input: { tblClientPatch: $input, id: $id }) {\n      clientMutationId\n    }\n  }\n"], ["\n  mutation updateById($id: BigInt!, $input: TblClientPatch!) {\n    updateTblClientById(input: { tblClientPatch: $input, id: $id }) {\n      clientMutationId\n    }\n  }\n"])));
export var GETBYID = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nquery getById($id:BigInt!) {\n  tblClientById(id:$id) {\n    address1\n    address2\n    country\n    email\n    fax\n    id\n    municipality\n    name\n    note\n    phone\n    postalCode\n    province\n    salutation\n    fullName\n    website\n    retainerRequired\n    retainerFee\n    title\n    designation\n    tblClientContactsByClientId {\n      nodes {\n        email\n        firstName\n        id\n        phoneMobile\n        lastName\n        title\n        phoneBusiness\n      }\n    }\n  }\n}\n"], ["\nquery getById($id:BigInt!) {\n  tblClientById(id:$id) {\n    address1\n    address2\n    country\n    email\n    fax\n    id\n    municipality\n    name\n    note\n    phone\n    postalCode\n    province\n    salutation\n    fullName\n    website\n    retainerRequired\n    retainerFee\n    title\n    designation\n    tblClientContactsByClientId {\n      nodes {\n        email\n        firstName\n        id\n        phoneMobile\n        lastName\n        title\n        phoneBusiness\n      }\n    }\n  }\n}\n"])));
export var DELETE_CLIENT = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nmutation deleteClient($id: BigInt!) {\n  deleteTblClientById(input: { id: $id }) {\n    clientMutationId\n    deletedTblClientId\n  }\n}"], ["\nmutation deleteClient($id: BigInt!) {\n  deleteTblClientById(input: { id: $id }) {\n    clientMutationId\n    deletedTblClientId\n  }\n}"])));
export var CREATECLIENT = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation createClient($input: TblClientInput!) {\n    createTblClient(input: { tblClient: $input }) {\n      tblClient {\n        name\n        id\n      }\n    }\n  }\n"], ["\n  mutation createClient($input: TblClientInput!) {\n    createTblClient(input: { tblClient: $input }) {\n      tblClient {\n        name\n        id\n      }\n    }\n  }\n"])));
export var Columns = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Name",
        fieldName: "name",
        sortField: 'NAME',
        sortable: true,
        columnFilter: true,
        columnFilterField: 'name',
    },
    {
        caption: "Contact Name",
        fieldName: "fullName",
        sortField: 'FULL_NAME',
        sortable: true,
        columnFilter: true,
        columnFilterField: 'fullName',
    },
    {
        caption: "Phone",
        fieldName: "phone",
        sortField: 'PHONE',
        sortable: true,
        columnFilter: true,
        columnFilterField: 'phone',
    },
    {
        caption: "Fax",
        fieldName: "fax",
        sortField: 'FAX',
        sortable: true,
    },
    {
        caption: "Email",
        fieldName: "email",
        sortField: 'EMAIL',
        columnFilter: true,
        sortable: true,
        columnFilterField: 'email',
    },
    {
        caption: "Created Date",
        fieldName: "zzCreatedDate",
        sortField: "ZZ_CREATED_DATE",
        sortable: true,
    },
    {
        caption: "Created By",
        fieldName: "zzCreatedBy",
        sortField: "ZZ_CREATED_BY",
        sortable: true,
    },
    {
        caption: "Title",
        fieldName: "title",
        isHidden: true,
    }
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
