var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Table } from "../../../ui-components/table/table.component";
import { TBLJOB, Columns } from "../../../graphqls/jobs/job.query";
import { canEditRequest, isClient } from "../../../services/user-service";
import { StatusEnum } from "@enums";
import { ListHeader } from "../../../ui-components/lists/Header.component";
import { JOB_LIST, CLIENT_JOB_LIST, JOBS_IMPORT } from "../../../routes";
import { tableRowClicked } from "../../../helpers/form.helper";
import { ModalForm } from "../../../ui-components/modals/Modal-Form.component";
import { Link } from "react-router-dom";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { isMobile } from "react-device-detect";
export var ApparaisalList = function (_a) {
    var props = __rest(_a, []);
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    var _c = useState(null), currentFilterQuery = _c[0], setCurrentFilterQuery = _c[1];
    var _d = useState([]), hiddenFields = _d[0], setHiddenFields = _d[1];
    var _e = useState(false), reload = _e[0], setReload = _e[1];
    var action = ((props === null || props === void 0 ? void 0 : props.action) ? props : (props.match ? props.match.params : { action: undefined })).action;
    var hasEdit = canEditRequest();
    var client = isClient();
    var getTitle = useMemo(function () {
        switch (action) {
            case "pending": return "Jobs / Pending Quotes";
            case "rejected": return "Jobs / Rejected Quotes";
            case "retainer": return "Jobs / Retainer Required";
            case "progress": return "Jobs / In Progress";
            case "completed": return "Jobs / Completed";
            case "cancelled": return "Jobs / Cancelled";
            default: return "Jobs";
        }
    }, [action]);
    var getAction = useMemo(function () {
        switch (action) {
            case "pending": return StatusEnum.QUOTE_PENDING;
            case "rejected": return StatusEnum.QUOTE_REJECTED;
            case "retainer": return StatusEnum.RETAINER_REQUIRED;
            case "all": return null;
            case "progress": return StatusEnum.IN_PROGRESS;
            case "completed": return StatusEnum.COMPLETED;
            case "cancelled": return StatusEnum.ARCHIVED;
            default: return client ? null : StatusEnum.QUOTE_SUBMITTED;
        }
    }, [action, client]);
    var rowClicked = function (e, id) {
        if (props.modal && tableRowClicked(e)) {
            ModalForm.hide({ id: id });
        }
    };
    useEffect(function () {
        if ((action && action !== "all") || client) {
            setEdit(false);
            if (client) {
                setHiddenFields(["paymentReceived", "invoiceSent", "tblClientByClientId.name"]);
            }
            else {
                setHiddenFields(["paymentReceived", "invoiceSent"]);
            }
        }
        else {
            setEdit((action === null || action === void 0 ? void 0 : action.length) > 0);
            setHiddenFields((action === null || action === void 0 ? void 0 : action.length) > 0 ? ["paymentReceived", "invoiceSent"] : ["paymentReceived", "invoiceSent", "tblStatusByStatusId.statusTitle"]);
        }
        var stauts = getAction;
        if (stauts) {
            if (stauts === StatusEnum.ARCHIVED) {
                setCurrentFilterQuery({ "statusId": { "in": [StatusEnum.ARCHIVED, StatusEnum.QUOTE_REJECTED] } });
            }
            else if (stauts === StatusEnum.IN_PROGRESS) {
                setCurrentFilterQuery({ "statusId": { "notIn": [StatusEnum.COMPLETED, StatusEnum.CLOSED, StatusEnum.ARCHIVED, StatusEnum.QUOTE_REJECTED] } });
            }
            else {
                setCurrentFilterQuery({ "statusId": { "equalTo": stauts } });
            }
        }
    }, [action, reload, client]);
    var allowedColumns = useMemo(function () { return !props.modal ? Columns : Columns.map(function (c) {
        return (__assign(__assign({}, c), { linkTo: '', linkFieldId: '' }));
    }); }, [props.modal]);
    return (_jsxs("div", __assign({ className: "content" }, { children: [!props.modal && (_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, { noHome: !hasEdit, title: getTitle, href: client ? CLIENT_JOB_LIST : JOB_LIST }, void 0) }, void 0) }, void 0)),
            _jsx(Table, { addLink: !props.modal && !client && hasEdit && edit ? "/admin/appraisals/all/edit" : '', addLinkTitle: props.modal ? '' : "New Job", rowClicked: rowClicked, tableName: "allTblJobs", customAction: null, query: TBLJOB, reload: reload, filterQuery: currentFilterQuery, showFilter: true, headers: allowedColumns.filter(function (c) { return hiddenFields.indexOf(c.fieldName) === -1; }), customHeaderButton: !hasEdit ? null : _jsxs(Button, __assign({ tag: Link, to: JOBS_IMPORT, color: "default" }, { children: [_jsx(ImportExportIcon, { style: { fontSize: 18 } }, void 0), " ", isMobile ? '' : 'Bulk Data Import'] }), void 0), defaultSort: 'ZZ_CREATED_DATE_DESC' }, void 0)] }), void 0));
};
