var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from "reactstrap";
import { useState, useEffect, useRef, useCallback } from "react";
import { InputText } from "./input/Input-Field.component";
import { Switch, Tooltip } from "@material-ui/core";
import { formatGoogleAddress } from "../services/job.service";
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { ModalForm } from "./modals/Modal-Form.component";
import { GoogleMap } from "./google-map/googleMap";
import { useDispatch } from "react-redux";
import { globalMessage } from "../stores/app.reducer";
/*
google format:

city: "Newmarket"
countryName: "Canada"
county: "York"
houseNumber: "843"
label: "843 Firth Ct, Newmarket, ON L3Y 8H6, Canada"
name: "843 Firth Ct, Newmarket, ON L3Y 8H6, Canada"
postalCode: "L3Y 8H6"
state: "Ontario"
street: "Firth Ct"
*/
export var AddressComponent = function (_a) {
    var setForm = _a.setForm, value = _a.value, form = _a.form, onChange = _a.onChange, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.multipleAddress, multipleAddress = _c === void 0 ? false : _c, _d = _a.vertical, vertical = _d === void 0 ? false : _d, _e = _a.editMode, editMode = _e === void 0 ? true : _e, _f = _a.title, title = _f === void 0 ? "Search an address" : _f, _g = _a.hideDetails, hideDetails = _g === void 0 ? false : _g, _h = _a.id, id = _h === void 0 ? 'search-address' : _h, _j = _a.error, error = _j === void 0 ? false : _j, _k = _a.defaultValue, defaultValue = _k === void 0 ? '' : _k;
    var _l = useState(value), store = _l[0], setStore = _l[1];
    var currentLocation = useRef(null);
    var dispatch = useDispatch();
    var handleInputChange = function (key, value) {
        var _a, _b;
        setForm && setForm(__assign(__assign({}, form), (_a = {}, _a[key] = value, _a)));
        setStore(__assign(__assign({}, store), (_b = {}, _b[key] = value, _b)));
    };
    useEffect(function () {
        if (store && !form && onChange) {
            var name_1 = (store.streetNo || store.houseNumber || '') + " " + (store.streetName || store.street || '') + ", " + (store.city || '') + ", " + (store.province || store.state || '') + " " + (store.postalCode || '') + ", " + (store.countryName || '');
            onChange({
                city: store.city,
                countryName: store.countryName,
                county: (store === null || store === void 0 ? void 0 : store.county) || '',
                houseNumber: store.streetNo || store.houseNumber,
                label: name_1,
                name: name_1,
                postalCode: store.postalCode,
                state: store.province || store.state,
                street: store.streetName || store.street,
                unit: store.unit,
            });
        }
    }, [store]);
    useEffect(function () {
        var input = document.getElementById(id);
        var google = window.google;
        var autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", function () {
            var place = autocomplete.getPlace();
            var formatted = (formatGoogleAddress(place));
            var formValue = {
                streetName: formatted.street,
                streetNo: formatted.houseNumber,
                unit: formatted.unit,
                province: formatted.state,
                postalCode: formatted.postalCode,
                zzFullAddress: formatted.label,
                city: formatted.city,
                county: formatted.county,
                countryName: formatted.countryName,
                label: formatted.label,
                lat: formatted.lat,
                lng: formatted.lng,
            };
            setForm && setForm(__assign(__assign({}, form), formValue));
            onChange && onChange(formValue);
            currentLocation.current = { lat: formatted.lat, lng: formatted.lng };
        });
    }, [form]);
    var locationAdded = useCallback(function (event) {
        var formValue = __assign(__assign({}, form), { lat: event.lat, lng: event.lng });
        setForm && setForm(formValue);
        onChange && onChange(formValue);
        currentLocation.current = { lat: event.lat, lng: event.lng };
    }, [form, setForm]);
    var displayMapPopup = function () {
        if (!(currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.current)) {
            dispatch(globalMessage({ type: 'error', message: 'Please search a City, Region or Country!' }));
            return;
        }
        ModalForm.show({
            title: 'Map',
            large: true,
            footer: false,
            body: (_jsx(GoogleMap, { onClickOnMap: locationAdded, searchLocation: true, height: "500px", zoom: 12, data: [currentLocation.current], displayInfo: false }, void 0))
        });
    };
    useEffect(function () {
        if (id && defaultValue && hideDetails && document.getElementById(id)) {
            document.getElementById(id).value = defaultValue;
        }
    }, [id, defaultValue, hideDetails]);
    if (hideDetails) {
        return (_jsx(InputText, { label: title, id: id, fullWidth: true, defaultValue: defaultValue, helperText: error ? 'required' : '' }, void 0));
    }
    return (_jsxs(_Fragment, { children: [editMode && (_jsxs(Row, { children: [_jsxs(Col, __assign({ xs: multipleAddress ? 8 : 12, className: "flex gap-4" }, { children: [_jsx("div", __assign({ className: "flex-1" }, { children: _jsx(InputText, { label: title, id: id, fullWidth: true, helperText: error ? 'required' : '' }, void 0) }), void 0),
                            _jsx("div", __assign({ className: "location-search flex-inline", onClick: displayMapPopup }, { children: _jsx(Tooltip, __assign({ title: "Select location on map" }, { children: _jsx(MyLocationIcon, {}, void 0) }), void 0) }), void 0)] }), void 0),
                    multipleAddress && (_jsxs(Col, __assign({ xs: 4, align: "right", className: "flex flex-end pt-20" }, { children: ["Single Address", _jsx(Switch, { checked: form.multi, onChange: function (event) { return handleInputChange("multi", event.target.checked); }, color: "primary" }, void 0), " Multiple Address"] }), void 0))] }, void 0)),
            _jsxs(Row, __assign({ className: className || 'mt-16' }, { children: [_jsx(Col, __assign({ xs: vertical ? 12 : !form ? 2 : 1 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("unit", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.unit) || (value === null || value === void 0 ? void 0 : value.unit) || '', label: "Unit #" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : !form ? 2 : 1 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("streetNo", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.streetNo) || (value === null || value === void 0 ? void 0 : value.houseNumber) || (value === null || value === void 0 ? void 0 : value.streetNo) || '', label: "Street #" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : !form ? 6 : 3 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("streetName", e.target.value); }, size: "sm", style: { width: '100%' }, value: (form === null || form === void 0 ? void 0 : form.streetName) || (value === null || value === void 0 ? void 0 : value.street) || (value === null || value === void 0 ? void 0 : value.streetName) || '', label: "Street Name" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : 2 }, { children: _jsx(InputText, { helperText: !form.city && (form.zzFullAddress || form.label) ? 'required' : '', editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("city", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.city) || (value === null || value === void 0 ? void 0 : value.city) || '', label: "Municipality" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : 2 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("county", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.county) || (value === null || value === void 0 ? void 0 : value.county) || '', label: "Region" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : !form ? 3 : 1 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("province", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.province) || (value === null || value === void 0 ? void 0 : value.state) || (value === null || value === void 0 ? void 0 : value.province) || '', label: "Province" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : !form ? 3 : 2 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("postalCode", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.postalCode) || (value === null || value === void 0 ? void 0 : value.postalCode) || '', label: "Postal Code" }, void 0) }), void 0),
                    _jsx(Col, __assign({ xs: vertical ? 12 : !form ? 3 : 2 }, { children: _jsx(InputText, { editMode: editMode, flex: vertical, onChange: function (e) { return handleInputChange("countryName", e.target.value); }, size: "sm", value: (form === null || form === void 0 ? void 0 : form.countryName) || (value === null || value === void 0 ? void 0 : value.countryName) || '', label: "Country" }, void 0) }), void 0)] }), void 0)] }, void 0));
};
