var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { GetUserId } from "../../services/user-service";
import { getStatements } from "../../services/payment.service";
import { Table } from "../../ui-components/table/table.component";
import { STATEMENT_COLUMNS } from "../../graphqls/accounting/payroll.query";
import { Payroll } from "./payroll";
import { tableRowClicked } from "../../helpers/form.helper";
import { httpPost } from "../../helpers/http.helper";
export var PayrollStatement = function (_a) {
    var _b = _a.appId, appId = _b === void 0 ? 0 : _b, _c = _a.breadcrumb, breadcrumb = _c === void 0 ? true : _c;
    var apolloClient = useApolloQuery()[0];
    var _d = useState([]), data = _d[0], setData = _d[1];
    var _e = useState(null), selectedDate = _e[0], setSelectedDate = _e[1];
    var loadStatements = function () {
        setSelectedDate(null);
    };
    var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getStatements(appId || GetUserId())(apolloClient)];
                case 1:
                    data = _a.sent();
                    setData(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var goBack = function () {
        setSelectedDate(null);
    };
    var rowClicked = function (e, id) {
        if (tableRowClicked(e)) {
            var row = data.find(function (d) { return +d.id === +id; });
            setSelectedDate({
                startDate: row === null || row === void 0 ? void 0 : row.startDate,
                endDate: row === null || row === void 0 ? void 0 : row.endDate,
            });
        }
    };
    var onDelete = function (id, key, data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("job/payrolle/deletePayrolle", {
                        userId: appId || GetUserId(),
                        startDate: data.startDate,
                        endDate: data.endDate,
                    })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, loadData()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        loadData();
    }, []);
    return (_jsx("div", __assign({ className: "content" }, { children: !selectedDate ? (_jsxs(_Fragment, { children: [!appId && breadcrumb && (_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(ListHeader, { title: "Statements" }, void 0) }), void 0) }, void 0)),
                _jsx(Table, { offlineData: data, customAction: null, rowClicked: rowClicked, action: null, headers: STATEMENT_COLUMNS, showAllData: true, exportToExcel: true, onDelete: appId ? onDelete : null }, void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [!appId && (_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(ListHeader, { mainClick: loadStatements, title: "Statements", others: ['Payroll'] }, void 0) }), void 0) }, void 0)),
                _jsx(Payroll, { id: selectedDate, appId: appId, goBack: goBack }, void 0)] }, void 0)) }), void 0));
};
