var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Sidebar } from "../../layouts/Sidebar.component";
import { Footer } from "../../layouts/Footer.component";
import { NavBar } from "../../layouts/NavBar.component";
import { CLIENT_JOB_LIST, DASHBOARD, JOB_LIST_PROGRESS, routes } from '../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { TYPETABLE } from "../../graphqls/type-tables/type-table.query";
import { setClient, setJobType, setPropertyType, setStatus, setMunicipalicity, setAppraisers, setOptions, setCompany } from '../../stores/client.reducer';
import { canRenderRoute, getUserRole, getUserToken } from '../../services/user-service';
import { addToStorage, STORAGE_KEY } from "../../utils/Storage.util";
import { DrawerContainer } from "./Drawer-Container";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../stylings/Dashboard.css';
import { Roles } from "@enums";
import { globalMessage, setAdjustmentItems, setAppModules, setFieldsSections } from '../../stores/app.reducer';
import { getAppModules, getGlobalMessage, getSidebarWidth } from '../../stores/app.selector';
import { httpGet } from '../../helpers/http.helper';
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { hideActionButtons } from '../../helpers/form.helper';
function Alert(props) {
    return _jsx(MuiAlert, __assign({ elevation: 6, variant: "filled" }, props), void 0);
}
function App() {
    var _this = this;
    var dispatch = useDispatch();
    var sidebarWidth = useSelector(getSidebarWidth);
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), isClient = _b[0], setIsClient = _b[1];
    var _c = useState(false), openSnackbar = _c[0], setOpenSnackbar = _c[1];
    var appModule = useSelector(getAppModules);
    var message = useSelector(getGlobalMessage);
    var role = getUserRole();
    var history = useHistory();
    var location = useLocation();
    useEffect(function () {
        if (appModule === null || appModule === void 0 ? void 0 : appModule.read_only) {
            hideActionButtons();
        }
    }, [location, appModule]);
    var loadData = useLazyQuery(TYPETABLE, {
        onCompleted: function (data) { return __awaiter(_this, void 0, void 0, function () {
            var modules;
            var _a, _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        dispatch(setClient(data === null || data === void 0 ? void 0 : data.allTblClients.nodes));
                        dispatch(setJobType(data === null || data === void 0 ? void 0 : data.allTblJobTypes.nodes));
                        dispatch(setPropertyType(data === null || data === void 0 ? void 0 : data.allTblPropertyTypes.nodes));
                        dispatch(setStatus(data === null || data === void 0 ? void 0 : data.allTblStatuses.nodes));
                        dispatch(setMunicipalicity(data === null || data === void 0 ? void 0 : data.allTblMunicipalities.nodes));
                        dispatch(setAppraisers(data === null || data === void 0 ? void 0 : data.allTblAppraisers.nodes));
                        dispatch(setOptions(data === null || data === void 0 ? void 0 : data.allTblOptions.nodes));
                        dispatch(setCompany((_a = data === null || data === void 0 ? void 0 : data.allTblCompanies.nodes) === null || _a === void 0 ? void 0 : _a[0]));
                        dispatch(setAdjustmentItems(data === null || data === void 0 ? void 0 : data.allTblAdjItems.nodes));
                        dispatch(setFieldsSections({
                            fields: (_b = data === null || data === void 0 ? void 0 : data.allTblFields) === null || _b === void 0 ? void 0 : _b.nodes,
                            sections: (_c = data === null || data === void 0 ? void 0 : data.allTblSections) === null || _c === void 0 ? void 0 : _c.nodes,
                            sectionFields: (_d = data === null || data === void 0 ? void 0 : data.allTblSectionFields) === null || _d === void 0 ? void 0 : _d.nodes,
                            fieldOption: (_e = data === null || data === void 0 ? void 0 : data.allTblFieldSelects) === null || _e === void 0 ? void 0 : _e.nodes
                        }));
                        addToStorage(STORAGE_KEY.COMPANY, (_f = data === null || data === void 0 ? void 0 : data.allTblCompanies.nodes) === null || _f === void 0 ? void 0 : _f[0]);
                        addToStorage(STORAGE_KEY.OFFICES, ((_g = data === null || data === void 0 ? void 0 : data.allTblOffices) === null || _g === void 0 ? void 0 : _g.nodes) || []);
                        if (document.location.pathname === "/" || document.location.pathname === "/admin") {
                            if (role === Roles.CLIENT) {
                                history.push(CLIENT_JOB_LIST);
                            }
                            else if (role === Roles.APPRAISER) {
                                history.push(JOB_LIST_PROGRESS);
                            }
                            else {
                                history.push(DASHBOARD);
                            }
                        }
                        setIsClient(role === Roles.CLIENT);
                        return [4 /*yield*/, httpGet("user/getSubscription")];
                    case 1:
                        modules = _h.sent();
                        dispatch(setAppModules(modules));
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    useEffect(function () {
        if (!getUserToken()) {
            history.push('/login');
        }
        else {
            loadData();
        }
    }, [loadData, history]);
    useEffect(function () {
        var _a;
        setOpenSnackbar(((_a = message === null || message === void 0 ? void 0 : message.message) === null || _a === void 0 ? void 0 : _a.length) > 0);
    }, [message]);
    var handleClose = function () {
        setOpenSnackbar(false);
        dispatch(globalMessage(null));
    };
    // useEffect(()=> {
    //   const eventSource = new EventSource(`${process.env.REACT_APP_EXTERNAL_API_URL}user/sse`);
    //   eventSource.onmessage = async ({ data }) => {
    //     const msg = JSON.parse(data);
    //     if(+msg.licenceId === lId) {
    //       switch(msg.action) {
    //         case SSE.CUSTOM_FIELDS: {
    //           await loadCustomFields(apolloClient, dispatch);
    //           break;
    //         }
    //       }
    //     }
    //   };
    //   return ()=> eventSource.close();
    // },[]);
    if (loading) {
        return _jsx("div", __assign({ className: 'app-loader' }, { children: _jsx("div", { className: "loader" }, void 0) }), void 0);
    }
    return (_jsxs("div", __assign({ className: "wrapper" }, { children: [_jsx(Sidebar, {}, void 0),
            _jsxs("div", __assign({ className: "main-panel", style: sidebarWidth ? { width: "calc(100% - " + sidebarWidth + ")" } : {} }, { children: [_jsx(NavBar, {}, void 0),
                    _jsx(Switch, { children: routes.filter(function (r) { return canRenderRoute(role, r, appModule === null || appModule === void 0 ? void 0 : appModule.inactive); }).map(function (prop, key) {
                            return (_jsx(Route, { path: prop.layout + prop.path, component: prop.component, exact: true }, key));
                        }) }, void 0),
                    _jsx(DrawerContainer, {}, void 0),
                    _jsx(Snackbar, __assign({ anchorOrigin: { vertical: 'top', horizontal: 'right' }, open: openSnackbar, onClose: handleClose, autoHideDuration: 10000, action: _jsx(IconButton, __assign({ size: "small", "aria-label": "close", color: "inherit", onClick: handleClose }, { children: _jsx(CloseIcon, { fontSize: "small" }, void 0) }), void 0) }, { children: _jsx(Alert, __assign({ onClose: handleClose, severity: (message === null || message === void 0 ? void 0 : message.type) || "success" }, { children: message === null || message === void 0 ? void 0 : message.message }), void 0) }), void 0)] }), void 0),
            _jsx(Footer, {}, void 0)] }), void 0));
}
export default App;
