var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { InputText } from "../../../ui-components/input/Input-Field.component";
import { setFormData } from "../../../helpers/form.helper";
import { Roles } from "@enums";
import { httpGet, httpPost } from "../../../helpers/http.helper";
import { Spinner } from "../../../ui-components/loading/Spinner.component";
import Alert from '@material-ui/lab/Alert';
import { useApolloQuery } from "../../../hooks/use-appolo.hooks";
import { getCompanyById } from "../../../services/user-service";
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { Alert as AlertMessage } from "../../../ui-components/modals/Alert.component";
import ReplayIcon from '@material-ui/icons/Replay';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
export var UserEdit = function (_a) {
    var _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.editCompany, editCompany = _c === void 0 ? false : _c;
    var apolloClient = useApolloQuery()[0];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(false), submit = _e[0], setSubmit = _e[1];
    var _f = useState({
        email: '',
        userName: '',
        role: Roles.ADMIN,
        licenceid: '1',
        password: '',
        repassword: '',
        company: '',
    }), form = _f[0], setForm = _f[1];
    var updateSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpGet("job/populateIndex/" + (form === null || form === void 0 ? void 0 : form.licenceid))];
                case 1:
                    _a.sent();
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var generateLatLng = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpGet("geo/regeneratelatLng/" + (form === null || form === void 0 ? void 0 : form.licenceid))];
                case 1:
                    _a.sent();
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var generateSaleModule = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpGet("user/saleModule/" + form.licenceid)];
                case 1:
                    result = _a.sent();
                    Spinner.hide();
                    if (result.error) {
                        AlertMessage.show({ title: 'Info', message: result.error });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var saveData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setError(null);
                    setSubmit(true);
                    if (!editCompany && (!form.email || !form.userName || !form.password || !form.company))
                        return [2 /*return*/];
                    Spinner.show();
                    return [4 /*yield*/, httpPost("user/adduseradmin", {
                            email: form.email,
                            password: form.password,
                            username: form.userName,
                            licenceid: +form.licenceid,
                            role: form.role,
                            company: form.company || '',
                        })];
                case 1:
                    user = _a.sent();
                    if (user.error) {
                        setError(user.error);
                    }
                    else {
                        // setForm({
                        //     email: '',
                        //     userName: '',
                        //     role: Roles.ADMIN,
                        //     licenceid: '1',
                        //     password: '',
                        //     repassword: '',
                        //     company:'',
                        // });
                        setSubmit(false);
                    }
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var loadCompany = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompanyById(id)(apolloClient)];
                case 1:
                    data = _a.sent();
                    setForm({
                        company: data.name,
                        email: data.email,
                        licenceid: data.licenceId,
                        role: Roles.ADMIN,
                        saleDb: data.saleDb,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (id) {
            loadCompany();
        }
    }, [id]);
    console.log('form', form);
    return (_jsxs("div", __assign({ className: "content p-0" }, { children: [error && (_jsx(Row, { children: _jsx(Col, { children: _jsx(Alert, __assign({ className: "mt-16 mb-16", severity: "error" }, { children: error.detail }), void 0) }, void 0) }, void 0)),
            _jsx(Card, __assign({ className: "border-0" }, { children: _jsx(CardBody, { children: _jsxs(Grid, __assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, __assign({ item: true, xs: editCompany ? 6 : 12 }, { children: _jsx(InputText, { helperText: !form.company && submit ? 'Company Name is required' : '', value: form.company, onChange: function (e) { return setFormData(e, "company", form, setForm); }, fullWidth: true, size: "small", label: "Company Name" }, void 0) }), void 0),
                            _jsx(Grid, __assign({ item: true, xs: editCompany ? 6 : 12 }, { children: _jsx(InputText, { helperText: !form.email && submit ? 'Email is required' : '', value: form.email, onChange: function (e) { return setFormData(e, "email", form, setForm); }, fullWidth: true, size: "small", label: "Email" }, void 0) }), void 0),
                            editCompany && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ className: "card-header-h5 bb-dotte" }, { children: "Re-generate" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-0" }, { children: _jsxs(Button, __assign({ className: "btn btn-xxs", onClick: generateSaleModule }, { children: [_jsx(ViewModuleIcon, {}, void 0), " Generate Sale Module"] }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-0" }, { children: _jsxs(Button, __assign({ className: "btn btn-xxs", onClick: generateLatLng }, { children: [_jsx(ReplayIcon, {}, void 0), " Generate Lat/Lng"] }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 12, className: "pt-0" }, { children: _jsxs(Button, __assign({ className: "btn btn-xxs", onClick: updateSearch }, { children: [_jsx(YoutubeSearchedForIcon, {}, void 0), " Generate Full Text Search"] }), void 0) }), void 0)] }, void 0)),
                            !id && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(InputText, { helperText: !form.userName && submit ? 'User Name is required' : '', value: form.userName, fullWidth: true, size: "small", onChange: function (e) { return setFormData(e, "userName", form, setForm); }, label: "User Name" }, void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { helperText: !form.password && submit ? 'Password is required' : '', type: "password", value: form.password, onChange: function (e) { return setFormData(e, "password", form, setForm); }, fullWidth: true, size: "small", label: "Password" }, void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { type: "password", value: form.repassword, onChange: function (e) { return setFormData(e, "repassword", form, setForm); }, fullWidth: true, size: "small", label: "Retype Password" }, void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsxs(InputText, __assign({ select: true, value: form.role, onChange: function (e) { return setFormData(e, "role", form, setForm); }, size: "small", label: "Role" }, { children: [_jsx(MenuItem, __assign({ value: Roles.ADMIN }, { children: "Admin" }), void 0),
                                                _jsx(MenuItem, __assign({ value: Roles.APPRAISER }, { children: "Appraiser" }), void 0)] }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(InputText, { value: form.licenceid, onChange: function (e) { return setFormData(e, "licenceid", form, setForm); }, size: "small", label: "Licence#" }, void 0) }), void 0)] }, void 0))] }), void 0) }, void 0) }), void 0),
            _jsx("input", { type: "button", className: "hidden", onClick: saveData, id: "btn-add-new-user" }, void 0)] }), void 0));
};
