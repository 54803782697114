var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { IconButton, Grid } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from "formik";
import { Button } from 'reactstrap';
import { map } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import { getAppraisers } from '../../../stores/client.selector';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputText } from '../../../ui-components/input/Input-Field.component';
import { Card, CardBody, Row, Col } from "reactstrap";
import { AddressComponent } from '../../../ui-components/Address.component';
export var PropertyAddress = function (_a) {
    var setForm = _a.setForm, form = _a.form, onChange = _a.onChange, _b = _a.enableAppraiser, enableAppraiser = _b === void 0 ? false : _b;
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var appraisers = useSelector(getAppraisers);
    var inputChaged = useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var post, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(value && value.length >= 3)) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetch("https://autocomplete.search.hereapi.com/v1/autocomplete?q=" + value + "&apiKey=" + process.env.REACT_APP_HERE_API_KEY + "&limit=10", {
                            method: "GET",
                        })];
                case 1:
                    post = _a.sent();
                    return [4 /*yield*/, post.json()];
                case 2:
                    result = _a.sent();
                    if (result && result.items) {
                        setOptions(result.items.map(function (c) { return (__assign(__assign({}, c.address), { name: c.address.label })); }));
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var removeRow = function (index, helper) {
        var _a;
        if (!((_a = form === null || form === void 0 ? void 0 : form.addresses) === null || _a === void 0 ? void 0 : _a[index])) {
            helper.remove(index);
            return;
        }
        setForm && setForm({
            addresses: ((form === null || form === void 0 ? void 0 : form.addresses) || []).reduce(function (list, curr, ind) {
                if (ind === index) {
                    list.push(__assign(__assign({}, curr), { deleted: true }));
                }
                else {
                    list.push(curr);
                }
                return list;
            }, []),
        });
    };
    var addNewRow = function (helper) {
        helper.push({ address: '', appraiser: 0, fee: 0, id: 0, feeType: 0 });
    };
    var handleFieldUpdate = function (index, value) {
        if (!(form === null || form === void 0 ? void 0 : form.addresses[index])) {
            setForm && setForm({
                addresses: ((form === null || form === void 0 ? void 0 : form.addresses) || []).concat({ id: 0, address: __assign({}, value) })
            });
        }
        else {
            setForm && setForm({
                addresses: ((form === null || form === void 0 ? void 0 : form.addresses) || []).reduce(function (list, curr, ind) {
                    if (ind === index) {
                        list.push(__assign(__assign({}, curr), { address: __assign({}, value) }));
                    }
                    else {
                        list.push(curr);
                    }
                    return list;
                }, []),
            });
        }
    };
    return (_jsx(Formik, { initialValues: __assign({}, form), enableReinitialize: true, onSubmit: function (values) {
            console.log('onSubmit', values.addresses);
        }, render: function (_a) {
            var values = _a.values, setFieldValue = _a.setFieldValue, handleChange = _a.handleChange;
            return (_jsx(Form, { children: _jsx(FieldArray, { name: "addresses", render: function (arrayHelpers) { return (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ container: true, className: "mt-24", spacing: 2 }, { children: map(values.addresses, function (pay, index) { return (_jsx(Grid, __assign({ item: true, xs: 6, style: { display: (pay === null || pay === void 0 ? void 0 : pay.deleted) ? 'none' : 'block' } }, { children: _jsx(Card, __assign({ className: "no-border-radius bg-fc" }, { children: _jsxs(CardBody, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", __assign({ className: 'top-right' }, { children: _jsx(IconButton, __assign({ className: "btn-xs-no-padd", onClick: function () { return removeRow(index, arrayHelpers); }, "aria-label": "delete" }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0) }), void 0),
                                                            _jsx(AddressComponent, { value: pay === null || pay === void 0 ? void 0 : pay.address, onChange: function (newValue) {
                                                                    setFieldValue("addresses." + index + ".address", newValue);
                                                                    handleFieldUpdate(index, newValue);
                                                                } }, void 0)] }, void 0) }, void 0),
                                                enableAppraiser && (_jsxs(Row, __assign({ className: "mt-24" }, { children: [_jsx(Col, __assign({ xs: 6 }, { children: _jsx(Autocomplete, { id: "appraiser-multi-demo", options: appraisers, value: pay === null || pay === void 0 ? void 0 : pay.appraiser, size: "small", onChange: function (event, newValue) {
                                                                    setFieldValue("addresses." + index + ".appraiser", newValue);
                                                                }, getOptionLabel: function (option) { return option.title; }, style: { width: '100%' }, renderInput: function (params) { return _jsx(InputText, __assign({}, params, { label: "Appraiser" }), void 0); } }, void 0) }), void 0),
                                                        _jsx(Col, { xs: 2 }, void 0),
                                                        _jsx(Col, __assign({ xs: 4 }, { children: _jsxs("div", __assign({ className: 'div-block' }, { children: [_jsx(InputText, { onChange: handleChange, name: "addresses." + index + ".fee", style: { width: 90 }, type: "number", size: "sm", value: pay === null || pay === void 0 ? void 0 : pay.fee, label: "Split" }, void 0),
                                                                    _jsxs(Field, __assign({ type: "number", style: { minHeight: 23, borderLeftStyle: 'none' }, value: +(pay === null || pay === void 0 ? void 0 : pay.feeType), name: "addresses." + index + ".feeType", as: "select" }, { children: [_jsx("option", __assign({ value: 0 }, { children: "%" }), void 0),
                                                                            _jsx("option", __assign({ value: 1 }, { children: "$" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0))] }, void 0) }), void 0) }), index)); }) }), void 0),
                            _jsxs(Button, __assign({ onClick: function () { return addNewRow(arrayHelpers); }, color: "default", className: "btn-xs" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Add a new address"] }), void 0)] }, void 0)); } }, void 0) }, void 0));
        } }, void 0));
};
