import { kebabCase } from "lodash";
export var fileSizeToString = function (size) {
    if (size < 1024) {
        return size + " bytes";
    }
    if (size / 1024 < 1024) {
        return (size / 1024).toFixed(2) + " KB";
    }
    if (size / 1024 / 1024 < 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
    }
    return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
};
export var getDefaultLocale = function () { return 'en-CA'; };
export var tobeLocal = function (locale) { return kebabCase(locale || getDefaultLocale()); };
export var formatPercentage = function (input) { var _a, _b; return input && ((_b = (_a = ("" + input).split('.')) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.length) > 2 ? (+input).toFixed(2) : input; };
export var formatTotalAsCurrency = function (val, dollarSign, round) {
    if (dollarSign === void 0) { dollarSign = true; }
    if (round === void 0) { round = false; }
    if ("" + val === '.') {
        return '.';
    }
    if ("" + val === '-') {
        return '-';
    }
    var result = (round ? Math.round(parseFloat("" + val)) : parseFloat("" + val)).toLocaleString('en-US', { maximumFractionDigits: 2 });
    if (("" + val).endsWith('.')) {
        result += '.';
    }
    else if (("" + val).endsWith('.0')) {
        result += '.0';
    }
    if (dollarSign && val && parseFloat("" + val) < 0)
        return "-$" + result.replace('-', '');
    return dollarSign ? '$' + result : result;
};
export var excludeHst = function (rate, hst) { return rate / (1 + hst); };
export var includeHst = function (rate, hst) { return rate + (rate * (hst / 100)); };
