var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Button } from 'reactstrap';
import { setAddInvoice, setAddPayment } from "../../stores/app.reducer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
var ModalForm = /** @class */ (function (_super) {
    __extends(ModalForm, _super);
    function ModalForm(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClose = function () {
            ModalForm.Instance.state.dispatch && ModalForm.Instance.state.dispatch(setAddInvoice('CLOSE'));
            ModalForm.Instance.state.dispatch && ModalForm.Instance.state.dispatch(setAddPayment('CLOSE'));
            ModalForm.Instance.state.onClose && ModalForm.Instance.state.onClose();
            _this.setState({ open: false });
        };
        _this.handleDownload = function () {
            if (ModalForm.Instance.state.onSuccessClose) {
                _this.setState({ open: false });
            }
            if (ModalForm.Instance.state.onDownload) {
                ModalForm.Instance.state.onDownload();
            }
        };
        _this.handleSuccess = function () {
            if (ModalForm.Instance.state.scope) {
                document.getElementById(ModalForm.Instance.state.scope).click();
                return;
            }
            else if (ModalForm.Instance.state.onSuccessClose) {
                _this.setState({ open: false });
            }
            if (ModalForm.Instance.state.onSuccess) {
                ModalForm.Instance.state.onSuccess();
            }
            else {
                _this.setState({ open: false });
            }
        };
        _this.state = {
            open: false,
            onSuccess: props.onSuccess,
        };
        ModalForm.Instance = _this;
        return _this;
    }
    ModalForm.close = function () {
        ModalForm.Instance.state.dispatch && ModalForm.Instance.state.dispatch(setAddInvoice('CLOSE'));
        ModalForm.Instance.state.dispatch && ModalForm.Instance.state.dispatch(setAddPayment('CLOSE'));
        ModalForm.Instance.setState({
            open: false,
            onSuccess: function () { },
        });
    };
    ModalForm.hide = function (_a) {
        var _b = _a.id, id = _b === void 0 ? null : _b;
        if (id) {
            ModalForm.Instance.state.onSuccess(id);
        }
        ModalForm.Instance.setState({
            open: false,
            onSuccess: function () { },
        });
    };
    ModalForm.confirm = function (_a) {
        var _b = _a.onSuccess, onSuccess = _b === void 0 ? null : _b, _c = _a.body, body = _c === void 0 ? {} : _c, _d = _a.title, title = _d === void 0 ? 'Confirmation' : _d, _e = _a.scope, scope = _e === void 0 ? '' : _e, _f = _a.className, className = _f === void 0 ? '' : _f, _g = _a.remove, remove = _g === void 0 ? false : _g;
        if (ModalForm.Instance) {
            ModalForm.Instance.setState({
                open: true,
                onSuccess: onSuccess,
                body: body,
                title: title,
                footer: true,
                small: false,
                large: false,
                md: true,
                save: 'Yes',
                scope: scope,
                className: className,
                remove: remove,
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    ModalForm.deleteConfirmation = function (_a) {
        var _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.onSuccess, onSuccess = _c === void 0 ? null : _c, _d = _a.body, body = _d === void 0 ? {} : _d, _e = _a.small, small = _e === void 0 ? false : _e, _f = _a.large, large = _f === void 0 ? false : _f, _g = _a.scope, scope = _g === void 0 ? '' : _g;
        if (ModalForm.Instance) {
            ModalForm.Instance.setState({
                open: true,
                onSuccess: onSuccess,
                body: body,
                title: title,
                footer: true,
                small: small,
                large: large,
                save: 'Delete',
                onSuccessClose: true,
                scope: scope,
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    ModalForm.show = function (_a) {
        var _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.onSuccess, onSuccess = _c === void 0 ? null : _c, _d = _a.body, body = _d === void 0 ? {} : _d, _e = _a.footer, footer = _e === void 0 ? true : _e, _f = _a.small, small = _f === void 0 ? false : _f, _g = _a.large, large = _g === void 0 ? false : _g, _h = _a.backdrop, backdrop = _h === void 0 ? true : _h, _j = _a.onSuccessClose, onSuccessClose = _j === void 0 ? false : _j, _k = _a.save, save = _k === void 0 ? 'Save' : _k, _l = _a.md, md = _l === void 0 ? false : _l, _m = _a.error, error = _m === void 0 ? false : _m, _o = _a.top, top = _o === void 0 ? false : _o, _p = _a.closeHeader, closeHeader = _p === void 0 ? true : _p, _q = _a.onClose, onClose = _q === void 0 ? null : _q, _r = _a.download, download = _r === void 0 ? false : _r, _s = _a.onDownload, onDownload = _s === void 0 ? null : _s, _t = _a.dispatch, dispatch = _t === void 0 ? null : _t, _u = _a.className, className = _u === void 0 ? '' : _u, _v = _a.scope, scope = _v === void 0 ? '' : _v, _w = _a.fullWidth, fullWidth = _w === void 0 ? false : _w;
        if (ModalForm.Instance) {
            ModalForm.Instance.setState({
                open: true,
                onSuccess: onSuccess,
                body: body,
                title: title,
                footer: footer,
                small: small,
                large: large,
                save: save,
                onSuccessClose: onSuccessClose,
                backdrop: backdrop,
                md: md,
                download: download,
                onDownload: onDownload,
                dispatch: dispatch,
                className: className,
                scope: scope,
                fullWidth: fullWidth,
                closeHeader: closeHeader,
                remove: false,
                top: top,
                error: error,
                onClose: onClose,
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    ModalForm.doAction = function (footer) {
        ModalForm.Instance.setState({
            footer: footer,
            closeHeader: footer,
        });
    };
    ModalForm.prototype.render = function () {
        var _a = this.state, open = _a.open, body = _a.body, title = _a.title, footer = _a.footer, small = _a.small, large = _a.large, save = _a.save, download = _a.download, md = _a.md, className = _a.className, fullWidth = _a.fullWidth, closeHeader = _a.closeHeader, remove = _a.remove, error = _a.error, top = _a.top;
        return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ fullScreen: fullWidth, disableEscapeKeyDown: true, disableBackdropClick: true, className: "no-scroll " + (top ? 'modal-top' : ''), maxWidth: md ? "md" : large ? "lg" : (small ? "sm" : "xl"), open: open, onClose: this.handleClose, "aria-labelledby": "max-width-dialog-title" }, { children: [_jsxs(MuiDialogTitle, __assign({ disableTypography: true, className: "db p-all-0" }, { children: [_jsx(Typography, __assign({ id: "modal-title-typography", className: "dib modal-title-text", variant: "h6" }, { children: title }), void 0),
                            closeHeader && (_jsx(IconButton, __assign({ className: "dib pull-right", "aria-label": "close", id: "modal-action-header", onClick: this.handleClose }, { children: _jsx(CloseIcon, {}, void 0) }), void 0))] }), void 0),
                    _jsxs(DialogContent, __assign({ className: className + (remove ? ' center' : '') }, { children: [remove && (_jsx("div", __assign({ className: "db w-100 center p-4" }, { children: _jsx(WarningIcon, { style: { color: '#c92f54', fontSize: 64 } }, void 0) }), void 0)),
                            error && _jsx("div", __assign({ className: "p-32" }, { children: body }), void 0), !error && body] }), void 0),
                    footer && (_jsxs(DialogActions, __assign({ id: "modal-action-row" }, { children: [download && (_jsx(Button, __assign({ color: "success", onClick: this.handleDownload }, { children: "Download" }), void 0)),
                            _jsx(Button, __assign({ id: "modalSaveButton", color: "info", className: "btn btn-md", onClick: this.handleSuccess }, { children: save }), void 0), ' ', _jsx(Button, __assign({ color: "default", onClick: this.handleClose, className: "btn btn-md" }, { children: "Close" }), void 0)] }), void 0))] }), void 0) }, void 0));
    };
    return ModalForm;
}(React.Component));
export { ModalForm };
