import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Spinner } from '../ui-components/loading/Spinner.component';
import { getUserToken, logOut } from '../services/user-service';
import { ModalForm } from '../ui-components/modals/Modal-Form.component';
import { LOGERROR } from './errors/error.query';
var errorLink = onError(function (_a) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, response = _a.response, operation = _a.operation, forward = _a.forward;
    if (graphQLErrors) {
        var errorMessages = graphQLErrors.map(function (g) { return g.message; }).join(".");
        if (errorMessages.toLowerCase().indexOf("jwt expired") !== -1 ||
            errorMessages.indexOf('Authorization header is not of the correct bearer scheme format') !== -1) {
            logOut();
            document.location.href = "/login";
            return;
        }
        console.log('[GraphQL error]:', graphQLErrors.map(function (g) { return g.message; }).join("."));
        if (operation) {
            operation.query = LOGERROR;
            operation.operationName = "";
            operation.variables = { "error": errorMessages };
        }
        Spinner.hide();
        ModalForm.show({ small: true, footer: false, title: 'Error', body: (errorMessages) });
        return forward(operation);
    }
    if (networkError) {
        Spinner.hide();
        ModalForm.show({ small: true, footer: false, title: 'Error', error: true, body: "Something went wrong !" });
        console.log("[Network error]: " + networkError);
    }
});
var httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    headers: getUserToken(),
});
export var apolloClient = global.apolloClient ||
    new ApolloClient({
        cache: new InMemoryCache({
            addTypename: false,
        }),
        link: ApolloLink.from([errorLink, httpLink]),
        defaultOptions: {
            query: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
        },
    });
