var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { httpPost } from "../../helpers/http.helper";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import "./login.css";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Typography } from "@material-ui/core";
import { Button } from "reactstrap";
export var ResetPassword = function () {
    var _a = useState('EMAIL'), step = _a[0], setStep = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var formik = useFormik({
        initialValues: {
            email: '',
            code: '',
            pwd: '',
            retryPwd: '',
        },
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, result, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setError('');
                        Spinner.show();
                        _a = step;
                        switch (_a) {
                            case 'EMAIL': return [3 /*break*/, 1];
                            case 'CODE': return [3 /*break*/, 3];
                            case 'PASSWORD': return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 7];
                    case 1: return [4 /*yield*/, httpPost("user/sendcode", { email: values === null || values === void 0 ? void 0 : values.email })];
                    case 2:
                        _b.sent();
                        setStep('CODE');
                        return [3 /*break*/, 7];
                    case 3: return [4 /*yield*/, httpPost("user/verifyCode", { email: values === null || values === void 0 ? void 0 : values.email, code: values.code })];
                    case 4:
                        result = _b.sent();
                        if (!(result === null || result === void 0 ? void 0 : result.error)) {
                            setStep('PASSWORD');
                        }
                        else {
                            setError(result === null || result === void 0 ? void 0 : result.error);
                        }
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, httpPost("user/changePassword", { email: values === null || values === void 0 ? void 0 : values.email, code: values.code, pwd: values.pwd })];
                    case 6:
                        result = _b.sent();
                        if (!(result === null || result === void 0 ? void 0 : result.error)) {
                            document.location.href = "/login";
                        }
                        else {
                            setError(result === null || result === void 0 ? void 0 : result.error);
                        }
                        return [3 /*break*/, 7];
                    case 7:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    });
    useEffect(function () {
        document.body.style.backgroundColor = 'white';
    }, []);
    return (_jsxs("div", __assign({ className: "login-wrapper" }, { children: [_jsx("div", __assign({ className: "login-side" }, { children: _jsxs("div", __assign({ className: "my-form__wrapper pt-0" }, { children: [_jsx("div", __assign({ className: "db w-full center pt-4 bold black" }, { children: step === 'PASSWORD' ? 'Create New Password' : 'Forgot Password' }), void 0),
                        _jsxs("form", __assign({ className: "my-form", onSubmit: formik.handleSubmit }, { children: [error && _jsx("div", __assign({ className: "error db center bold" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: error }), void 0) }), void 0),
                                step === 'PASSWORD' && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "pwd" }, { children: "New Password:" }), void 0),
                                                _jsx("input", { type: "password", id: "pwd", name: "pwd", placeholder: "New Password", onChange: formik.handleChange }, void 0),
                                                _jsx(LockOutlinedIcon, {}, void 0)] }), void 0),
                                        _jsxs("div", __assign({ className: "text-field mt-8" }, { children: [_jsx("label", __assign({ htmlFor: "retryPwd" }, { children: "Confirm New Password:" }), void 0),
                                                _jsx("input", { type: "password", id: "retryPwd", name: "retryPwd", placeholder: "Confirm New Password", onChange: formik.handleChange }, void 0),
                                                _jsx(LockOutlinedIcon, {}, void 0)] }), void 0)] }, void 0)),
                                step === 'CODE' && (_jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "code" }, { children: "Enter the OTP code sent to your email address:" }), void 0),
                                        _jsx("input", { type: "text", id: "code", name: "code", placeholder: "Enter OTP", onChange: formik.handleChange }, void 0),
                                        _jsx(LockOutlinedIcon, {}, void 0)] }), void 0)),
                                step === 'EMAIL' && (_jsxs("div", __assign({ className: "text-field" }, { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Enter Your Email:" }), void 0),
                                        _jsx("input", { type: "email", id: "email", name: "email", placeholder: "Email address...", onChange: formik.handleChange }, void 0),
                                        _jsx(AlternateEmailIcon, {}, void 0)] }), void 0)),
                                _jsx(Button, __assign({ type: "submit", color: "info", className: "my-form__button mt-32" }, { children: step === 'PASSWORD' ? 'Change Password' : step === 'EMAIL' ? 'Next' : 'Verify' }), void 0),
                                _jsxs("div", __assign({ className: "my-form__actions mt-32" }, { children: [_jsx("div", __assign({ className: "my-form__signup" }, { children: _jsx("span", { children: "Already have an account? " }, void 0) }), void 0),
                                        _jsx("div", __assign({ className: "my-form__signup" }, { children: _jsx("a", __assign({ href: "/login", title: "Sign in", className: "pl-8" }, { children: "Sign in" }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0),
            _jsx("div", { className: "info-side" }, void 0)] }), void 0));
};
