var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { reduce, differenceBy } from "lodash";
import { JOB_VIEW_URL } from "../routes";
export var hideActionButtons = function () {
    var btns = document.querySelectorAll(".btn-info");
    btns.forEach(function (btn) {
        btn.classList.add('none');
    });
};
export var setFormData = function (e, key, form, setData) {
    var _a;
    var _b;
    setData(__assign(__assign({}, form), (_a = {}, _a[key] = (!(e === null || e === void 0 ? void 0 : e.target) ? e : ((_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value) || ''), _a)));
};
export var setFormDataChecked = function (e, key, form, setData) {
    var _a;
    setData(__assign(__assign({}, form), (_a = {}, _a[key] = e.target.checked, _a)));
};
export var setFormArrayData = function (e, key, index, form, setData) {
    setData(reduce(form, function (result, item, i) {
        var _a;
        if (i === index) {
            result.push(__assign(__assign({}, item), (_a = {}, _a[key] = e.target.value, _a)));
        }
        else {
            result.push(item);
        }
        return result;
    }, []));
};
export var openJobInViewMode = function (id) {
    window.open(JOB_VIEW_URL.replace('{id}', id));
};
export var getRemovedItem = function (original, current) {
    return differenceBy(original, current, 'id');
};
export var tableRowClicked = function (e) { var _a; return ['td', 'span'].includes((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.localName); };
export var submitModal = function () {
    var _a;
    (_a = document.getElementById('modalSaveButton')) === null || _a === void 0 ? void 0 : _a.click();
};
export var toggleModalActions = function (visible, title) {
    if (title === void 0) { title = ''; }
    document.getElementById('modal-action-row').style.display = !visible ? 'none' : '';
    if (document.getElementById('modal-action-header'))
        document.getElementById('modal-action-header').style.display = !visible ? 'none' : '';
    var header = document.getElementById('modal-title-typography');
    if (!visible && title) {
        header.setAttribute('data-title', header.innerHTML);
        header.innerHTML = title;
    }
    else if (visible) {
        var text = header.getAttribute('data-title');
        if (text) {
            header.innerHTML = text;
        }
    }
};
export var addressChanged = function (value, setOptions) { return __awaiter(void 0, void 0, void 0, function () {
    var post, result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(value && value.length >= 3)) return [3 /*break*/, 3];
                return [4 /*yield*/, fetch("https://autocomplete.search.hereapi.com/v1/autocomplete?q=" + value + "&apiKey=" + process.env.REACT_APP_HERE_API_KEY + "&limit=10", {
                        method: "GET",
                    })];
            case 1:
                post = _a.sent();
                return [4 /*yield*/, post.json()];
            case 2:
                result = _a.sent();
                if (result && result.items) {
                    if (setOptions) {
                        setOptions(result.items.map(function (c) { return (__assign(__assign({}, c.address), { name: c.address.label })); }));
                    }
                    else {
                        return [2 /*return*/, result.items.map(function (c) { return (__assign(__assign({}, c.address), { name: c.address.label })); })];
                    }
                }
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
