var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Table } from "../table/table.component";
import { useApolloQuery } from '../../hooks/use-appolo.hooks';
import { GET_FIELDS_By_SECTION } from '../../graphqls/section-fields/section-fields.query';
import { getDataType, mapDataType, removeFieldSection, upsertField } from '../../services/custom-field.service';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { InputText } from "../../ui-components/input/Input-Field.component";
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { Spinner } from '../loading/Spinner.component';
import { Typography } from '@material-ui/core';
import { FIELD_TYPE, PROPERTY_SECTION } from '@enums';
import DeleteIcon from '@material-ui/icons/Delete';
var columns = [
    { caption: 'Field Name', fieldName: 'title' },
    { caption: 'id', fieldName: 'ID', isPrimaryKey: true, isHidden: true },
    { caption: 'Data Type', fieldName: 'type' },
    { caption: 'Default Value', fieldName: 'defaultValue' },
];
var CustomFieldList = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.sectionId, sectionId = _f === void 0 ? PROPERTY_SECTION.JOB_DETAILS : _f;
    var apolloClient = useApolloQuery()[0];
    var _g = useState(null), data = _g[0], setData = _g[1];
    var _h = useState(false), editMode = _h[0], setEditMode = _h[1];
    var formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            default_value: '',
            type: 0,
        },
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Spinner.show();
                        return [4 /*yield*/, upsertField(values, sectionId)];
                    case 1:
                        _a.sent();
                        setData(null);
                        setEditMode(false);
                        return [4 /*yield*/, loadSectionFields()];
                    case 2:
                        _a.sent();
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); },
    });
    var loadSectionFields = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, apolloClient.query({
                        query: GET_FIELDS_By_SECTION,
                        variables: {
                            id: sectionId,
                        }
                    })];
                case 1:
                    data = (_c.sent()).data;
                    setData((_b = (_a = data === null || data === void 0 ? void 0 : data.allTblSections) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b[0]);
                    return [2 /*return*/];
            }
        });
    }); };
    var fields = useMemo(function () {
        var _a, _b;
        return ((_b = (_a = data === null || data === void 0 ? void 0 : data.tblSectionFieldsBySectionId) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map(function (f) { return ({
            customTitle: f.customTitle,
            title: f.tblFieldByFieldId.title,
            id: f.tblFieldByFieldId.id,
            type: mapDataType(f.tblFieldByFieldId.type),
            defaultValue: f.tblFieldByFieldId.defaultValue,
            dataType: f.tblFieldByFieldId.type,
            options: f.tblFieldByFieldId.tblFieldSelectsByFieldId.nodes || [],
        }); })) || [];
    }, [data]);
    var close = function () {
        setEditMode(false);
    };
    var createNewField = function () {
        formik.resetForm();
        setEditMode(true);
    };
    var save = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formik.submitForm()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var editRow = function (id) {
        var fld = fields === null || fields === void 0 ? void 0 : fields.find(function (f) { return +f.id === +id; });
        formik.setValues(__assign(__assign({}, fld), { type: fld.dataType }));
        setEditMode(true);
    };
    var removeRow = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, removeFieldSection(+id, sectionId)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, loadSectionFields()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (sectionId) {
            loadSectionFields();
        }
    }, [sectionId]);
    return (_jsx("div", { children: editMode ?
            _jsx(Card, __assign({ className: "border-0 mt-16" }, { children: _jsx(CardBody, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ xs: 12 }, { children: _jsx(InputText, { flexWidth: "150", value: (_b = formik.values) === null || _b === void 0 ? void 0 : _b.title, onChange: formik.handleChange, id: "title", name: "title", flex: true, label: "Caption" }, void 0) }), void 0),
                            _jsx(Col, __assign({ xs: 12, className: 'mt-16' }, { children: _jsx(InputText, { flexWidth: "150", className: "min-w-200", extendMenu: false, value: (_c = formik.values) === null || _c === void 0 ? void 0 : _c.type, onChange: formik.handleChange, id: "type", name: "type", select: true, flex: true, label: "Data Type", menuItems: getDataType(), displayText: 'title' }, void 0) }), void 0),
                            [FIELD_TYPE.SINGLE_SELECT, FIELD_TYPE.Multi_SELECT].includes((_d = formik.values) === null || _d === void 0 ? void 0 : _d.type) &&
                                _jsx(Col, __assign({ xs: 12, className: 'mt-32 mb-32' }, { children: _jsx(FormikProvider, __assign({ value: formik }, { children: _jsx(FieldArray, { name: "options", render: function (arrayHelpers) {
                                                var _a;
                                                return (_jsxs(Row, { children: [_jsx(Col, __assign({ xs: 12 }, { children: _jsxs(Button, __assign({ onClick: function () { return arrayHelpers.push({ id: 0 }); }, className: "btn btn-xxs btn-link text-blue btn btn-secondary" }, { children: [_jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0), " Add Option"] }), void 0) }), void 0),
                                                        _jsx(Col, __assign({ xs: 12, className: 'mt-16' }, { children: (_a = formik.values.options) === null || _a === void 0 ? void 0 : _a.map(function (o, i) { return (_jsxs("div", __assign({ className: 'flex gap-8' }, { children: [_jsx(InputText, { className: "mt-8", value: o.option, onChange: formik.handleChange, id: "options." + i + ".option", name: "options." + i + ".option" }, "option_" + i),
                                                                    _jsx(DeleteIcon, { style: { fontSize: 18 }, className: "pointer", onClick: function () { return arrayHelpers.remove(i); } }, void 0)] }), void 0)); }) }), void 0)] }, void 0));
                                            } }, void 0) }), void 0) }), void 0),
                            _jsx(Col, __assign({ xs: 12, className: 'mt-16' }, { children: _jsx(InputText, { flexWidth: "150", flex: true, label: "Default Value", value: (_e = formik.values) === null || _e === void 0 ? void 0 : _e.default_value, onChange: formik.handleChange, id: "default_value", name: "default_value" }, void 0) }), void 0),
                            _jsxs(Col, __assign({ xs: 12, className: 'mt-40 flex gap-16' }, { children: [_jsxs(Button, __assign({ className: 'btn btn-xs', color: "info", onClick: save }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), "  Save"] }), void 0),
                                    _jsx(Button, __assign({ className: 'btn btn-xs', color: "secondary", onClick: close }, { children: "Cancel" }), void 0)] }), void 0)] }, void 0) }, void 0) }), void 0)
            : _jsx(Table, __assign({ addLinkTitle: 'New Custom Field', addLink: createNewField, offlineData: fields, headers: columns, editRoute: editRow, onDelete: removeRow }, { children: _jsx(Typography, __assign({ variant: "h5", className: 'black' }, { children: data === null || data === void 0 ? void 0 : data.title }), void 0) }), void 0) }, void 0));
};
export default CustomFieldList;
