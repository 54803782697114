import { createSelector } from 'reselect';
import { flatten, uniq, orderBy } from "lodash";
var appSelectors = function (state) { return state.app; };
export var getDrawerStatus = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.drawerStatus; });
export var getSidebarWidth = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.sidebarWidth; });
export var getSidebarLeft = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.sideBarLeft; });
export var getJobInEditMode = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.jobInEditMode; });
export var getTriggerInvoice = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.triggerInvoice; });
export var getNeighbors = createSelector([appSelectors], function (app) { return orderBy(uniq(flatten(((app === null || app === void 0 ? void 0 : app.neighbors) || []).map(function (c) { return c.list.map(function (l) { return l.name; }); })))); });
export var getAllCityPolygon = createSelector([appSelectors], function (app) { return (app === null || app === void 0 ? void 0 : app.neighbors) || []; });
export var getTriggerPayment = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.triggerPayment; });
export var getTriggerPropertyType = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.callAddPropertyType; });
export var getAllFields = createSelector([appSelectors], function (app) { var _a; return (_a = app === null || app === void 0 ? void 0 : app.fieldSection) === null || _a === void 0 ? void 0 : _a.fields; });
export var getAllFieldSelectOption = createSelector([appSelectors], function (app) { var _a; return (_a = app === null || app === void 0 ? void 0 : app.fieldSection) === null || _a === void 0 ? void 0 : _a.fieldOption; });
export var getAllSections = createSelector([appSelectors], function (app) { var _a; return (_a = app === null || app === void 0 ? void 0 : app.fieldSection) === null || _a === void 0 ? void 0 : _a.sections; });
export var getAllSectionFields = createSelector([appSelectors], function (app) { var _a; return (_a = app === null || app === void 0 ? void 0 : app.fieldSection) === null || _a === void 0 ? void 0 : _a.sectionFields; });
export var getTriggerSections = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.triggerAddSection; });
export var getAdjustmentItems = createSelector([appSelectors], function (app) { return ((app === null || app === void 0 ? void 0 : app.adjustmentItems) || []).map(function (c) {
    var _a;
    return ({
        id: c.id,
        description: c.description,
        title: c.title,
        options: ((_a = c === null || c === void 0 ? void 0 : c.tblAdjDescriptionsByHeaderId) === null || _a === void 0 ? void 0 : _a.nodes) || [],
    });
}); });
export var getAppModules = createSelector([appSelectors], function (app) { return app === null || app === void 0 ? void 0 : app.appModules; });
export var getGlobalMessage = createSelector([appSelectors], function (app) { return (app === null || app === void 0 ? void 0 : app.globalMessage) || null; });
