var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IconButton, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { map, sumBy } from 'lodash';
import { Table } from 'reactstrap';
import { GETBYJOBID, ADDPAYMENT, DELETE_PAYMENT, GETBYCLIENT, BATCHPAYMENT_COLUMNS } from '../../../graphqls/payments/payment.query';
import SaveIcon from '@material-ui/icons/Save';
import { Spinner } from '../../../ui-components/loading/Spinner.component';
import { formatTotalAsCurrency } from '../../../helpers/numbers-helper.helper';
import { canEditRequest, isAppraiser } from '../../../services/user-service';
import { FormDynamic } from '../../../ui-components/forms/Form-Dynamic';
import { ModalForm } from '../../../ui-components/modals/Modal-Form.component';
import { DeleteButton } from '../../../ui-components/button/Delete-Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAppModules, getTriggerPayment } from '../../../stores/app.selector';
import { PAYMENT_TYPES } from "@enums";
import { Table as Tablix } from "../../../ui-components/table/table.component";
import { EmptyDataError } from "../../../ui-components/table/EmptyDataError";
import { httpGet, httpPost } from '../../../helpers/http.helper';
import { isMobile } from 'react-device-detect';
import { formatDate } from '@utils/date';
export var AddPayment = function (_a) {
    var _b = _a.jobId, jobId = _b === void 0 ? "" : _b, _c = _a.reload, reload = _c === void 0 ? '' : _c, _d = _a.clientId, clientId = _d === void 0 ? null : _d, _e = _a.mt, mt = _e === void 0 ? 'mt-24' : _e;
    var _f = useState([]), payments = _f[0], setPayments = _f[1];
    var _isAppraiser = isAppraiser();
    var editable = canEditRequest();
    var triggerPayment = useSelector(getTriggerPayment);
    var _g = useState(null), currentFilterQuery = _g[0], setCurrentFilterQuery = _g[1];
    var appModule = useSelector(getAppModules);
    var dispatch = useDispatch();
    var getByJobId = useLazyQuery(GETBYJOBID, {
        onCompleted: function (data) {
            setPayments(data.allTblPayments.nodes);
            Spinner.hide();
        }
    })[0];
    var addNewPayment = useMutation(ADDPAYMENT, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            return __generator(this, function (_x) {
                switch (_x.label) {
                    case 0:
                        setPayments(payments.concat((_a = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _a === void 0 ? void 0 : _a.tblPayment));
                        if (!((_e = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _b === void 0 ? void 0 : _b.tblPayment) === null || _c === void 0 ? void 0 : _c.tblJobByJobId) === null || _d === void 0 ? void 0 : _d.tblClientByClientId) === null || _e === void 0 ? void 0 : _e.qbId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, httpPost("intuit/createPayment", {
                                amount: (_g = (_f = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _f === void 0 ? void 0 : _f.tblPayment) === null || _g === void 0 ? void 0 : _g.amount,
                                customer: (_l = (_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _h === void 0 ? void 0 : _h.tblPayment) === null || _j === void 0 ? void 0 : _j.tblJobByJobId) === null || _k === void 0 ? void 0 : _k.tblClientByClientId) === null || _l === void 0 ? void 0 : _l.qbId,
                                refNo: ((_o = (_m = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _m === void 0 ? void 0 : _m.tblPayment) === null || _o === void 0 ? void 0 : _o.refNumber) || '',
                                description: ((_q = (_p = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _p === void 0 ? void 0 : _p.tblPayment) === null || _q === void 0 ? void 0 : _q.description) || '',
                                type: ((_s = (_r = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _r === void 0 ? void 0 : _r.tblPayment) === null || _s === void 0 ? void 0 : _s.paymentType) || '',
                                dueDate: (_u = (_t = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _t === void 0 ? void 0 : _t.tblPayment) === null || _u === void 0 ? void 0 : _u.paymentDate,
                                id: (_w = (_v = data === null || data === void 0 ? void 0 : data.createTblPayment) === null || _v === void 0 ? void 0 : _v.tblPayment) === null || _w === void 0 ? void 0 : _w.id,
                                jobId: +jobId,
                            })];
                    case 1:
                        _x.sent();
                        _x.label = 2;
                    case 2:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var deletePayment = useMutation(DELETE_PAYMENT, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        setPayments(payments.filter(function (p) { var _a, _b; return p.id !== ((_b = (_a = data === null || data === void 0 ? void 0 : data.deleteTblPaymentById) === null || _a === void 0 ? void 0 : _a.tblPayment) === null || _b === void 0 ? void 0 : _b.id); }));
                        if (!((_b = (_a = data === null || data === void 0 ? void 0 : data.deleteTblPaymentById) === null || _a === void 0 ? void 0 : _a.tblPayment) === null || _b === void 0 ? void 0 : _b.qbId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, httpGet("intuit/deletePayment/" + ((_d = (_c = data === null || data === void 0 ? void 0 : data.deleteTblPaymentById) === null || _c === void 0 ? void 0 : _c.tblPayment) === null || _d === void 0 ? void 0 : _d.qbId))];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var saveRow = function (pay) {
        Spinner.show();
        if (+pay.id === 0) {
            delete pay.id;
            delete pay.edit;
            var amount = parseFloat(pay.amount);
            if (pay.isPayment === 1) {
                amount *= -1;
            }
            delete pay.isPayment;
            addNewPayment({
                variables: {
                    input: __assign(__assign({}, pay), { amount: amount, jobId: jobId })
                }
            });
        }
        else {
        }
    };
    var removeRow = function (id) {
        Spinner.show();
        deletePayment({
            variables: {
                id: id,
            }
        });
    };
    useEffect(function () {
        if (triggerPayment === 'OPEN') {
            addNewRow();
        }
    }, [triggerPayment]);
    var addNewRow = function () {
        ModalForm.show({
            title: 'Payment/Refund',
            md: true,
            footer: false,
            dispatch: dispatch,
            body: _jsx(FormDynamic, { formFields: [
                    { fieldName: 'isPayment', title: 'Type', type: 'number', defaultValue: 0,
                        options: [{ id: 0, title: 'Payment' }, { id: 1, title: 'Refund' }] },
                    { fieldName: 'paymentType', title: 'Payment Type', defaultValue: 'N/A', type: 'string', options: PAYMENT_TYPES.map(function (c) { return ({
                            id: c,
                            title: c,
                        }); }) },
                    { fieldName: 'amount', title: 'Amount', type: 'number', required: true },
                    { fieldName: 'paymentDate', title: 'Payment Date', type: 'date', defaultValue: formatDate(new Date(), 'yyyy-MM-dd'), required: true },
                    { fieldName: 'id', title: 'id', type: 'number', defaultValue: 0, hidden: true },
                    { fieldName: 'refNumber', title: 'Ref#' },
                    { fieldName: 'description', title: 'Description' },
                ], onSubmit: function (data) {
                    saveRow(data);
                    ModalForm.close();
                } }, void 0)
        });
    };
    useEffect(function () {
        if (reload) {
            getByJobId({
                variables: {
                    id: jobId,
                }
            });
        }
    }, [reload]);
    useEffect(function () {
        if (clientId) {
            setCurrentFilterQuery({ "tblJobByJobId": { "clientId": { "equalTo": clientId } } });
        }
        else {
            getByJobId({
                variables: {
                    id: jobId,
                }
            });
        }
    }, [getByJobId, jobId, clientId]);
    return (_jsx(_Fragment, { children: clientId ? (_jsx(Tablix, { tableName: "allTblPayments", pKey: "id", filterQuery: currentFilterQuery, query: GETBYCLIENT, defaultSort: 'ZZ_CREATED_DATE_DESC', headers: BATCHPAYMENT_COLUMNS }, void 0)) : (!(payments === null || payments === void 0 ? void 0 : payments.length) ? _jsx(EmptyDataError, { className: "db center" }, void 0) : _jsxs(Table, __assign({ responsive: isMobile, className: mt + " table-small" }, { children: [_jsx(TableHead, __assign({ className: 'text-primary' }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Type" }, void 0),
                            _jsx(TableCell, { children: "Amount" }, void 0),
                            _jsx(TableCell, { children: "Payment Date" }, void 0),
                            _jsx(TableCell, { children: "Description" }, void 0),
                            _jsx(TableCell, { children: "Ref#" }, void 0),
                            editable && (_jsx(TableCell, { children: "Remove" }, void 0))] }, void 0) }), void 0),
                _jsxs(TableBody, { children: [map(payments || [], function (pay, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: (pay === null || pay === void 0 ? void 0 : pay.paymentType) || 'N/A' }, void 0),
                                _jsx(TableCell, { children: formatTotalAsCurrency(pay === null || pay === void 0 ? void 0 : pay.amount) }, void 0),
                                _jsx(TableCell, { children: pay === null || pay === void 0 ? void 0 : pay.paymentDate }, void 0),
                                _jsx(TableCell, { children: pay === null || pay === void 0 ? void 0 : pay.description }, void 0),
                                _jsx(TableCell, { children: pay === null || pay === void 0 ? void 0 : pay.refNumber }, void 0),
                                editable && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsxs(TableCell, { children: [!_isAppraiser && (_jsx(DeleteButton, { index: pay === null || pay === void 0 ? void 0 : pay.id, onClick: function (row) { return removeRow(row); } }, void 0)),
                                        (pay === null || pay === void 0 ? void 0 : pay.id) === 0 && (_jsx(IconButton, __assign({ className: "btn-xs-no-padd", onClick: function () { return saveRow(pay); }, "aria-label": "delete" }, { children: _jsx(SaveIcon, {}, void 0) }), void 0))] }, void 0))] }, index)); }),
                        _jsxs(TableRow, __assign({ className: 'row-total' }, { children: [_jsx(TableCell, __assign({ align: "right" }, { children: _jsx("strong", { children: "Total" }, void 0) }), void 0),
                                _jsx(TableCell, __assign({ colSpan: 6, className: "total" }, { children: _jsx("strong", { children: formatTotalAsCurrency(sumBy(payments || [], function (i) { return +(i === null || i === void 0 ? void 0 : i.amount); })) }, void 0) }), void 0)] }), void 0)] }, void 0)] }), void 0)) }, void 0));
};
