import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { EmptyDataError } from "../../../ui-components/table/EmptyDataError";
var breakpoints = [256, 3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];
export var PhotoGallery = function (_a) {
    var _b = _a.images, images = _b === void 0 ? [] : _b;
    var _c = useState([]), photos = _c[0], setPhotos = _c[1];
    var _d = useState(-1), index = _d[0], setIndex = _d[1];
    useEffect(function () {
        setPhotos(images.map(function (photo) {
            var width = breakpoints[0];
            return {
                src: "https://public-map-icons.s3.ca-central-1.amazonaws.com/" + photo.fileKey,
                width: width,
                height: 150,
                srcSet: breakpoints.map(function (breakpoint) {
                    return {
                        src: "https://public-map-icons.s3.ca-central-1.amazonaws.com/" + photo.fileKey,
                        width: breakpoint,
                    };
                }),
            };
        }));
    }, [images]);
    var light = useMemo(function () { return images.map(function (photo) {
        return {
            src: "https://public-map-icons.s3.ca-central-1.amazonaws.com/" + photo.fileKey,
            width: window.screen.width,
            height: window.screen.height,
            srcSet: breakpoints.map(function (breakpoint) {
                return {
                    src: "https://public-map-icons.s3.ca-central-1.amazonaws.com/" + photo.fileKey,
                    width: 1080,
                };
            }),
        };
    }); }, [images]);
    return (_jsxs(_Fragment, { children: [!(photos === null || photos === void 0 ? void 0 : photos.length) && _jsx(EmptyDataError, { className: "db center", text: "No image available" }, void 0),
            _jsx(PhotoAlbum, { columns: 3, photos: photos, layout: "rows", targetRowHeight: 150, onClick: function (_a) {
                    var index = _a.index;
                    return setIndex(index);
                } }, void 0),
            _jsx(Lightbox, { slides: light, open: index >= 0, index: index, close: function () { return setIndex(-1); }, 
                // enable optional lightbox plugins
                plugins: [Fullscreen, Slideshow, Thumbnails, Zoom] }, void 0)] }, void 0));
};
