var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { ColumnType, SelectorName, DRAWERCOMPONENT } from "@enums";
export var DELETE_ORDER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation deleteTblOrderById($id: UUID!) {\n    deleteTblOrderById(input: { id: $id }) {\n      clientMutationId\n      deletedTblOrderId\n    }\n  }\n  "], ["mutation deleteTblOrderById($id: UUID!) {\n    deleteTblOrderById(input: { id: $id }) {\n      clientMutationId\n      deletedTblOrderId\n    }\n  }\n  "])));
export var UPDATE_ORDER = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["mutation updateTblOrderById($input: TblOrderPatch!, $id: UUID!) {\n    updateTblOrderById(input: { tblOrderPatch: $input, id: $id }) {\n      clientMutationId\n    }\n  }\n  "], ["mutation updateTblOrderById($input: TblOrderPatch!, $id: UUID!) {\n    updateTblOrderById(input: { tblOrderPatch: $input, id: $id }) {\n      clientMutationId\n    }\n  }\n  "])));
export var ORDER_BY_ID = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["query tblOrderById($id: UUID!) {\n    tblOrderById(id: $id) {\n      city\n      clientId\n      contactname\n      contactphone\n      countryName\n      county\n      function\n      id\n      interest\n      jobDueDate\n      jobNumber\n      lat\n      lng\n      note\n      owner\n      postalCode\n      propertySubType\n      propertyType\n      province\n      reportTypeId\n      streetName\n      streetNo\n      unit\n      valueType\n      zzFullAddress\n      status\n      tblOptionByFunction {\n        title\n      }\n      tblOptionByInterest {\n        title\n      }\n      tblOptionByValueType {\n        title\n      }\n      tblClientByClientId {\n        name\n        retainerRequired\n        retainerFee\n      }\n    }\n  }\n  "], ["query tblOrderById($id: UUID!) {\n    tblOrderById(id: $id) {\n      city\n      clientId\n      contactname\n      contactphone\n      countryName\n      county\n      function\n      id\n      interest\n      jobDueDate\n      jobNumber\n      lat\n      lng\n      note\n      owner\n      postalCode\n      propertySubType\n      propertyType\n      province\n      reportTypeId\n      streetName\n      streetNo\n      unit\n      valueType\n      zzFullAddress\n      status\n      tblOptionByFunction {\n        title\n      }\n      tblOptionByInterest {\n        title\n      }\n      tblOptionByValueType {\n        title\n      }\n      tblClientByClientId {\n        name\n        retainerRequired\n        retainerFee\n      }\n    }\n  }\n  "])));
export var CREATE_ORDER = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["mutation createTblOrder($input: TblOrderInput!) {\n    createTblOrder(input: { tblOrder: $input }) {\n      tblOrder {\n        id\n      }\n    }\n  }\n  "], ["mutation createTblOrder($input: TblOrderInput!) {\n    createTblOrder(input: { tblOrder: $input }) {\n      tblOrder {\n        id\n      }\n    }\n  }\n  "])));
export var ALL_ORDERS = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nquery getAllOrders(\n  $offset: Int\n  $first: Int\n  $orderBy: [TblOrdersOrderBy!]\n  $filter: TblOrderFilter\n) {\n  allTblOrders(\n    first: $first\n    offset: $offset\n    orderBy: $orderBy\n    filter: $filter\n  ) {\n    totalCount\n    nodes {\n      id\n      jobNumber\n      portfolioName\n      zzCreatedBy\n      zzCreatedDate\n      zzFullAddress\n      zzModifiedBy\n      zzModifiedDate\n      clientId\n      postalCode\n      province\n      streetName\n      streetNo\n      unit\n      city\n      owner\n      propertyType\n      propertySubType\n      jobDueDate\n      status\n      tblClientByClientId {\n        name\n      }\n    }\n  }\n}"], ["\nquery getAllOrders(\n  $offset: Int\n  $first: Int\n  $orderBy: [TblOrdersOrderBy!]\n  $filter: TblOrderFilter\n) {\n  allTblOrders(\n    first: $first\n    offset: $offset\n    orderBy: $orderBy\n    filter: $filter\n  ) {\n    totalCount\n    nodes {\n      id\n      jobNumber\n      portfolioName\n      zzCreatedBy\n      zzCreatedDate\n      zzFullAddress\n      zzModifiedBy\n      zzModifiedDate\n      clientId\n      postalCode\n      province\n      streetName\n      streetNo\n      unit\n      city\n      owner\n      propertyType\n      propertySubType\n      jobDueDate\n      status\n      tblClientByClientId {\n        name\n      }\n    }\n  }\n}"])));
export var Columns = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Reference#",
        fieldName: "jobNumber",
        sortField: "JOB_NUMBER",
        filterable: true,
        sortable: true,
        columnFilter: true,
        columnFilterField: 'jobNumber',
    },
    {
        caption: "Address",
        fieldName: "zzFullAddress",
        sortField: "",
        filterable: true,
        linkTo: '/admin/orders/detail/:id',
        linkFieldId: 'id',
    },
    {
        caption: "Portfolio Name",
        fieldName: "portfolioName",
        sortField: "",
        filterable: true,
        isHidden: true,
    },
    {
        caption: "Client",
        fieldName: "tblClientByClientId.name",
        sortField: "CLIENT_ID",
        sortable: true,
        linkTo: DRAWERCOMPONENT.CLIENT,
        linkFieldId: 'clientId',
        openInDrawer: true,
        columnFilter: true,
        columnType: ColumnType.LIST,
        selectorName: SelectorName.CLIENT,
        columnFilterField: 'clientId',
    },
    {
        caption: "Property Type",
        fieldName: "propertyType",
        sortField: "PROPERTY_TYPE",
        sortable: true,
        columnFilter: true,
        columnType: ColumnType.LIST,
        selectorName: SelectorName.PROPERTY_TYPE_SALES,
        columnFilterField: 'propertyType',
        isFromOptions: true,
    },
    {
        caption: "Property Sub Type",
        fieldName: "propertySubType",
        sortField: "PROPERTY_SUB_TYPE",
        sortable: true,
        columnFilter: true,
        columnType: ColumnType.LIST,
        selectorName: SelectorName.PROPERTY_SUB_TYPE,
        columnFilterField: 'propertySubType',
        isFromOptions: true,
    },
    {
        caption: "Created Date",
        fieldName: "zzCreatedDate",
        sortField: "ZZ_CREATED_DATE",
        sortable: true,
        columnType: ColumnType.DATE_ONLY,
        columnFilter: true,
        columnFilterField: 'zzCreatedDate',
    },
    {
        caption: "Created By",
        upperCase: true,
        fieldName: "zzCreatedBy",
        sortField: "ZZ_CREATED_BY",
        sortable: true,
        isHidden: true,
    },
    {
        caption: "Status",
        fieldName: "status",
        columnType: ColumnType.LIST,
        sortField: "ZZ_CREATED_BY",
        sortable: true,
        listItems: ["Created", "Accepted", "Rejected"],
    },
    {
        caption: "Owner",
        fieldName: "owner",
        sortField: "",
        sortable: false,
        isHidden: true,
    },
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
