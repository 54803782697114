var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { List, ListItem, ListItemText, Typography, ListItemSecondaryAction, IconButton, Divider, Tooltip } from "@material-ui/core";
import { Card, CardBody, Button, CardHeader } from "reactstrap";
import { GET_ENGAGEMENT_LETTER } from "../../graphqls/jobs/job.query";
import { httpGet } from "../../helpers/http.helper";
import { canEditRequest, getJwtToken, getOffices, isAppraiser } from "../../services/user-service";
import SendIcon from '@material-ui/icons/Send';
import { ModalForm } from "../../ui-components/modals/Modal-Form.component";
import { PreviewQuote } from "./Preview-Quote.component";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { formatDate } from "@utils/date";
import { DeleteButton } from "../../ui-components/button/Delete-Button";
import { GOOGLE_EDIT_URL } from "@enums";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FormDynamic } from "../../ui-components/forms/Form-Dynamic";
import { useDispatch, useSelector } from "react-redux";
import { getAppModules } from "../../stores/app.selector";
import { globalMessage } from "../../stores/app.reducer";
import { EmptyDataError } from "../../ui-components/table/EmptyDataError";
export var EngagementLetter = function (_a) {
    var jobId = _a.jobId, email = _a.email, onDocumentUpdated = _a.onDocumentUpdated, _b = _a.drawer, drawer = _b === void 0 ? false : _b;
    var editable = canEditRequest();
    var _isAppraiser = isAppraiser();
    var dispatch = useDispatch();
    var _c = useState([]), docs = _c[0], setDocs = _c[1];
    var templates = getOffices();
    var appModule = useSelector(getAppModules);
    var _d = useLazyQuery(GET_ENGAGEMENT_LETTER, {
        onCompleted: function (data) {
            setDocs(data.allTblDocuments.nodes);
        }
    }), getDocuemtns = _d[0], refetch = _d[1].refetch;
    var editEngLetter = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(docs.length === 0 && templates.length > 1)) return [3 /*break*/, 1];
                    ModalForm.show({
                        title: 'Select an office',
                        small: true,
                        footer: false,
                        body: _jsx(FormDynamic, { save: "Generate", formFields: [
                                { noEditable: true, fieldName: 'id', title: 'Offices', required: true, options: templates },
                            ], onSubmit: function (data) { return __awaiter(void 0, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            Spinner.show();
                                            return [4 /*yield*/, httpGet("quote/generateSavereport/" + jobId + "/" + data.id + "/" + getJwtToken())];
                                        case 1:
                                            result = _a.sent();
                                            return [4 /*yield*/, refreshList(result)];
                                        case 2:
                                            _a.sent();
                                            ModalForm.close();
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, void 0)
                    });
                    return [3 /*break*/, 4];
                case 1:
                    Spinner.show();
                    return [4 /*yield*/, httpGet("quote/generateSavereport/" + jobId + "/" + templates[0].id + "/" + getJwtToken())];
                case 2:
                    result = _a.sent();
                    return [4 /*yield*/, refreshList(result)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var refreshList = function (result) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (result === null || result === void 0 ? void 0 : result.error) {
                        Spinner.hide();
                        dispatch(globalMessage({ type: 'error', message: result.error }));
                        return [2 /*return*/];
                    }
                    if (!refetch) return [3 /*break*/, 2];
                    return [4 /*yield*/, refetch()];
                case 1:
                    data = (_a.sent()).data;
                    setDocs(data.allTblDocuments.nodes);
                    Spinner.hide();
                    if (result === null || result === void 0 ? void 0 : result.id) {
                        showFile(result === null || result === void 0 ? void 0 : result.id);
                    }
                    onDocumentUpdated && onDocumentUpdated();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var showFile = function (fileKey) {
        if (_isAppraiser)
            return;
        window.open("" + GOOGLE_EDIT_URL + fileKey + "/edit");
    };
    var viewEngAndSend = function (doc) {
        //const url = `${GOOGLE_EDIT_URL}${doc.fileName}/export?format=pdf`;
        var url = process.env.REACT_APP_FRONT_END_URl + "/apiv1/quote/viewEngRev/" + doc.id + "/" + getJwtToken();
        ModalForm.show({
            footer: false,
            body: (_jsx(PreviewQuote, { docId: doc.id, url: url, email: email }, void 0)),
            title: 'Engagement Letter',
            save: 'Send',
            large: _isAppraiser,
            download: false,
        });
    };
    var deleteDoc = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpGet("quote/removeEngLetter/remove/" + id)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refreshList()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getDocuemtns({
            variables: {
                jobId: jobId,
            }
        });
    }, [jobId]);
    return (_jsxs(Card, __assign({ className: "no-border-radius" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "db" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Engagement Letter" }), void 0)] }), void 0),
                        _jsx("div", __assign({ className: "dib right w-50" }, { children: editable && !drawer && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsxs(Button, __assign({ onClick: editEngLetter, color: "default", className: "btn-xss" }, { children: [_jsx("i", { className: "nc-icon nc-paper" }, void 0), docs.length === 0 ? ' Generate' : ' New Revision'] }), void 0)) }), void 0)] }), void 0) }), void 0),
            _jsx(CardBody, { children: (docs === null || docs === void 0 ? void 0 : docs.length) > 0 ? _jsx(List, __assign({ dense: true }, { children: docs.map(function (doc) { return (_jsxs("div", { children: [_jsxs(ListItem, { children: [_jsx(ListItemText, { className: 'primary-ellipse ' + (editable ? "table-row-link pointer pr-30" : "pr-30"), onClick: function () { return showFile(doc.fileName); }, primary: doc.description, secondary: formatDate(doc.zzCreatedDate, "MMMM dd , yyyy") }, void 0),
                                    !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) &&
                                        _jsxs(ListItemSecondaryAction, __assign({ className: "flex gap-16" }, { children: [editable && (_jsx(DeleteButton, { index: doc.id, onClick: deleteDoc }, void 0)),
                                                _jsx(IconButton, __assign({ edge: "end", className: "btn-xs-no-padd", onClick: function () { return viewEngAndSend(doc); } }, { children: _isAppraiser ? _jsx(VisibilityIcon, { style: { fontSize: 20 } }, void 0) : _jsx(Tooltip, __assign({ title: 'Preview and Send' }, { children: _jsx(SendIcon, { style: { fontSize: 20 } }, void 0) }), void 0) }), void 0)] }), void 0)] }, void 0),
                            _jsx(Divider, { component: "li" }, void 0)] }, "row_" + doc.id)); }) }), void 0) : _jsx(EmptyDataError, { className: "db center" }, void 0) }, void 0)] }), void 0));
};
