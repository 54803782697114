var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { StatusEnum } from '@enums';
export var GET_FIELDS_By_SECTION = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery getbySection($id: BigInt) {\n    allTblSections(condition: {id: $id}) {\n      nodes {\n        id\n        title\n        tblSectionFieldsBySectionId {\n          nodes {\n            customTitle\n            groupTitle\n            showInGrid\n            formula\n            tblFieldByFieldId {\n              defaultValue\n              id\n              title\n              type\n              tblFieldSelectsByFieldId {\n                nodes {\n                  id\n                  option\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }"], ["\nquery getbySection($id: BigInt) {\n    allTblSections(condition: {id: $id}) {\n      nodes {\n        id\n        title\n        tblSectionFieldsBySectionId {\n          nodes {\n            customTitle\n            groupTitle\n            showInGrid\n            formula\n            tblFieldByFieldId {\n              defaultValue\n              id\n              title\n              type\n              tblFieldSelectsByFieldId {\n                nodes {\n                  id\n                  option\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }"])));
export var ADD_SECTION_FIELD_VALUE = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["mutation createTblSectionFieldValue($input: TblSectionFieldValueInput!) {\n    createTblSectionFieldValue(input: { tblSectionFieldValue: $input }) {\n      clientMutationId\n    }\n  }"], ["mutation createTblSectionFieldValue($input: TblSectionFieldValueInput!) {\n    createTblSectionFieldValue(input: { tblSectionFieldValue: $input }) {\n      clientMutationId\n    }\n  }"])));
export var ADD_SECTION_VALUE = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmutation addSectionValues($input: TblSectionFieldHeaderInput!) {\n  createTblSectionFieldHeader(input: { tblSectionFieldHeader: $input }) {\n    clientMutationId\n    tblSectionFieldHeader {\n      id\n    }\n  }\n}"], ["\nmutation addSectionValues($input: TblSectionFieldHeaderInput!) {\n  createTblSectionFieldHeader(input: { tblSectionFieldHeader: $input }) {\n    clientMutationId\n    tblSectionFieldHeader {\n      id\n    }\n  }\n}"])));
export var DELETE_SECTION_VALUE = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation deleteById($id: BigInt!) {\n  deleteTblSectionFieldHeaderById(input: { id: $id }) {\n    clientMutationId\n    deletedTblSectionFieldHeaderId\n  }\n}"], ["\nmutation deleteById($id: BigInt!) {\n  deleteTblSectionFieldHeaderById(input: { id: $id }) {\n    clientMutationId\n    deletedTblSectionFieldHeaderId\n  }\n}"])));
export var GET_ALL_FIELDS = "\n    allTblFields {\n      nodes {\n        defaultValue\n        id\n        title\n        type\n        tblFieldSelectsByFieldId {\n          nodes {\n            id\n            option\n          }\n        }\n      }\n    }";
export var GET_ALL_SECTION_FIELDS = "\n    allTblSectionFields {\n      nodes {\n        customTitle\n        fieldId\n        groupTitle\n        id\n        sectionId\n        showInGrid\n        formula\n        licenceId\n      }\n    }\n  ";
export var GET_ALL_SECTIONS = "\n    allTblSections {\n      nodes {\n        id\n        title\n        tableName\n      }\n    }";
export var GET_JOBS_BY_APPRAISER_FOR_MAP = function (status) { return gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["query jobsByAppraiser($id:BigInt!) {\n  allTblAppraisers(condition: { id: $id }) {\n    nodes {\n      tblPropertyAppraisersById ", " ", "  {\n        nodes {\n          tblJobByJobId {\n            city\n            zzFullAddress\n            lng\n            lat\n            zzCreatedDate\n            feeSplitAmount\n            id\n            jobNumber\n            tblClientByClientId {\n              name\n            }\n            fileNumber\n            statusId\n            tblStatusByStatusId {\n              statusTitle\n            }\n          }\n        }\n      }\n    }\n  }\n}"], ["query jobsByAppraiser($id:BigInt!) {\n  allTblAppraisers(condition: { id: $id }) {\n    nodes {\n      tblPropertyAppraisersById ", " ", "  {\n        nodes {\n          tblJobByJobId {\n            city\n            zzFullAddress\n            lng\n            lat\n            zzCreatedDate\n            feeSplitAmount\n            id\n            jobNumber\n            tblClientByClientId {\n              name\n            }\n            fileNumber\n            statusId\n            tblStatusByStatusId {\n              statusTitle\n            }\n          }\n        }\n      }\n    }\n  }\n}"])), status === StatusEnum.COMPLETED ? "(filter: { tblJobByJobId: { statusId: { in: [" + StatusEnum.COMPLETED + "," + StatusEnum.CLOSED + "] } } })" : "", status === StatusEnum.IN_PROGRESS ? "(filter: { tblJobByJobId: { statusId: { notIn: [" + StatusEnum.COMPLETED + "," + StatusEnum.CLOSED + "] } } })" : ""); };
export var GET_JOBS_FOR_MAP = function (status) { return gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["query allTblJobsForMap {\n  allTblJobs(filter: {lat: {isNull: false} ", " ", " }) {\n    nodes {\n      city\n      zzFullAddress\n      lng\n      lat\n      zzCreatedDate\n      feeSplitAmount\n      id\n      jobNumber\n      statusId\n      tblClientByClientId {\n        name\n      }\n      fileNumber\n      tblStatusByStatusId {\n        statusTitle\n      }\n    }\n  }\n}\n"], ["query allTblJobsForMap {\n  allTblJobs(filter: {lat: {isNull: false} ", " ", " }) {\n    nodes {\n      city\n      zzFullAddress\n      lng\n      lat\n      zzCreatedDate\n      feeSplitAmount\n      id\n      jobNumber\n      statusId\n      tblClientByClientId {\n        name\n      }\n      fileNumber\n      tblStatusByStatusId {\n        statusTitle\n      }\n    }\n  }\n}\n"])), status === StatusEnum.IN_PROGRESS ? ", statusId: { notIn: [" + StatusEnum.COMPLETED + "," + StatusEnum.CLOSED + "]}" : "", status === StatusEnum.COMPLETED ? ", statusId: {in: [" + StatusEnum.COMPLETED + "," + StatusEnum.CLOSED + "]}" : ""); };
export var GET_VALUES_BY_JOB = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["query getSectionValuesByJob($id: BigInt, $sectionId: BigInt, $table: String) {\n  allTblSectionFieldHeaders(\n    condition: { referenceId: $id, sectionId: $sectionId, tableName: $table }\n  ) {\n    nodes {\n      title\n      id\n      tblSectionFieldValuesByHeaderId {\n        nodes {\n          fieldSectionId\n          id\n          value\n          tblSectionFieldByFieldSectionId {\n            customTitle\n            showInGrid\n            tblFieldByFieldId {\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n}"], ["query getSectionValuesByJob($id: BigInt, $sectionId: BigInt, $table: String) {\n  allTblSectionFieldHeaders(\n    condition: { referenceId: $id, sectionId: $sectionId, tableName: $table }\n  ) {\n    nodes {\n      title\n      id\n      tblSectionFieldValuesByHeaderId {\n        nodes {\n          fieldSectionId\n          id\n          value\n          tblSectionFieldByFieldSectionId {\n            customTitle\n            showInGrid\n            tblFieldByFieldId {\n              title\n            }\n          }\n        }\n      }\n    }\n  }\n}"])));
export var SHOW_FIELDS_IN_DETAILS = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["query getsections($jobId: BigInt) {\n  allTblSectionFieldHeaders(\n    filter: {\n      referenceId: { equalTo: $jobId }\n      tableName: { equalTo: \"JOB\" }\n      sectionId: { in: [\"1\",\"3\"] }\n    }\n  ) {\n    nodes {\n      sectionId\n      tblSectionFieldValuesByHeaderId(\n        filter: {\n          tblSectionFieldByFieldSectionId: { showInDetail: { equalTo: true } }\n        }\n      ) {\n        nodes {\n          tblSectionFieldByFieldSectionId {\n            customTitle\n            fieldId\n            groupTitle\n            tblFieldByFieldId {\n              title\n            }\n          }\n          value\n        }\n      }\n    }\n  }\n}\n"], ["query getsections($jobId: BigInt) {\n  allTblSectionFieldHeaders(\n    filter: {\n      referenceId: { equalTo: $jobId }\n      tableName: { equalTo: \"JOB\" }\n      sectionId: { in: [\"1\",\"3\"] }\n    }\n  ) {\n    nodes {\n      sectionId\n      tblSectionFieldValuesByHeaderId(\n        filter: {\n          tblSectionFieldByFieldSectionId: { showInDetail: { equalTo: true } }\n        }\n      ) {\n        nodes {\n          tblSectionFieldByFieldSectionId {\n            customTitle\n            fieldId\n            groupTitle\n            tblFieldByFieldId {\n              title\n            }\n          }\n          value\n        }\n      }\n    }\n  }\n}\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
