var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Table } from 'reactstrap';
import { formatTotalAsCurrency } from '../../helpers/numbers-helper.helper';
import { Spinner } from '../../ui-components/loading/Spinner.component';
import { paymentReceived } from '../../services/payment.service';
import { ModalForm } from '../../ui-components/modals/Modal-Form.component';
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { PAYMENT_TYPES } from "@enums";
import { FormDynamic } from "../../ui-components/forms/Form-Dynamic";
import { formatDate } from "@utils/date";
import { sumBy } from "lodash";
export var InvoiceList = function (_a) {
    var _b = _a.invoices, invoices = _b === void 0 ? [] : _b, _c = _a.actionText, actionText = _c === void 0 ? '' : _c, _d = _a.selectedIds, selectedIds = _d === void 0 ? [] : _d;
    var apolloClient = useApolloQuery()[0];
    var batchPayment = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, paymentReceived(selectedIds, true, data)(apolloClient)];
                case 1:
                    _a.sent();
                    Spinner.hide();
                    ModalForm.close();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: 'db' }, { children: [_jsx(FormDynamic, { divider: false, save: actionText, formFields: [
                    { fieldName: 'paymentType', title: 'Type', defaultValue: 'N/A', type: 'string', fieldId: 0, options: PAYMENT_TYPES.map(function (c) { return ({
                            id: c,
                            title: c,
                        }); }) },
                    { fieldName: 'amount', title: 'Amount', type: 'number', required: true, defaultValue: sumBy(invoices, function (pay) { return +pay.due; }) },
                    { fieldName: 'paymentDate', title: 'Payment Date', type: 'date', defaultValue: formatDate(new Date(), 'yyyy-MM-dd'), required: true },
                    { fieldName: 'refNumber', title: 'Ref#' },
                    { fieldName: 'description', title: 'Description' },
                ], onSubmit: function (data) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, batchPayment(data)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }, void 0),
            _jsxs(Table, __assign({ className: "mt-20" }, { children: [_jsx(TableHead, __assign({ className: "text-primary" }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Address" }, void 0),
                                _jsx(TableCell, { children: "Due" }, void 0),
                                _jsx(TableCell, {}, void 0)] }, void 0) }), void 0),
                    _jsx(TableBody, { children: invoices.map(function (pay, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: pay.zzFullAddress }, void 0),
                                _jsx(TableCell, { children: formatTotalAsCurrency(pay.due) }, void 0),
                                _jsx(TableCell, {}, void 0)] }, index)); }) }, void 0)] }), void 0)] }), void 0));
};
