var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { InputText } from "../../ui-components/input/Input-Field.component";
export var ICAutoComplete = function (_a) {
    var _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.flex, flex = _c === void 0 ? false : _c, _d = _a.flexWidth, flexWidth = _d === void 0 ? '' : _d, _e = _a.options, options = _e === void 0 ? [] : _e, _f = _a.error, error = _f === void 0 ? false : _f, props = __rest(_a, ["label", "flex", "flexWidth", "options", "error"]);
    var _g = useState(null), value = _g[0], setValue = _g[1];
    var onChange = function (event, newValue, reason) {
        if (reason === 'clear') {
            setValue(null);
        }
        props.onChange && props.onChange(__assign(__assign({}, event), { id: props.id, name: props.id, target: __assign(__assign({}, event.target), { id: props.id, name: props.id, value: (newValue === null || newValue === void 0 ? void 0 : newValue.id) && reason !== 'clear' ? +newValue.id : null }) }));
    };
    useEffect(function () {
        if ((props === null || props === void 0 ? void 0 : props.value) && "" + props.value !== "-1") {
            setValue(options === null || options === void 0 ? void 0 : options.find(function (o) { return +o.id === +props.value; }));
        }
    }, [props]);
    return (_jsx(Autocomplete, { value: value, options: options, className: props.className + " " + (error ? 'control-error' : ''), size: props.size, disabled: props.disabled, getOptionSelected: function (option) { return +(option === null || option === void 0 ? void 0 : option.id) === +props.value; }, getOptionLabel: function (option) { return (option === null || option === void 0 ? void 0 : option.title) || ''; }, onChange: onChange, renderInput: function (params) { return _jsx(InputText, __assign({}, params, { flex: flex, label: label, flexWidth: flexWidth }), void 0); } }, void 0));
};
