var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { PROPERTY_SECTION, ADJUSTMENT_ITEMS, DOLLAR_PER_UNIT, COVERAGE, JOB_SECTION_FIELDS_ARRAY, ADJUSTMENT_METHODOLOGY } from "@enums";
import { SHOW_FIELDS_IN_DETAILS } from "../graphqls/section-fields/section-fields.query";
import { GET_VALUATION_BY_ID, ADD_VALUATION, UPDATE_VALUATION, DELETE_VALUATION_PART, GET_DCA_BY_PART, ADD_DCA, UPDATE_DCA, DELETE_VALUATION, GET_VALUATIONS_BY_JOB, GET_INCOME_APP_BY_PART, ADD_INCOME_APP, UPDATE_INCOME_APP, DELETE_TENANAT, UPDATE_INCOME_EXPENSES, UPDATE_COSTS, GET_COSTS_BY_PARTID, ADD_NEW_COSTS, DELETE_COSTS } from "../graphqls/valuation/valuation.query";
import { flatten, groupBy, sumBy, max, min } from "lodash";
import { convertDateToYear } from "@utils/date";
import { formatAsDecimalPlaces, median, sqFeetToAcres, stdev } from "@utils/text";
export var deleteValuationPart = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: DELETE_VALUATION_PART,
                    variables: {
                        id: id,
                    }
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var deleteValuation = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: DELETE_VALUATION,
                    variables: {
                        id: id,
                    }
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var getValuationHisotry = function (jobId, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_VALUATIONS_BY_JOB,
                    variables: {
                        jobId: jobId,
                    }
                })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, (((_a = data === null || data === void 0 ? void 0 : data.allTblValuations) === null || _a === void 0 ? void 0 : _a.nodes) || []).map(function (item) { return ({
                        id: item.id,
                        saleDate: item.effectiveDate,
                        salePrice: item.finalAdjustedValue,
                    }); })];
        }
    });
}); };
export var getValuationById = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data, result, address;
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_VALUATION_BY_ID,
                    variables: {
                        id: id,
                    }
                })];
            case 1:
                data = (_c.sent()).data;
                result = data === null || data === void 0 ? void 0 : data.tblValuationById;
                address = result === null || result === void 0 ? void 0 : result.tblJobByJobId;
                result === null || result === void 0 ? true : delete result.tblJobByJobId;
                if (!result.jobId) {
                    address = {
                        city: result.city,
                        streetNo: result.streetNo,
                        streetName: result.streetName,
                        postalCode: result.postalCode,
                        zzFullAddress: result.zzFullAddress,
                        province: result.province,
                        unit: result.unit,
                        county: result.county,
                        lat: result.lat,
                        countryName: result.countryName,
                        lng: result.lng,
                    };
                }
                ;
                _a = {
                    data: result,
                    address: address
                };
                return [4 /*yield*/, getValuationHisotry(result.jobId, apolloClient)];
            case 2: return [2 /*return*/, (_a.history = (_c.sent()).filter(function (c) { return +c.id !== id; }),
                    _a.finalValue = sumBy(((_b = result === null || result === void 0 ? void 0 : result.tblValuationPartsByHeaderId) === null || _b === void 0 ? void 0 : _b.nodes) || [], function (v) { return +v.finalValue; }),
                    _a)];
        }
    });
}); };
export var getApproaches = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data, income, costs, incomeData;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return __generator(this, function (_o) {
        switch (_o.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_DCA_BY_PART,
                    variables: {
                        id: id,
                    }
                })];
            case 1:
                data = (_o.sent()).data;
                return [4 /*yield*/, apolloClient.query({
                        query: GET_INCOME_APP_BY_PART,
                        variables: {
                            id: id,
                        }
                    })];
            case 2:
                income = _o.sent();
                return [4 /*yield*/, apolloClient.query({
                        query: GET_COSTS_BY_PARTID,
                        variables: {
                            id: id,
                        }
                    })];
            case 3:
                costs = _o.sent();
                incomeData = (_c = (((_b = (_a = income === null || income === void 0 ? void 0 : income.data) === null || _a === void 0 ? void 0 : _a.allTblIncomes) === null || _b === void 0 ? void 0 : _b.nodes) || []).map(function (_a) {
                    var tblIncomeExpensesByIncomeId = _a.tblIncomeExpensesByIncomeId, tblTenantsByIncomeId = _a.tblTenantsByIncomeId, input = __rest(_a, ["tblIncomeExpensesByIncomeId", "tblTenantsByIncomeId"]);
                    return ({
                        income: __assign(__assign({}, input), { otherIncome: input.otherIncome ? JSON.parse(input.otherIncome) : [] }),
                        tenants: ((tblTenantsByIncomeId === null || tblTenantsByIncomeId === void 0 ? void 0 : tblTenantsByIncomeId.nodes) || []).map(function (item) { return (__assign(__assign({}, item), { expenses: item.expenses ? JSON.parse(item.expenses) : [] })); }),
                        incomeExpenses: (tblIncomeExpensesByIncomeId === null || tblIncomeExpensesByIncomeId === void 0 ? void 0 : tblIncomeExpensesByIncomeId.nodes) || [],
                    });
                })) === null || _c === void 0 ? void 0 : _c[0];
                return [2 /*return*/, {
                        dca: ((_d = data === null || data === void 0 ? void 0 : data.allTblDcas) === null || _d === void 0 ? void 0 : _d.nodes) || [],
                        income: incomeData,
                        cost: JSON.parse(((_h = (_g = (_f = (_e = costs === null || costs === void 0 ? void 0 : costs.data) === null || _e === void 0 ? void 0 : _e.allTblCosts) === null || _f === void 0 ? void 0 : _f.nodes) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.costs) || null),
                        id: (_m = (_l = (_k = (_j = costs === null || costs === void 0 ? void 0 : costs.data) === null || _j === void 0 ? void 0 : _j.allTblCosts) === null || _k === void 0 ? void 0 : _k.nodes) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.id,
                    }];
        }
    });
}); };
export var upsertCosts = function (partId, id, input) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.log('upsertCosts', input, id);
                if (!(!(input === null || input === void 0 ? void 0 : input.length) && id)) return [3 /*break*/, 2];
                return [4 /*yield*/, apolloClient.query({
                        query: DELETE_COSTS,
                        variables: {
                            id: id,
                        },
                    })];
            case 1:
                _a.sent();
                return [3 /*break*/, 6];
            case 2:
                if (!id) return [3 /*break*/, 4];
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_COSTS,
                        variables: {
                            id: id,
                            input: {
                                costs: JSON.stringify(input),
                                fees: null,
                                total: getTotalCosts(input),
                            }
                        },
                    })];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                if (!(input === null || input === void 0 ? void 0 : input.length)) return [3 /*break*/, 6];
                return [4 /*yield*/, apolloClient.query({
                        query: ADD_NEW_COSTS,
                        variables: {
                            input: {
                                partId: partId,
                                costs: JSON.stringify(input),
                                fees: null,
                                total: getTotalCosts(input),
                            }
                        },
                    })];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}); }; };
export var addIncomeApproach = function (input) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!input)
                    return [2 /*return*/];
                if (!input.income.id) return [3 /*break*/, 5];
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_INCOME_APP,
                        variables: {
                            id: input.income.id,
                            input: __assign(__assign({}, input.income), { otherIncome: JSON.stringify(input.income.otherIncome), tblTenantsUsingId: {
                                    create: input.tenants.filter(function (t) { return !t.id; }).map(function (_a) {
                                        var expenses = _a.expenses, t = __rest(_a, ["expenses"]);
                                        return (__assign(__assign({}, t), { expenses: JSON.stringify(expenses) }));
                                    }),
                                    updateById: input.tenants.filter(function (t) { return t.id; }).map(function (_a) {
                                        var expenses = _a.expenses, t = __rest(_a, ["expenses"]);
                                        return ({
                                            id: t.id,
                                            tblTenantPatch: __assign(__assign({}, t), { expenses: JSON.stringify(expenses) }),
                                        });
                                    }),
                                }, tblIncomeExpensesUsingId: {
                                    create: (_a = input.incomeExpenses) === null || _a === void 0 ? void 0 : _a.filter(function (ie) { return !ie.id; }).map(function (_a) {
                                        var id = _a.id, t = __rest(_a, ["id"]);
                                        return (__assign({}, t));
                                    }),
                                } })
                        }
                    })];
            case 1:
                _e.sent();
                if (!((_b = input.deletedTenants) === null || _b === void 0 ? void 0 : _b.length)) return [3 /*break*/, 3];
                return [4 /*yield*/, Promise.all(input.deletedTenants.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, apolloClient.query({
                                        query: DELETE_TENANAT,
                                        variables: {
                                            id: id,
                                        },
                                    })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/, null];
                            }
                        });
                    }); }))];
            case 2:
                _e.sent();
                _e.label = 3;
            case 3: return [4 /*yield*/, Promise.all((input.incomeExpenses || []).filter(function (ie) { return ie.id; }).map(function (ie) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, apolloClient.query({
                                    query: UPDATE_INCOME_EXPENSES,
                                    variables: {
                                        id: ie.id,
                                        input: __assign({}, ie),
                                    },
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/, null];
                        }
                    });
                }); }))];
            case 4:
                _e.sent();
                return [2 /*return*/, +input.income.id];
            case 5: return [4 /*yield*/, apolloClient.query({
                    query: ADD_INCOME_APP,
                    variables: {
                        input: __assign(__assign({}, input.income), { otherIncome: JSON.stringify(input.income.otherIncome), tblTenantsUsingId: {
                                create: input.tenants.map(function (_a) {
                                    var expenses = _a.expenses, t = __rest(_a, ["expenses"]);
                                    return (__assign(__assign({}, t), { tblTenantExpensesUsingId: {
                                            create: expenses,
                                        } }));
                                })
                            } })
                    }
                })];
            case 6:
                data = (_e.sent()).data;
                return [2 /*return*/, (_d = (_c = data === null || data === void 0 ? void 0 : data.createTblIncome) === null || _c === void 0 ? void 0 : _c.tblIncome) === null || _d === void 0 ? void 0 : _d.id];
        }
    });
}); }; };
export var updateValuation = function (dcaData, id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: UPDATE_VALUATION,
                    variables: {
                        id: id,
                        input: {
                            totalValueEstimate: dcaData.finalRate.salePrice,
                            initialValue: dcaData.finalRate.salePrice,
                        },
                    },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var upsertDca = function (dcaData, partId, valuationId, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!dcaData)
                    return [2 /*return*/];
                return [4 /*yield*/, updateValuation(dcaData, valuationId, apolloClient)];
            case 1:
                _b.sent();
                if (!!dcaData.id) return [3 /*break*/, 3];
                return [4 /*yield*/, apolloClient.query({
                        query: ADD_DCA,
                        variables: {
                            input: {
                                partId: partId,
                                result: JSON.stringify(dcaData),
                                unit: dcaData.unit,
                            },
                        }
                    })];
            case 2:
                data = (_b.sent()).data;
                return [2 /*return*/, (_a = data === null || data === void 0 ? void 0 : data.createTblDca) === null || _a === void 0 ? void 0 : _a.id];
            case 3: return [4 /*yield*/, apolloClient.query({
                    query: UPDATE_DCA,
                    variables: {
                        id: dcaData.id,
                        input: {
                            partId: partId,
                            result: JSON.stringify(dcaData),
                            unit: dcaData.unit,
                        },
                    }
                })];
            case 4:
                _b.sent();
                return [2 /*return*/, null];
        }
    });
}); };
export var getSiteDetailsFromJob = function (sites) {
    var result = {};
    sites === null || sites === void 0 ? void 0 : sites.forEach(function (site) {
        var _a;
        (_a = site === null || site === void 0 ? void 0 : site.data) === null || _a === void 0 ? void 0 : _a.forEach(function (row) {
            var _a;
            if (JOB_SECTION_FIELDS_ARRAY.includes(row.fieldId) && row.value) {
                result = __assign(__assign({}, result), (_a = {}, _a[row.fieldId] = +row.value, _a));
            }
        });
    });
    return result;
};
export var getSiteDetails = function (jobId, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: SHOW_FIELDS_IN_DETAILS,
                    variables: {
                        jobId: jobId,
                    }
                })];
            case 1:
                data = (_c.sent()).data;
                return [2 /*return*/, (_b = (_a = data === null || data === void 0 ? void 0 : data.allTblSectionFieldHeaders) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map(function (row) {
                        var _a, _b;
                        return ({
                            section: +row.sectionId === PROPERTY_SECTION.SITE ? 'Site' : 'Building',
                            data: (_b = (_a = row === null || row === void 0 ? void 0 : row.tblSectionFieldValuesByHeaderId) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map(function (value) {
                                var _a, _b, _c, _d;
                                return ({
                                    value: value.value,
                                    title: ((_a = value === null || value === void 0 ? void 0 : value.tblSectionFieldByFieldSectionId) === null || _a === void 0 ? void 0 : _a.customTitle) || ((_c = (_b = value === null || value === void 0 ? void 0 : value.tblSectionFieldByFieldSectionId) === null || _b === void 0 ? void 0 : _b.tblFieldByFieldId) === null || _c === void 0 ? void 0 : _c.title),
                                    fieldId: +((_d = value === null || value === void 0 ? void 0 : value.tblSectionFieldByFieldSectionId) === null || _d === void 0 ? void 0 : _d.fieldId),
                                });
                            })
                        });
                    })];
        }
    });
}); };
export var prepareDcaForUpsert = function (dcaData) {
    if (!dcaData)
        return {};
    if (!dcaData.id)
        return {
            tblDcasUsingId: {
                create: {
                    result: JSON.stringify(dcaData),
                    unit: dcaData === null || dcaData === void 0 ? void 0 : dcaData.unit,
                }
            }
        };
    return {
        tblDcasUsingId: {
            updateById: {
                id: dcaData.id,
                tblDcaPatch: {
                    result: JSON.stringify(dcaData),
                    unit: dcaData === null || dcaData === void 0 ? void 0 : dcaData.unit,
                }
            }
        }
    };
};
export var prepareIncomeApp = function (input) {
    var _a, _b;
    if (!input)
        return {};
    if ((_a = input.income) === null || _a === void 0 ? void 0 : _a.id) {
        return {
            tblIncomesUsingId: {
                updateById: {
                    id: input.income.id,
                    tblIncomePatch: __assign(__assign({}, input.income), { otherIncome: JSON.stringify(input.income.otherIncome), tblTenantsUsingId: {
                            create: input.tenants.filter(function (t) { return !t.id; }).map(function (_a) {
                                var expenses = _a.expenses, t = __rest(_a, ["expenses"]);
                                return (__assign(__assign({}, t), { expenses: JSON.stringify(expenses) }));
                            }),
                            updateById: input.tenants.filter(function (t) { return t.id; }).map(function (_a) {
                                var expenses = _a.expenses, t = __rest(_a, ["expenses"]);
                                return ({
                                    id: t.id,
                                    tblTenantPatch: __assign(__assign({}, t), { expenses: JSON.stringify(expenses) }),
                                });
                            }),
                        } })
                }
            }
        };
    }
    return {
        tblIncomesUsingId: {
            create: __assign(__assign({}, input.income), { otherIncome: JSON.stringify(input.income.otherIncome), tblTenantsUsingId: {
                    create: input.tenants.map(function (_a) {
                        var expenses = _a.expenses, t = __rest(_a, ["expenses"]);
                        return (__assign(__assign({}, t), { expenses: JSON.stringify(expenses) }));
                    })
                }, tblIncomeExpensesUsingId: {
                    create: (_b = input.incomeExpenses) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                        var id = _a.id, t = __rest(_a, ["id"]);
                        return (__assign({}, t));
                    }),
                } }),
        },
    };
};
export var prepareCosts = function (data) {
    if (!(data === null || data === void 0 ? void 0 : data.length))
        return {};
    return {
        tblCostsUsingId: {
            create: {
                costs: JSON.stringify(data),
                fees: null,
                total: getTotalCosts(data),
            }
        },
    };
};
export var upsertValuation = function (formData, apolloClient, valAddress) { return __awaiter(void 0, void 0, void 0, function () {
    var header, details, data;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                header = __assign({}, formData);
                delete header.tblValuationPartsByHeaderId;
                details = ((_a = formData === null || formData === void 0 ? void 0 : formData.tblValuationPartsByHeaderId) === null || _a === void 0 ? void 0 : _a.nodes) || [];
                if (valAddress) {
                    header = __assign(__assign({}, header), { city: valAddress.city, streetNo: valAddress.streetNo, streetName: valAddress.streetName, postalCode: valAddress.postalCode, zzFullAddress: valAddress.zzFullAddress, province: valAddress.province, unit: valAddress.unit, county: valAddress.county, lat: valAddress.lat, countryName: valAddress.countryName, lng: valAddress.lng });
                }
                if (!!formData.id) return [3 /*break*/, 2];
                if (details.length) {
                    header = __assign(__assign({}, header), { tblValuationPartsUsingId: {
                            create: details.map(function (_a) {
                                var costResult = _a.costResult, income = _a.income, dcaData = _a.dcaData, item = __rest(_a, ["costResult", "income", "dcaData"]);
                                return (__assign(__assign(__assign(__assign({}, item), prepareDcaForUpsert(dcaData)), prepareIncomeApp(income)), prepareCosts(costResult)));
                            }),
                        } });
                }
                return [4 /*yield*/, apolloClient.query({
                        query: ADD_VALUATION,
                        variables: {
                            input: header,
                        },
                    })];
            case 1:
                data = (_d.sent()).data;
                return [2 /*return*/, (_c = (_b = data === null || data === void 0 ? void 0 : data.createTblValuation) === null || _b === void 0 ? void 0 : _b.tblValuation) === null || _c === void 0 ? void 0 : _c.id];
            case 2:
                if (details.length) {
                    if (details.some(function (d) { return !d.id; })) {
                        header = __assign(__assign({}, header), { tblValuationPartsUsingId: {
                                create: details.filter(function (d) { return !d.id; }).map(function (_a) {
                                    var costResult = _a.costResult, income = _a.income, dcaData = _a.dcaData, item = __rest(_a, ["costResult", "income", "dcaData"]);
                                    return (__assign(__assign(__assign(__assign({}, item), prepareDcaForUpsert(dcaData)), prepareIncomeApp(income)), prepareCosts(costResult)));
                                }),
                            } });
                    }
                    if (details.some(function (d) { return d.id; })) {
                        header = __assign(__assign({}, header), { tblValuationPartsUsingId: __assign(__assign({}, (header === null || header === void 0 ? void 0 : header.tblValuationPartsUsingId) || {}), { updateById: details.filter(function (d) { return d.id; }).map(function (_a) {
                                    var costResult = _a.costResult, income = _a.income, dcaData = _a.dcaData, item = __rest(_a, ["costResult", "income", "dcaData"]);
                                    return ({
                                        id: item.id,
                                        tblValuationPartPatch: __assign(__assign({}, item), prepareDcaForUpsert(dcaData)),
                                    });
                                }) }) });
                    }
                }
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_VALUATION,
                        variables: {
                            id: formData.id,
                            input: header,
                        },
                    })];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4: return [2 /*return*/, formData.id];
        }
    });
}); };
export var calculateAdjustment = function (dcaRow, saleValue, sale, defaultAdjusted) {
    var _a;
    var saleRate = 0;
    if (+dcaRow.id > 4 && (defaultAdjusted === null || defaultAdjusted === void 0 ? void 0 : defaultAdjusted.length)) {
        saleRate = ((_a = defaultAdjusted.find(function (a) { return +a.id === +sale.id; })) === null || _a === void 0 ? void 0 : _a.value) || sale.saleRate;
    }
    else {
        saleRate = sale.saleRate;
    }
    switch (dcaRow.type) {
        case "%": return parseFloat(((dcaRow.value - saleValue) * (dcaRow.parameter / 100) * saleRate).toFixed(2));
        case "$": return parseFloat(((dcaRow.value - saleValue) * (dcaRow.parameter)).toFixed(2));
        case DOLLAR_PER_UNIT: return parseFloat((((dcaRow.value - saleValue) * (dcaRow.parameter)) / sale.saleUnit).toFixed(2));
        case COVERAGE: return formatAsDecimalPlaces(((sale.saleUnit / dcaRow.value - sale.siteArea) * dcaRow.parameter) / sale.saleUnit);
    }
};
var caclDefaultAdjustedNetPercentage = function (saleId, dca, values, sales) {
    var _a;
    var value = sumBy(values, function (i) { return +(i.adjustedValueText || '0'); }) || 0;
    var saleRate = ((_a = sales.find(function (s) { return +s.id === +saleId; })) === null || _a === void 0 ? void 0 : _a.saleRate) || 0;
    var def = flatten(dca.filter(function (o) { return +o.id > 4; }).map(function (o) { return o.sales.filter(function (s) { return +(s.id || '0') === +saleId; }); }));
    var sumOther = sumBy(def, function (i) { return +(i.adjustedValueText || '0'); }) || 0;
    return value + ((value * saleRate * sumOther) / saleRate) / 100;
};
var caclDefaultAdjustedAdjustedRate = function (saleId, dca, values, sales) {
    var _a;
    var percentage = caclDefaultAdjustedNetPercentage(saleId, dca, values, sales);
    var saleRate = ((_a = sales.find(function (s) { return +s.id === +saleId; })) === null || _a === void 0 ? void 0 : _a.saleRate) || 0;
    return parseFloat((saleRate * (percentage / 100)).toFixed(2));
};
export var calcDefaultAdjustmentValues = function (dca, sales, defaultAdjusted, methodology) {
    var total = flatten(dca.map(function (o) { return o.sales; }));
    var totals = Object.entries(groupBy(total, 'id')).map(function (_a) {
        var key = _a[0], value = _a[1];
        return ({
            id: +key,
            value: sumBy(value, 'adjustedValue'),
        });
    });
    var def = flatten(dca.filter(function (o) { return +o.id <= 4; }).map(function (o) { return o.sales; }));
    return Object.entries(groupBy(def, 'id')).map(function (_a) {
        var _b, _c, _d, _e, _f, _g, _h;
        var key = _a[0], value = _a[1];
        return ({
            id: +key,
            value: methodology === ADJUSTMENT_METHODOLOGY.PERCENTAGE ? sumBy(value, function (i) { return +(i.adjustedValueText || '0'); }) : parseFloat((sumBy(value, 'adjustedValue') + ((_b = sales.find(function (s) { return +s.id === +key; })) === null || _b === void 0 ? void 0 : _b.saleRate) || 0).toFixed(2)),
            net: methodology === ADJUSTMENT_METHODOLOGY.PERCENTAGE ? caclDefaultAdjustedNetPercentage(key, dca, value, sales) : parseFloat((((_c = totals.find(function (t) { return +t.id === +key; })) === null || _c === void 0 ? void 0 : _c.value) || 0).toFixed(2)),
            netPer: parseFloat((((((_d = totals.find(function (t) { return +t.id === +key; })) === null || _d === void 0 ? void 0 : _d.value) || 0) / ((_e = sales.find(function (s) { return +s.id === +key; })) === null || _e === void 0 ? void 0 : _e.saleRate)) * 100).toFixed(2)),
            final: methodology === ADJUSTMENT_METHODOLOGY.PERCENTAGE ? caclDefaultAdjustedAdjustedRate(key, dca, value, sales) : parseFloat((((_f = totals.find(function (t) { return +t.id === +key; })) === null || _f === void 0 ? void 0 : _f.value) + ((_g = sales.find(function (s) { return +s.id === +key; })) === null || _g === void 0 ? void 0 : _g.saleRate) || 0).toFixed(2)),
            finalText: ((_h = defaultAdjusted === null || defaultAdjusted === void 0 ? void 0 : defaultAdjusted.find(function (d) { return +d.id === +key; })) === null || _h === void 0 ? void 0 : _h.finalText) || '',
        });
    });
};
export var calcFinalRates = function (dca, sales, subject, defaultAdjusted, methodology) {
    var data = calcDefaultAdjustmentValues(dca, sales, defaultAdjusted, methodology).map(function (c) { return c.final; });
    var saleData = sales.map(function (s) { return s.salePrice; });
    var saleRates = sales.map(function (s) { return s.saleRate; });
    var result = {
        min: formatAsDecimalPlaces(min(data)),
        max: formatAsDecimalPlaces(max(data)),
        mean: formatAsDecimalPlaces(median(data)),
        minSale: formatAsDecimalPlaces(min(saleData)),
        maxSale: formatAsDecimalPlaces(max(saleData)),
        meanSale: formatAsDecimalPlaces(median(saleData)),
        stdevSale: formatAsDecimalPlaces(stdev(saleData), 0),
        stdev: formatAsDecimalPlaces(stdev(data)),
        minSaleRate: formatAsDecimalPlaces(min(saleRates)),
        maxSaleRate: formatAsDecimalPlaces(max(saleRates)),
        meanSaleRate: formatAsDecimalPlaces(median(saleRates)),
        stdevSaleRate: formatAsDecimalPlaces(stdev(saleRates)),
    };
    return __assign(__assign({}, result), { diff: formatAsDecimalPlaces(result.max - result.min), saleDiff: formatAsDecimalPlaces(result.maxSale - result.minSale), saleRateDiff: formatAsDecimalPlaces(result.maxSaleRate - result.minSaleRate), minEst: formatAsDecimalPlaces(result.min * ((subject === null || subject === void 0 ? void 0 : subject.saleUnit) || 0), 0), maxEst: formatAsDecimalPlaces(result.max * ((subject === null || subject === void 0 ? void 0 : subject.saleUnit) || 0), 0), meanEst: formatAsDecimalPlaces(result.mean * ((subject === null || subject === void 0 ? void 0 : subject.saleUnit) || 0), 0), diffEst: formatAsDecimalPlaces((result.max - result.min) * ((subject === null || subject === void 0 ? void 0 : subject.saleUnit) || 0), 0), stdevEst: formatAsDecimalPlaces(result.stdev * ((subject === null || subject === void 0 ? void 0 : subject.saleUnit) || 0), 0), salePrice: formatAsDecimalPlaces(result.mean * subject.saleUnit, 0), saleUnit: subject.saleUnit });
};
export var calculateAdjustedValuesOnChanges = function (methodology, dca, sales, defaultAdjusted, subject) {
    var output = [];
    (dca || []).forEach(function (dcaRow) {
        var tempDca = __assign(__assign({}, dcaRow), { sales: [] });
        (sales || []).forEach(function (sale) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var value = +dcaRow.id === 1 ? convertDateToYear(sale.saleDate) : ((_b = (_a = dcaRow.sales) === null || _a === void 0 ? void 0 : _a.find(function (s) { return +s.id === +sale.id; })) === null || _b === void 0 ? void 0 : _b.value) || 0;
            var defDescription = (_d = (_c = dcaRow.sales) === null || _c === void 0 ? void 0 : _c.find(function (s) { return +s.id === +sale.id; })) === null || _d === void 0 ? void 0 : _d.description;
            var _sale = {
                description: defDescription ? defDescription : +dcaRow.id === 1 ? sale.saleDate : defDescription,
                id: +sale.id,
                value: value,
                adjustedValue: calculateAdjustment(dcaRow, value, sale, defaultAdjusted || []),
                comment: ((_f = (_e = dcaRow.sales) === null || _e === void 0 ? void 0 : _e.find(function (s) { return +s.id === +sale.id; })) === null || _f === void 0 ? void 0 : _f.comment) || '',
                adjustedValueText: ((_h = (_g = dcaRow.sales) === null || _g === void 0 ? void 0 : _g.find(function (s) { return +s.id === +sale.id; })) === null || _h === void 0 ? void 0 : _h.adjustedValueText) || '',
            };
            tempDca.sales.push(_sale);
        });
        output.push(tempDca);
    });
    return {
        output: output,
        systemAdjusted: calcDefaultAdjustmentValues(output, sales || [], defaultAdjusted, methodology),
        finalRate: calcFinalRates(output, sales || [], subject, defaultAdjusted, methodology),
    };
};
export var updateDcaSelection = function (adjustment, props, dca, setDca, subject, sales, sale) {
    if (sale === void 0) { sale = null; }
    return function (e) {
        var _a;
        if (sale) {
            setDca(dca.reduce(function (result, curr) {
                var _a;
                var _b, _c, _d, _e;
                if (+curr.id === +adjustment.id) {
                    result.push(__assign(__assign({}, curr), { sales: !((_b = curr.sales) === null || _b === void 0 ? void 0 : _b.find(function (s) { return +s.id === +sale.id; })) ? (curr.sales || []).concat((_a = { id: +sale.id }, _a[props] = ((_c = e.target) === null || _c === void 0 ? void 0 : _c.type) === "number" ? +((_d = e.target) === null || _d === void 0 ? void 0 : _d.value) : (_e = e.target) === null || _e === void 0 ? void 0 : _e.value, _a)) :
                            curr.sales.reduce(function (result2, curr2) {
                                var _a;
                                var _b, _c, _d;
                                if (+curr2.id === +sale.id) {
                                    result2.push(__assign(__assign({}, curr2), (_a = {}, _a[props] = ((_b = e.target) === null || _b === void 0 ? void 0 : _b.type) === "number" ? +((_c = e.target) === null || _c === void 0 ? void 0 : _c.value) : (_d = e.target) === null || _d === void 0 ? void 0 : _d.value, _a)));
                                }
                                else {
                                    result2.push(curr2);
                                }
                                return result2;
                            }, []) }));
                }
                else {
                    result.push(curr);
                }
                return result;
            }, []));
        }
        else {
            var defaultVals_1 = {};
            if (props === 'type' && ((_a = e.target) === null || _a === void 0 ? void 0 : _a.value) === COVERAGE) {
                defaultVals_1 = {
                    description: ((subject.buildingAreaSf / subject.siteAreaSf) * 100).toFixed(2) + "%",
                    value: formatAsDecimalPlaces(subject.buildingAreaSf / subject.siteAreaSf),
                    sales: sales.map(function (s) { return ({
                        description: ((s.buildingArea / s.siteArea) * 100).toFixed(2) + "%",
                        value: formatAsDecimalPlaces(s.buildingArea / s.siteArea),
                        id: +s.id,
                    }); }),
                };
            }
            setDca(dca.reduce(function (result, curr) {
                var _a;
                var _b, _c, _d;
                if (+curr.id === +adjustment.id) {
                    result.push(__assign(__assign(__assign({}, curr), (_a = {}, _a[props] = ((_b = e.target) === null || _b === void 0 ? void 0 : _b.type) === "number" ? +((_c = e.target) === null || _c === void 0 ? void 0 : _c.value) : (_d = e.target) === null || _d === void 0 ? void 0 : _d.value, _a)), defaultVals_1));
                }
                else {
                    result.push(curr);
                }
                return result;
            }, []));
        }
    };
};
export var adjustmentItemChanged = function (id, dca, setDca, subject) { return function (e) {
    var newId = +e.target.value;
    var extra = {};
    switch (newId) {
        case ADJUSTMENT_ITEMS.BUILDING_SF: {
            extra = {
                type: DOLLAR_PER_UNIT,
                value: parseFloat(subject.buildingAreaSf),
            };
            break;
        }
        case ADJUSTMENT_ITEMS.SITE_SF: {
            extra = {
                type: DOLLAR_PER_UNIT,
                value: parseFloat(subject.siteAreaSf),
            };
            break;
        }
        case ADJUSTMENT_ITEMS.SITE_AC: {
            extra = {
                type: DOLLAR_PER_UNIT,
                value: parseFloat(subject.siteAreaAc || sqFeetToAcres(subject.siteAreaSf)),
            };
            break;
        }
        case ADJUSTMENT_ITEMS.BUILDABLE_SF: {
            extra = {
                type: DOLLAR_PER_UNIT,
                value: parseFloat(subject.siteBuildableSf || 0),
            };
            break;
        }
    }
    setDca(dca.reduce(function (result, curr) {
        if (+curr.id === +id) {
            result.push(__assign(__assign(__assign({}, curr), extra), { id: newId }));
        }
        else {
            result.push(curr);
        }
        return result;
    }, []));
}; };
export var calcSaleUnitParams = function (unit, sale) {
    var saleUnit = 0;
    switch (unit) {
        case ADJUSTMENT_ITEMS.BUILDING_SF: {
            saleUnit = (sale === null || sale === void 0 ? void 0 : sale.buildingArea) || (sale === null || sale === void 0 ? void 0 : sale.buildingAreaSf) || 0;
            break;
        }
        case ADJUSTMENT_ITEMS.SITE_SF: {
            saleUnit = (sale === null || sale === void 0 ? void 0 : sale.siteArea) || (sale === null || sale === void 0 ? void 0 : sale.siteAreaSf) || 0;
            break;
        }
        case ADJUSTMENT_ITEMS.SITE_AC: {
            saleUnit = parseFloat((sale === null || sale === void 0 ? void 0 : sale.siteAreaAc) || sqFeetToAcres((sale === null || sale === void 0 ? void 0 : sale.siteArea) || (sale === null || sale === void 0 ? void 0 : sale.siteAreaSf)));
            break;
        }
        case ADJUSTMENT_ITEMS.BUILDABLE_SF: {
            saleUnit = (sale === null || sale === void 0 ? void 0 : sale.buildableSf) || (sale === null || sale === void 0 ? void 0 : sale.siteBuildableSf) || 0;
            break;
        }
        //default: saleUnit = sale?.buildingArea || sale?.buildingAreaSf || 0;
        default: saleUnit = (sale === null || sale === void 0 ? void 0 : sale.saleUnit) || 0;
    }
    return {
        unitOfComp: unit,
        saleUnit: saleUnit,
        saleRate: formatAsDecimalPlaces(((sale === null || sale === void 0 ? void 0 : sale.salePrice) || 0) / saleUnit),
    };
};
export var getTotalCosts = function (data) { var _a; return !(data === null || data === void 0 ? void 0 : data.length) ? 0 : (sumBy(flatten(data.map(function (d) { return d.items; })), 'depCost') + (sumBy(((_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.fees) || [], 'fee') || 0)); };
