var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { ColumnType, DRAWERCOMPONENT } from '@enums';
export var GET_STATEMENTS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation spStatement($id: BigInt) {\n  spStatement(input: { appid: $id }) {\n    results {\n      endDate\n      id\n      jobs\n      startDate\n      total\n    }\n  }\n}\n"], ["mutation spStatement($id: BigInt) {\n  spStatement(input: { appid: $id }) {\n    results {\n      endDate\n      id\n      jobs\n      startDate\n      total\n    }\n  }\n}\n"])));
export var GET_PAYROLL_HISTORY = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery allTblPayrolls($start: Date, $end: Date, $userId: BigInt) {\n    allTblPayrolls(\n      condition: { endDate: $end, userId: $userId, startDate: $start }\n    ) {\n      nodes {\n        jobId\n        id\n        payment\n        expense\n        endDate\n        startDate\n        billed\n        revenue\n        commission\n        feeType\n        totalJobExpenses\n        hst\n        tblJobByJobId {\n          city\n          county\n          countryName\n          fileNumber\n          jobNumber\n          unit\n          streetNo\n          streetName\n          province\n          zzFullAddress\n          tblClientByClientId {\n            name\n          }\n        }\n      }\n    }\n  }\n  "], ["\nquery allTblPayrolls($start: Date, $end: Date, $userId: BigInt) {\n    allTblPayrolls(\n      condition: { endDate: $end, userId: $userId, startDate: $start }\n    ) {\n      nodes {\n        jobId\n        id\n        payment\n        expense\n        endDate\n        startDate\n        billed\n        revenue\n        commission\n        feeType\n        totalJobExpenses\n        hst\n        tblJobByJobId {\n          city\n          county\n          countryName\n          fileNumber\n          jobNumber\n          unit\n          streetNo\n          streetName\n          province\n          zzFullAddress\n          tblClientByClientId {\n            name\n          }\n        }\n      }\n    }\n  }\n  "])));
export var ADD_PAYROLL = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmutation createTblPayroll($input: TblPayrollInput!) {\n    createTblPayroll(input: { tblPayroll: $input }) {\n      clientMutationId\n    }\n  }\n  "], ["\nmutation createTblPayroll($input: TblPayrollInput!) {\n    createTblPayroll(input: { tblPayroll: $input }) {\n      clientMutationId\n    }\n  }\n  "])));
export var GET_APPRAISER_PAYROLLS = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation spPayrollList($appId: BigInt) {\n    spPayrollList(input: { appid: $appId }) {\n      results {\n        enddate\n        startdate\n      }\n    }\n  }\n  "], ["\n  mutation spPayrollList($appId: BigInt) {\n    spPayrollList(input: { appid: $appId }) {\n      results {\n        enddate\n        startdate\n      }\n    }\n  }\n  "])));
export var GET_PAID = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["query getPaid($id: BigInt, $start: Date, $end: Date) {\n    allTblPayrolls(\n      filter: {\n        userId: { equalTo: $id }\n        and: {\n          startDate: { greaterThanOrEqualTo: $start }\n          and: { startDate: { lessThanOrEqualTo: $end } }\n        }\n      }\n    ) {\n      nodes {\n        payment\n      }\n    }\n  }\n  "], ["query getPaid($id: BigInt, $start: Date, $end: Date) {\n    allTblPayrolls(\n      filter: {\n        userId: { equalTo: $id }\n        and: {\n          startDate: { greaterThanOrEqualTo: $start }\n          and: { startDate: { lessThanOrEqualTo: $end } }\n        }\n      }\n    ) {\n      nodes {\n        payment\n      }\n    }\n  }\n  "])));
export var GET_ALL_PAYROLLS = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["mutation allPayrolls($id: BigInt, $start: Date, $end: Date, $alldata: Boolean) {\n  spPayroll(\n    input: { appid: $id, enddate: $end, startdate: $start, alldata: $alldata }\n  ) {\n    results {\n      address\n      appfee\n      appportion\n      expportion\n      appraiser\n      clientid\n      clientname\n      feetype\n      filenumber\n      jobid\n      jobnumber\n      paidexpenses\n      payments\n      propertysubtype\n      propertytype\n      propertytypetitle\n      saleprice\n      statusid\n      statusname\n      totalexpenses\n      appid\n      totalJobExpenses\n      hst\n      zzcreateddate\n      paymentdate\n    }\n  }\n}"], ["mutation allPayrolls($id: BigInt, $start: Date, $end: Date, $alldata: Boolean) {\n  spPayroll(\n    input: { appid: $id, enddate: $end, startdate: $start, alldata: $alldata }\n  ) {\n    results {\n      address\n      appfee\n      appportion\n      expportion\n      appraiser\n      clientid\n      clientname\n      feetype\n      filenumber\n      jobid\n      jobnumber\n      paidexpenses\n      payments\n      propertysubtype\n      propertytype\n      propertytypetitle\n      saleprice\n      statusid\n      statusname\n      totalexpenses\n      appid\n      totalJobExpenses\n      hst\n      zzcreateddate\n      paymentdate\n    }\n  }\n}"])));
export var Columns = [
    {
        caption: "ID",
        fieldName: "jobid",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Address",
        fieldName: "address",
        sortField: "",
        linkTo: DRAWERCOMPONENT.JOB_VIEW,
        openInDrawer: true,
        linkFieldId: 'jobid',
    },
    {
        caption: "Client",
        fieldName: "clientname",
        sortField: "",
    },
    {
        caption: "File#",
        fieldName: "filenumber",
        sortField: "filenumber",
        sortable: true,
        filterable: false,
        upperCase: true,
        companyFormat: true,
        columnFilter: true,
        columnFilterField: 'filenumber',
    },
    {
        caption: "Reference#",
        fieldName: "jobnumber",
        sortField: "jobnumber",
        sortable: true,
        filterable: false,
        upperCase: true,
        columnFilter: true,
        columnFilterField: 'jobnumber',
    },
    {
        caption: "Report Fee",
        fieldName: "saleprice",
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
        formula: "(([saleprice]/(1+[hst]))-[totalJobExpenses])*(1+[hst])",
    },
    {
        caption: "Comission",
        fieldName: "appfee|feetype",
        sortable: true,
        filterable: false,
        upperCase: true,
        columnType: ColumnType.NUMBER,
    },
    {
        caption: "Revenue",
        fieldName: "payments",
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Job Expenses",
        fieldName: "totalJobExpenses",
        sortable: true,
        filterable: false,
        upperCase: true,
        columnType: ColumnType.CURRENCY,
    },
    {
        caption: "Override Expense($)",
        fieldName: "expportion",
        columnType: ColumnType.CURRENCY,
        editable: true,
        totalInFooter: true,
    },
    {
        caption: "Sub Total",
        fieldName: "subtotal",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
        editable: true,
        formula: "[appportion]+[expportion]",
    },
    {
        caption: "HST(__TAX__%)",
        fieldName: "hstportion",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
        formula: "([appportion]+[expportion])*[hst]",
    },
    {
        caption: "Total",
        fieldName: "total",
        columnType: ColumnType.CURRENCY,
        formula: "([appportion]+[expportion])*(1+[hst])",
        totalInFooter: true,
    },
];
export var STATEMENT_COLUMNS = [
    {
        caption: "ID",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Start Date",
        fieldName: "startDate",
        sortable: true,
        columnType: ColumnType.DATE_ONLY,
    },
    {
        caption: "End Date",
        fieldName: "endDate",
        sortable: true,
        columnType: ColumnType.DATE_ONLY,
    },
    {
        caption: "Total Jobs",
        fieldName: "jobs",
        sortable: true,
        columnType: ColumnType.NUMBER,
        // linkTo: DRAWERCOMPONENT.PAYROLL,
        // openInDrawer: true,
        // linkFieldId: 'startDate|endDate',
    },
    {
        caption: "Total",
        fieldName: "total",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
];
export var HistoryColumns = [
    {
        caption: "ID",
        fieldName: "jobid",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Address",
        fieldName: "address",
        sortField: "",
        linkTo: DRAWERCOMPONENT.JOB_VIEW,
        openInDrawer: true,
        linkFieldId: 'jobid',
    },
    {
        caption: "Client",
        fieldName: "clientname",
        sortField: "",
    },
    {
        caption: "File#",
        fieldName: "filenumber",
        sortField: "filenumber",
        sortable: true,
        filterable: false,
        upperCase: true,
        companyFormat: true,
        columnFilter: true,
        columnFilterField: 'filenumber',
    },
    {
        caption: "Reference#",
        fieldName: "jobnumber",
        sortField: "jobnumber",
        sortable: true,
        filterable: false,
        upperCase: true,
        columnFilter: true,
        columnFilterField: 'jobnumber',
    },
    {
        caption: "Report Fee",
        fieldName: "billed",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Commission",
        fieldName: "appFee",
        sortable: true,
    },
    {
        caption: "Revenue(+Tax)",
        fieldName: "revenue",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Total Expense",
        fieldName: "totalJobExpenses",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Expenses Received",
        fieldName: "expense",
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Sub Total",
        fieldName: "subtotal",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "HST(__TAX__%)",
        fieldName: "hst",
        sortable: true,
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Total",
        fieldName: "payment",
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
