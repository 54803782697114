import React from "react";
import { Dashboard } from "./components/dashboard/Dashboard.component";
import { ApparaisalList } from "./components/appraisals/list/Appraisal-list.component";
import { JobComponent } from "./components/appraisals/edit/Edit.component";
import { Clients } from "./components/clients/Client.component";
import { Appraiser } from "./components/appraisers/Appraiser.component";
import { ViewComponent as ApparaisalView } from "./components/appraisals/view/View.component";
import { ClientEdit } from "./components/clients/edit/Edit.component";
import { EditAppraiser } from "./components/appraisers/edit/Edit.component";
import { UserEdit } from "./components/users/edit/admin-user-edit.component";
import { Company } from "./components/settings/Company.component";
import { Income } from "./components/accounting/Income";
import { OrderList, OrderDetail } from "./components/orders";
import { PayrollStatement } from "./components/accounting/statements";
import { ClientFee } from "./components/settings/Client-Fee.component";
import { Payments } from "./components/invoices/Payments.component";
import { UserProfile } from "./components/users/Profile.component";
import { AppraisalInMap } from "./components/maps/map-appraisal";
import { BatchPayment } from "./components/payments/batchPayment";
import { MyCalendar } from "./components/calendar";
export var JOB_VIEW_URL = '/admin/appraisals/all/view/{id}';
export var JOB_LIST = '/admin/appraisals/all';
export var DASHBOARD = '/admin/dashboard';
export var ORDER_LIST = '/admin/orders';
export var ORDER_DETAIL = '/admin/orders/detail/{id}';
export var JOB_LIST_PROGRESS = '/admin/appraisals/progress';
export var SALES_DETAILS = '/admin/sales/detail';
export var SALES_IMPORT = '/admin/SALES/bulkimport';
export var JOBS_IMPORT = '/admin/JOBS/bulkimport';
export var SALES_LIST = '/admin/sales';
export var VALUATION = '/admin/valuations/job/{id}';
export var VALUATIONLIST = '/admin/valuations';
export var VALUATIONEDIT = '/admin/valuations/detail/{id}';
export var CLIENT_JOB_LIST = '/public/jobs/progress';
var SaleList = React.lazy(function () { return import("./modules/sales/SaleList.component"); });
var SaleDetails = React.lazy(function () { return import("./modules/sales/SaleDetails.component"); });
var Users = React.lazy(function () { return import("./components/users/admin-user.component"); });
var Companies = React.lazy(function () { return import("./components/users/Company.component"); });
var BulkSaleImport = React.lazy(function () { return import("./modules/template-import/BulkSaleImport.component"); });
var AddValuation = React.lazy(function () { return import("./modules/evaluation/addValuation"); });
var ValuationList = React.lazy(function () { return import("./modules/evaluation/valuationList"); });
var AddonsList = React.lazy(function () { return import("./modules/addons/addonList"); });
var ImportCustomers = React.lazy(function () { return import("./modules/addons/importCustomers"); });
var ProfileSubscription = React.lazy(function () { return import("./modules/subscription/profile-subscription"); });
var Billing = React.lazy(function () { return import("./modules/subscription/billing"); });
export var routes = [
    {
        path: "/valuations/job/:jobId?",
        name: "",
        icon: "",
        component: AddValuation,
        layout: "/admin",
        hidden: true,
        id: 'VALUATION',
    },
    {
        path: "/valuations/detail/:id?",
        name: "",
        icon: "",
        component: AddValuation,
        layout: "/admin",
        hidden: true,
        id: 'VALUATION',
    },
    {
        path: "/:sales/bulkimport",
        name: "",
        icon: "",
        component: BulkSaleImport,
        layout: "/admin",
        hidden: true,
        id: 'SALESIMPORT',
    },
    {
        path: "/users",
        name: "Users",
        icon: "fa fa-user-circle",
        component: Users,
        layout: "/admin",
        id: 'Users',
    },
    {
        path: "/users/edit/:id?",
        name: "Users",
        icon: "fa fa-user-circle",
        component: UserEdit,
        hidden: true,
        layout: "/admin",
        id: 'Users',
    },
    {
        path: "/Companies",
        name: "Companies",
        icon: "fa fa-briefcase",
        component: Companies,
        layout: "/admin",
        id: 'Companies',
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-chart-bar-32",
        component: Dashboard,
        layout: "/admin",
        id: 'Dashboard',
    },
    {
        path: "/appraisals/:action?",
        name: "Jobs",
        component: ApparaisalList,
        icon: "nc-settings-gear-65",
        layout: "/admin",
        id: 'Requests',
        hidden: true,
    },
    {
        path: "/appraisals/progress",
        name: "In Progress",
        component: ApparaisalList,
        icon: "fa fa-spinner",
        layout: "/admin",
        id: 'INPROGRESS',
    },
    {
        path: "/appraisals/pending",
        name: "Pending",
        component: ApparaisalList,
        icon: "fa fa-stop",
        layout: "/admin",
        id: 'PENDINGS',
    },
    {
        path: "/appraisals/completed",
        name: "Completed",
        component: ApparaisalList,
        icon: "fa fa-check-circle",
        layout: "/admin",
        id: 'INPROGRESS',
    },
    {
        path: "/appraisals/cancelled",
        name: "Cancelled",
        component: ApparaisalList,
        icon: "fa fa-ban",
        layout: "/admin",
        id: 'INPROGRESS',
    },
    {
        path: "/appraisals/all",
        name: "Jobs",
        component: ApparaisalList,
        icon: "nc-icon nc-box",
        layout: "/admin",
        id: 'ALLRequests',
    },
    {
        path: "/map",
        name: "Map",
        component: AppraisalInMap,
        icon: "fa fa-map",
        layout: "/admin",
        id: 'MAPVIEW',
        hidden: true,
    },
    {
        path: "/valuations",
        name: "Valuation",
        icon: "nc-icon nc-diamond",
        component: ValuationList,
        layout: "/admin",
        id: 'VALUATIONLIST',
        env: "REACT_APP_VALUATION",
    },
    {
        path: "/quote/pending",
        name: "Pending Quotes",
        component: ApparaisalList,
        icon: "nc-icon nc-time-alarm",
        layout: "/admin",
        id: 'Quotes',
        hidden: true,
    },
    {
        path: "/appraisals/retainer",
        name: "Retainer Required",
        component: ApparaisalList,
        icon: "nc-icon nc-money-coins",
        layout: "/admin",
        id: 'Retainers',
        hidden: true,
    },
    {
        path: "/clients",
        name: "Clients",
        component: Clients,
        icon: "fa fa-briefcase",
        layout: "/admin",
        id: 'Clients',
    },
    {
        path: "/clients/edit",
        name: "",
        component: ClientEdit,
        icon: "fa fa-user-circle",
        layout: "/admin",
        hidden: true,
        id: 'Clients',
    },
    {
        path: "/clients/edit/:id?",
        name: "",
        component: ClientEdit,
        icon: "fa fa-user-circle",
        layout: "/admin",
        hidden: true,
        id: 'Clients',
    },
    {
        path: "/appraisers",
        name: "Appraisers",
        component: Appraiser,
        icon: "fa fa-users",
        layout: "/admin",
        id: 'Appraisers',
    },
    {
        path: "/appraisers/edit",
        name: "Appraisers",
        component: EditAppraiser,
        icon: "fa fa-users",
        layout: "/admin",
        hidden: true,
        id: 'Appraisers',
    },
    {
        path: "/appraisers/edit/:id",
        name: "Appraisers",
        component: EditAppraiser,
        icon: "fa fa-users",
        layout: "/admin",
        hidden: true,
        id: 'Appraisers',
    },
    {
        path: "/payments",
        name: "Accounts Receivable",
        component: Payments,
        icon: "nc-icon nc-money-coins",
        layout: "/admin",
        id: 'PAYMENTS',
    },
    {
        path: "/earning",
        name: "Payroll",
        icon: "nc-icon nc-tile-56",
        component: Income,
        layout: "/admin",
        id: 'ACCOUNTING',
    },
    {
        path: "/batch-payments",
        name: "Batch Payments",
        component: BatchPayment,
        icon: "fa fa-credit-card",
        layout: "/admin",
        id: 'BATCHPAYMENTS',
    },
    {
        path: "/appraisals/all/edit",
        name: "Appraisal",
        component: JobComponent,
        icon: "fa fa-user-circle",
        layout: "/admin",
        hidden: true,
        id: 'Appraisal',
    },
    {
        path: "/appraisals/all/view/:id",
        name: "Appraisal",
        component: ApparaisalView,
        icon: "fa fa-user-circle",
        layout: "/admin",
        hidden: true,
        id: 'Appraisal',
    },
    {
        path: "/appraisals/all/edit/:id",
        name: "Appraisal",
        component: JobComponent,
        icon: "fa fa-user-circle",
        layout: "/admin",
        hidden: true,
        id: 'Appraisal',
    },
    {
        path: "/fees",
        name: "Report Type",
        icon: "nc-icon nc-tag-content",
        component: ClientFee,
        layout: "/admin",
        hidden: true,
        id: 'CLIENTFEES',
    },
    {
        path: "/sales",
        name: "Sales Data",
        icon: "nc-icon nc-bank",
        component: SaleList,
        layout: "/admin",
        id: 'SALES',
        env: "REACT_APP_SALES",
    },
    {
        path: "/sales/detail/:id?",
        name: "",
        icon: "",
        component: SaleDetails,
        layout: "/admin",
        hidden: true,
        id: 'SALEDETAILS',
    },
    {
        path: "/statements",
        name: "Statements",
        icon: "fa fa-credit-card",
        component: PayrollStatement,
        layout: "/admin",
        id: 'STATEMENTS',
    },
    {
        path: "/profile",
        name: "",
        component: UserProfile,
        icon: "",
        layout: "/admin",
        hidden: true,
        id: 'PROFILE',
    },
    {
        path: "/subscription",
        name: "",
        component: ProfileSubscription,
        icon: "",
        layout: "/admin",
        hidden: true,
        id: 'SETTINGS',
    },
    {
        path: "/billing",
        name: "",
        component: Billing,
        icon: "",
        layout: "/admin",
        hidden: true,
        id: 'SETTINGS',
    },
    {
        path: "/jobs/:action?",
        name: "Jobs",
        component: ApparaisalList,
        icon: "",
        layout: "/public",
        id: 'CLIENTJOBS',
        hidden: true,
    },
    {
        path: "/jobs/progress",
        name: "In Progress",
        component: ApparaisalList,
        icon: "fa fa-spinner",
        layout: "/public",
        id: 'CLIENTJOBS',
    },
    {
        path: "/jobs/completed",
        name: "Completed",
        component: ApparaisalList,
        icon: "fa fa-check-circle",
        layout: "/public",
        id: 'CLIENTJOBS',
    },
    {
        path: "/addons",
        name: "ADD-ONS",
        component: AddonsList,
        icon: "fa fa-puzzle-piece",
        layout: "/public",
        id: 'ADDONS',
        env: "REACT_APP_ADDONS",
    },
    {
        path: "/addons/import",
        name: "",
        component: ImportCustomers,
        icon: "fa fa-puzzle-piece",
        layout: "/public",
        hidden: true,
        id: 'ADDONS',
    },
    {
        path: "/calendar",
        name: "",
        component: MyCalendar,
        icon: "",
        layout: "/admin",
        hidden: true,
        id: 'CALENDAR',
    },
    {
        path: "/orders",
        name: "Request for Quote",
        component: OrderList,
        icon: "nc-icon nc-box",
        layout: "/admin",
        id: 'ORDERS',
    },
    {
        path: "/orders/detail/:orderId?",
        name: "",
        component: OrderDetail,
        icon: "",
        layout: "/admin",
        id: 'ORDERS',
        hidden: true,
    },
    {
        path: "/company",
        name: "Settings",
        icon: "nc-icon nc-settings-gear-65",
        component: Company,
        layout: "/admin",
        id: 'SETTINGS',
    },
];
