var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { InputText } from "../input/Input-Field.component";
import { CustomDatePickerOptions, CUSTOMDATEPICKER } from "./Date.enum";
import { getThisMonth, getLastMonth, getThisYear, getLastYear, getLastThreeMonth, formatDate, getThisQuarterToDate, toDate, getLast30Days, getThisMonthToDate, getThisYearToDate, getThisQuarter } from "@utils/date";
import { ReactDatePicker } from "./DatePicker.component";
import { isMobile } from "react-device-detect";
export var CustomDatePicker = function (_a) {
    var _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.onChange, onChange = _c === void 0 ? null : _c, _d = _a.defaultValue, defaultValue = _d === void 0 ? CUSTOMDATEPICKER.THIS_MONTH : _d;
    var _e = useState(defaultValue), date = _e[0], setDate = _e[1];
    var _f = useState({}), dateRange = _f[0], setDateRange = _f[1];
    var dateChanged = function (ev) {
        var _a, _b;
        var selectedDateRange = [];
        switch (+((_a = ev === null || ev === void 0 ? void 0 : ev.target) === null || _a === void 0 ? void 0 : _a.value)) {
            case CUSTOMDATEPICKER.LAST_30_DAYS: {
                selectedDateRange = getLast30Days();
                break;
            }
            case CUSTOMDATEPICKER.THIS_QUARTER: {
                selectedDateRange = getThisQuarter();
                break;
            }
            case CUSTOMDATEPICKER.THIS_QUARTER_TO_DATE: {
                selectedDateRange = getThisQuarterToDate();
                break;
            }
            case CUSTOMDATEPICKER.THIS_MONTH_To_DATE: {
                selectedDateRange = getThisMonthToDate();
                break;
            }
            case CUSTOMDATEPICKER.THIS_MONTH: {
                selectedDateRange = getThisMonth();
                break;
            }
            case CUSTOMDATEPICKER.LAST_MONTH: {
                selectedDateRange = getLastMonth();
                break;
            }
            case CUSTOMDATEPICKER.LAST_THREE_MONTH: {
                selectedDateRange = getLastThreeMonth();
                break;
            }
            case CUSTOMDATEPICKER.LAST_YEAR: {
                selectedDateRange = getLastYear();
                break;
            }
            case CUSTOMDATEPICKER.THIS_YEAR: {
                selectedDateRange = getThisYear();
                break;
            }
            case CUSTOMDATEPICKER.THIS_YEAR_To_DATE: {
                selectedDateRange = getThisYearToDate();
                break;
            }
            case CUSTOMDATEPICKER.CUSTOM:
                {
                    selectedDateRange = [];
                    break;
                }
        }
        setDate(+((_b = ev === null || ev === void 0 ? void 0 : ev.target) === null || _b === void 0 ? void 0 : _b.value));
        selectedDateRange.length === 2 && onChange && onChange(selectedDateRange);
    };
    var handleInputChange = function (key, value) {
        var _a;
        setDateRange(__assign(__assign({}, dateRange), (_a = {}, _a[key] = formatDate(value, "yyyy-MM-dd"), _a)));
    };
    useEffect(function () {
        if (onChange && dateRange && dateRange.start && dateRange.end) {
            onChange([dateRange.start, dateRange.end]);
        }
    }, [dateRange]);
    return (_jsxs("div", __assign({ className: isMobile && date === CUSTOMDATEPICKER.CUSTOM ? "db w-100" : "flex" }, { children: [_jsx(InputText, __assign({ value: date, onChange: dateChanged, select: true, size: "small", defaultValue: defaultValue, label: label, className: "min-w-200 bg-white" }, { children: CustomDatePickerOptions.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.title }), o.id)); }) }), void 0),
            date === CUSTOMDATEPICKER.CUSTOM && (_jsxs("div", __assign({ className: "flex " + (isMobile ? "mt-4" : "") }, { children: [_jsx("div", __assign({ className: "zindex-99 " + (isMobile ? '' : 'ml-2') }, { children: _jsx(ReactDatePicker, { placeholderText: "from...", width: "80", selected: (dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) ? toDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) : '', onChange: function (e) { return handleInputChange("start", e); }, className: "min-w-120", label: isMobile ? "" : "From Date" }, void 0) }), void 0),
                    _jsx("div", __assign({ className: "zindex-99 " + (isMobile ? '' : 'ml-2') }, { children: _jsx(ReactDatePicker, { placeholderText: "to...", width: "80", selected: (dateRange === null || dateRange === void 0 ? void 0 : dateRange.end) ? toDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.end) : '', onChange: function (e) { return handleInputChange("end", e); }, className: "min-w-120", label: isMobile ? "" : "To Date" }, void 0) }), void 0)] }), void 0))] }), void 0));
};
