var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { GET_COMPANY_BY_ID } from '../graphqls/users/user.query';
import { Roles } from "@enums";
import { httpGet, httpPostUpload } from '../helpers/http.helper';
import { ALL_OFFICES, CREATE_OFFICE, DELETE_OFFICE, UPDATE_OFFICE } from '../graphqls/settings/company.query';
import { STORAGE_KEY, addToStorage, readFromStorage } from '../utils/Storage.util';
export var getJwtToken = function () { return localStorage.getItem('API_TOKEN') || ''; };
export var getUserToken = function () {
    return {
        authorization: "Bearer " + getJwtToken()
    };
};
export var setUserToken = function (token) { return localStorage.setItem('API_TOKEN', token); };
export var setUserData = function (user) { return localStorage.setItem('API_USER', JSON.stringify(user)); };
export var logOut = function () {
    setUserToken('');
    setUserData(null);
    localStorage.clear();
};
export var GetUser = function () {
    var user = localStorage.getItem('API_USER') || '';
    if (user) {
        return JSON.parse(user);
    }
    return null;
};
export var GetUserId = function () {
    var _a;
    return ((_a = GetUser()) === null || _a === void 0 ? void 0 : _a.id) || 0;
};
export var getUserRole = function () {
    var _a;
    var user = GetUser();
    if (user) {
        return (_a = user === null || user === void 0 ? void 0 : user.role) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    }
    return '';
};
export var getUserLicence = function () {
    var user = GetUser();
    if (user) {
        return +user.licenceid;
    }
    return 1;
};
export var getUserName = function () {
    var user = GetUser();
    if (user) {
        return user.userName;
    }
    return '';
};
export var canRenderRoute = function (role, route, hideModule) {
    if (hideModule === void 0) { hideModule = []; }
    if ((hideModule === null || hideModule === void 0 ? void 0 : hideModule.indexOf(route.id)) !== -1)
        return false;
    if (route.env)
        return false;
    switch (route.id) {
        case 'Appraisers':
        case "BATCHPAYMENTS":
        case 'ADDONS':
        case 'Clients': return ![Roles.APPRAISER, Roles.CLIENT, Roles.SUPER_ADMIN].includes(role);
        case 'ACCOUNTING': return ![Roles.CLIENT, Roles.SUPER_ADMIN, Roles.APPRAISER].includes(role);
        case 'ALLRequests': return ![Roles.CLIENT, Roles.SUPER_ADMIN, Roles.APPRAISER].includes(role);
        case 'STATEMENTS': return [Roles.APPRAISER].includes(role);
        case 'INPROGRESS': return [Roles.APPRAISER].includes(role);
        case 'PENDINGS': return [Roles.APPRAISER, Roles.CLIENT].includes(role);
        case 'Dashboard': return ![Roles.CLIENT, Roles.SUPER_ADMIN].includes(role);
        case 'MAPVIEW': return ![Roles.CLIENT, Roles.SUPER_ADMIN].includes(role);
        case 'Quotes':
        case 'PAYMENTS':
        case 'CLIENTFEES': return ![Roles.CLIENT, Roles.APPRAISER, Roles.SUPER_ADMIN].includes(role);
        case 'Users':
        case "Companies": return role === Roles.SUPER_ADMIN;
        case 'SALES':
        case 'VALUATION':
        case 'VALUATIONLIST':
        case 'SETTINGS': return role === Roles.ADMIN;
        case "CLIENTJOBS": return role === Roles.CLIENT;
        case "Requests": return role !== Roles.CLIENT;
        case "ORDERS": return [Roles.CLIENT, Roles.ADMIN].includes(role);
    }
    return true;
};
export var canEditAppraisers = function () {
    return ![Roles.APPRAISER, Roles.CLIENT].includes(getUserRole());
};
export var canEditRequest = function () {
    return ![Roles.APPRAISER, Roles.CLIENT].includes(getUserRole());
};
export var isClient = function () { return getUserRole() === Roles.CLIENT; };
export var isAppraiser = function () {
    return getUserRole() === Roles.APPRAISER;
};
export var isAdmin = function () {
    return getUserRole() === Roles.ADMIN;
};
export var getCompanyId = function () {
    var cmp = readFromStorage(STORAGE_KEY.COMPANY);
    return -1 * (+(cmp === null || cmp === void 0 ? void 0 : cmp.id));
};
export var getOffices = function () {
    var cmp = readFromStorage(STORAGE_KEY.COMPANY);
    var offices = readFromStorage(STORAGE_KEY.OFFICES);
    var output = [];
    output.push({
        id: -1 * (+(cmp === null || cmp === void 0 ? void 0 : cmp.id)),
        title: cmp === null || cmp === void 0 ? void 0 : cmp.name, // + ' - ' + cmp?.zzFullAddress,
    });
    offices === null || offices === void 0 ? void 0 : offices.forEach(function (o) {
        output.push({
            id: +o.id,
            title: o === null || o === void 0 ? void 0 : o.name, // + ' - ' + o.address,
        });
    });
    return output;
};
export var isSuperAdmin = function () { return getUserRole() === Roles.SUPER_ADMIN; };
export var getCompanyById = function (id) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data, sub;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: GET_COMPANY_BY_ID,
                    variables: {
                        id: id,
                    }
                })];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, httpGet("user/getSubscriptionSettinsg/" + ((_a = data === null || data === void 0 ? void 0 : data.tblCompanyById) === null || _a === void 0 ? void 0 : _a.licenceId))];
            case 2:
                sub = _b.sent();
                return [2 /*return*/, __assign(__assign({}, data === null || data === void 0 ? void 0 : data.tblCompanyById), sub)];
        }
    });
}); }; };
export var deleteOffice = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: DELETE_OFFICE,
                    variables: {
                        id: id,
                    },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var upsertOffices = function (offices, attachments) { return function (apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, Promise.all(offices.map(function (_a, index) { return __awaiter(void 0, void 0, void 0, function () {
                    var engLetter, result;
                    var id = _a.id, f = __rest(_a, ["id"]);
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                engLetter = f === null || f === void 0 ? void 0 : f.engLetter;
                                if (!(attachments === null || attachments === void 0 ? void 0 : attachments["engLetter" + index])) return [3 /*break*/, 2];
                                return [4 /*yield*/, httpPostUpload("document/uploadTemplate", [attachments === null || attachments === void 0 ? void 0 : attachments["engLetter" + index]])];
                            case 1:
                                result = _b.sent();
                                engLetter = result.Key;
                                _b.label = 2;
                            case 2:
                                if (!(!id || ("" + id).startsWith('-'))) return [3 /*break*/, 4];
                                return [4 /*yield*/, apolloClient.query({
                                        query: CREATE_OFFICE,
                                        variables: {
                                            input: __assign(__assign({}, f), { engLetter: engLetter })
                                        },
                                    })];
                            case 3:
                                _b.sent();
                                return [3 /*break*/, 6];
                            case 4: return [4 /*yield*/, apolloClient.query({
                                    query: UPDATE_OFFICE,
                                    variables: {
                                        id: id,
                                        input: __assign(__assign({}, f), { engLetter: engLetter })
                                    }
                                })];
                            case 5:
                                _b.sent();
                                _b.label = 6;
                            case 6: return [2 /*return*/, true];
                        }
                    });
                }); }))];
            case 1:
                _c.sent();
                return [4 /*yield*/, apolloClient.query({
                        query: ALL_OFFICES,
                        variables: {}
                    })];
            case 2:
                data = (_c.sent()).data;
                addToStorage(STORAGE_KEY.OFFICES, ((_a = data === null || data === void 0 ? void 0 : data.allTblOffices) === null || _a === void 0 ? void 0 : _a.nodes) || []);
                return [2 /*return*/, ((_b = data === null || data === void 0 ? void 0 : data.allTblOffices) === null || _b === void 0 ? void 0 : _b.nodes) || []];
        }
    });
}); }; };
