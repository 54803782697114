var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { QuoteOptionType } from "@enums";
import { PropertyType } from "./Property-Type.component";
import { OptionManager } from "./Option-component";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { useDispatch } from "react-redux";
import { addPropertyType } from "../../stores/app.reducer";
import { useMutation } from "@apollo/client";
import { ADD_OPTION } from "../../graphqls/type-tables/type-table.query";
import { addNewOption } from "../../stores/client.reducer";
import { Alert } from "../../ui-components/modals/Alert.component";
import { DialogForm } from "../../ui-components/modals/DialogForm.component";
import { Button } from "reactstrap";
var titles = ['Property Type', 'Purpose', 'Function', 'Property Interest', 'Report Type', 'Expenses', 'Type', 'Rights Traded', 'Tenancy Type', 'Development Timeline', 'Development Status',
    'Residential Style', 'Garage', 'Bedrooms', 'Basement', 'Rooms', 'Extras', 'Fee Inclusions', 'Amenities', 'Property Sub Type', 'Property Type', 'Title Type', 'Value Type', 'Value Opinion',
    'Interested Valued', 'Exposure Time', 'Selected DCA', 'Selected Income', 'Selected Cost', '', '', '', '', 'Report Type'];
export var OptionManagerModal = function (_a) {
    var _b = _a.optionType, optionType = _b === void 0 ? 0 : _b, open = _a.open, _c = _a.id, id = _c === void 0 ? 0 : _c;
    var optionTitle = useRef();
    var dispatch = useDispatch();
    var addOption = useMutation(ADD_OPTION, {
        onCompleted: function (data) {
            dispatch(addNewOption(data.createTblOption.tblOption));
        }
    })[0];
    var updateOption = function (title) {
        optionTitle.current = title;
    };
    var addNewQuoteOption = function (optionType) {
        if (optionType === QuoteOptionType.PROPERTY_TYPE) {
            dispatch(addPropertyType('ADD'));
            return;
        }
        if (!optionTitle.current) {
            Alert.show({ title: 'Alert', message: 'Empty value is not allowed !' });
            return;
        }
        addOption({
            variables: {
                input: {
                    title: optionTitle.current,
                    optionType: optionType,
                }
            }
        });
    };
    var addOptions = function (optionType) {
        DialogForm.show({
            title: "" + (optionType === QuoteOptionType.DCA_ADJUSTMENT_DESCRIPTION ? 'Adjustment Description' : titles[+optionType]),
            footer: false,
            small: false,
            save: '',
            scope: optionType === QuoteOptionType.DCA_ADJUSTMENT_DESCRIPTION ? 'save-adjustment-button' : '',
            md: true,
            body: (optionType !== QuoteOptionType.DCA_ADJUSTMENT_DESCRIPTION ? (_jsxs("div", __assign({ className: "db w-full" }, { children: [_jsxs("div", __assign({ className: "flex gap-8" }, { children: [_jsx(InputText, { onChange: function (e) { return updateOption(e.target.value); }, size: "sm", mt: "flex-1", placeholder: 'Type here...' }, void 0),
                            _jsx(Button, __assign({ color: "info", className: "btn btn-xs", onClick: function () { return addNewQuoteOption(optionType); } }, { children: "Save Changes" }), void 0)] }), void 0),
                    _jsx(OptionManager, { optionType: optionType }, void 0)] }), void 0)) : (_jsx(PropertyType, { id: id }, void 0))),
            onSuccess: function () { return addNewQuoteOption(optionType); },
        });
    };
    useEffect(function () {
        if (open) {
            addOptions(optionType);
        }
    }, [open]);
    return null;
};
