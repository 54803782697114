var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { calculateFormula } from '../utils/Formula.utility';
import { ADJUSTMENT_METHODOLOGY, ColumnType } from '@enums';
import { formatTotalAsCurrency } from './numbers-helper.helper';
import { groupBy as groupedBy, sumBy } from "lodash";
import { gql } from '@apollo/client';
var excelHeader = " ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export var toPdf = function (content, asFile, fileName, orientation) {
    if (asFile === void 0) { asFile = false; }
    if (fileName === void 0) { fileName = 'pdfcreated'; }
    if (orientation === void 0) { orientation = 'portrait'; }
    return __awaiter(void 0, void 0, void 0, function () {
        var doc_width, doc_height, html2canvasOpts, opt, pdf;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    doc_width = 8.27;
                    doc_height = 11.69;
                    html2canvasOpts = {
                        scale: 2.5,
                        windowHeight: doc_height * 96 - 3,
                    };
                    opt = {
                        margin: 0,
                        filename: fileName + '.pdf',
                        html2canvas: html2canvasOpts,
                        jsPDF: {
                            unit: 'in',
                            format: [doc_width, doc_height],
                            orientation: orientation,
                        }
                    };
                    if (!asFile) return [3 /*break*/, 1];
                    window.html2pdf().set(opt).from(content).save();
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, window.html2pdf().set(opt).from(content).toPdf().output('datauristring')];
                case 2:
                    pdf = _a.sent();
                    return [2 /*return*/, pdf.split(',')[1]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var prepareDataForExport = function (tableName, columns, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var foreign, inlineQuery, data;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                foreign = {};
                columns.filter(function (c) { return c.fieldName.indexOf('.') !== -1; }).forEach(function (col) {
                    var keys = col.fieldName.split('.');
                    keys.forEach(function (key, index) {
                        var _a;
                        if (index > 0) {
                            foreign = __assign(__assign({}, foreign), (_a = {}, _a[keys[0]] = (foreign[keys[0]] || []).concat(key), _a));
                        }
                    });
                });
                inlineQuery = '';
                Object.entries(foreign).map(function (_a) {
                    var key = _a[0], value = _a[1];
                    inlineQuery += "\n" + key + " {\n " + value.join('\n') + " \n}";
                });
                return [4 /*yield*/, apolloClient.query({
                        query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["query MyQuery {\n            ", " {\n              nodes {\n                ", "\n                ", "\n              }\n            }\n          }\n          "], ["query MyQuery {\n            ", " {\n              nodes {\n                ",
                            "\n                ", "\n              }\n            }\n          }\n          "])), tableName, columns.filter(function (c) { return c.fieldName.indexOf('.') === -1; }).map(function (c) { return c.fieldName; }).reduce(function (cols, curr) {
                            cols = cols.concat(curr.split('|'));
                            return cols;
                        }, []).join('\n'), inlineQuery),
                        variables: {}
                    })];
            case 1:
                data = (_b.sent()).data;
                return [2 /*return*/, ((_a = data === null || data === void 0 ? void 0 : data[tableName]) === null || _a === void 0 ? void 0 : _a.nodes) || []];
        }
    });
}); };
export var toExcel = function (columns, rawData, fileName, groupBy) {
    if (groupBy === void 0) { groupBy = ''; }
    return __awaiter(void 0, void 0, void 0, function () {
        var workbook, data, hasTotal_1, footer_1, total_1, worksheet_1, buf, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    workbook = new Excel.Workbook();
                    data = [];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    rawData.forEach(function (curr) {
                        var row = __assign({}, curr);
                        columns.filter(function (c) { return c.fieldName.indexOf('.') !== -1 || c.fieldName.indexOf('|') !== -1 || c.formula || [ColumnType.CURRENCY, ColumnType.NUMBER].includes(c.columnType || 0); }).forEach(function (col) {
                            var _a;
                            var data = col.formula ? calculateFormula(col, curr) : curr[col.fieldName];
                            if ([ColumnType.CURRENCY].includes(col.columnType || 0)) {
                                data = formatTotalAsCurrency(data);
                            }
                            if (col.fieldName.indexOf('|') !== -1) {
                                data = '';
                                col.fieldName.split('|').forEach(function (c) {
                                    data += curr[c] + " ";
                                });
                            }
                            if (col.fieldName.indexOf('.') !== -1) {
                                data = Object.assign({}, curr);
                                col.fieldName.split('.').forEach(function (c) {
                                    data = data === null || data === void 0 ? void 0 : data[c];
                                });
                            }
                            row = __assign(__assign({}, row), (_a = {}, _a[col.fieldName] = data, _a));
                        });
                        data.push(row);
                    });
                    hasTotal_1 = columns.some(function (d) { return d.totalInFooter; });
                    if (!groupBy && hasTotal_1) {
                        footer_1 = {};
                        total_1 = '';
                        columns.forEach(function (col) {
                            var _a;
                            total_1 = '';
                            if (col.totalInFooter) {
                                total_1 = formatTotalAsCurrency(sumBy(data, function (r) { var _a, _b, _c; return +((_c = (_b = (_a = r[col.fieldName]) === null || _a === void 0 ? void 0 : _a.replace(/\$/g, '')) === null || _b === void 0 ? void 0 : _b.replace(/%/g, '')) === null || _c === void 0 ? void 0 : _c.replace(/,/g, '')); }));
                            }
                            footer_1 = __assign(__assign({}, footer_1), (_a = {}, _a[col.fieldName] = total_1, _a));
                        });
                        data.push(footer_1);
                    }
                    worksheet_1 = workbook.addWorksheet(fileName);
                    worksheet_1.columns = columns.map(function (c) { return ({
                        header: c.caption,
                        key: c.fieldName,
                    }); });
                    worksheet_1.getRow(1).font = { bold: true };
                    worksheet_1.columns.forEach(function (column, index) {
                        column.width = index === 0 ? 40 : column.header.length + 10;
                        column.alignment = { horizontal: index === 0 ? 'left' : 'center' };
                    });
                    if (groupBy) {
                        Object.entries(groupedBy(data, groupBy)).map(function (_a) {
                            var _b;
                            var key = _a[0], value = _a[1];
                            worksheet_1.addRow((_b = {},
                                _b[columns[0].fieldName] = key,
                                _b));
                            var currentRowIdx = worksheet_1.rowCount;
                            var endColumnIdx = worksheet_1.columnCount;
                            worksheet_1.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);
                            worksheet_1.getRow(currentRowIdx).getCell(1).font = { bold: true };
                            worksheet_1.getRow(currentRowIdx).getCell(1).alignment = { horizontal: 'left', vertical: 'middle' };
                            worksheet_1.getRow(currentRowIdx).height = 25;
                            worksheet_1.getRow(currentRowIdx).fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFF2F6F9' },
                            };
                            value.forEach(function (singleData) {
                                worksheet_1.addRow(singleData);
                            });
                            if (hasTotal_1) {
                                var footer_2 = {};
                                var total_2 = '';
                                columns.forEach(function (col) {
                                    var _a;
                                    total_2 = '';
                                    if (col.totalInFooter) {
                                        total_2 = formatTotalAsCurrency(sumBy(value, function (r) { var _a, _b, _c; return +((_c = (_b = (_a = r[col.fieldName]) === null || _a === void 0 ? void 0 : _a.replace(/\$/g, '')) === null || _b === void 0 ? void 0 : _b.replace(/%/g, '')) === null || _c === void 0 ? void 0 : _c.replace(/,/g, '')); }));
                                    }
                                    footer_2 = __assign(__assign({}, footer_2), (_a = {}, _a[col.fieldName] = total_2, _a));
                                });
                                worksheet_1.addRow(footer_2);
                            }
                            return null;
                        });
                    }
                    else {
                        data.forEach(function (singleData) {
                            worksheet_1.addRow(singleData);
                        });
                    }
                    worksheet_1.eachRow({ includeEmpty: false }, function (row) {
                        var currentCell = row._cells;
                        currentCell.forEach(function (singleCell) {
                            var cellAddress = singleCell._address;
                            worksheet_1.getCell(cellAddress).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        });
                    });
                    return [4 /*yield*/, workbook.xlsx.writeBuffer()];
                case 2:
                    buf = _a.sent();
                    saveAs(new Blob([buf]), fileName + ".xlsx");
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('<<<ERRROR>>>', error_1);
                    console.error('Something Went Wrong', error_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    workbook.removeWorksheet(fileName);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    });
};
export var exportValuationToExcel = function (dcaResult, adjustmentItems, uoc, fileName) {
    if (fileName === void 0) { fileName = 'valuation'; }
    return __awaiter(void 0, void 0, void 0, function () {
        var dca, sales, subject, defaultAdjusted, finalRate, methodology, workbook, worksheet, numberSign, currentRowIndex, colIndex, data, i, j, buf;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dca = dcaResult.dca, sales = dcaResult.sales, subject = dcaResult.subject, defaultAdjusted = dcaResult.defaultAdjusted, finalRate = dcaResult.finalRate, methodology = dcaResult.methodology;
                    workbook = new Excel.Workbook();
                    worksheet = workbook.addWorksheet(fileName);
                    numberSign = methodology === ADJUSTMENT_METHODOLOGY.PERCENTAGE ? '%' : '';
                    currentRowIndex = 0;
                    worksheet.addRow({});
                    ++currentRowIndex;
                    worksheet.getRow(currentRowIndex).getCell(1).value = "Unit of Comparison : " + ((_a = uoc.find(function (u) { return +u.id === +dcaResult.unit; })) === null || _a === void 0 ? void 0 : _a.title);
                    worksheet.mergeCells("A" + currentRowIndex + ":" + excelHeader.charAt(sales.length * 3 + 6) + currentRowIndex);
                    worksheet.getRow(currentRowIndex).getCell(1).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF000000' },
                    };
                    worksheet.getRow(currentRowIndex).getCell(1).font = { color: { argb: 'FFFFFFFF' } };
                    worksheet.addRow({});
                    ++currentRowIndex;
                    worksheet.mergeCells("A" + currentRowIndex + ":" + excelHeader.charAt(sales.length * 3 + 6) + currentRowIndex);
                    worksheet.getRow(currentRowIndex).getCell(1).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF000000' },
                    };
                    colIndex = 7;
                    // await Promise.all(
                    //     sales?.map(async (sale) => {
                    //         const base64: any = await fetch(getImageSrc(sale.image || '') || '')
                    //             .then(response => response.blob())
                    //             .then(blob => {
                    //                 const reader = new FileReader();
                    //                 reader.readAsDataURL(blob);
                    //                 return new Promise((res) => {
                    //                     reader.onloadend = () => {
                    //                         res(reader.result);
                    //                     }
                    //                 })
                    //             })
                    //         const imgId = workbook.addImage({
                    //             base64: base64,
                    //             extension: 'jpeg',
                    //         });
                    //         worksheet.addImage(imgId, `${excelHeader.charAt(colIndex)}${currentRowIndex}:${excelHeader.charAt(colIndex + 2)}${currentRowIndex}`)
                    //         colIndex += 3;
                    //         return true;
                    //     })
                    // );
                    worksheet.addRow({});
                    ++currentRowIndex;
                    worksheet.getRow(currentRowIndex).getCell(4).value = 'Property';
                    cellHighlight(worksheet, currentRowIndex, 4);
                    worksheet.getRow(currentRowIndex).getCell(5).value = 'Subject Property';
                    cellHighlight(worksheet, currentRowIndex, 5);
                    cellAlignment(worksheet, currentRowIndex, 5);
                    worksheet.mergeCells("E" + currentRowIndex + ":F" + currentRowIndex);
                    colIndex = 7;
                    sales === null || sales === void 0 ? void 0 : sales.forEach(function (_, i) {
                        worksheet.getRow(currentRowIndex).getCell(colIndex).value = "Sale Index " + (i + 1);
                        cellHighlight(worksheet, currentRowIndex, colIndex);
                        cellAlignment(worksheet, currentRowIndex, colIndex);
                        worksheet.mergeCells("" + excelHeader.charAt(colIndex) + currentRowIndex + ":" + excelHeader.charAt(colIndex + 2) + currentRowIndex);
                        colIndex += 3;
                    });
                    data = [
                        { caption: 'Address', field: 'address', subField: 'address' },
                        { caption: 'City', field: 'city', subField: 'city' },
                        { caption: 'Building Area', field: 'buildingArea', subField: 'buildingAreaSf' },
                        { caption: 'Site Area', field: 'siteArea', subField: 'siteAreaSf' },
                        { caption: 'Sale Date', field: 'saleDate', subField: 'effectiveDate' },
                        { caption: 'Sale Price', field: 'salePrice', subField: 'salePrice', currency: true },
                        { caption: 'Sale Unit', field: 'saleUnit', subField: 'saleUnit' },
                        { caption: 'Sale Rate', field: 'saleRate', subField: 'mean', currency: true },
                    ];
                    data.forEach(function (row) {
                        ++currentRowIndex;
                        repeatRowStructure(worksheet, currentRowIndex, sales, __assign(__assign({}, subject), finalRate), row);
                    });
                    worksheet.mergeCells("A3:C11");
                    ++currentRowIndex;
                    populateAdjustmentHeaders(worksheet, currentRowIndex, numberSign, sales);
                    dca === null || dca === void 0 ? void 0 : dca.forEach(function (row) {
                        var _a, _b, _c, _d, _e, _f;
                        worksheet.addRow({});
                        ++currentRowIndex;
                        worksheet.getRow(currentRowIndex).getCell(1).value = ((_a = adjustmentItems.find(function (a) { return +a.id === +row.id; })) === null || _a === void 0 ? void 0 : _a.title) || '';
                        worksheet.getRow(currentRowIndex).getCell(2).value = numberSign ? '' : row.type;
                        worksheet.getRow(currentRowIndex).getCell(3).value = row.comment || '';
                        worksheet.getRow(currentRowIndex).getCell(4).value = numberSign ? '' : +(row.parameter || '0');
                        worksheet.getRow(currentRowIndex).getCell(5).value = ((_d = (_c = (_b = adjustmentItems.find(function (a) { return +a.id === +row.id; })) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.find(function (o) { return o.id === row.description; })) === null || _d === void 0 ? void 0 : _d.option) || row.description || '';
                        worksheet.getRow(currentRowIndex).getCell(6).value = numberSign ? '' : row.value || '0';
                        worksheet.getRow(currentRowIndex).getCell(6).numFmt = "#,##0.00";
                        var colIndex = 7;
                        (_e = row.sales) === null || _e === void 0 ? void 0 : _e.forEach(function (adjSale) {
                            var _a, _b, _c;
                            worksheet.getRow(currentRowIndex).getCell(colIndex).value = ((_c = (_b = (_a = adjustmentItems.find(function (a) { return +a.id === +row.id; })) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.find(function (o) { return o.id === adjSale.description; })) === null || _c === void 0 ? void 0 : _c.option) || adjSale.description || '';
                            worksheet.getRow(currentRowIndex).getCell(colIndex + 1).value = numberSign ? '' : adjSale.value || '0';
                            worksheet.getRow(currentRowIndex).getCell(colIndex + 1).numFmt = "#,##0.00";
                            worksheet.getRow(currentRowIndex).getCell(colIndex + 2).value = (numberSign ? adjSale === null || adjSale === void 0 ? void 0 : adjSale.adjustedValueText : adjSale.adjustedValue) || '0';
                            worksheet.getRow(currentRowIndex).getCell(colIndex + 2).numFmt = "#,##0.00";
                            colIndex += 3;
                        });
                        if (row.id === 4) {
                            worksheet.addRow({});
                            ++currentRowIndex;
                            worksheet.getRow(currentRowIndex).getCell(1).value = "Value After Transaction Adjustment";
                            worksheet.mergeCells("A" + currentRowIndex + ":F" + currentRowIndex);
                            colIndex = 7;
                            (_f = row.sales) === null || _f === void 0 ? void 0 : _f.forEach(function (adjSale) {
                                var _a;
                                worksheet.getRow(currentRowIndex).getCell(colIndex).value = formatTotalAsCurrency(((_a = defaultAdjusted === null || defaultAdjusted === void 0 ? void 0 : defaultAdjusted.find(function (d) { return d.id === adjSale.id; })) === null || _a === void 0 ? void 0 : _a.value) || '0', methodology === ADJUSTMENT_METHODOLOGY.QUANTITY) + numberSign;
                                worksheet.mergeCells("" + excelHeader.charAt(colIndex) + currentRowIndex + ":" + excelHeader.charAt(colIndex + 2) + currentRowIndex);
                                cellAlignment(worksheet, currentRowIndex, colIndex, { horizontal: 'right' });
                                colIndex += 3;
                            });
                            cellStyle(worksheet, currentRowIndex);
                        }
                    });
                    worksheet.addRow({});
                    ++currentRowIndex;
                    worksheet.getRow(currentRowIndex).getCell(1).value = "Total Net Adjustments";
                    worksheet.mergeCells("A" + currentRowIndex + ":F" + currentRowIndex);
                    colIndex = 7;
                    defaultAdjusted === null || defaultAdjusted === void 0 ? void 0 : defaultAdjusted.forEach(function (da) {
                        worksheet.getRow(currentRowIndex).getCell(colIndex).value = formatTotalAsCurrency(da.net || '0', methodology === ADJUSTMENT_METHODOLOGY.QUANTITY) + numberSign;
                        worksheet.mergeCells("" + excelHeader.charAt(colIndex) + currentRowIndex + ":" + excelHeader.charAt(colIndex + 2) + currentRowIndex);
                        cellAlignment(worksheet, currentRowIndex, colIndex, { horizontal: 'right' });
                        colIndex += 3;
                    });
                    cellStyle(worksheet, currentRowIndex);
                    if (!numberSign) {
                        worksheet.addRow({});
                        ++currentRowIndex;
                        worksheet.getRow(currentRowIndex).getCell(1).value = "Net Percentage Adjustments";
                        worksheet.mergeCells("A" + currentRowIndex + ":F" + currentRowIndex);
                        colIndex = 7;
                        defaultAdjusted === null || defaultAdjusted === void 0 ? void 0 : defaultAdjusted.forEach(function (da) {
                            worksheet.getRow(currentRowIndex).getCell(colIndex).value = (da.netPer || '0') + '%';
                            worksheet.mergeCells("" + excelHeader.charAt(colIndex) + currentRowIndex + ":" + excelHeader.charAt(colIndex + 2) + currentRowIndex);
                            cellAlignment(worksheet, currentRowIndex, colIndex, { horizontal: 'right' });
                            colIndex += 3;
                        });
                        cellStyle(worksheet, currentRowIndex);
                    }
                    worksheet.addRow({});
                    ++currentRowIndex;
                    worksheet.getRow(currentRowIndex).getCell(1).value = "Adjusted Rate";
                    worksheet.mergeCells("A" + currentRowIndex + ":F" + currentRowIndex);
                    colIndex = 7;
                    defaultAdjusted === null || defaultAdjusted === void 0 ? void 0 : defaultAdjusted.forEach(function (da) {
                        worksheet.getRow(currentRowIndex).getCell(colIndex).value = formatTotalAsCurrency(da.final || '0');
                        worksheet.mergeCells("" + excelHeader.charAt(colIndex) + currentRowIndex + ":" + excelHeader.charAt(colIndex + 2) + currentRowIndex);
                        cellAlignment(worksheet, currentRowIndex, colIndex, { horizontal: 'right' });
                        colIndex += 3;
                    });
                    cellStyle(worksheet, currentRowIndex);
                    for (i = 0; i < worksheet.rowCount; i++) {
                        for (j = 0; j < (sales === null || sales === void 0 ? void 0 : sales.length) * 3 + 6; j++) {
                            worksheet.getRow(i + 1).getCell(j + 1).style.border = {
                                left: { color: { argb: 'FFdee2e6' }, style: 'thick' },
                                right: { color: { argb: 'FFdee2e6' }, style: 'thick' },
                                top: { color: { argb: 'FFdee2e6' }, style: 'thick' },
                                bottom: { color: { argb: 'FFdee2e6' }, style: 'thick' },
                            };
                        }
                    }
                    currentRowIndex += 5;
                    renderSummary(worksheet, finalRate, currentRowIndex);
                    return [4 /*yield*/, workbook.xlsx.writeBuffer()];
                case 1:
                    buf = _b.sent();
                    saveAs(new Blob([buf]), fileName + ".xlsx");
                    return [2 /*return*/];
            }
        });
    });
};
var renderSummary = function (worksheet, finalRate, row) {
    var currentRowIndex = row;
    for (var i = 1; i < currentRowIndex; i++) {
        worksheet.addRow({});
    }
    worksheet.addRow({});
    ++currentRowIndex;
    worksheet.mergeCells("A" + currentRowIndex + ":C" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(4).value = "Comparable Sales Prices";
    worksheet.mergeCells("D" + currentRowIndex + ":E" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(6).value = "Estimated Subject Value";
    worksheet.mergeCells("F" + currentRowIndex + ":G" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(8).value = "Unadjusted Sale Rate";
    worksheet.mergeCells("H" + currentRowIndex + ":I" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(10).value = "Adjusted Sale Rate";
    worksheet.mergeCells("J" + currentRowIndex + ":K" + currentRowIndex);
    cellStyle(worksheet, currentRowIndex, 15, true, 30);
    worksheet.addRow({});
    ++currentRowIndex;
    worksheet.getRow(currentRowIndex).getCell(1).value = "Minimum";
    worksheet.mergeCells("A" + currentRowIndex + ":C" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(4).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.minSale) || 0);
    worksheet.mergeCells("D" + currentRowIndex + ":E" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(6).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.minEst) || 0);
    worksheet.mergeCells("F" + currentRowIndex + ":G" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(8).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.minSaleRate) || 0);
    worksheet.mergeCells("H" + currentRowIndex + ":I" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(10).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.min) || 0);
    worksheet.mergeCells("J" + currentRowIndex + ":K" + currentRowIndex);
    cellStyle(worksheet, currentRowIndex, 15, true, 30);
    worksheet.addRow({});
    ++currentRowIndex;
    worksheet.getRow(currentRowIndex).getCell(1).value = "Maximum";
    worksheet.mergeCells("A" + currentRowIndex + ":C" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(4).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.maxSale) || 0);
    worksheet.mergeCells("D" + currentRowIndex + ":E" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(6).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.maxEst) || 0);
    worksheet.mergeCells("F" + currentRowIndex + ":G" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(8).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.maxSaleRate) || 0);
    worksheet.mergeCells("H" + currentRowIndex + ":I" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(10).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.max) || 0);
    worksheet.mergeCells("J" + currentRowIndex + ":K" + currentRowIndex);
    cellStyle(worksheet, currentRowIndex, 15, true, 30);
    worksheet.addRow({});
    ++currentRowIndex;
    worksheet.getRow(currentRowIndex).getCell(1).value = "Median";
    worksheet.mergeCells("A" + currentRowIndex + ":C" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(4).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.meanSale) || 0);
    worksheet.mergeCells("D" + currentRowIndex + ":E" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(6).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.meanEst) || 0);
    worksheet.mergeCells("F" + currentRowIndex + ":G" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(8).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.meanSaleRate) || 0);
    worksheet.mergeCells("H" + currentRowIndex + ":I" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(10).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.mean) || 0);
    worksheet.mergeCells("J" + currentRowIndex + ":K" + currentRowIndex);
    cellStyle(worksheet, currentRowIndex, 15, true, 30);
    worksheet.addRow({});
    ++currentRowIndex;
    worksheet.getRow(currentRowIndex).getCell(1).value = "Min-Max Differential";
    worksheet.mergeCells("A" + currentRowIndex + ":C" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(4).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.saleDiff) || 0);
    worksheet.mergeCells("D" + currentRowIndex + ":E" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(6).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.diffEst) || 0);
    worksheet.mergeCells("F" + currentRowIndex + ":G" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(8).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.saleRateDiff) || 0);
    worksheet.mergeCells("H" + currentRowIndex + ":I" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(10).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.diff) || 0);
    worksheet.mergeCells("J" + currentRowIndex + ":K" + currentRowIndex);
    cellStyle(worksheet, currentRowIndex, 15, true, 30);
    worksheet.addRow({});
    ++currentRowIndex;
    worksheet.getRow(currentRowIndex).getCell(1).value = "Standard Deviation";
    worksheet.mergeCells("A" + currentRowIndex + ":C" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(4).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.stdevSale) || 0);
    worksheet.mergeCells("D" + currentRowIndex + ":E" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(6).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.stdevEst) || 0);
    worksheet.mergeCells("F" + currentRowIndex + ":G" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(8).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.stdevSaleRate) || 0);
    worksheet.mergeCells("H" + currentRowIndex + ":I" + currentRowIndex);
    worksheet.getRow(currentRowIndex).getCell(10).value = formatTotalAsCurrency((finalRate === null || finalRate === void 0 ? void 0 : finalRate.stdev) || 0);
    worksheet.mergeCells("J" + currentRowIndex + ":K" + currentRowIndex);
    cellStyle(worksheet, currentRowIndex, 15, true, 30);
};
var cellHighlight = function (worksheet, currentRowIndex, cell, bold) {
    if (bold === void 0) { bold = true; }
    worksheet.getRow(currentRowIndex).getCell(cell).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFf2f6f9' },
    };
    worksheet.getRow(currentRowIndex).getCell(cell).font = { bold: bold, };
};
var cellStyle = function (worksheet, currentRowIndex, width, center, height) {
    for (var j = 0; j < worksheet.getRow(currentRowIndex).cellCount; j++) {
        cellHighlight(worksheet, currentRowIndex, j + 1);
        worksheet.getRow(currentRowIndex).getCell(j + 1).style.font = { bold: true };
        if (width) {
            worksheet.getColumn(j + 1).width = width;
        }
        if (center) {
            worksheet.getRow(currentRowIndex).getCell(j + 1).style.alignment = { horizontal: 'center', vertical: 'middle' };
        }
        if (height) {
            worksheet.getRow(currentRowIndex).height = height;
        }
    }
};
var populateAdjustmentHeaders = function (worksheet, currentRowIndex, numberSign, sales) {
    worksheet.addRow({});
    worksheet.getRow(currentRowIndex).getCell(1).value = '';
    worksheet.getRow(currentRowIndex).getCell(2).value = numberSign ? '' : 'Adj Type';
    worksheet.getRow(currentRowIndex).getCell(3).value = 'Adj Comments';
    worksheet.getRow(currentRowIndex).getCell(4).value = numberSign ? '' : 'Parameter';
    worksheet.getRow(currentRowIndex).getCell(5).value = 'Description';
    worksheet.getRow(currentRowIndex).getCell(6).value = numberSign ? '' : 'Value';
    var colIndex = 7;
    sales === null || sales === void 0 ? void 0 : sales.forEach(function () {
        worksheet.getRow(currentRowIndex).getCell(colIndex).value = 'Description';
        worksheet.getRow(currentRowIndex).getCell(colIndex + 1).value = numberSign ? '' : 'Value';
        worksheet.getRow(currentRowIndex).getCell(colIndex + 2).value = 'Adjustment';
        colIndex += 3;
    });
    cellStyle(worksheet, currentRowIndex, 15);
};
var repeatRowStructure = function (worksheet, currentRowIndex, sales, subject, data) {
    worksheet.addRow({});
    worksheet.getRow(currentRowIndex).getCell(4).value = data === null || data === void 0 ? void 0 : data.caption;
    cellHighlight(worksheet, currentRowIndex, 4);
    worksheet.getRow(currentRowIndex).getCell(5).value = (data === null || data === void 0 ? void 0 : data.currency) ? formatTotalAsCurrency((subject === null || subject === void 0 ? void 0 : subject[(data === null || data === void 0 ? void 0 : data.subField) || '']) || '0') : (subject === null || subject === void 0 ? void 0 : subject[(data === null || data === void 0 ? void 0 : data.subField) || '']) || '';
    cellAlignment(worksheet, currentRowIndex, 5);
    worksheet.mergeCells("E" + currentRowIndex + ":F" + currentRowIndex);
    var colIndex = 7;
    sales === null || sales === void 0 ? void 0 : sales.forEach(function (sale) {
        worksheet.getRow(currentRowIndex).getCell(colIndex).value = (data === null || data === void 0 ? void 0 : data.currency) ? formatTotalAsCurrency((sale === null || sale === void 0 ? void 0 : sale[(data === null || data === void 0 ? void 0 : data.field) || '']) || '0') : (sale === null || sale === void 0 ? void 0 : sale[(data === null || data === void 0 ? void 0 : data.field) || '']) || '';
        cellAlignment(worksheet, currentRowIndex, colIndex);
        worksheet.mergeCells("" + excelHeader.charAt(colIndex) + currentRowIndex + ":" + excelHeader.charAt(colIndex + 2) + currentRowIndex);
        colIndex += 3;
    });
};
var cellAlignment = function (worksheet, row, col, alignment) {
    if (alignment === void 0) { alignment = { horizontal: 'center' }; }
    worksheet.getRow(row).getCell(col).alignment = alignment;
};
var templateObject_1;
