import { StatusEnum } from "./status.enum";
import { FeeTypeEnum, JobTypes } from "./fee-type.enum";
import { InvoiceStatus } from "./invoice-status.enum";
import { QuoteOptionType } from "./option-type.enum";
import { PROPERTY_SECTION, SYSTEM_TABLES, COLUMN_TYPE_NUMBER } from "./property-section.enum";
import { Roles } from "./roles.enum";
export { StatusEnum, FeeTypeEnum, JobTypes, InvoiceStatus, QuoteOptionType, PROPERTY_SECTION, SYSTEM_TABLES, COLUMN_TYPE_NUMBER, Roles };
export var EXPENSE_TYPE;
(function (EXPENSE_TYPE) {
    EXPENSE_TYPE[EXPENSE_TYPE["PRO_RATE"] = 1] = "PRO_RATE";
    EXPENSE_TYPE[EXPENSE_TYPE["AMOUNT"] = 2] = "AMOUNT";
    EXPENSE_TYPE[EXPENSE_TYPE["DOLLAR_PSF"] = 3] = "DOLLAR_PSF";
})(EXPENSE_TYPE || (EXPENSE_TYPE = {}));
export var INCOME_TYPE;
(function (INCOME_TYPE) {
    INCOME_TYPE[INCOME_TYPE["BEFORE_VACANCY"] = 1] = "BEFORE_VACANCY";
    INCOME_TYPE[INCOME_TYPE["AFTER_VACANCY"] = 2] = "AFTER_VACANCY";
})(INCOME_TYPE || (INCOME_TYPE = {}));
export var JOB_SECTION_FIELDS;
(function (JOB_SECTION_FIELDS) {
    JOB_SECTION_FIELDS[JOB_SECTION_FIELDS["AREA_SF"] = 1] = "AREA_SF";
    JOB_SECTION_FIELDS[JOB_SECTION_FIELDS["AREA_AC"] = 4] = "AREA_AC";
    JOB_SECTION_FIELDS[JOB_SECTION_FIELDS["BUILDING_SF"] = 68] = "BUILDING_SF";
    JOB_SECTION_FIELDS[JOB_SECTION_FIELDS["BUILDABLE_SF"] = 35] = "BUILDABLE_SF";
})(JOB_SECTION_FIELDS || (JOB_SECTION_FIELDS = {}));
export var JOB_SECTION_FIELDS_ARRAY = [JOB_SECTION_FIELDS.AREA_AC, JOB_SECTION_FIELDS.AREA_SF, JOB_SECTION_FIELDS.BUILDABLE_SF, JOB_SECTION_FIELDS.BUILDING_SF];
//tblAdjItems
export var ADJUSTMENT_ITEMS;
(function (ADJUSTMENT_ITEMS) {
    ADJUSTMENT_ITEMS[ADJUSTMENT_ITEMS["BUILDING_SF"] = 7] = "BUILDING_SF";
    ADJUSTMENT_ITEMS[ADJUSTMENT_ITEMS["SITE_SF"] = 9] = "SITE_SF";
    ADJUSTMENT_ITEMS[ADJUSTMENT_ITEMS["SITE_AC"] = 10] = "SITE_AC";
    ADJUSTMENT_ITEMS[ADJUSTMENT_ITEMS["BUILDABLE_SF"] = 11] = "BUILDABLE_SF";
})(ADJUSTMENT_ITEMS || (ADJUSTMENT_ITEMS = {}));
export var ADJUSTMENT_COMP_UNIT = [
    { id: ADJUSTMENT_ITEMS.BUILDING_SF, title: 'Building Area' },
    { id: ADJUSTMENT_ITEMS.SITE_SF, title: 'Site Area SF' },
    { id: ADJUSTMENT_ITEMS.SITE_AC, title: 'Site Area AC' },
    { id: ADJUSTMENT_ITEMS.BUILDABLE_SF, title: 'Buildable SF' },
];
export var DRAWERCOMPONENT;
(function (DRAWERCOMPONENT) {
    DRAWERCOMPONENT["CLIENT"] = "CLIENT";
    DRAWERCOMPONENT["JOB_VIEW"] = "JOB_VIEW";
    DRAWERCOMPONENT["APPRAISER"] = "APPRAISER";
    DRAWERCOMPONENT["JOB_EDIT"] = "JOB_EDIT";
    DRAWERCOMPONENT["PAYROLL"] = "PAYROLL";
})(DRAWERCOMPONENT || (DRAWERCOMPONENT = {}));
export var ColumnType;
(function (ColumnType) {
    ColumnType[ColumnType["DATE"] = 1] = "DATE";
    ColumnType[ColumnType["NUMBER"] = 2] = "NUMBER";
    ColumnType[ColumnType["LIST"] = 3] = "LIST";
    ColumnType[ColumnType["BIT"] = 4] = "BIT";
    ColumnType[ColumnType["CURRENCY"] = 5] = "CURRENCY";
    ColumnType[ColumnType["DATE_ONLY"] = 6] = "DATE_ONLY";
    ColumnType[ColumnType["BIG_TEXT"] = 7] = "BIG_TEXT";
    ColumnType[ColumnType["PERCENT"] = 8] = "PERCENT";
    ColumnType[ColumnType["EXTRA_BIG_TEXT"] = 9] = "EXTRA_BIG_TEXT";
})(ColumnType || (ColumnType = {}));
export var NUMBER_COLUMNS = [ColumnType.NUMBER, ColumnType.CURRENCY, ColumnType.PERCENT];
export var SelectorName;
(function (SelectorName) {
    SelectorName[SelectorName["STATUS"] = 1] = "STATUS";
    SelectorName[SelectorName["CLIENT"] = 2] = "CLIENT";
    SelectorName[SelectorName["PROPERTY_TYPE"] = 3] = "PROPERTY_TYPE";
    SelectorName[SelectorName["APPRAISERS"] = 4] = "APPRAISERS";
    SelectorName[SelectorName["SALE_TYPE"] = 6] = "SALE_TYPE";
    SelectorName[SelectorName["RIGHTS_TRADED"] = 7] = "RIGHTS_TRADED";
    SelectorName[SelectorName["TENANCY_TYPE"] = 8] = "TENANCY_TYPE";
    SelectorName[SelectorName["TIMELINE"] = 9] = "TIMELINE";
    SelectorName[SelectorName["DEV_STATUS"] = 10] = "DEV_STATUS";
    SelectorName[SelectorName["RESIDENTIAL_STYLE"] = 11] = "RESIDENTIAL_STYLE";
    SelectorName[SelectorName["GARAGE"] = 12] = "GARAGE";
    SelectorName[SelectorName["BEDROOMS"] = 13] = "BEDROOMS";
    SelectorName[SelectorName["BASMENT"] = 14] = "BASMENT";
    SelectorName[SelectorName["ROOMS"] = 15] = "ROOMS";
    SelectorName[SelectorName["EXTRAS"] = 16] = "EXTRAS";
    SelectorName[SelectorName["FEEINCLUSION"] = 17] = "FEEINCLUSION";
    SelectorName[SelectorName["AMENITIES"] = 18] = "AMENITIES";
    SelectorName[SelectorName["PROPERTY_SUB_TYPE"] = 19] = "PROPERTY_SUB_TYPE";
    SelectorName[SelectorName["PROPERTY_TYPE_SALES"] = 20] = "PROPERTY_TYPE_SALES";
    SelectorName[SelectorName["TITLE_TYPE"] = 21] = "TITLE_TYPE";
    SelectorName[SelectorName["VALUE_TYPE"] = 22] = "VALUE_TYPE";
    SelectorName[SelectorName["VALUE_OPINION"] = 23] = "VALUE_OPINION";
    SelectorName[SelectorName["INTEREST_VALUE"] = 24] = "INTEREST_VALUE";
    SelectorName[SelectorName["EXPOSURE_TIME"] = 25] = "EXPOSURE_TIME";
    SelectorName[SelectorName["SELECTED_DCA"] = 26] = "SELECTED_DCA";
    SelectorName[SelectorName["SELECTED_INCOME"] = 27] = "SELECTED_INCOME";
    SelectorName[SelectorName["SELECTED_COST"] = 28] = "SELECTED_COST";
    SelectorName[SelectorName["TENANCY_STATUS"] = 29] = "TENANCY_STATUS";
    SelectorName[SelectorName["INCOME_EXPENSES"] = 30] = "INCOME_EXPENSES";
    SelectorName[SelectorName["EXPENSE_TYPE"] = 31] = "EXPENSE_TYPE";
    SelectorName[SelectorName["INCOME_TYPE"] = 32] = "INCOME_TYPE";
    SelectorName[SelectorName["REPORT_TYPE"] = 33] = "REPORT_TYPE";
    SelectorName[SelectorName["UNIT_TYPE"] = 34] = "UNIT_TYPE";
    SelectorName[SelectorName["ADJUSTMENT_ITEMS"] = 101] = "ADJUSTMENT_ITEMS";
    SelectorName[SelectorName["UNIT_OF_CMP"] = 102] = "UNIT_OF_CMP";
    SelectorName[SelectorName["FIELD_DATA_TYPE"] = 103] = "FIELD_DATA_TYPE";
})(SelectorName || (SelectorName = {}));
export var DOCUMENT_TYPE;
(function (DOCUMENT_TYPE) {
    DOCUMENT_TYPE["APPRAISERS"] = "APPRAISERS";
    DOCUMENT_TYPE["JOBS"] = "JOBS";
    DOCUMENT_TYPE["ENGAGEMENT_LETTER"] = "ENGAGEMENT_LETTER";
    DOCUMENT_TYPE["SALES"] = "SALES";
    DOCUMENT_TYPE["CLIENTS"] = "CLIENTS";
    DOCUMENT_TYPE["JOB_REPORT_TYPE"] = "JOB_REPORT_TYPE";
    DOCUMENT_TYPE["JOB_PHOTOS"] = "JOB_PHOTOS";
    DOCUMENT_TYPE["ORDERS"] = "ORDERS";
})(DOCUMENT_TYPE || (DOCUMENT_TYPE = {}));
export var PAYMENT_TYPES = [
    'N/A', 'Credit Card', 'Cheque', 'Transfer', 'Cash', 'ACH', 'Bank Transfer', 'EFT', 'EMT', 'Debit', 'PayPal', 'AMEX', 'Quickbooks', 'Venmo', 'Coupon', 'Visa', 'MasterCard'
];
export var APP_MODULES;
(function (APP_MODULES) {
    APP_MODULES["SALE"] = "tblSaleHeader";
})(APP_MODULES || (APP_MODULES = {}));
export var SALUTATIONS = [
    'Mr.', 'Mrs.', 'Ms.', 'Sir', 'Madam'
];
export var INVOICE_TYPE;
(function (INVOICE_TYPE) {
    INVOICE_TYPE["REPORT_FEE"] = "REPORT_FEE";
    INVOICE_TYPE["EXPENSES"] = "EXPENSES";
    INVOICE_TYPE["OTHER"] = "";
})(INVOICE_TYPE || (INVOICE_TYPE = {}));
export var WEIGHT_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export var DOLLAR_PER_UNIT = '$/Unit';
export var COVERAGE = 'FSI';
export var ADJUSTMENT_TYPES = ['%', '$', DOLLAR_PER_UNIT, COVERAGE];
export var EMPTY_AUTO_COMPLETE_OPTION = [{ id: 0, title: '' }];
export var NEGATIVE_AUTO_COMPLETE_OPTION = [{ id: -1, title: '' }];
export var GOOGLE_EDIT_URL = 'https://docs.google.com/document/d/';
export var INLINE_EDITOR;
(function (INLINE_EDITOR) {
    INLINE_EDITOR["ADD_OR_EDIT"] = "Manage Options...";
})(INLINE_EDITOR || (INLINE_EDITOR = {}));
export var APPRAISER_ROLE;
(function (APPRAISER_ROLE) {
    APPRAISER_ROLE[APPRAISER_ROLE["OWNER"] = 0] = "OWNER";
    APPRAISER_ROLE[APPRAISER_ROLE["REVIEWER"] = 1] = "REVIEWER";
})(APPRAISER_ROLE || (APPRAISER_ROLE = {}));
export var SUBSCRIPTION_STATUS;
(function (SUBSCRIPTION_STATUS) {
    SUBSCRIPTION_STATUS[SUBSCRIPTION_STATUS["ACTIVE"] = 0] = "ACTIVE";
    SUBSCRIPTION_STATUS[SUBSCRIPTION_STATUS["INACTIVE"] = 1] = "INACTIVE";
    SUBSCRIPTION_STATUS[SUBSCRIPTION_STATUS["DELETED"] = 2] = "DELETED";
    SUBSCRIPTION_STATUS[SUBSCRIPTION_STATUS["PAST"] = 3] = "PAST";
})(SUBSCRIPTION_STATUS || (SUBSCRIPTION_STATUS = {}));
export var SUBSCRIPTION_DURATION;
(function (SUBSCRIPTION_DURATION) {
    SUBSCRIPTION_DURATION[SUBSCRIPTION_DURATION["ONE_MONTH"] = 1] = "ONE_MONTH";
    SUBSCRIPTION_DURATION[SUBSCRIPTION_DURATION["THREE_MONTH"] = 3] = "THREE_MONTH";
    SUBSCRIPTION_DURATION[SUBSCRIPTION_DURATION["SIX_MONTH"] = 6] = "SIX_MONTH";
    SUBSCRIPTION_DURATION[SUBSCRIPTION_DURATION["YEAR"] = 12] = "YEAR";
})(SUBSCRIPTION_DURATION || (SUBSCRIPTION_DURATION = {}));
export var AppraiserRoles = [{ id: APPRAISER_ROLE.OWNER, ttile: 'Appraiser' }, { id: APPRAISER_ROLE.REVIEWER, ttile: 'Reviewer' }];
export var ADDONS;
(function (ADDONS) {
    ADDONS["QB"] = "QB0";
})(ADDONS || (ADDONS = {}));
export var REDIS_KEYS;
(function (REDIS_KEYS) {
    REDIS_KEYS["QB"] = "QB0";
})(REDIS_KEYS || (REDIS_KEYS = {}));
export var ADJUSTMENT_METHODOLOGY;
(function (ADJUSTMENT_METHODOLOGY) {
    ADJUSTMENT_METHODOLOGY[ADJUSTMENT_METHODOLOGY["QUANTITY"] = 1] = "QUANTITY";
    ADJUSTMENT_METHODOLOGY[ADJUSTMENT_METHODOLOGY["PERCENTAGE"] = 2] = "PERCENTAGE";
    ADJUSTMENT_METHODOLOGY[ADJUSTMENT_METHODOLOGY["QUALITY"] = 3] = "QUALITY";
})(ADJUSTMENT_METHODOLOGY || (ADJUSTMENT_METHODOLOGY = {}));
export var AdjustmentMethodology = [{ id: ADJUSTMENT_METHODOLOGY.QUANTITY, title: 'Quantitative' },
    { id: ADJUSTMENT_METHODOLOGY.PERCENTAGE, title: 'Percentage' },
    { id: ADJUSTMENT_METHODOLOGY.QUALITY, title: 'Qualitative' }];
export var POSTMARK_TEMPLATEID;
(function (POSTMARK_TEMPLATEID) {
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["SUBSCRIPTION_REMINDER"] = 34448336] = "SUBSCRIPTION_REMINDER";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["PAYMENT_FAILED"] = 34449202] = "PAYMENT_FAILED";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["VERIFICATION_CODE"] = 34449364] = "VERIFICATION_CODE";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["JOB_COMMENT"] = 34449479] = "JOB_COMMENT";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["JOB_ASSIGNED"] = 34449644] = "JOB_ASSIGNED";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["JOB_CREATED"] = 34449921] = "JOB_CREATED";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["JOB_UPDATED"] = 34501559] = "JOB_UPDATED";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["RESET_PASSWORD"] = 34736234] = "RESET_PASSWORD";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["QUOTE_APPROVAL"] = 35928677] = "QUOTE_APPROVAL";
    POSTMARK_TEMPLATEID[POSTMARK_TEMPLATEID["INVOICE"] = 35945048] = "INVOICE";
})(POSTMARK_TEMPLATEID || (POSTMARK_TEMPLATEID = {}));
export var RECORD_TYPE;
(function (RECORD_TYPE) {
    RECORD_TYPE[RECORD_TYPE["JOB"] = 1] = "JOB";
    RECORD_TYPE[RECORD_TYPE["INVOICE"] = 2] = "INVOICE";
    RECORD_TYPE[RECORD_TYPE["PAYMENT"] = 3] = "PAYMENT";
    RECORD_TYPE[RECORD_TYPE["COMMENT"] = 4] = "COMMENT";
    RECORD_TYPE[RECORD_TYPE["CLIENT"] = 5] = "CLIENT";
})(RECORD_TYPE || (RECORD_TYPE = {}));
export var ORDER_STATUS;
(function (ORDER_STATUS) {
    ORDER_STATUS[ORDER_STATUS["CREATED"] = 0] = "CREATED";
    ORDER_STATUS[ORDER_STATUS["ACCEPTED"] = 1] = "ACCEPTED";
    ORDER_STATUS[ORDER_STATUS["REJECTED"] = 2] = "REJECTED";
})(ORDER_STATUS || (ORDER_STATUS = {}));
export var APP_PACKAGES;
(function (APP_PACKAGES) {
    APP_PACKAGES["PACKAGE"] = "PACKAGE";
    APP_PACKAGES["CRUD"] = "CRUD";
    APP_PACKAGES["ORDERS"] = "ORDERS";
    APP_PACKAGES["ADDONS"] = "ADDONS";
    APP_PACKAGES["CLIENTJOBS"] = "CLIENTJOBS";
    APP_PACKAGES["SALEDETAILS"] = "SALEDETAILS";
    APP_PACKAGES["SALES"] = "SALES";
    APP_PACKAGES["VALUATIONLIST"] = "VALUATIONLIST";
    APP_PACKAGES["SALESIMPORT"] = "SALESIMPORT";
    APP_PACKAGES["VALUATION"] = "VALUATION";
    APP_PACKAGES["ACCOUNTING"] = "ACCOUNTING";
    APP_PACKAGES["BATCHPAYMENTS"] = "BATCHPAYMENTS";
    APP_PACKAGES["NOTE"] = "NOTE";
    APP_PACKAGES["ENGAGEMENTLETTER"] = "EngagementLetter";
    APP_PACKAGES["ADD_INVOICE"] = "AddInvoice";
    APP_PACKAGES["TIMELINE"] = "TimelineComponent";
    APP_PACKAGES["PROPERTY_SECTION"] = "PropertySections";
})(APP_PACKAGES || (APP_PACKAGES = {}));
export var FIELD_TYPE;
(function (FIELD_TYPE) {
    FIELD_TYPE["NONE"] = "";
    FIELD_TYPE[FIELD_TYPE["NUMBER"] = 1] = "NUMBER";
    FIELD_TYPE[FIELD_TYPE["TEXT"] = 2] = "TEXT";
    FIELD_TYPE[FIELD_TYPE["SINGLE_SELECT"] = 3] = "SINGLE_SELECT";
    FIELD_TYPE[FIELD_TYPE["Multi_SELECT"] = 7] = "Multi_SELECT";
    FIELD_TYPE[FIELD_TYPE["CURRENCY"] = 8] = "CURRENCY";
    FIELD_TYPE[FIELD_TYPE["TEXT_AREA"] = 9] = "TEXT_AREA";
    FIELD_TYPE[FIELD_TYPE["PERCENT"] = 4] = "PERCENT";
    FIELD_TYPE[FIELD_TYPE["DATE"] = 5] = "DATE";
    FIELD_TYPE[FIELD_TYPE["YES_NO"] = 6] = "YES_NO";
})(FIELD_TYPE || (FIELD_TYPE = {}));
export var SSE;
(function (SSE) {
    SSE["GLOBAL_EVENTS"] = "sse.event";
    SSE["CUSTOM_FIELDS"] = "CUSTOM_FIELDS";
})(SSE || (SSE = {}));
export var BULK_IMPORT_TABLES;
(function (BULK_IMPORT_TABLES) {
    BULK_IMPORT_TABLES["SALES"] = "SALES";
    BULK_IMPORT_TABLES["JOBS"] = "JOBS";
})(BULK_IMPORT_TABLES || (BULK_IMPORT_TABLES = {}));
export var CALENDAR_TYPE;
(function (CALENDAR_TYPE) {
    CALENDAR_TYPE["JOBS"] = "JOBS";
    CALENDAR_TYPE["EVENTS"] = "EVENTS";
})(CALENDAR_TYPE || (CALENDAR_TYPE = {}));
