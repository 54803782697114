import { configureStore } from '@reduxjs/toolkit';
import { clientReducer } from "./client.reducer";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./saga/sagas";
import { appReducer } from './app.reducer';
import { tableReducer } from "./table.reducer";
import { errorReducer } from "./error.reducer";
import { jobReducer } from "./job.reducer";
var sagaMiddleware = createSagaMiddleware();
var store = configureStore({
    reducer: {
        clients: clientReducer,
        app: appReducer,
        tableFilter: tableReducer,
        error: errorReducer,
        job: jobReducer,
    },
    middleware: function (getDefaultMiddleware) { return getDefaultMiddleware().concat(sagaMiddleware); },
});
sagaMiddleware.run(rootSaga);
export default store;
