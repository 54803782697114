var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Drawer from '@material-ui/core/Drawer';
import { getDrawerStatus } from "../../stores/app.selector";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setOpenDrawer } from '../../stores/app.reducer';
import { DrawerComponent } from "./drawer-component";
export var DrawerContainer = function () {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(null), component = _b[0], setComponent = _b[1];
    var drawerStatus = useSelector(getDrawerStatus);
    var dispatch = useDispatch();
    var toggleDrawer = function (open) { return function (event) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!open) {
            dispatch(setOpenDrawer({ status: 'CLOSE' }));
        }
        setOpen(open);
    }; };
    useEffect(function () {
        switch (drawerStatus === null || drawerStatus === void 0 ? void 0 : drawerStatus.status) {
            case 'OPEN': {
                setOpen(true);
                setComponent(__assign({}, drawerStatus));
                break;
            }
            case 'CLOSE': {
                setOpen(false);
                setComponent(null);
                break;
            }
        }
    }, [drawerStatus]);
    return (_jsx(Drawer, __assign({ anchor: 'right', open: open, onClose: toggleDrawer(false) }, { children: _jsx("div", __assign({ className: "drawer-big p-0" }, { children: _jsx(DrawerComponent, { component: drawerStatus === null || drawerStatus === void 0 ? void 0 : drawerStatus.component, id: drawerStatus === null || drawerStatus === void 0 ? void 0 : drawerStatus.id }, void 0) }), void 0) }), void 0));
};
