var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CREATE_ORDER, DELETE_ORDER, ORDER_BY_ID, UPDATE_ORDER } from "../graphqls/orders/orders.query";
import { formatAddressEntry } from "./job.service";
import { uuid } from "@utils/text";
import { getUserName } from "./user-service";
import { httpPostUpload } from "../helpers/http.helper";
import { DOCUMENT_TYPE } from "@enums";
export var loadOrderById = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: ORDER_BY_ID,
                    variables: {
                        id: id,
                    },
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data === null || data === void 0 ? void 0 : data.tblOrderById];
        }
    });
}); };
export var deleteOrderById = function (id, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apolloClient.query({
                    query: DELETE_ORDER,
                    variables: {
                        id: id,
                    },
                })];
            case 1:
                _a.sent();
                return [2 /*return*/, true];
        }
    });
}); };
export var upsertOrder = function (input, attachment, decription, apolloClient) { return __awaiter(void 0, void 0, void 0, function () {
    var city, clientId, countryName, county, jobDueDate, jobNumber, lat, lng, owner, postalCode, propertySubType, propertyType, province, reportTypeId, streetName, streetNo, id, valueType, interest, unit, data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                city = input.city, clientId = input.clientId, countryName = input.countryName, county = input.county, jobDueDate = input.jobDueDate, jobNumber = input.jobNumber, lat = input.lat, lng = input.lng, owner = input.owner, postalCode = input.postalCode, propertySubType = input.propertySubType, propertyType = input.propertyType, province = input.province, reportTypeId = input.reportTypeId, streetName = input.streetName, streetNo = input.streetNo, id = input.id, valueType = input.valueType, interest = input.interest, unit = input.unit;
                if (!id) return [3 /*break*/, 3];
                return [4 /*yield*/, apolloClient.query({
                        query: UPDATE_ORDER,
                        variables: {
                            id: id,
                            input: {
                                city: city,
                                countryName: countryName,
                                county: county,
                                jobDueDate: jobDueDate,
                                jobNumber: jobNumber,
                                lat: lat,
                                lng: lng,
                                owner: owner,
                                postalCode: postalCode,
                                propertySubType: propertySubType,
                                propertyType: propertyType,
                                province: province,
                                reportTypeId: reportTypeId,
                                streetName: streetName,
                                streetNo: streetNo,
                                unit: unit,
                                function: input.function,
                                valueType: valueType,
                                interest: interest,
                                zzFullAddress: formatAddressEntry(input),
                            },
                        },
                    })];
            case 1:
                _c.sent();
                return [4 /*yield*/, postUpsert(id, attachment, decription)];
            case 2: return [2 /*return*/, _c.sent()];
            case 3: return [4 /*yield*/, apolloClient.query({
                    query: CREATE_ORDER,
                    variables: {
                        input: {
                            city: city,
                            countryName: countryName,
                            county: county,
                            jobDueDate: jobDueDate,
                            jobNumber: jobNumber,
                            lat: lat,
                            lng: lng,
                            owner: owner,
                            postalCode: postalCode,
                            propertySubType: propertySubType,
                            propertyType: propertyType,
                            province: province,
                            function: input.function,
                            valueType: valueType,
                            interest: interest,
                            reportTypeId: reportTypeId,
                            streetName: streetName,
                            streetNo: streetNo,
                            unit: unit,
                            zzFullAddress: formatAddressEntry(input),
                            id: uuid(),
                            zzCreatedBy: getUserName(),
                            clientId: clientId,
                        },
                    },
                })];
            case 4:
                data = (_c.sent()).data;
                return [4 /*yield*/, postUpsert((_b = (_a = data === null || data === void 0 ? void 0 : data.createTblOrder) === null || _a === void 0 ? void 0 : _a.tblOrder) === null || _b === void 0 ? void 0 : _b.id, attachment, decription)];
            case 5: return [2 /*return*/, _c.sent()];
        }
    });
}); };
var postUpsert = function (id, attachment, decription) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(attachment && attachment.length)) return [3 /*break*/, 2];
                return [4 /*yield*/, httpPostUpload("document/upload/" + id, attachment, { documentType: DOCUMENT_TYPE.ORDERS, description: decription })];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/, id];
        }
    });
}); };
