var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { InputText } from "../input/Input-Field.component";
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalError } from "../../stores/error.selector";
import { setGlobalError } from '../../stores/error.reducer';
import { Typography } from "@material-ui/core";
import { groupBy } from 'lodash';
import { getAllFieldSelectOption } from '../../stores/app.selector';
import { OptionManager } from '../../components/options/Option-component';
import { handleFormula } from '../../utils/Formula.utility';
import { ReactDatePicker } from "../../ui-components/date-picker/DatePicker.component";
import { handleDateChanged, toDate } from "@utils/date";
import { DialogForm } from '../modals/DialogForm.component';
export var FormDynamic = function (_a) {
    var _b = _a.formFields, formFields = _b === void 0 ? [] : _b, onSubmit = _a.onSubmit, _c = _a.inline, inline = _c === void 0 ? false : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? false : _d, save = _a.save, _e = _a.divider, divider = _e === void 0 ? true : _e;
    var dispatch = useDispatch();
    var _f = useState(null), error = _f[0], setError = _f[1];
    var globalError = useSelector(getGlobalError);
    var fieldSelectOption = useSelector(getAllFieldSelectOption);
    var initValues = {};
    formFields.map(function (field) {
        var _a;
        initValues = __assign(__assign({}, initValues), (_a = {}, _a[field.fieldName] = field.defaultValue, _a));
        return null;
    });
    var formik = useFormik({
        initialValues: initValues,
        validate: function (values) {
            var errors = {};
            formFields.map(function (field) {
                var _a;
                if (field.required && !values[field.fieldName]) {
                    errors = __assign(__assign({}, errors), (_a = {}, _a[field.fieldName] = 'Required!', _a));
                }
                return null;
            });
            return errors;
        },
        onSubmit: function (values) {
            dispatch(setGlobalError(null));
            onSubmit(values);
        },
    });
    var groups = useMemo(function () { return Object.entries(groupBy(formFields, 'group')).map(function (_a) {
        var key = _a[0], value = _a[1];
        return ({ key: key.replace('undefined', ''), value: value });
    }); }, [formFields]);
    useEffect(function () {
        setError(globalError);
    }, [globalError]);
    var handleSelectChanges = function (field) { return function (e) {
        var _a, _b, _c, _d, _e;
        if (((_b = (_a = e === null || e === void 0 ? void 0 : e.nativeEvent) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.className.indexOf('MuiListItem-button')) !== -1 && +((_c = e === null || e === void 0 ? void 0 : e.target) === null || _c === void 0 ? void 0 : _c.value) === -10) {
            DialogForm.show({
                title: "" + field.title,
                footer: false,
                small: false,
                save: '',
                large: true,
                body: (_jsx(OptionManager, { fieldId: +(field.fieldId || '0') }, void 0))
            });
            return;
        }
        formik.setFieldValue(field.fieldName, field.type === 'string' ? (_d = e === null || e === void 0 ? void 0 : e.target) === null || _d === void 0 ? void 0 : _d.value : +((_e = e === null || e === void 0 ? void 0 : e.target) === null || _e === void 0 ? void 0 : _e.value));
    }; };
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [groups.map(function (group) { return (_jsxs(Card, __assign({ className: group.key ? "" : 'border-0' }, { children: [_jsx(CardHeader, __assign({ className: group.key ? "p-2" : 'none' }, { children: _jsxs("div", __assign({ className: "dib w-100" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: group.key }), void 0)] }), void 0) }), void 0),
                    _jsx(CardBody, __assign({ className: 'pl-4' }, { children: group.value.filter(function (f) { return !f.hidden; }).map(function (field, index) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                            return (_jsxs("div", __assign({ className: "block mt-16 " + (['number', 'date'].includes(field.type || 'text') ? "max-w-" + (inline ? '' : '150') : '') }, { children: [inline && (_jsx(Typography, __assign({ title: field.title, color: ((_a = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _a === void 0 ? void 0 : _a[field.fieldName]) ? 'error' : 'initial', variant: "caption", className: "dib w-30 right pr-2 v-middle" }, { children: field.title }), void 0)),
                                    _jsx("div", __assign({ className: "dib w-" + (inline ? '70' : '100') }, { children: (((_b = field.options) === null || _b === void 0 ? void 0 : _b.length) || field.type === 'select') ? (_jsxs(InputText, __assign({ select: true, type: field.type || 'text', name: field.fieldName, id: field.fieldName, defaultValue: field.defaultValue, value: (_c = formik === null || formik === void 0 ? void 0 : formik.values) === null || _c === void 0 ? void 0 : _c[field.fieldName], onChange: handleSelectChanges(field), size: "small", extendMenu: !((_d = field.options) === null || _d === void 0 ? void 0 : _d.length), className: fullWidth ? "w-100" : "min-w-200 max-w-300", label: inline ? '' : field.title }, { children: [(_e = field === null || field === void 0 ? void 0 : field.options) === null || _e === void 0 ? void 0 : _e.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.title }), o.id)); }),
                                                (_f = fieldSelectOption === null || fieldSelectOption === void 0 ? void 0 : fieldSelectOption.filter(function (c) { return +c.fieldId === +(field.fieldId || '0'); })) === null || _f === void 0 ? void 0 : _f.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.option }), o.id)); })] }), void 0)) : (field.type === 'date' ?
                                            _jsx(ReactDatePicker, { className: "min-w-150", id: field.fieldName, label: inline ? '' : field.title, block: !inline, selected: ((_g = formik === null || formik === void 0 ? void 0 : formik.values) === null || _g === void 0 ? void 0 : _g[field.fieldName]) ? toDate((_h = formik === null || formik === void 0 ? void 0 : formik.values) === null || _h === void 0 ? void 0 : _h[field.fieldName]) : '', onChange: handleDateChanged(formik, field.fieldName) }, void 0)
                                            :
                                                _jsx(InputText, { helperText: !inline && ((_j = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _j === void 0 ? void 0 : _j[field.fieldName]) || '', type: field.type || 'text', name: field.fieldName, id: field.fieldName, step: field.type === "number" ? ".01" : "any", value: (_k = formik === null || formik === void 0 ? void 0 : formik.values) === null || _k === void 0 ? void 0 : _k[field.fieldName], onChange: formik.handleChange, prepend: field.noPrepend ? '' : field.prepend || "$", InputProps: field.type === 'number' && !field.noPrepend, fullWidth: !field.type || ['text', 'area'].includes(field.type), size: !field.type || ['text', 'area'].includes(field.type) ? 'small' : 'sm', defaultValue: field.defaultValue, className: field.type === 'number' ? "max-w-150" : "", placeholder: ((_l = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _l === void 0 ? void 0 : _l[field.fieldName]) ? '' : field.title, onBlur: handleFormula(field, formik), multiline: field.type === 'area', rows: field.type === 'area' ? 5 : 0, label: inline ? '' : field.title }, void 0)) }), void 0),
                                    inline && ((_m = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _m === void 0 ? void 0 : _m[field.fieldName]) && (_jsx("div", __assign({ className: 'error pl-8' }, { children: (_o = formik === null || formik === void 0 ? void 0 : formik.errors) === null || _o === void 0 ? void 0 : _o[field.fieldName] }), void 0))] }), "form_field_" + field.title));
                        }) }), void 0)] }), "card_" + group.key)); }),
            (error === null || error === void 0 ? void 0 : error.error) && (_jsx("div", __assign({ className: 'error mt-16 center' }, { children: error.error }), void 0)),
            divider && (_jsx(Divider, { className: "mt-8" }, void 0)),
            _jsx("div", __assign({ className: 'block mt-8 right' }, { children: _jsxs(Button, __assign({ type: "submit", color: "info", className: "mr-16 btn btn-xs" }, { children: [!save && _jsx("i", { className: "fa fa-save" }, void 0), " ", save || 'Save Changes'] }), void 0) }), void 0)] }), void 0));
};
