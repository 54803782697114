var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { ColumnType } from '@enums';
export var UPDATE_PAYMENT = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation updateTblPaymentById($id: BigInt!, $input: TblPaymentPatch!) {\n  updateTblPaymentById(input: { tblPaymentPatch: $input, id: $id }) {\n    clientMutationId\n  }\n}\n"], ["mutation updateTblPaymentById($id: BigInt!, $input: TblPaymentPatch!) {\n  updateTblPaymentById(input: { tblPaymentPatch: $input, id: $id }) {\n    clientMutationId\n  }\n}\n"])));
export var GET_PAYMENTS_BY_BATCHID = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["query allTblPaymentsByBatch($batchId: String!) {\n  allTblPayments(condition: { batchId: $batchId }) {\n    nodes {\n      id\n    }\n  }\n}\n"], ["query allTblPaymentsByBatch($batchId: String!) {\n  allTblPayments(condition: { batchId: $batchId }) {\n    nodes {\n      id\n    }\n  }\n}\n"])));
export var UPDATE_BATCH_PAYMENT = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmutation updateTblBatchPaymentById($id: BigInt!, $input: TblBatchPaymentPatch!) {\n  updateTblBatchPaymentById(input: { tblBatchPaymentPatch: $input, id: $id }) {\n    clientMutationId\n  }\n}\n"], ["\nmutation updateTblBatchPaymentById($id: BigInt!, $input: TblBatchPaymentPatch!) {\n  updateTblBatchPaymentById(input: { tblBatchPaymentPatch: $input, id: $id }) {\n    clientMutationId\n  }\n}\n"])));
export var ADDPAYMENT = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation addPayment($input:TblPaymentInput!) {\n        createTblPayment(input: {tblPayment: $input}) {\n            tblPayment {\n              id\n              amount\n              description\n              paymentDate\n              paymentType\n              refNumber\n              zzCreatedBy\n              tblJobByJobId {\n                tblClientByClientId {\n                  qbId\n                }\n              }\n            }\n          }\n    }\n  "], ["\n    mutation addPayment($input:TblPaymentInput!) {\n        createTblPayment(input: {tblPayment: $input}) {\n            tblPayment {\n              id\n              amount\n              description\n              paymentDate\n              paymentType\n              refNumber\n              zzCreatedBy\n              tblJobByJobId {\n                tblClientByClientId {\n                  qbId\n                }\n              }\n            }\n          }\n    }\n  "])));
export var DELETE_PAYMENT = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nmutation deleteById($id: BigInt!) {\n  deleteTblPaymentById(input: { id: $id }) {\n    tblPayment {\n      id\n      qbId\n    }\n  }\n}"], ["\nmutation deleteById($id: BigInt!) {\n  deleteTblPaymentById(input: { id: $id }) {\n    tblPayment {\n      id\n      qbId\n    }\n  }\n}"])));
export var ADD_BULK_PAYMENT = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation addPayment($input:TblPaymentInput!) {\n        createTblPayment(input: {tblPayment: $input}) {\n            tblPayment {\n              id\n            }\n          }\n    }\n  "], ["\n    mutation addPayment($input:TblPaymentInput!) {\n        createTblPayment(input: {tblPayment: $input}) {\n            tblPayment {\n              id\n            }\n          }\n    }\n  "])));
export var GETBYCLIENT = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["query getByClient($filter: TblPaymentFilter, $orderBy: [TblPaymentsOrderBy!]) {\n  allTblPayments(filter: $filter ,orderBy: $orderBy) {\n    nodes {\n      amount\n      description\n      id\n      jobId\n      paymentDate\n      paymentType\n      refNumber\n      zzCreatedDate\n      zzCreatedBy\n    }\n    totalCount\n  }\n}"], ["query getByClient($filter: TblPaymentFilter, $orderBy: [TblPaymentsOrderBy!]) {\n  allTblPayments(filter: $filter ,orderBy: $orderBy) {\n    nodes {\n      amount\n      description\n      id\n      jobId\n      paymentDate\n      paymentType\n      refNumber\n      zzCreatedDate\n      zzCreatedBy\n    }\n    totalCount\n  }\n}"])));
export var GETBYJOBID = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    query getPayments($id:BigInt) {\n        allTblPayments(condition: {jobId: $id}) {\n        nodes {\n            amount\n            description\n            id\n            paymentDate\n            paymentType\n            refNumber\n            zzCreatedBy\n            zzCreatedDate\n        }\n        }\n    }\n"], ["\n    query getPayments($id:BigInt) {\n        allTblPayments(condition: {jobId: $id}) {\n        nodes {\n            amount\n            description\n            id\n            paymentDate\n            paymentType\n            refNumber\n            zzCreatedBy\n            zzCreatedDate\n        }\n        }\n    }\n"])));
export var ALL_TBL_BATCH_PAYMENTS = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["query allTblBatchPayments(\n  $offset: Int\n  $first: Int\n  $orderBy: [TblBatchPaymentsOrderBy!]\n  $filter: TblBatchPaymentFilter\n) {\n  allTblBatchPayments(\n    first: $first\n    offset: $offset\n    orderBy: $orderBy\n    filter: $filter\n  ) {\n    nodes {\n      amount\n      batchId\n      description\n      id\n      paymentDate\n      refNo\n      type\n      zzCreatedBy\n      zzModifiedDate\n    }\n    totalCount\n  }\n}\n"], ["query allTblBatchPayments(\n  $offset: Int\n  $first: Int\n  $orderBy: [TblBatchPaymentsOrderBy!]\n  $filter: TblBatchPaymentFilter\n) {\n  allTblBatchPayments(\n    first: $first\n    offset: $offset\n    orderBy: $orderBy\n    filter: $filter\n  ) {\n    nodes {\n      amount\n      batchId\n      description\n      id\n      paymentDate\n      refNo\n      type\n      zzCreatedBy\n      zzModifiedDate\n    }\n    totalCount\n  }\n}\n"])));
export var GET_PAYMENTS_BY_BATCH_ID = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["query allTblPayments($batch: String) {\n  allTblPayments(condition: {batchId: $batch}) {\n    nodes {\n      amount\n      tblJobByJobId {\n        city\n        countryName\n        county\n        fileNumber\n        id\n        jobNumber\n        postalCode\n        province\n        streetName\n        streetNo\n        unit\n        zzFullAddress\n        tblClientByClientId {\n          name\n        }\n        tblStatusByStatusId {\n          statusTitle\n        }\n      }\n    }\n  }\n}\n"], ["query allTblPayments($batch: String) {\n  allTblPayments(condition: {batchId: $batch}) {\n    nodes {\n      amount\n      tblJobByJobId {\n        city\n        countryName\n        county\n        fileNumber\n        id\n        jobNumber\n        postalCode\n        province\n        streetName\n        streetNo\n        unit\n        zzFullAddress\n        tblClientByClientId {\n          name\n        }\n        tblStatusByStatusId {\n          statusTitle\n        }\n      }\n    }\n  }\n}\n"])));
export var BATCHPAYMENT_JOBS_COLUMNS = [
    {
        caption: "id",
        fieldName: "tblJobByJobId.id",
        sortField: "",
        isHidden: true,
        isPrimaryKey: true,
    },
    {
        caption: "Address",
        fieldName: "tblJobByJobId.zzFullAddress",
    },
    {
        caption: "Client",
        fieldName: "tblJobByJobId.tblClientByClientId.name",
    },
    {
        caption: "Status",
        fieldName: "tblJobByJobId.tblStatusByStatusId.statusTitle",
    },
    {
        caption: "Payment Amount",
        fieldName: "amount",
        sortField: "",
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
];
export var BATCHPAYMENT_COLUMNS = [
    {
        caption: "id",
        fieldName: "batchId",
        sortField: "",
        isHidden: true,
        isPrimaryKey: true,
    },
    {
        caption: "Payment Date",
        fieldName: "paymentDate",
        sortField: "PAYMENT_DATE",
        sortable: true,
        columnType: ColumnType.DATE_ONLY,
        columnFilter: true,
        columnFilterField: 'paymentDate',
    },
    {
        caption: "Amount",
        fieldName: "amount",
        sortField: "AMOUNT",
        sortable: true,
        totalInFooter: true,
        columnType: ColumnType.CURRENCY,
    },
    {
        caption: "Payment Type",
        fieldName: "type",
        sortable: true,
        sortField: "TYPE",
        columnFilter: true,
        columnFilterField: 'type',
    },
    {
        caption: "Ref#",
        fieldName: "refNo",
        sortField: "",
        columnFilter: true,
        columnFilterField: 'refNo',
    },
    {
        caption: "Description",
        fieldName: "description",
        sortField: "",
    },
    {
        caption: "Created By",
        fieldName: "zzCreatedBy",
        sortField: "",
    },
    {
        caption: "Modified By",
        fieldName: "zzModifiedDate",
        sortField: "",
        columnType: ColumnType.DATE_ONLY,
        isHidden: true,
    },
];
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
