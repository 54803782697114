var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldArray } from "formik";
import { Button, Col } from "reactstrap";
import { QuoteOptionType } from "@enums";
import { InputText } from '../../ui-components/input/Input-Field.component';
export var JobExpense = function (_a) {
    var _b = _a.fieldName, fieldName = _b === void 0 ? '' : _b, _c = _a.quoteOptions, quoteOptions = _c === void 0 ? null : _c, _d = _a.handleChange, handleChange = _d === void 0 ? null : _d, _e = _a.form, form = _e === void 0 ? {} : _e, _f = _a.xs, xs = _f === void 0 ? 3 : _f, _g = _a.mt, mt = _g === void 0 ? 'mt-16' : _g, _h = _a.addNew, addNew = _h === void 0 ? null : _h;
    var addNewExpense = function () {
        addNew && addNew();
    };
    return (_jsx(FieldArray, { name: fieldName, render: function () { return (_jsxs(_Fragment, { children: [(quoteOptions || []).filter(function (c) { return c.optionType === QuoteOptionType.EXPENSES; }).map(function (type, index) {
                    var _a, _b;
                    return (_jsx(Col, __assign({ xs: xs, className: mt + (index > 0 ? ' mt-4' : '') }, { children: _jsx(InputText, { onChange: handleChange, name: fieldName + "." + index + ".value" + type.id, prepend: "$", InputProps: true, type: "number", step: ".01", size: "sm", id: fieldName + "." + index + ".value" + type.id, defaultValue: (_b = (_a = form === null || form === void 0 ? void 0 : form.expenseIds) === null || _a === void 0 ? void 0 : _a.find(function (c) { var _a; return +((_a = c === null || c === void 0 ? void 0 : c.tblOptionByOptionId) === null || _a === void 0 ? void 0 : _a.id) === +type.id; })) === null || _b === void 0 ? void 0 : _b.value, label: type.title }, void 0) }), void 0));
                }),
                _jsx(Col, __assign({ xs: 12, className: "flex justify-content-center mt-4" }, { children: _jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue btn btn-secondary", onClick: addNewExpense }, { children: [_jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0), " Add a new Expense"] }), void 0) }), void 0)] }, void 0)); } }, void 0));
};
