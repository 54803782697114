var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IconButton, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { map, reduce, sumBy } from 'lodash';
import { Table } from 'reactstrap';
import { GETBYJOBID, ADDNEWINVOICE, DELETE_INVOICE } from '../../graphqls/invoices/invoice.query';
import { FormDynamic } from "../../ui-components/forms/Form-Dynamic";
import { Spinner } from '../../ui-components/loading/Spinner.component';
import { formatTotalAsCurrency, excludeHst } from '../../helpers/numbers-helper.helper';
import SendIcon from '@material-ui/icons/Send';
import { httpGet, httpPost } from '../../helpers/http.helper';
import { ModalForm } from '../../ui-components/modals/Modal-Form.component';
import { InvoiceStatus } from "@enums";
import { canEditRequest, isAppraiser } from '../../services/user-service';
import { ADDPAYMENT } from '../../graphqls/payments/payment.query';
import { DeleteButton } from '../../ui-components/button/Delete-Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAppModules, getTriggerInvoice } from '../../stores/app.selector';
import { InvoicePreview } from "./Invoice-Preview.component";
import { formatDate } from "@utils/date";
import { isMobile } from 'react-device-detect';
import { getCompanyTaxPercent } from '../../utils/Storage.util';
import { EmptyDataError } from '../../ui-components/table/EmptyDataError';
import { Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
export var AddInvoice = function (_a) {
    var _b;
    var _c = _a.jobId, jobId = _c === void 0 ? "" : _c, _d = _a.modal, modal = _d === void 0 ? false : _d, _e = _a.onPaymentReceived, onPaymentReceived = _e === void 0 ? null : _e;
    var _f = useState([]), payments = _f[0], setPayments = _f[1];
    var _isAppraiser = isAppraiser();
    var editable = canEditRequest();
    var triggerInvoice = useSelector(getTriggerInvoice);
    var dispatch = useDispatch();
    var appModule = useSelector(getAppModules);
    var getByJobId = useLazyQuery(GETBYJOBID, {
        onCompleted: function (data) {
            setPayments(data.allTblInvoices.nodes);
            Spinner.hide();
        }
    })[0];
    var deleteInvoice = useMutation(DELETE_INVOICE, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        setPayments(payments.reduce(function (result, curr) {
                            var _a, _b;
                            if (+curr.id === +((_b = (_a = data === null || data === void 0 ? void 0 : data.updateTblInvoiceById) === null || _a === void 0 ? void 0 : _a.tblInvoice) === null || _b === void 0 ? void 0 : _b.id)) {
                                result.push(__assign(__assign({}, curr), { status: InvoiceStatus.VOID }));
                            }
                            else {
                                result.push(curr);
                            }
                            return result;
                        }, []));
                        if (!((_b = (_a = data === null || data === void 0 ? void 0 : data.updateTblInvoiceById) === null || _a === void 0 ? void 0 : _a.tblInvoice) === null || _b === void 0 ? void 0 : _b.qbId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, httpGet("intuit/voidAnInvoice/" + ((_d = (_c = data === null || data === void 0 ? void 0 : data.updateTblInvoiceById) === null || _c === void 0 ? void 0 : _c.tblInvoice) === null || _d === void 0 ? void 0 : _d.qbId))];
                    case 1:
                        _e.sent();
                        _e.label = 2;
                    case 2:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var addNewInvoice = useMutation(ADDNEWINVOICE, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            return __generator(this, function (_x) {
                switch (_x.label) {
                    case 0:
                        setPayments(payments.concat((_a = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _a === void 0 ? void 0 : _a.tblInvoice));
                        if (!((_e = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _b === void 0 ? void 0 : _b.tblInvoice) === null || _c === void 0 ? void 0 : _c.tblJobByJobId) === null || _d === void 0 ? void 0 : _d.tblClientByClientId) === null || _e === void 0 ? void 0 : _e.qbId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, httpPost("intuit/createInvoices", {
                                link: process.env.REACT_APP_FRONT_END_URl + "/admin/appraisals/all/view/" + ((_g = (_f = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _f === void 0 ? void 0 : _f.tblInvoice) === null || _g === void 0 ? void 0 : _g.id),
                                amount: excludeHst(+((_j = (_h = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _h === void 0 ? void 0 : _h.tblInvoice) === null || _j === void 0 ? void 0 : _j.rate), +((_l = (_k = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _k === void 0 ? void 0 : _k.tblInvoice) === null || _l === void 0 ? void 0 : _l.hst)),
                                customer: (_q = (_p = (_o = (_m = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _m === void 0 ? void 0 : _m.tblInvoice) === null || _o === void 0 ? void 0 : _o.tblJobByJobId) === null || _p === void 0 ? void 0 : _p.tblClientByClientId) === null || _q === void 0 ? void 0 : _q.qbId,
                                id: (_s = (_r = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _r === void 0 ? void 0 : _r.tblInvoice) === null || _s === void 0 ? void 0 : _s.id,
                                type: ((_u = (_t = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _t === void 0 ? void 0 : _t.tblInvoice) === null || _u === void 0 ? void 0 : _u.description) || '',
                                dueDate: ((_w = (_v = data === null || data === void 0 ? void 0 : data.createTblInvoice) === null || _v === void 0 ? void 0 : _v.tblInvoice) === null || _w === void 0 ? void 0 : _w.dueDate) || '',
                            })];
                    case 1:
                        _x.sent();
                        _x.label = 2;
                    case 2:
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var addNewPayment = useMutation(ADDPAYMENT, {
        onCompleted: function (data) {
            Spinner.hide();
            onPaymentReceived && onPaymentReceived();
        }
    })[0];
    var paymentReceived = function (index, id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            Spinner.show();
            addNewPayment({
                variables: {
                    input: {
                        description: payments[index].description,
                        amount: +payments[index].rate,
                        refNumber: '',
                        paymentDate: new Date(),
                        paymentType: '',
                        jobId: +jobId,
                    }
                }
            });
            return [2 /*return*/];
        });
    }); };
    var previewInvoice = function (index, pay) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            ModalForm.show({
                title: 'Invoice Preview',
                scope: 'send-invoice-button',
                save: 'Send invoice',
                body: (_jsx(InvoicePreview, { email: (_b = (_a = pay === null || pay === void 0 ? void 0 : pay.tblJobByJobId) === null || _a === void 0 ? void 0 : _a.tblClientByClientId) === null || _b === void 0 ? void 0 : _b.email, index: index, id: pay.id, onSend: sendInvoice }, void 0)),
            });
            return [2 /*return*/];
        });
    }); };
    var sendInvoice = function (index, id, email, signature, cc, templateId) { return __awaiter(void 0, void 0, void 0, function () {
        var newData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpPost("invoice/send/" + id, {
                            email: email,
                            signature: signature,
                            cc: cc,
                            templateId: templateId,
                        })];
                case 1:
                    _a.sent();
                    newData = reduce(payments, function (result, item, i) {
                        if (i === index) {
                            result.push(__assign(__assign({}, item), { isSent: true, sentDate: new Date(), status: InvoiceStatus.SENT }));
                        }
                        else {
                            result.push(item);
                        }
                        return result;
                    }, []);
                    setPayments(newData);
                    Spinner.hide();
                    if (modal) {
                        ModalForm.hide({ id: true });
                    }
                    else {
                        ModalForm.close();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var saveRow = function (data) {
        Spinner.show();
        var pay = __assign({}, data);
        if (+pay.id === 0) {
            delete pay.id;
            delete pay.edit;
            pay = __assign(__assign({}, pay), { rate: pay.total, hst: pay.hst / 100 });
            delete pay.total;
            addNewInvoice({
                variables: {
                    input: __assign(__assign({}, pay), { jobId: jobId, comissionType: +pay.comissionType })
                }
            });
        }
        else {
        }
    };
    var removeRow = function (id) {
        Spinner.show();
        deleteInvoice({
            variables: {
                id: id,
                status: InvoiceStatus.VOID,
            },
        });
    };
    useEffect(function () {
        if (triggerInvoice === 'OPEN') {
            addNewRow();
        }
    }, [triggerInvoice]);
    var addNewRow = function () {
        ModalForm.show({
            title: 'Add Fee',
            md: true,
            footer: false,
            dispatch: dispatch,
            body: _jsx(FormDynamic, { formFields: [
                    { fieldName: 'description', title: 'Description' },
                    { fieldName: 'rate', title: 'Rate', type: 'number', required: true, formula: "total=[rate]+[rate]*([hst]/100)" },
                    { fieldName: 'hst', title: 'HST', type: 'number', required: true, defaultValue: getCompanyTaxPercent() * 100, formula: "total=[rate]+[rate]*([hst]/100)", prepend: '%' },
                    { fieldName: 'total', title: 'Total', type: 'number', formula: "[rate]+[rate]*([hst]/100)" },
                    { fieldName: 'comission', title: 'Compensation', type: 'number', required: false, noPrepend: true, defaultValue: 0, hidden: true },
                    { fieldName: 'comissionType', title: 'Commission Type', defaultValue: 0, options: [{ id: 0, title: '%' }, { id: 1, title: '$' }], hidden: true },
                    { fieldName: 'invoiceDate', title: 'Invoice Date', type: 'date', defaultValue: formatDate(new Date(), 'yyyy-MM-dd'), required: true },
                    { fieldName: 'dueDate', title: 'Due Date', type: 'date' },
                    { fieldName: 'id', title: 'id', type: 'number', defaultValue: 0, hidden: true },
                    { fieldName: 'billClient', title: 'billClient', type: 'text', defaultValue: true, hidden: true },
                    { fieldName: 'status', title: 'status', type: 'text', defaultValue: InvoiceStatus.NEW, hidden: true },
                    { fieldName: 'isSent', title: 'isSent', type: 'text', defaultValue: false, hidden: true },
                ], onSubmit: function (data) {
                    saveRow(data);
                    ModalForm.close();
                } }, void 0)
        });
    };
    var calcExpenses = function (pay, includeHst) {
        var _a, _b;
        if (includeHst === void 0) { includeHst = false; }
        var total = sumBy((_b = (_a = pay.tblJobByJobId) === null || _a === void 0 ? void 0 : _a.tblExpensesByJobId) === null || _b === void 0 ? void 0 : _b.nodes, function (i) { return +i.value; }) || 0;
        if (includeHst) {
            total = total * (1 + pay.hst);
        }
        return total;
    };
    useEffect(function () {
        getByJobId({
            variables: {
                id: jobId,
            }
        });
    }, [jobId]);
    return (!(payments === null || payments === void 0 ? void 0 : payments.length) ? _jsx(EmptyDataError, { className: "db center" }, void 0) : _jsxs(Table, __assign({ responsive: isMobile, className: "table-small" }, { children: [_jsx(TableHead, __assign({ className: 'text-primary' }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Description" }, void 0),
                        _jsx(TableCell, { children: "Rate" }, void 0),
                        _jsx(TableCell, { children: "HST" }, void 0),
                        _jsx(TableCell, { children: "Total" }, void 0),
                        _jsx(TableCell, { children: "Invoice Date" }, void 0),
                        editable && (_jsx(TableCell, { children: modal ? 'Send' : 'Action' }, void 0))] }, void 0) }), void 0),
            _jsxs(TableBody, { children: [map(payments, function (pay, index) {
                        var _a, _b, _c;
                        return (_jsxs(React.Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: pay.description }, void 0),
                                        _jsx(TableCell, { children: formatTotalAsCurrency(excludeHst(+pay.rate, +pay.hst) - calcExpenses(pay)) }, void 0),
                                        _jsxs(TableCell, { children: [+pay.hst * 100, "%"] }, void 0),
                                        _jsx(TableCell, { children: formatTotalAsCurrency(parseFloat(pay.rate) - calcExpenses(pay, true)) }, void 0),
                                        _jsx(TableCell, { children: pay.invoiceDate }, void 0),
                                        editable && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsxs(TableCell, __assign({ className: 'flex gap-8' }, { children: [!pay.isSent && !_isAppraiser && pay.status !== InvoiceStatus.VOID && !pay.autoGenerated && (_jsx(DeleteButton, { toolTip: "Void", title: 'Confirmation', body: 'Are you sure about Voiding this invoice?', index: pay === null || pay === void 0 ? void 0 : pay.id, onClick: function (row) { return removeRow(row); } }, void 0)),
                                                ( /*!pay.isSent &&*/pay.status !== InvoiceStatus.VOID) ? (_jsx(IconButton, __assign({ title: "Send invoice", className: "btn-xs-no-padd ml-8", onClick: function () { return previewInvoice(index, pay); }, "aria-label": "Send invoice" }, { children: _jsx(SendIcon, { style: { fontSize: 20 } }, void 0) }), void 0)) : (_jsx(_Fragment, { children: pay.status !== InvoiceStatus.VOID ? 'Sent' : 'Void' }, void 0)),
                                                (pay === null || pay === void 0 ? void 0 : pay.sentDate) && pay.status !== InvoiceStatus.VOID && _jsx(Tooltip, __assign({ title: 'sent on ' + formatDate(pay === null || pay === void 0 ? void 0 : pay.sentDate, 'yyyy-MM-dd') }, { children: _jsx(DoneIcon, { style: { fontSize: 20, color: 'green' } }, void 0) }), void 0)] }), void 0))] }, void 0),
                                (_c = (_b = (_a = pay.tblJobByJobId) === null || _a === void 0 ? void 0 : _a.tblExpensesByJobId) === null || _b === void 0 ? void 0 : _b.nodes) === null || _c === void 0 ? void 0 : _c.map(function (exp, i) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: exp.tblOptionByOptionId.title }, void 0),
                                        _jsx(TableCell, { children: formatTotalAsCurrency(parseFloat(exp.value)) }, void 0),
                                        _jsxs(TableCell, { children: [+pay.hst * 100, "%"] }, void 0),
                                        _jsx(TableCell, { children: formatTotalAsCurrency(parseFloat(exp.value) * (1 + pay.hst)) }, void 0),
                                        _jsx(TableCell, { children: pay.invoiceDate }, void 0)] }, "expense_row_" + i)); })] }, index));
                    }),
                    _jsxs(TableRow, __assign({ className: 'row-total' }, { children: [_jsx(TableCell, { children: _jsx("strong", { children: "Total" }, void 0) }, void 0),
                            _jsx(TableCell, { children: _jsx("strong", { children: formatTotalAsCurrency(sumBy(payments, function (i) { return +i.rate; }) / (1 + (((_b = payments === null || payments === void 0 ? void 0 : payments[0]) === null || _b === void 0 ? void 0 : _b.hst) || 0))) }, void 0) }, void 0),
                            _jsx(TableCell, {}, void 0),
                            _jsx(TableCell, __assign({ className: "total" }, { children: _jsx("strong", { children: formatTotalAsCurrency(sumBy(payments, function (i) { return +i.rate; })) }, void 0) }), void 0)] }), void 0)] }, void 0)] }), void 0));
};
