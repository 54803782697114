var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { useFormik } from 'formik';
import { InputText } from "../../ui-components/input/Input-Field.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COMPANY, UPDATE_COMPANY } from "../../graphqls/settings/company.query";
import { Avatar, MenuItem, Tab, Tabs } from "@material-ui/core";
import { addToStorage, STORAGE_KEY } from "../../utils/Storage.util";
import { deleteOffice, getJwtToken, upsertOffices } from "../../services/user-service";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { httpPostUpload } from "../../helpers/http.helper";
import { TextEditor } from "../../ui-components/editor/Text-Editor.component";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ModalForm } from "../../ui-components/modals/Modal-Form.component";
import { FormDynamic } from "../../ui-components/forms/Form-Dynamic";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import InfoIcon from '@material-ui/icons/Info';
import { DeleteButton } from "../../ui-components/button/Delete-Button";
import { AddressComponent } from "../../ui-components/Address.component";
import { useSelector } from "react-redux";
import { getAppModules } from "../../stores/app.selector";
import { isMobile } from 'react-device-detect';
import { uniqueId } from "lodash";
import { Tooltip } from "@material-ui/core";
export var Company = function () {
    var _a, _b;
    var _c = useState(0), activeStep = _c[0], setActiveStep = _c[1];
    var _d = useState(''), templateId = _d[0], setTemplateId = _d[1];
    var _e = useState([]), offices = _e[0], setOffices = _e[1];
    var _f = useState({}), attachments = _f[0], setAttachments = _f[1];
    var currentTemplate = useRef('');
    var apolloClient = useApolloQuery()[0];
    var appModule = useSelector(getAppModules);
    var formik = useFormik({
        initialValues: {
            name: '',
            zzFullAddress: '',
            phone: '',
            fax: '',
            id: 0,
            logo: '',
            email: '',
            engagementLetter: '',
            emailTemplate: '',
            invoiceTemplate: {},
            lat: 0,
            lng: 0,
            tax: 0,
        },
        onSubmit: function (values) {
        },
    });
    var addressChanged = function (e) {
        formik.setFieldValue('zzFullAddress', e.zzFullAddress);
        formik.setFieldValue('lat', e.lat);
        formik.setFieldValue('lng', e.lng);
    };
    var addNewOffice = function () {
        setOffices(offices.concat({
            name: formik.values.name,
            phone: formik.values.phone,
            fax: formik.values.fax,
            email: formik.values.email,
            address: formik.values.zzFullAddress,
            lat: formik.values.lat || 0,
            lng: formik.values.lng || 0,
            engLetter: '',
            id: uniqueId('-'),
        }));
    };
    var changeTabs = function (tabId) {
        setActiveStep(tabId);
    };
    var templateContentChanged = function (content) {
        var _a;
        var _b;
        var invoiceTemplate = __assign(__assign({}, (_b = formik.values) === null || _b === void 0 ? void 0 : _b.invoiceTemplate), (_a = {}, _a[currentTemplate.current] = content, _a));
        formik.setFieldValue('invoiceTemplate', invoiceTemplate);
    };
    var updateCompany = useMutation(UPDATE_COMPANY, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var ofc;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        addToStorage(STORAGE_KEY.COMPANY, (_a = data === null || data === void 0 ? void 0 : data.updateTblCompanyById) === null || _a === void 0 ? void 0 : _a.tblCompany);
                        return [4 /*yield*/, upsertOffices(offices, attachments)(apolloClient)];
                    case 1:
                        ofc = _b.sent();
                        setOffices(ofc);
                        Spinner.hide();
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var removeOffice = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!id) return [3 /*break*/, 3];
                    if (!("" + id).startsWith('-')) return [3 /*break*/, 1];
                    setOffices(offices.filter(function (o) { return o.id != id; }));
                    return [2 /*return*/];
                case 1:
                    Spinner.show();
                    return [4 /*yield*/, deleteOffice(id, apolloClient)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    getById();
                    return [2 /*return*/];
            }
        });
    }); };
    var downloadFile = function (key) { return function () {
        window.open(process.env.REACT_APP_EXTERNAL_API_URL + "document/documentTemplate/" + key + "/" + getJwtToken());
    }; };
    var deleteTemplate = useCallback(function () {
        var _a;
        var invoiceTemplate = __assign({}, (_a = formik.values) === null || _a === void 0 ? void 0 : _a.invoiceTemplate);
        delete invoiceTemplate[currentTemplate.current];
        formik.setFieldValue('invoiceTemplate', invoiceTemplate);
    }, [formik]);
    var getById = useLazyQuery(GET_COMPANY, {
        onCompleted: function (data) {
            var _a, _b, _c;
            if (data && data.allTblCompanies && data.allTblCompanies.nodes) {
                addToStorage(STORAGE_KEY.COMPANY, data.allTblCompanies.nodes[0]);
                formik.setValues(__assign(__assign({}, data.allTblCompanies.nodes[0]), { invoiceTemplate: !((_a = data.allTblCompanies.nodes[0]) === null || _a === void 0 ? void 0 : _a.invoiceTemplate) ? {} : JSON.parse((_b = data.allTblCompanies.nodes[0]) === null || _b === void 0 ? void 0 : _b.invoiceTemplate) }));
            }
            setOffices(((_c = data === null || data === void 0 ? void 0 : data.allTblOffices) === null || _c === void 0 ? void 0 : _c.nodes) || []);
            Spinner.hide();
        }
    })[0];
    var getTemplateData = useMemo(function () { var _a, _b; return ((_b = (_a = formik === null || formik === void 0 ? void 0 : formik.values) === null || _a === void 0 ? void 0 : _a.invoiceTemplate) === null || _b === void 0 ? void 0 : _b[templateId]) || ''; }, [templateId, formik]);
    var saveSettings = function () { return __awaiter(void 0, void 0, void 0, function () {
        var logo, engLetter, result, result;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    Spinner.show();
                    logo = (_a = formik.values) === null || _a === void 0 ? void 0 : _a.logo;
                    engLetter = (_b = formik.values) === null || _b === void 0 ? void 0 : _b.engagementLetter;
                    if (!(attachments === null || attachments === void 0 ? void 0 : attachments.logo)) return [3 /*break*/, 2];
                    return [4 /*yield*/, httpPostUpload("document/uploadLogo", [attachments === null || attachments === void 0 ? void 0 : attachments.logo])];
                case 1:
                    result = _d.sent();
                    logo = result.Location;
                    _d.label = 2;
                case 2:
                    if (!(attachments === null || attachments === void 0 ? void 0 : attachments.engLetter)) return [3 /*break*/, 4];
                    return [4 /*yield*/, httpPostUpload("document/uploadTemplate", [attachments === null || attachments === void 0 ? void 0 : attachments.engLetter])];
                case 3:
                    result = _d.sent();
                    engLetter = result.Key;
                    _d.label = 4;
                case 4:
                    updateCompany({
                        variables: {
                            id: +formik.values.id,
                            input: __assign(__assign({}, formik.values), { logo: logo, engagementLetter: engLetter, invoiceTemplate: JSON.stringify(((_c = formik.values) === null || _c === void 0 ? void 0 : _c.invoiceTemplate) || {}) })
                        }
                    });
                    formik.setFieldValue('logo', logo);
                    formik.setFieldValue('engagementLetter', engLetter);
                    return [2 /*return*/];
            }
        });
    }); };
    var logoChanged = function (evt) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (evt.target.files && evt.target.files.length) {
                setAttachments(__assign(__assign({}, attachments), { logo: evt.target.files[0] }));
            }
            return [2 /*return*/];
        });
    }); };
    var templateChanged = function (i) { return function (evt) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            if (evt.target.files && evt.target.files.length) {
                setAttachments(__assign(__assign({}, attachments), (_a = {}, _a[i > -1 ? "engLetter" + i : 'engLetter'] = evt.target.files[0], _a)));
            }
            return [2 /*return*/];
        });
    }); }; };
    var officeChanged = function (i) { return function (e) {
        setOffices(offices.reduce(function (result, curr, index) {
            var _a;
            if (i === index) {
                if (!e.target) {
                    result.push(__assign(__assign({}, curr), { address: e.zzFullAddress || '', lat: e.lat, lng: e.lng }));
                }
                else {
                    result.push(__assign(__assign({}, curr), (_a = {}, _a[e.target.id] = e.target.value, _a)));
                }
            }
            else {
                result.push(curr);
            }
            return result;
        }, []));
    }; };
    useEffect(function () {
        getById();
    }, []);
    var EngLetterMappingInfo = function () {
        return (_jsxs("ul", __assign({ className: "mapping-field" }, { children: [_jsxs("li", { children: ["{LetterDate}", _jsx("strong", { children: "Current Date" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{CompanyName}", _jsx("strong", { children: "Company Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ContactSal}", _jsx("strong", { children: "Client Salutation" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ContactFirstName}", _jsx("strong", { children: "Client First Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ContactLastName}", _jsx("strong", { children: "Client Last Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ContactDesignation}", _jsx("strong", { children: "Client Designation" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ContactTitle}", _jsx("strong", { children: "Client Title" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Address1}", _jsx("strong", { children: "Client Address 1" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Address2}", _jsx("strong", { children: "Client Address 2" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{City}", _jsx("strong", { children: "Client City" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Province}", _jsx("strong", { children: "Client Province" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Country}", _jsx("strong", { children: "Client Country" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{PostalCode}", _jsx("strong", { children: "Client Postal Code" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{PropertyAddress}", _jsx("strong", { children: "Property Address" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Function}", _jsx("strong", { children: "Function" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{SingleMulti}", _jsx("strong", { children: "Single or Multi" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ValueType}", _jsx("strong", { children: "Value Type" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Interest}", _jsx("strong", { children: "Interest" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{AppraiserName}", _jsx("strong", { children: "Appraiser Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{Fee}", _jsx("strong", { children: "Report Fee(+HST)" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{RetainerAmount}", _jsx("strong", { children: "Retainer Amount" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{DeliveryTimeline}", _jsx("strong", { children: "Delivery Timeline" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{AppraiserTitle}", _jsx("strong", { children: "Appraiser Title" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{AppraiserEmail}", _jsx("strong", { children: "Appraiser Email" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{additionlScope}", _jsx("strong", { children: "Job additionl Scope" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{hasScope}", _jsx("strong", { children: "JOb has Scope?" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ClientName}", _jsx("strong", { children: "Client Name" }, void 0)] }, void 0)] }), void 0));
    };
    var InvLetterMappingInfo = function () {
        return (_jsxs("ul", __assign({ className: "mapping-field" }, { children: [_jsxs("li", { children: ["{companyName}", _jsx("strong", { children: "Company Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{companyAddress}", _jsx("strong", { children: "Company Address" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{companyPhone}", _jsx("strong", { children: "Company Phone" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{companyEmail}", _jsx("strong", { children: "Company Email" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{clientName}", _jsx("strong", { children: "Client Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{clientPhone}", _jsx("strong", { children: "Client Phone" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{contactName}", _jsx("strong", { children: "Client Contact Name" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{propertyAddress}", _jsx("strong", { children: "Property Address" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{clientFax}", _jsx("strong", { children: "Client Fax" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{rate}", _jsx("strong", { children: "Rate" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{total}", _jsx("strong", { children: "Total (+HST)" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{hst}", _jsx("strong", { children: "HST" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{description}", _jsx("strong", { children: "Invoice Description" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{DATE}", _jsx("strong", { children: "Current Date" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{DueDate}", _jsx("strong", { children: "Invoice Due Date" }, void 0)] }, void 0),
                _jsxs("li", { children: ["{ID}", _jsx("strong", { children: "Invoice ID" }, void 0)] }, void 0)] }), void 0));
    };
    var invLetterMappingInfo = function () {
        ModalForm.show({
            footer: false,
            small: true,
            title: 'Invoice Template mapping details',
            body: (_jsx(InvLetterMappingInfo, {}, void 0))
        });
    };
    var engLetterMappingInfo = function () {
        ModalForm.show({
            footer: false,
            small: true,
            title: 'Engagement Letter mapping details',
            body: (_jsx(EngLetterMappingInfo, {}, void 0))
        });
    };
    var copyTemplate = useCallback(function () {
        ModalForm.show({
            title: 'Copy Template',
            small: true,
            footer: false,
            body: _jsx(FormDynamic, { formFields: [
                    { fieldName: 'title', title: 'Template Name', required: true },
                ], onSubmit: function (data) {
                    var _a;
                    var _b, _c;
                    var invoiceTemplate = __assign(__assign({}, (_b = formik.values) === null || _b === void 0 ? void 0 : _b.invoiceTemplate), (_a = {}, _a[data.title] = "<p>Copied Content</p>" + ((_c = formik.values) === null || _c === void 0 ? void 0 : _c.invoiceTemplate[currentTemplate.current]), _a));
                    formik.setFieldValue('invoiceTemplate', invoiceTemplate);
                    ModalForm.close();
                } }, void 0)
        });
    }, [formik]);
    var options = useMemo(function () { var _a; return Object.keys(((_a = formik.values) === null || _a === void 0 ? void 0 : _a.invoiceTemplate) || {}); }, [formik.values]);
    var addNewTemplate = useCallback(function () {
        ModalForm.show({
            title: 'Add Template',
            small: true,
            footer: false,
            body: _jsx(FormDynamic, { formFields: [
                    { fieldName: 'title', title: 'Template Name', required: true },
                ], onSubmit: function (data) {
                    var _a;
                    var _b;
                    var invoiceTemplate = __assign(__assign({}, (_b = formik.values) === null || _b === void 0 ? void 0 : _b.invoiceTemplate), (_a = {}, _a[data.title] = "<div>Content Here</div>", _a));
                    formik.setFieldValue('invoiceTemplate', invoiceTemplate);
                    ModalForm.close();
                } }, void 0)
        });
    }, [formik]);
    useEffect(function () {
        currentTemplate.current = templateId;
    }, [templateId]);
    return (_jsxs("div", __assign({ className: "content" }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, __assign({ title: "Settings" }, { children: !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) &&
                            _jsxs(Button, __assign({ onClick: saveSettings, color: "info", className: "btn btn-xs" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " Save Changes"] }), void 0) }), void 0) }, void 0) }, void 0),
            _jsxs(Tabs, __assign({ className: "mt-16", value: activeStep, onChange: function (e, newValue) { return changeTabs(newValue); }, indicatorColor: "primary", textColor: "primary", variant: "standard" }, { children: [_jsx(Tab, { value: 0, label: " Company Details" }, void 0),
                    _jsx(Tab, { value: 1, label: "  Invoice Template" }, void 0)] }), void 0),
            _jsx(Card, __assign({ className: "border-0 top-radius-0" }, { children: _jsx(CardBody, __assign({ className: "mt-16" }, { children: _jsxs(Row, __assign({ className: "pb-16" }, { children: [_jsx(Col, __assign({ xs: 12, md: 6, lg: 6, xl: 6, style: { display: activeStep === 0 ? 'block' : 'none' } }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit, className: "form-input-padding" }, { children: [_jsxs(Row, __assign({ className: "mt-16" }, { children: [_jsx(Col, __assign({ xs: 12, md: 6, lg: 6, xl: 6 }, { children: _jsx(InputText, { value: formik.values.name, name: "name", fullWidth: true, onChange: formik.handleChange, label: "Company Name" }, void 0) }), void 0),
                                                _jsx(Col, __assign({ xs: 12, md: 6, lg: 6, xl: 6, className: isMobile ? 'mt-8' : '' }, { children: _jsx(InputText, { value: formik.values.email, name: "email", fullWidth: true, onChange: formik.handleChange, label: "Email" }, void 0) }), void 0)] }), void 0),
                                        _jsx(Row, __assign({ className: "mt-8" }, { children: _jsx(Col, { children: _jsx(AddressComponent, { id: "search_company_address", editMode: true, defaultValue: formik.values.zzFullAddress, title: "Company Address", hideDetails: true, className: "w-100", onChange: addressChanged }, void 0) }, void 0) }), void 0),
                                        _jsxs(Row, __assign({ className: "mt-8" }, { children: [_jsx(Col, __assign({ xs: 12, md: 6, lg: 6, xl: 6 }, { children: _jsx(InputText, { value: formik.values.phone, name: "phone", fullWidth: true, onChange: formik.handleChange, label: "Phone" }, void 0) }), void 0),
                                                _jsx(Col, __assign({ xs: 12, md: 6, lg: 6, xl: 6, className: isMobile ? 'mt-8' : '' }, { children: _jsx(InputText, { value: formik.values.fax, name: "fax", fullWidth: true, onChange: formik.handleChange, label: "Fax" }, void 0) }), void 0)] }), void 0),
                                        _jsxs(Row, __assign({ className: "mt-8" }, { children: [_jsx(Col, __assign({ xs: 2 }, { children: _jsx(InputText, { value: formik.values.tax, name: "tax", onChange: formik.handleChange, label: "Tax (%)", type: "number" }, void 0) }), void 0),
                                                _jsx(Col, { xs: 10 }, void 0)] }), void 0),
                                        !appModule.inactive.includes("VALUATION") && _jsxs(Row, __assign({ className: "mt-16" }, { children: [_jsxs(Col, __assign({ xs: 9, className: "flex gap-8" }, { children: [_jsxs("div", { children: [_jsxs("label", __assign({ className: "input-text-label" }, { children: ["Engagement Letter ", _jsx(InfoIcon, { onClick: engLetterMappingInfo, className: "pointer", style: { fontSize: '18px' } }, void 0)] }), void 0),
                                                                _jsx("input", { type: "file", onChange: templateChanged(-1), id: "engletterupload", className: "inputfile" }, void 0),
                                                                _jsxs("label", __assign({ htmlFor: "engletterupload" }, { children: [_jsx("span", {}, void 0), " ", _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "17", viewBox: "0 0 20 17" }, { children: _jsx("path", { d: "M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" }, void 0) }), void 0), "Choose a file\u2026"] }), void 0)] }, void 0),
                                                        _jsx("label", __assign({ className: "filename-label" }, { children: (_a = attachments === null || attachments === void 0 ? void 0 : attachments.engLetter) === null || _a === void 0 ? void 0 : _a.name }), void 0)] }), void 0),
                                                formik.values.engagementLetter && (_jsx(Col, __assign({ xs: 3, className: "flex" }, { children: _jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue btn btn-secondary underline", onClick: downloadFile(formik.values.engagementLetter) }, { children: [_jsx("i", { className: "fa fa-eye" }, void 0), " View Template"] }), void 0) }), void 0))] }), void 0),
                                        _jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, { children: _jsx(InputText, { multiline: true, rows: 8, value: formik.values.emailTemplate, name: "emailTemplate", fullWidth: true, onChange: formik.handleChange, label: "Email Signature" }, void 0) }, void 0) }), void 0),
                                        _jsx(Row, __assign({ className: "mt-16" }, { children: _jsxs(Col, __assign({ className: "flex gap-8" }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ className: "input-text-label" }, { children: "Company Logo: " }), void 0),
                                                            _jsx("input", { accept: "image/*", type: "file", onChange: logoChanged, id: "companylogo", className: "inputfile" }, void 0),
                                                            _jsxs("label", __assign({ htmlFor: "companylogo" }, { children: [_jsx("span", {}, void 0), " ", _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "17", viewBox: "0 0 20 17" }, { children: _jsx("path", { d: "M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" }, void 0) }), void 0), "Choose a file\u2026"] }), void 0)] }, void 0),
                                                    _jsx("label", __assign({ className: "filename-label" }, { children: (_b = attachments === null || attachments === void 0 ? void 0 : attachments.logo) === null || _b === void 0 ? void 0 : _b.name }), void 0)] }), void 0) }), void 0),
                                        formik.values.logo && (_jsx(Row, { children: _jsx(Col, { children: _jsx(Avatar, { className: "logo-avatar", src: formik.values.logo }, void 0) }, void 0) }, void 0))] }), void 0) }), void 0),
                            _jsxs(Col, __assign({ xs: 12, md: 6, lg: 6, xl: 6, style: { display: activeStep === 0 ? 'block' : 'none' } }, { children: [!(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && _jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue btn btn-secondary", onClick: addNewOffice }, { children: [_jsx("i", { className: "fa fa-plus-circle pr-8" }, void 0), " Add Office"] }), void 0),
                                    offices.map(function (f, i) { return (_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsxs(Card, __assign({ className: "p-8" }, { children: [_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12, className: "text-right" }, { children: _jsx(DeleteButton, { onDelete: removeOffice, index: i, id: f.id }, void 0) }), void 0) }), void 0),
                                                    _jsxs(Row, __assign({ className: "mt-8" }, { children: [_jsx(Col, { children: _jsx(InputText, { value: f.name, id: "name", fullWidth: true, onChange: officeChanged(i), label: "Office Name" }, void 0) }, void 0),
                                                            _jsx(Col, { children: _jsx(InputText, { value: f.email, id: "email", fullWidth: true, onChange: officeChanged(i), label: "Email" }, void 0) }, void 0)] }), void 0),
                                                    _jsx(Row, __assign({ className: "mt-8" }, { children: _jsx(Col, { children: _jsx(AddressComponent, { id: "address_search_" + i, editMode: true, defaultValue: f.address, title: "Office Address", hideDetails: true, className: "w-100", onChange: function (e) { return officeChanged(i)(e); } }, void 0) }, void 0) }), void 0),
                                                    _jsxs(Row, __assign({ className: "mt-8" }, { children: [_jsx(Col, { children: _jsx(InputText, { value: f.phone, id: "phone", fullWidth: true, onChange: officeChanged(i), label: "Phone" }, void 0) }, void 0),
                                                            _jsx(Col, { children: _jsx(InputText, { value: f.fax, id: "fax", fullWidth: true, onChange: officeChanged(i), label: "Fax" }, void 0) }, void 0)] }), void 0),
                                                    _jsxs(Row, __assign({ className: "mt-16" }, { children: [_jsxs(Col, __assign({ xs: !f.id ? 12 : 9 }, { children: [_jsx("label", __assign({ className: "input-text-label" }, { children: "Engagement Letter " }), void 0),
                                                                    _jsx("input", { type: "file", onChange: templateChanged(i), id: "engletterupload" + i, className: "inputfile" }, void 0),
                                                                    _jsxs("label", __assign({ htmlFor: "engletterupload" + i }, { children: [_jsx("span", {}, void 0), " ", _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "17", viewBox: "0 0 20 17" }, { children: _jsx("path", { d: "M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" }, void 0) }), void 0), "Choose a file\u2026"] }), void 0)] }), void 0),
                                                            f.id && f.engLetter && (_jsx(Col, __assign({ xs: 3, className: "flex" }, { children: _jsxs(Button, __assign({ className: "btn btn-xxs btn-link text-blue btn btn-secondary underline", onClick: downloadFile(f.engLetter) }, { children: [_jsx("i", { className: "fa fa-eye" }, void 0), " View Template"] }), void 0) }), void 0))] }), void 0)] }), void 0) }), void 0) }), "office_" + i)); })] }), void 0),
                            _jsxs(Col, __assign({ xs: 12, style: { display: activeStep === 1 ? 'block' : 'none' } }, { children: [_jsxs("div", __assign({ className: "align-items-bottom gap-16" }, { children: [_jsx(InputText, __assign({ select: true, label: "Select a Template", className: "min-w-200", onChange: function (e) { var _a; return setTemplateId((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value); } }, { children: options.map(function (key) { return (_jsx(MenuItem, __assign({ value: key }, { children: key }), key)); }) }), void 0),
                                            !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) &&
                                                _jsxs("div", __assign({ className: "gap-16 flex pb-4" }, { children: [_jsx(Tooltip, __assign({ title: "Template Details" }, { children: _jsx(InfoIcon, { onClick: invLetterMappingInfo, className: "pointer", style: { fontSize: '18px' } }, void 0) }), void 0),
                                                        getTemplateData && (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ title: "Copy Template" }, { children: _jsx(FileCopyIcon, { onClick: copyTemplate, className: "pointer", style: { fontSize: '16px' } }, void 0) }), void 0),
                                                                _jsx(Tooltip, __assign({ title: "Delete Template" }, { children: _jsx(DeleteForeverIcon, { onClick: deleteTemplate, className: "pointer", style: { fontSize: '16px' } }, void 0) }), void 0)] }, void 0)),
                                                        _jsx(Tooltip, __assign({ title: "New Template" }, { children: _jsx(AddCircleIcon, { onClick: addNewTemplate, className: "pointer", style: { fontSize: '16px' } }, void 0) }), void 0)] }), void 0)] }), void 0),
                                    getTemplateData && (_jsx("div", __assign({ className: "mt-16 min-h-400" }, { children: _jsx(TextEditor, { data: getTemplateData, onChange: templateContentChanged }, void 0) }), void 0))] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
