var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { InputText } from '../../ui-components/input/Input-Field.component';
import { setFormData } from "../../helpers/form.helper";
import { getCompanyEmailSignature } from '../../utils/Storage.util';
var EmailTemplateForm = function (props, ref) {
    var _a = useState({
        email: props.email || '',
        cc: '',
        signature: getCompanyEmailSignature(),
    }), form = _a[0], setForm = _a[1];
    useImperativeHandle(ref, function () { return ({
        getData: function () {
            return form;
        }
    }); });
    return (_jsxs("div", __assign({ className: "db w-full" }, { children: [_jsx(Row, __assign({ className: 'mt-16' }, { children: _jsx(Col, { children: _jsx(InputText, { value: form === null || form === void 0 ? void 0 : form.email, label: "Email", size: "small", fullWidth: true, onChange: function (e) { return setFormData(e, 'email', form, setForm); } }, void 0) }, void 0) }), void 0),
            _jsx(Row, __assign({ className: 'mt-16' }, { children: _jsx(Col, { children: _jsx(InputText, { value: form === null || form === void 0 ? void 0 : form.cc, label: "Cc", size: "small", fullWidth: true, onChange: function (e) { return setFormData(e, 'cc', form, setForm); } }, void 0) }, void 0) }), void 0),
            _jsx(Row, __assign({ className: "mt-24" }, { children: _jsx(Col, { children: _jsx(InputText, { value: form === null || form === void 0 ? void 0 : form.signature, label: "Email Signature", size: "small", multiline: true, fullWidth: true, rows: 10, onChange: function (e) { return setFormData(e, 'signature', form, setForm); } }, void 0) }, void 0) }), void 0)] }), void 0));
};
export default forwardRef(EmailTemplateForm);
