var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormDynamic } from "../ui-components/forms/Form-Dynamic";
import { addAppointment, getAppointment } from "../services/job.service";
import { WithApollo } from "../hooks/withApollo.hoc";
import { GetUserId } from "../services/user-service";
import { formatDate } from "@utils/date";
var Appointment = function (_a) {
    var props = __rest(_a, []);
    var date = props.date, apolloClient = props.apolloClient, onUpdated = props.onUpdated, id = props.id;
    var _b = useState(null), data = _b[0], setData = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var formFields = useMemo(function () { return [
        { fieldName: 'description', title: 'Description', required: true, defaultValue: (data === null || data === void 0 ? void 0 : data.description) || '' },
        { fieldName: 'dueDate', title: 'Date', required: true, type: 'date', defaultValue: (data === null || data === void 0 ? void 0 : data.dueDate) || formatDate(date, 'yyyy-MM-dd') },
        { fieldName: 'notes', title: 'Notes', type: 'area', required: true, defaultValue: (data === null || data === void 0 ? void 0 : data.notes) || '' },
    ]; }, [data]);
    var onSubmit = useCallback(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var _data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addAppointment(apolloClient, __assign(__assign({}, formData), { id: id, ownerId: GetUserId() }))];
                case 1:
                    _data = _a.sent();
                    onUpdated && onUpdated(_data);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var loadEvent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAppointment(apolloClient, id)];
                case 1:
                    _data = _a.sent();
                    setData(_data);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (id) {
            loadEvent();
        }
        else {
            setLoading(false);
        }
    }, [id]);
    if (loading) {
        return null;
    }
    return (_jsx(FormDynamic, { formFields: formFields, onSubmit: onSubmit }, void 0));
};
export default WithApollo(Appointment);
