var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import TablePagination from '@material-ui/core/TablePagination';
import { isMobile } from "react-device-detect";
export var TableFooter = function (_a) {
    var _b = _a.pageIndex, pageIndex = _b === void 0 ? 0 : _b, _c = _a.fetchMore, fetchMore = _c === void 0 ? null : _c, _d = _a.totalCount, totalCount = _d === void 0 ? 0 : _d, _e = _a.pageSize, pageSize = _e === void 0 ? 20 : _e;
    var _f = useState(pageIndex), currentPage = _f[0], setCurrentPage = _f[1];
    var handleChangePage = function (_, newPage) {
        fetchMore(newPage, pageSize);
        setCurrentPage(newPage);
    };
    var onChangeRowsPerPage = function (event) {
        fetchMore(0, +event.target.value);
        setCurrentPage(0);
    };
    return (_jsx("div", __assign({ className: "db w-100 bg-white footer-radius " + (isMobile ? '' : 'pr-30') }, { children: _jsx(TablePagination, { component: "div", count: totalCount, page: currentPage, onChangePage: handleChangePage, rowsPerPage: pageSize, onChangeRowsPerPage: onChangeRowsPerPage }, void 0) }), void 0));
};
