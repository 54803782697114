var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { TableHead, TableRow, TableCell, TableBody, MenuItem, Chip, Grid, Checkbox } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Card, CardBody, Table, Row, Col, CardHeader, Button } from "reactstrap";
import { map } from "lodash";
import { formatDate } from "@utils/text";
import clsx from "clsx";
import { ModalForm } from "../../../ui-components/modals/Modal-Form.component";
import { Alert } from "../../../ui-components/modals/Alert.component";
import { Appraiser } from "../../appraisers/Appraiser.component";
import { ADDSINGLEJOBAPPRAISER, UPDATE_APPRAISER_FEE, DELETE_FROM_JOB_APPRAISER } from "../../../graphqls/appraisal/appraisal.query";
import { AddPayment } from "../../payments/edit/Add-Payment.component";
import { AddInvoice } from "../../invoices/Add-Invoice.component";
import { formatTotalAsCurrency } from "../../../helpers/numbers-helper.helper";
import { canEditAppraisers, canEditRequest, getJwtToken, isAppraiser, isClient } from "../../../services/user-service";
import { InputText } from "../../../ui-components/input/Input-Field.component";
import { setFormArrayData } from "../../../helpers/form.helper";
import { convertFeeType } from "../../../helpers/enum.helper";
import { RequestDetail } from "./Request-detail.component";
import { ListHeader } from "../../../ui-components/lists/Header.component";
import Typography from "@material-ui/core/Typography";
import { TimelineComponent } from "../../../ui-components/timeline/time-line.component";
import { Note } from "../../note/Note.component";
import { DeleteButton } from "../../../ui-components/button/Delete-Button";
import { useDispatch, useSelector } from "react-redux";
import { setAddInvoice, setAddPayment } from "../../../stores/app.reducer";
import { EngagementLetter } from "../Engagement.component";
import { appraiserEmail, formatAddress, getAppExpensePortion, removeAppExpByAppId, updateReviewer, upsertAppExpensePortion } from "../../../services/job.service";
import { CLIENT_JOB_LIST, JOB_LIST } from "../../../routes";
import { APPRAISER_ROLE, DOCUMENT_TYPE, SYSTEM_TABLES, PROPERTY_SECTION, APP_PACKAGES } from "@enums";
import { useApolloQuery } from "../../../hooks/use-appolo.hooks";
import { getAppModules, getJobInEditMode } from "../../../stores/app.selector";
import { PropertySections } from "../Property-Section.component";
import { isMobile } from "react-device-detect";
import { EmptyDataError } from "../../../ui-components/table/EmptyDataError";
export var ViewComponent = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var props = __rest(_a, []);
    var _h = useState(0), tab = _h[0], setTab = _h[1];
    var _j = useState(null), job = _j[0], setJob = _j[1];
    var _k = useState([]), appExpPortion = _k[0], setAppExpPortion = _k[1];
    var _l = useState([]), jobAppr = _l[0], setJobAppr = _l[1];
    var _m = useState(''), reloadPayment = _m[0], setReloadPayment = _m[1];
    var _o = useState(''), reloadTimeLine = _o[0], setReloadTimeLine = _o[1];
    var apolloClient = useApolloQuery()[0];
    var appModule = useSelector(getAppModules);
    var editable = canEditRequest();
    var canEditFee = canEditAppraisers();
    var _isAppraiser = isAppraiser();
    var _isClient = isClient();
    var id = ((props === null || props === void 0 ? void 0 : props.id) ? props : (props.match ? props.match.params : { id: undefined })).id;
    var drawer = ((props === null || props === void 0 ? void 0 : props.drawer) ? props : (props.match ? props.match.params : { drawer: false })).drawer;
    var dispatch = useDispatch();
    var jobInEdit = useSelector(getJobInEditMode);
    var updateAppExpense = function (exp, appr) { return function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, upsertAppExpensePortion(+job.id, +((_a = appr === null || appr === void 0 ? void 0 : appr.tblAppraiserById) === null || _a === void 0 ? void 0 : _a.id), +exp.id, +((_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value))(apolloClient)];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var addNewInvoice = function () {
        dispatch(setAddInvoice('OPEN'));
    };
    var onDocumentUpdated = function () {
        setReloadTimeLine("" + new Date().getTime());
    };
    var onPaymentReceived = function () {
        setReloadPayment("" + new Date().getTime());
    };
    var addNewPayment = function () {
        dispatch(setAddPayment('OPEN'));
    };
    var updateReviewers = function (appraiser) { return function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateReviewer(appraiser.rowId, e.target.checked ? APPRAISER_ROLE.REVIEWER : APPRAISER_ROLE.OWNER)(apolloClient)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var addAppraisor = useMutation(ADDSINGLEJOBAPPRAISER, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(data && data.createTblPropertyAppraiser)) return [3 /*break*/, 2];
                        setJobAppr(jobAppr.concat([
                            __assign({ tblAppraiserById: __assign({}, data.createTblPropertyAppraiser.tblAppraiserById) }, data.createTblPropertyAppraiser.tblPropertyAppraiser)
                        ]));
                        return [4 /*yield*/, appraiserEmail(+job.id)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var deleteAppraiser = useMutation(DELETE_FROM_JOB_APPRAISER, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var id, appId;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        id = +data.deleteTblPropertyAppraiserByRowId.tblPropertyAppraiser.rowId;
                        appId = (_b = (_a = jobAppr.find(function (a) { return +a.rowId === id; })) === null || _a === void 0 ? void 0 : _a.tblAppraiserById) === null || _b === void 0 ? void 0 : _b.id;
                        return [4 /*yield*/, removeAppExpByAppId(appExpPortion.filter(function (c) { return +c.appId === +appId; }).map(function (c) { return c.id; }))(apolloClient)];
                    case 1:
                        _c.sent();
                        setJobAppr(jobAppr.filter(function (a) { return +a.rowId !== id; }));
                        return [2 /*return*/];
                }
            });
        }); }
    })[0];
    var removeAppraiser = function (rowId) {
        deleteAppraiser({
            variables: {
                id: +rowId,
            }
        });
    };
    var updateReportFee = useMutation(UPDATE_APPRAISER_FEE, {
        onCompleted: function () { }
    })[0];
    var updateFeeType = function (e, id, index) {
        updateReportFee({
            variables: {
                feeType: +e.target.value,
                id: +id,
                reportFee: +jobAppr[index].reportFee,
            }
        });
    };
    var updateFee = function (e, id, index) {
        updateReportFee({
            variables: {
                feeType: +jobAppr[index].feeType,
                id: +id,
                reportFee: +e.target.value,
            }
        });
    };
    var onSuccessAppraisor = function (id) {
        if (jobAppr.some(function (a) { return +a.tblAppraiserById.id === +id; })) {
            Alert.show({ title: 'Error', message: 'Appraiser already exists !' });
            return;
        }
        addAppraisor({
            variables: {
                jobId: +job.id,
                appid: +id,
                reportFee: 0,
            }
        });
    };
    var getAppExpensePortions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAppExpensePortion(+job.id)(apolloClient)];
                case 1:
                    data = _a.sent();
                    setAppExpPortion(data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (job === null || job === void 0 ? void 0 : job.id) {
            getAppExpensePortions();
        }
    }, [job]);
    var downloadReport = function (id) {
        window.open(process.env.REACT_APP_EXTERNAL_API_URL + "document/generatereport/" + id + "/" + getJwtToken());
    };
    return (_jsxs("div", __assign({ className: "content " + (drawer ? 'p-3' : '') }, { children: [_jsx(Row, __assign({ className: "mb-16" }, { children: !drawer ? (_jsx(Col, { children: _jsx(ListHeader, { cancel: false, noHome: !editable, href: _isClient ? CLIENT_JOB_LIST : JOB_LIST, title: "Jobs", others: isMobile ? [] : [formatAddress(job)] }, void 0) }, void 0)) : (_jsx(Col, __assign({ xs: 12, className: "flex gap-16 " }, { children: _jsx(Chip, { color: "primary", label: formatAddress(job) }, void 0) }), void 0)) }), void 0),
            _jsxs(Grid, __assign({ container: true, spacing: 5 }, { children: [_jsxs(Grid, __assign({ item: true, xs: isMobile ? 12 : 6 }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(RequestDetail, { setJobAppr: setJobAppr, jobId: id, setParentJob: setJob, drawer: drawer }, void 0) }, void 0) }, void 0),
                            !jobInEdit && (_jsxs(_Fragment, { children: [(editable || _isAppraiser) && (_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, { children: _jsxs(Card, { children: [_jsxs(CardHeader, __assign({ className: "p-2" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "ASSIGNMENTS" }), void 0)] }), void 0),
                                                            !_isAppraiser && canEditRequest() && !drawer && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsx("div", __assign({ className: "dib right w-50" }, { children: _jsxs(Button, __assign({ onClick: function () { return ModalForm.show({ onSuccess: onSuccessAppraisor, title: 'Select Appraiser', footer: false, body: (_jsx(Appraiser, { header: false }, void 0)) }); }, color: "default", className: "btn-xss" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Add Appraiser"] }), void 0) }), void 0))] }), void 0),
                                                    _jsx(CardBody, __assign({ className: "p-0" }, { children: ((_c = (_b = job === null || job === void 0 ? void 0 : job.tblPropertiesByJobId) === null || _b === void 0 ? void 0 : _b.nodes) === null || _c === void 0 ? void 0 : _c.length) > 0 || (jobAppr === null || jobAppr === void 0 ? void 0 : jobAppr.length) > 0 ? _jsxs(Table, __assign({ responsive: isMobile, size: "small", className: "table-small" }, { children: [_jsx(TableHead, __assign({ className: 'text-primary' }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Full Name" }, void 0),
                                                                            _jsx(TableCell, { children: "Commission" }, void 0),
                                                                            (_e = (_d = job === null || job === void 0 ? void 0 : job.tblExpensesByJobId) === null || _d === void 0 ? void 0 : _d.nodes) === null || _e === void 0 ? void 0 : _e.map(function (e) {
                                                                                var _a;
                                                                                return (_jsxs(TableCell, { children: [((_a = e === null || e === void 0 ? void 0 : e.tblOptionByOptionId) === null || _a === void 0 ? void 0 : _a.title) || '', " %"] }, "col_exp_" + (e === null || e === void 0 ? void 0 : e.id)));
                                                                            }),
                                                                            _jsx(TableCell, { children: "Reviewer?" }, void 0),
                                                                            editable && (_jsx(TableCell, {}, void 0))] }, void 0) }), void 0),
                                                                _jsxs(TableBody, { children: [map((_f = job === null || job === void 0 ? void 0 : job.tblPropertiesByJobId) === null || _f === void 0 ? void 0 : _f.nodes, function (props) { return (map(props.tblPropertyAppraisersByPropertyId.nodes.filter(function (a) { return +a.tblAppraiserById.id !== 0; }), function (item) { return (_jsxs(TableRow, { children: [_jsxs(TableCell, { children: [item.tblAppraiserById.salutation, " ", item.tblAppraiserById.firstName, " ", item.tblAppraiserById.lastName, _jsx("br", {}, void 0),
                                                                                        _jsx(Typography, __assign({ variant: "caption", className: "italic" }, { children: item.tblAppraiserById.title }), void 0)] }, void 0),
                                                                                _jsx(TableCell, { children: formatTotalAsCurrency(item.reportFee) }, void 0)] }, item.tblAppraiserById.id)); })); }),
                                                                        jobAppr.length > 0 && (map(jobAppr, function (item, index) {
                                                                            var _a, _b;
                                                                            return (_jsxs(TableRow, { children: [_jsxs(TableCell, { children: [item.tblAppraiserById.salutation, " ", item.tblAppraiserById.firstName, " ", item.tblAppraiserById.lastName, _jsx("br", {}, void 0),
                                                                                            _jsx(Typography, __assign({ variant: "caption", className: "italic" }, { children: item.tblAppraiserById.title }), void 0)] }, void 0),
                                                                                    _jsx(TableCell, { children: canEditFee ? (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(InputText, { onChange: function (e) { return setFormArrayData(e, 'reportFee', index, jobAppr, setJobAppr); }, onBlur: function (e) { return updateFee(e, item.rowId, index); }, type: "number", size: "sm", disabled: appModule === null || appModule === void 0 ? void 0 : appModule.read_only, className: "max-w-70", value: item.reportFee, label: "" }, void 0),
                                                                                                _jsxs(InputText, __assign({ select: true, label: "", className: "border-free", value: item.feeType, size: "small", onChange: function (e) {
                                                                                                        setFormArrayData(e, 'feeType', index, jobAppr, setJobAppr);
                                                                                                        updateFeeType(e, item.rowId, index);
                                                                                                    } }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "%" }), void 0),
                                                                                                        _jsx(MenuItem, __assign({ value: 1 }, { children: "$" }), void 0)] }), void 0)] }), void 0)) : (_jsxs("div", { children: [item.reportFee, convertFeeType(item.feeType)] }, void 0)) }, void 0),
                                                                                    (_b = (_a = job === null || job === void 0 ? void 0 : job.tblExpensesByJobId) === null || _a === void 0 ? void 0 : _a.nodes) === null || _b === void 0 ? void 0 : _b.map(function (e) {
                                                                                        var _a;
                                                                                        return (_jsx(TableCell, { children: _jsx(InputText, { onBlur: updateAppExpense(e, item), type: "number", disabled: appModule === null || appModule === void 0 ? void 0 : appModule.read_only, size: "sm", className: "max-w-70", defaultValue: (_a = appExpPortion.find(function (d) { var _a; return +d.appId === +((_a = item === null || item === void 0 ? void 0 : item.tblAppraiserById) === null || _a === void 0 ? void 0 : _a.id) && +d.expId === +e.id; })) === null || _a === void 0 ? void 0 : _a.rate, label: "" }, void 0) }, "data_col_exp_" + (e === null || e === void 0 ? void 0 : e.id)));
                                                                                    }),
                                                                                    _jsx(TableCell, __assign({ align: editable ? "center" : "left" }, { children: editable ? (_jsx(Checkbox, { onChange: updateReviewers(item), defaultChecked: (item === null || item === void 0 ? void 0 : item.role) === APPRAISER_ROLE.REVIEWER, color: "primary", size: "small" }, void 0)) : (_jsxs(Typography, __assign({ variant: "caption" }, { children: [(item === null || item === void 0 ? void 0 : item.role) === APPRAISER_ROLE.REVIEWER ? 'Yes' : 'No', " "] }), void 0)) }), void 0),
                                                                                    editable && (_jsx(TableCell, __assign({ align: "center" }, { children: _jsx(DeleteButton, { index: item.rowId, onClick: function (row) { return removeAppraiser(row); } }, void 0) }), void 0))] }, item.rowId));
                                                                        }))] }, void 0)] }), void 0) : _jsx(EmptyDataError, { className: 'db center' }, void 0) }), void 0)] }, void 0) }, void 0) }), void 0)),
                                    (editable || _isAppraiser) && appModule.active.includes(APP_PACKAGES.ENGAGEMENTLETTER) && (_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(EngagementLetter, { drawer: drawer, onDocumentUpdated: onDocumentUpdated, jobId: id, email: ((_g = job === null || job === void 0 ? void 0 : job.tblClientByClientId) === null || _g === void 0 ? void 0 : _g.email) || '' }, void 0) }), void 0) }), void 0)),
                                    !_isAppraiser && appModule.active.includes(APP_PACKAGES.NOTE) && (_jsxs(_Fragment, { children: [editable && (_jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(Note, { documentType: DOCUMENT_TYPE.JOB_REPORT_TYPE, jobId: id, onNoteAdded: onDocumentUpdated, modal: false, header: 'Reports' }, void 0) }), void 0) }), void 0)),
                                            _jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(Note, { jobId: id, onNoteAdded: onDocumentUpdated, modal: false }, void 0) }), void 0) }), void 0)] }, void 0))] }, void 0))] }), void 0),
                    !jobInEdit && (_jsxs(Grid, __assign({ item: true, xs: isMobile ? 12 : 6, className: "no-scroll" }, { children: [_isAppraiser && appModule.active.includes(APP_PACKAGES.NOTE) && (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(Note, { documentType: DOCUMENT_TYPE.JOB_REPORT_TYPE, jobId: id, onNoteAdded: onDocumentUpdated, modal: false, header: 'Reports' }, void 0) }), void 0) }, void 0),
                                    _jsx(Row, __assign({ className: "mt-16" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsx(Note, { jobId: id, onNoteAdded: onDocumentUpdated, modal: false }, void 0) }), void 0) }), void 0)] }, void 0)),
                            appModule.active.includes(APP_PACKAGES.ADD_INVOICE) &&
                                _jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsxs(Card, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "db" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Invoices" }), void 0)] }), void 0),
                                                            (editable && !drawer && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only)) && (_jsx("div", __assign({ className: "dib right w-50" }, { children: _jsxs(Button, __assign({ onClick: addNewInvoice, color: "default", className: "btn-xss" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Add Fee"] }), void 0) }), void 0))] }), void 0) }), void 0),
                                                _jsx(CardBody, __assign({ className: "p-0" }, { children: _jsx(AddInvoice, { jobId: id, onPaymentReceived: onPaymentReceived }, void 0) }), void 0)] }, void 0) }), void 0) }, void 0),
                            _jsx(Row, __assign({ className: appModule.active.includes(APP_PACKAGES.ADD_INVOICE) ? "mt-16" : "" }, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsxs(Card, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "db" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Payments/Refunds" }), void 0)] }), void 0),
                                                        (editable && !drawer && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only)) && (_jsx("div", __assign({ className: "dib right w-50" }, { children: _jsxs(Button, __assign({ onClick: addNewPayment, color: "default", className: "btn-xss" }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), " Add Payment/Refund"] }), void 0) }), void 0))] }), void 0) }), void 0),
                                            _jsx(CardBody, __assign({ className: "p-0" }, { children: _jsx(AddPayment, { mt: '', reload: reloadPayment, jobId: id }, void 0) }), void 0)] }, void 0) }), void 0) }), void 0),
                            appModule.active.includes(APP_PACKAGES.PROPERTY_SECTION) &&
                                _jsx(Row, { children: _jsx(Col, { children: _jsx(PropertySections, { displayForm: true, canEdit: editable && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only), table: SYSTEM_TABLES.JOB_DETAILS, sectionId: PROPERTY_SECTION.JOB_DETAILS, jobId: id }, void 0) }, void 0) }, void 0),
                            appModule.active.includes(APP_PACKAGES.TIMELINE) &&
                                _jsx(Row, { children: _jsx(Col, { children: _jsx(TimelineComponent, { id: id, reload: reloadTimeLine }, void 0) }, void 0) }, void 0)] }), void 0))] }), void 0),
            tab === 2 && (_jsx(Card, __assign({ className: clsx("no-border-radius") }, { children: _jsx(CardBody, { children: _jsxs(Table, __assign({ size: "small", className: clsx("mt-24", "table-border") }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Name" }, void 0),
                                        _jsx(TableCell, { children: "Current Use" }, void 0),
                                        _jsx(TableCell, { children: "Shape" }, void 0),
                                        _jsx(TableCell, { children: "Location" }, void 0),
                                        _jsx(TableCell, { children: "Note" }, void 0),
                                        _jsx(TableCell, { children: "Corner" }, void 0),
                                        _jsx(TableCell, { children: "Depth" }, void 0),
                                        _jsx(TableCell, { children: "Site FrontageFT" }, void 0),
                                        _jsx(TableCell, { children: "Site Area SF" }, void 0),
                                        _jsx(TableCell, { children: "Site Area AC" }, void 0),
                                        _jsx(TableCell, { children: "Services Summary" }, void 0),
                                        _jsx(TableCell, { children: "Created Date" }, void 0),
                                        _jsx(TableCell, { children: "Created By" }, void 0)] }, void 0) }, void 0),
                            _jsx(TableBody, { children: map(job === null || job === void 0 ? void 0 : job.tblSitesByJobId.nodes, function (item, index) {
                                    var _a;
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: item.name }, void 0),
                                            _jsx(TableCell, { children: item.currentUse }, void 0),
                                            _jsx(TableCell, { children: item.shape }, void 0),
                                            _jsx(TableCell, { children: item.location }, void 0),
                                            _jsx(TableCell, { children: item.note }, void 0),
                                            _jsx(TableCell, { children: item.corner }, void 0),
                                            _jsx(TableCell, { children: item.siteDepthFt }, void 0),
                                            _jsx(TableCell, { children: item.siteFrontageFt }, void 0),
                                            _jsx(TableCell, { children: item.siteAreaSf }, void 0),
                                            _jsx(TableCell, { children: item.siteAreaAc }, void 0),
                                            _jsx(TableCell, { children: item.servicesSummary }, void 0),
                                            _jsx(TableCell, { children: formatDate(item.zzCreationDate) }, void 0),
                                            _jsx(TableCell, { children: (_a = item.zzCreatedBy) === null || _a === void 0 ? void 0 : _a.toUpperCase() }, void 0)] }, index));
                                }) }, void 0)] }), void 0) }, void 0) }), void 0)),
            tab === 3 && (_jsx(Card, __assign({ className: "no-border-radius" }, { children: _jsx(CardBody, { children: _jsxs(Table, __assign({ size: "small", className: clsx("mt-24", "table-border") }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Pin" }, void 0),
                                        _jsx(TableCell, { children: "Pin2" }, void 0),
                                        _jsx(TableCell, { children: "Pin3" }, void 0),
                                        _jsx(TableCell, { children: "Logal Description" }, void 0),
                                        _jsx(TableCell, { children: "Roll#" }, void 0),
                                        _jsx(TableCell, { children: "Owner" }, void 0),
                                        _jsx(TableCell, { children: "Full Address" }, void 0),
                                        _jsx(TableCell, { children: "Created Date" }, void 0),
                                        _jsx(TableCell, { children: "Created By" }, void 0)] }, void 0) }, void 0),
                            _jsx(TableBody, { children: map(job === null || job === void 0 ? void 0 : job.tblGeowarehousesByJobId.nodes, function (item, index) {
                                    var _a;
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: item.pin }, void 0),
                                            _jsx(TableCell, { children: item.pin2 }, void 0),
                                            _jsx(TableCell, { children: item.pin3 }, void 0),
                                            _jsx(TableCell, { children: item.legalDescription }, void 0),
                                            _jsx(TableCell, { children: item.rollNo }, void 0),
                                            _jsx(TableCell, { children: item.owner }, void 0),
                                            _jsx(TableCell, { children: item.zzFullAddress }, void 0),
                                            _jsx(TableCell, { children: formatDate(item.zzCreationDate) }, void 0),
                                            _jsx(TableCell, { children: (_a = item.zzCreatedBy) === null || _a === void 0 ? void 0 : _a.toUpperCase() }, void 0)] }, index));
                                }) }, void 0)] }), void 0) }, void 0) }), void 0))] }), void 0));
};
