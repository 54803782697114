var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { TBLCLIENTS, Columns, DELETE_CLIENT } from "../../graphqls/clients/client.query";
import { Table } from "../../ui-components/table/table.component";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { useMutation } from "@apollo/client";
import { tableRowClicked } from "../../helpers/form.helper";
import { useDispatch } from "react-redux";
import { setOpenDrawer } from "../../stores/app.reducer";
import { DRAWERCOMPONENT } from '@enums';
export var Clients = function () {
    var dispatch = useDispatch();
    var _a = useState(false), reload = _a[0], setReload = _a[1];
    var deleteClient = useMutation(DELETE_CLIENT, {
        onCompleted: function () {
            setReload(!reload);
        }
    })[0];
    var removeClient = function (id) {
        deleteClient({
            variables: {
                id: +id,
            },
        });
    };
    var rowClicked = function (e, id) {
        if (tableRowClicked(e)) {
            dispatch(setOpenDrawer({ status: 'OPEN', id: id, component: DRAWERCOMPONENT.CLIENT }));
        }
    };
    return (_jsxs("div", __assign({ className: "content" }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, { title: "Clients" }, void 0) }, void 0) }, void 0),
            _jsx(Table, { addLink: "/admin/clients/edit", addLinkTitle: "New Client", rowClicked: rowClicked, reload: reload, exportToExcel: true, onDelete: removeClient, editRoute: "/admin/clients/edit/", tableName: "allTblClients", query: TBLCLIENTS, headers: Columns }, void 0)] }), void 0));
};
