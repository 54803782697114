var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import('./bootstrap');
// export { }
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client/react';
import { ModalForm } from './ui-components/modals/Modal-Form.component';
import { Alert } from './ui-components/modals/Alert.component';
import { Provider } from 'react-redux';
import { Spinner } from "./ui-components/loading/Spinner.component";
import { Login } from "./components/login/Login.component";
import { RegisterTrial } from "./components/login/Register.component";
import { ResetPassword } from "./components/login/Reset-Password.component";
import { apolloClient } from './graphqls/apollo-client';
import { ClientJobStatus } from "./components/clients/Job-Status.component";
import store from "./stores";
import { PopupForm } from './ui-components/forms/Popup-Form';
import { DialogForm } from './ui-components/modals/DialogForm.component';
var Home = React.lazy(function () { return import("./dynamo/Home"); });
var CheckOut = React.lazy(function () { return import("./modules/subscription/checkout.component"); });
var hist = createBrowserHistory();
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsxs(ApolloProvider, __assign({ client: apolloClient }, { children: [_jsx(Router, __assign({ history: hist }, { children: _jsx(Suspense, __assign({ fallback: _jsx("div", __assign({ className: 'app-loader-suspend' }, { children: _jsx("div", { className: "loader" }, void 0) }), void 0) }, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/login", render: function () { return _jsx(Login, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/valuation", render: function () { return _jsx(Home, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/admin", render: function () { return _jsx(App, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/public", render: function () { return _jsx(App, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/subscription/:id", render: function () { return _jsx(CheckOut, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/register/:plan?", render: function () { return _jsx(RegisterTrial, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/reset", render: function () { return _jsx(ResetPassword, {}, void 0); } }, void 0),
                                _jsx(Route, { path: "/quote/:id/:uuid/approve", render: function () { return _jsx(ClientJobStatus, {}, void 0); } }, void 0),
                                _jsx(Redirect, { to: "/admin" }, void 0)] }, void 0) }), void 0) }), void 0),
                _jsx(ModalForm, {}, void 0),
                _jsx(PopupForm, {}, void 0),
                _jsx(Spinner, {}, void 0),
                _jsx(Alert, {}, void 0),
                _jsx(DialogForm, {}, void 0)] }), void 0) }), void 0) }, void 0), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
