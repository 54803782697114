export var CUSTOMDATEPICKER;
(function (CUSTOMDATEPICKER) {
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["THIS_MONTH"] = 0] = "THIS_MONTH";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["LAST_MONTH"] = 1] = "LAST_MONTH";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["LAST_THREE_MONTH"] = 2] = "LAST_THREE_MONTH";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["LAST_YEAR"] = 3] = "LAST_YEAR";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["THIS_YEAR"] = 4] = "THIS_YEAR";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["CUSTOM"] = 5] = "CUSTOM";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["LAST_30_DAYS"] = 6] = "LAST_30_DAYS";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["THIS_MONTH_To_DATE"] = 7] = "THIS_MONTH_To_DATE";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["THIS_QUARTER"] = 8] = "THIS_QUARTER";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["THIS_YEAR_To_DATE"] = 9] = "THIS_YEAR_To_DATE";
    CUSTOMDATEPICKER[CUSTOMDATEPICKER["THIS_QUARTER_TO_DATE"] = 10] = "THIS_QUARTER_TO_DATE";
})(CUSTOMDATEPICKER || (CUSTOMDATEPICKER = {}));
export var CustomDatePickerOptions = [
    { id: CUSTOMDATEPICKER.LAST_30_DAYS, title: 'Last 30 days' },
    { id: CUSTOMDATEPICKER.THIS_MONTH, title: 'This month' },
    { id: CUSTOMDATEPICKER.THIS_MONTH_To_DATE, title: 'This month to date' },
    { id: CUSTOMDATEPICKER.THIS_QUARTER, title: 'This quarter' },
    { id: CUSTOMDATEPICKER.THIS_QUARTER_TO_DATE, title: 'This quarter to date' },
    { id: CUSTOMDATEPICKER.THIS_YEAR, title: 'This year' },
    { id: CUSTOMDATEPICKER.THIS_YEAR_To_DATE, title: 'This year to date' },
    { id: CUSTOMDATEPICKER.LAST_MONTH, title: 'Last month' },
    { id: CUSTOMDATEPICKER.LAST_THREE_MONTH, title: 'Last quarter' },
    { id: CUSTOMDATEPICKER.LAST_YEAR, title: 'Last year' },
    { id: CUSTOMDATEPICKER.CUSTOM, title: 'Custom Range' }
];
