var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from 'reselect';
import { orderBy } from "lodash";
import { EXPENSE_TYPE, INCOME_TYPE, SelectorName, StatusEnum } from "@enums";
var clientsSelectors = function (state) { return state.clients; };
export var getClients = createSelector([clientsSelectors], function (client) { return !client ? [] : orderBy(client === null || client === void 0 ? void 0 : client.clients.map(function (c) { return (__assign(__assign({}, c), { id: c.id, title: c.name, retainerRequired: c.retainerRequired, retainerFee: c.retainerFee })); }), 'title'); });
export var getJobTypes = createSelector([clientsSelectors], function (client) { return !client ? [] : client === null || client === void 0 ? void 0 : client.jobTypes.map(function (c) { return ({ id: c.id, title: c.typeTitle }); }); });
export var getPropertyTypes = createSelector([clientsSelectors], function (client) { return !client ? [] : client === null || client === void 0 ? void 0 : client.propertyTypes.map(function (c) { return ({ id: c.id, title: c.propertyType + " -> " + c.propertySubType, subTitle: c.propertySubType, pt: c.propertyType }); }); });
export var getStatuses = createSelector([clientsSelectors], function (client) { return !client ? [] : orderBy(client === null || client === void 0 ? void 0 : client.status.map(function (c) { return ({ id: c.id, title: c.statusTitle }); }), 'title'); });
export var getJobStatuses = createSelector([getStatuses], function (st) { return st.filter(function (s) { return ![StatusEnum.APPROVED, StatusEnum.CLOSED, StatusEnum.IN_PROGRESS, StatusEnum.CREATE_WORK_ORDER,
    StatusEnum.INVOICE_SENT, StatusEnum.PAYMENT_RECEIVED, StatusEnum.QUOTE_APPROVED, StatusEnum.QUOTE_SUBMITTED, StatusEnum.RELIANCE, StatusEnum.RETAINER_REQUIRED].includes(s.id); }); });
export var getAppraiserStatuses = createSelector([clientsSelectors], function (client) { return !client ? [] : client === null || client === void 0 ? void 0 : client.status.filter(function (c) {
    return [StatusEnum.QUOTE_SUBMITTED, StatusEnum.QUOTE_REJECTED, StatusEnum.WORK_IN_PROGRESS, StatusEnum.DRAFT_SUBMITTED, StatusEnum.FINAL_REPORT_SUBMITTED, StatusEnum.COMPLETED, StatusEnum.ARCHIVED].includes(c.id);
}).map(function (c) { return ({ id: c.id, title: c.statusTitle }); }); });
export var getMunicipalicities = createSelector([clientsSelectors], function (client) { return !client ? [] : client === null || client === void 0 ? void 0 : client.municipalicity.map(function (c) { return ({ id: c.id, title: c.municipality, subTitle: c.region }); }); });
export var getAppraisers = createSelector([clientsSelectors], function (client) { return !client ? [] : client === null || client === void 0 ? void 0 : client.appraisers.map(function (c) { return ({ id: c.id, title: c.firstName + " " + c.lastName, commission: +c.commissions, feeType: +c.feeTypes }); }); });
var expenseType = [
    { id: EXPENSE_TYPE.PRO_RATE, title: 'Pro Rate', optionType: SelectorName.EXPENSE_TYPE },
    { id: EXPENSE_TYPE.AMOUNT, title: '$Amount', optionType: SelectorName.EXPENSE_TYPE },
    { id: EXPENSE_TYPE.DOLLAR_PSF, title: '$PSF', optionType: SelectorName.EXPENSE_TYPE }
];
var incomeType = [{ id: INCOME_TYPE.AFTER_VACANCY, title: 'After Vacancy', optionType: SelectorName.INCOME_TYPE },
    { id: INCOME_TYPE.BEFORE_VACANCY, title: 'Before Vacancy', optionType: SelectorName.INCOME_TYPE }];
export var getOptions = createSelector([clientsSelectors], function (client) { return !client ? [{ id: 0, title: '' }] : orderBy(client === null || client === void 0 ? void 0 : client.options.map(function (c) { return ({ id: c.id, title: c.title, optionType: c.optionType, licenceId: c.licenceId }); }).concat(expenseType).concat(incomeType), 'title'); });
export var getCompany = createSelector([clientsSelectors], function (client) { return (client === null || client === void 0 ? void 0 : client.company) || { fileNumberFormat: "" }; });
