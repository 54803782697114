export var STORAGE_KEY;
(function (STORAGE_KEY) {
    STORAGE_KEY["COMPANY"] = "COMPANY";
    STORAGE_KEY["TABLE_VIEW"] = "TABLE_VIEW";
    STORAGE_KEY["COLUMN_ORDERS"] = "COLUMN_ORDERS";
    STORAGE_KEY["OFFICES"] = "OFFICES";
})(STORAGE_KEY || (STORAGE_KEY = {}));
export var addToStorage = function (key, value) {
    localStorage.setItem(key, JSON.stringify(value));
};
export var readFromStorage = function (key) {
    var value = localStorage.getItem(key);
    if (value && value !== "undefined") {
        return JSON.parse(value);
    }
    return {};
};
export var updateTableViews = function (viewName, view) {
    localStorage.setItem(STORAGE_KEY.TABLE_VIEW + "_" + viewName, JSON.stringify(view));
};
export var getTableColumnOrderViews = function (viewName) {
    var data = readFromStorage(STORAGE_KEY.COLUMN_ORDERS + "_" + viewName);
    return (data === null || data === void 0 ? void 0 : data.length) ? data : null;
};
export var updateTableColumnOrders = function (viewName, view) {
    localStorage.setItem(STORAGE_KEY.COLUMN_ORDERS + "_" + viewName, JSON.stringify(view));
};
export var getTableViews = function (viewName) {
    var data = readFromStorage(STORAGE_KEY.TABLE_VIEW + "_" + viewName);
    return (data === null || data === void 0 ? void 0 : data.length) ? data : null;
};
export var getCompanyName = function () {
    var _a;
    return ((_a = readFromStorage(STORAGE_KEY.COMPANY)) === null || _a === void 0 ? void 0 : _a.name) || '';
};
export var getCompanyTax = function () {
    var _a;
    var tax = +(((_a = readFromStorage(STORAGE_KEY.COMPANY)) === null || _a === void 0 ? void 0 : _a.tax) || '0');
    return 1 + parseFloat((tax / 100).toFixed(2));
};
export var getCompanyTaxPercent = function () {
    var _a;
    var tax = +(((_a = readFromStorage(STORAGE_KEY.COMPANY)) === null || _a === void 0 ? void 0 : _a.tax) || '0');
    return parseFloat((tax / 100).toFixed(2));
};
export var getCompanyEmailSignature = function () {
    var _a;
    return ((_a = readFromStorage(STORAGE_KEY.COMPANY)) === null || _a === void 0 ? void 0 : _a.emailTemplate) || '';
};
export var getInvoiceTemplates = function () {
    var menu = readFromStorage(STORAGE_KEY.COMPANY);
    if (menu === null || menu === void 0 ? void 0 : menu.invoiceTemplate) {
        return JSON.parse(menu.invoiceTemplate);
    }
    return {};
};
export var getCompanyMenu = function () {
    var menu = readFromStorage(STORAGE_KEY.COMPANY);
    if (menu === null || menu === void 0 ? void 0 : menu.menuItems) {
        return JSON.parse(menu.menuItems);
    }
    return null;
};
