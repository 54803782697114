var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { ColumnType } from '@enums';
export var GET_COMPANY_BY_ID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery getCompanyById($id: BigInt!) {\n  tblCompanyById(id: $id) {\n    name\n    email\n    licenceId\n  }\n}"], ["\nquery getCompanyById($id: BigInt!) {\n  tblCompanyById(id: $id) {\n    name\n    email\n    licenceId\n  }\n}"])));
export var ALLUSERS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery getAllUsers ($offset: Int,$first: Int,$orderBy:[CoreUsersOrderBy!]) {\n  allCoreUsers(first: $first, offset: $offset,orderBy:$orderBy) {\n     totalCount\n    nodes {\n      email\n      id\n      licenceid\n      role\n      userName\n    }\n   \n  }\n}\n"], ["\nquery getAllUsers ($offset: Int,$first: Int,$orderBy:[CoreUsersOrderBy!]) {\n  allCoreUsers(first: $first, offset: $offset,orderBy:$orderBy) {\n     totalCount\n    nodes {\n      email\n      id\n      licenceid\n      role\n      userName\n    }\n   \n  }\n}\n"])));
export var LOGIN = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation doLogin($email:String!,$pwd:String!) {\n    authenticate(input: {email: $email, password:$pwd}) {\n      results {\n        id\n        jwtTokens\n        role\n        licenceid\n        userName\n        roles\n      }\n    }\n  }\n"], ["\n    mutation doLogin($email:String!,$pwd:String!) {\n    authenticate(input: {email: $email, password:$pwd}) {\n      results {\n        id\n        jwtTokens\n        role\n        licenceid\n        userName\n        roles\n      }\n    }\n  }\n"])));
export var BILLINGS = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Payment Date",
        fieldName: "zzCreatedDate",
        columnType: ColumnType.DATE,
    },
    {
        caption: "Start Date",
        fieldName: "startDate",
        columnType: ColumnType.DATE_ONLY,
    },
    {
        caption: "End Date",
        fieldName: "endDate",
        columnType: ColumnType.DATE_ONLY,
    },
    {
        caption: "Amount",
        fieldName: "amount",
        columnType: ColumnType.CURRENCY,
        totalInFooter: true,
    },
    {
        caption: "Description",
        fieldName: "description",
    }
];
export var Columns = [
    {
        caption: "id",
        fieldName: "id",
        sortField: "",
        isHidden: true,
    },
    {
        caption: "Email",
        fieldName: "email",
        sortField: "EMAIL",
        sortable: true,
    },
    {
        caption: "User Name",
        fieldName: "userName",
        sortField: "USER_NAME",
        sortable: true,
    },
    {
        caption: "Role",
        fieldName: "role",
        sortField: "ROLE",
        sortable: true,
    },
    {
        caption: "Licence#",
        fieldName: "licenceid",
        sortField: "LICENCEID",
        sortable: true,
    }
];
var templateObject_1, templateObject_2, templateObject_3;
