var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { setFormData } from '../../helpers/form.helper';
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { ModalForm } from '../../ui-components/modals/Modal-Form.component';
import { setUserToken, setUserData } from "../../services/user-service";
import { httpPost } from "../../helpers/http.helper";
import "./login.css";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { SubscriptionPlans } from "../../modules/subscription/subscription-plans.component";
export var Login = function () {
    var _a = useState(''), returnUrl = _a[0], setReturnUrl = _a[1];
    var _b = useState(false), error = _b[0], setError = _b[1];
    var _c = useState({
        email: '',
        password: '',
        id: 0,
        licenceid: 0,
    }), login = _c[0], setLogin = _c[1];
    var continuePayment = function (id, user, licenceid) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("stripe/continuetopayment", {
                        plan: id,
                        email: login.email,
                        userId: user,
                        licenceId: licenceid,
                        count: 1,
                    })];
                case 1:
                    result = _a.sent();
                    if (!(result === null || result === void 0 ? void 0 : result.severity)) {
                        document.location.href = result.url;
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var renew = function (licenceid, isNew) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpPost("stripe/createPaymentLink", {
                        licence: licenceid,
                        email: login.email,
                        isNew: isNew,
                    })];
                case 1:
                    data = _a.sent();
                    document.location.href = data.url;
                    return [2 /*return*/];
            }
        });
    }); }; };
    var doLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setError(false);
                    Spinner.show();
                    return [4 /*yield*/, httpPost("user/auth/userlogin", {
                            email: login.email,
                            password: login.password
                        })];
                case 1:
                    data = _a.sent();
                    Spinner.hide();
                    if (!(data === null || data === void 0 ? void 0 : data.id)) {
                        setError(true);
                        return [2 /*return*/];
                    }
                    if (+data.licenceid !== 1) {
                        if (!(data === null || data === void 0 ? void 0 : data.jwtToken)) {
                            ModalForm.show({ small: !data.isNew, large: data.isNew, footer: false, title: 'Subscription Status', body: (_jsxs("div", __assign({ className: "db" }, { children: [_jsx("div", __assign({ className: "db pt-20 center" }, { children: data.isNew ? 'You have not purchased any plan yet.' : 'Your current subscription has been expired!' }), void 0),
                                        data.isNew && _jsx("div", __assign({ className: "db pt-10 center" }, { children: "Please choose a plan and subscribe." }), void 0),
                                        _jsx("div", __assign({ className: "db pt-20" }, { children: "\u00A0" }), void 0),
                                        data.isNew && _jsx("div", __assign({ className: "db pt-20" }, { children: _jsx(SubscriptionPlans, { onSelect: continuePayment, plans: data.plans, user: data.id, licenceid: data.licenceid }, void 0) }), void 0),
                                        !data.isNew && _jsx("div", __assign({ className: "db mb-32" }, { children: _jsx(Button, __assign({ color: "info", onClick: renew(data.licenceid, data.isNew), className: "btn btn-block" }, { children: "Renew your subscription" }), void 0) }), void 0)] }), void 0)) });
                            return [2 /*return*/];
                        }
                    }
                    setUserToken(data.jwtToken);
                    setUserData(data);
                    if (returnUrl) {
                        document.location.href = returnUrl + "?code=" + data.jwtToken;
                    }
                    else {
                        document.location.href = "/admin";
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a, _b;
        if (((_a = window.location.search) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf("return_url")) !== -1) {
            setReturnUrl((_b = window.location.search) === null || _b === void 0 ? void 0 : _b.replace('?return_url=', ''));
        }
        document.body.style.backgroundColor = 'white';
    }, [window.location]);
    return (_jsxs("div", __assign({ className: "login-wrapper" }, { children: [_jsx("div", __assign({ className: "login-side" }, { children: _jsx("div", __assign({ className: "my-form__wrapper" }, { children: _jsxs("div", __assign({ className: "my-form" }, { children: [_jsx("div", __assign({ className: "socials-row" }, { children: _jsx("a", __assign({ href: "/register", title: "Create Account" }, { children: "Create Account \uD83D\uDC4F" }), void 0) }), void 0),
                            _jsxs("div", __assign({ className: "divider" }, { children: [_jsx("div", { className: "divider-line" }, void 0), "Or", _jsx("div", { className: "divider-line" }, void 0)] }), void 0),
                            _jsxs("div", __assign({ className: "text-field " + (error ? 'input-error' : '') }, { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Email:" }), void 0),
                                    _jsx("input", { type: "email", id: "email", name: "email", onChange: function (e) { return setFormData(e, 'email', login, setLogin); }, placeholder: "Your Email", required: true, className: error ? 'input-error' : '' }, void 0),
                                    _jsx(AlternateEmailIcon, {}, void 0)] }), void 0),
                            _jsxs("div", __assign({ className: "text-field " + (error ? 'input-error' : '') }, { children: [_jsx("label", __assign({ htmlFor: "password" }, { children: "Password:" }), void 0),
                                    _jsx("input", { id: "password", type: "password", name: "password", onChange: function (e) { return setFormData(e, 'password', login, setLogin); }, placeholder: "Your Password", title: "Minimum 6 characters at \n                                    least 1 Alphabet and 1 Number", pattern: "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{6,}$", required: true }, void 0),
                                    _jsx(LockOutlinedIcon, {}, void 0)] }), void 0),
                            error && _jsxs("div", __assign({ className: "error flex gap-8" }, { children: [_jsx("i", { className: "fa fa-exclamation-circle" }, void 0), " Wrong email or password"] }), void 0),
                            _jsx("input", { type: "submit", onClick: doLogin, className: "my-form__button", value: "Login" }, void 0),
                            _jsxs("div", __assign({ className: "my-form__actions" }, { children: [_jsx("div", __assign({ className: "my-form__row" }, { children: _jsx("span", { children: "Did you forget your password?" }, void 0) }), void 0),
                                    _jsx("div", __assign({ className: "my-form__signup" }, { children: _jsx("a", __assign({ href: "/reset", title: "Reset Password" }, { children: "Reset Password" }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0),
            _jsx("div", { className: "info-side" }, void 0)] }), void 0));
};
