var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCompanyTaxPercent } from "../../utils/Storage.util";
export var TableHeader = function (_a) {
    var _b = _a.headers, headers = _b === void 0 ? [] : _b, _c = _a.hasAction, hasAction = _c === void 0 ? true : _c, _d = _a.selection, selection = _d === void 0 ? false : _d, onSelectAll = _a.onSelectAll;
    var tax = getCompanyTaxPercent() * 100;
    var selectAllChanged = function (e) {
        var _a;
        onSelectAll && onSelectAll((_a = e.target) === null || _a === void 0 ? void 0 : _a.checked);
    };
    return (_jsxs("tr", { children: [selection && (_jsx("th", { children: _jsx("input", { id: "chk_select_all_table", type: "checkbox", onChange: selectAllChanged }, void 0) }, void 0)),
            headers.map(function (header, key) {
                return (_jsx("th", { children: _jsx("span", __assign({ className: "pointer" }, { children: header.caption.replace('__TAX__', "" + tax) }), void 0) }, key));
            }),
            hasAction && (_jsx("th", { children: _jsx("span", { children: "Action" }, void 0) }, "action"))] }, void 0));
};
