var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { getJobsForMap } from "../../services/job.service";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { uniq, orderBy } from "lodash";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { MenuItem, Typography } from "@material-ui/core";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { getAppraisers } from "../../stores/client.selector";
import { useSelector } from "react-redux";
import { isAppraiser } from "../../services/user-service";
import { StatusEnum } from "@enums";
import { GoogleMap } from "../../ui-components/google-map/googleMap";
import { ButtonGroup } from "../../ui-components/button/ButtonGroup";
import { isMobile } from "react-device-detect";
export var AppraisalInMap = function () {
    var apolloClient = useApolloQuery()[0];
    var appraisers = useSelector(getAppraisers);
    var _a = useState(null), points = _a[0], setPoints = _a[1];
    var _b = useState(null), selected = _b[0], setSelected = _b[1];
    var _c = useState(null), cities = _c[0], setCities = _c[1];
    var _d = useState('ALL'), city = _d[0], setCity = _d[1];
    var _e = useState(0), appId = _e[0], setAppId = _e[1];
    var _f = useState(StatusEnum.IN_PROGRESS), status = _f[0], setStatus = _f[1];
    var _isAppraiser = isAppraiser();
    var getJobs = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, getJobsForMap(appId, status)(apolloClient)];
                case 1:
                    data = _a.sent();
                    setPoints(data);
                    setSelected(data);
                    setCities(orderBy(uniq(data.map(function (d) { return d.city; }))));
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var setJobStatus = function (status) {
        setStatus(status);
    };
    var appChanged = function (ev) {
        var _a;
        setAppId(+((_a = ev.target) === null || _a === void 0 ? void 0 : _a.value));
    };
    var cityChanged = function (e) {
        setCity(e.target.value);
        setSelected(points.filter(function (p) { return p.city === e.target.value || e.target.value === 'ALL'; }));
    };
    useEffect(function () {
        getJobs();
    }, [appId, status]);
    return (_jsxs("div", __assign({ className: "content pb-0 pr-0 bg-white pt-0" }, { children: [_jsxs(Row, __assign({ className: "bg-white " + (isMobile ? '' : 'pr-90 pl-10') }, { children: [_jsxs(Col, __assign({ xs: isMobile ? 12 : 3, className: "flex gap-16 " + (isMobile ? 'pt-4' : '') }, { children: [_jsxs(InputText, __assign({ select: true, onChange: cityChanged, className: "min-w-200", label: "City", value: city }, { children: [_jsx(MenuItem, __assign({ value: 'ALL' }, { children: "-- All --" }), 'ALL'),
                                    cities === null || cities === void 0 ? void 0 : cities.map(function (city) { return (_jsx(MenuItem, __assign({ value: city }, { children: city }), city)); })] }), void 0),
                            isMobile && _jsxs(Typography, __assign({ variant: "subtitle2" }, { children: ["(", selected === null || selected === void 0 ? void 0 : selected.length, ") Jobs"] }), void 0)] }), void 0),
                    !_isAppraiser && (_jsx(Col, __assign({ xs: isMobile ? 12 : 3, className: "" + (isMobile ? 'pt-4' : '') }, { children: _jsxs(InputText, __assign({ select: true, label: "Appraiser Filter", onChange: appChanged, value: appId, className: "min-w-200" }, { children: [_jsx(MenuItem, __assign({ value: 0 }, { children: "-- ALL --" }), 0),
                                appraisers === null || appraisers === void 0 ? void 0 : appraisers.map(function (o) { return (_jsx(MenuItem, __assign({ value: o.id }, { children: o.title }), void 0)); })] }), void 0) }), void 0)),
                    _jsx(Col, __assign({ xs: isMobile ? 12 : 3, className: "pt-16" }, { children: _jsx(ButtonGroup, { onClick: setJobStatus, selected: status, items: [{ id: StatusEnum.NONE, title: 'All' }, { id: StatusEnum.IN_PROGRESS, title: 'In Progress' }, { id: StatusEnum.COMPLETED, title: 'Completed' }] }, void 0) }), void 0),
                    !isMobile && _jsx(Col, __assign({ xs: _isAppraiser ? 6 : 3, className: "flex flex-end" }, { children: _jsxs(Typography, __assign({ variant: "subtitle2" }, { children: ["Total jobs: (", selected === null || selected === void 0 ? void 0 : selected.length, ")"] }), void 0) }), void 0)] }), void 0),
            _jsx(Row, { children: _jsx(Col, __assign({ xs: 12, className: "bg-white pt-16 pl-20 pr-20" }, { children: _jsx(GoogleMap, { height: isMobile ? "66vh" : "74vh", data: selected }, void 0) }), void 0) }, void 0)] }), void 0));
};
