var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { InputText } from '../../ui-components/input/Input-Field.component';
import { getInvoiceTemplates } from '../../utils/Storage.util';
import { toPdf } from "../../helpers/doc.helper";
import { MenuItem } from '@material-ui/core';
import { httpGet } from '../../helpers/http.helper';
import EmailTemplateForm from "../email/EmailTemplateForm";
export var InvoicePreview = function (_a) {
    var _b = _a.email, email = _b === void 0 ? '' : _b, id = _a.id, index = _a.index, onSend = _a.onSend;
    var emailRef = useRef();
    var _c = useState({
        templates: null,
    }), form = _c[0], setForm = _c[1];
    var _d = useState(''), templateId = _d[0], setTemplateId = _d[1];
    var _e = useState(''), preview = _e[0], setPreview = _e[1];
    var sendInvoice = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, email, signature, cc, pdf;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = (_b = emailRef.current) === null || _b === void 0 ? void 0 : _b.getData(), email = _a.email, signature = _a.signature, cc = _a.cc;
                    return [4 /*yield*/, toPdf(document.getElementById('preview'))];
                case 1:
                    pdf = _c.sent();
                    onSend && onSend(index, id, email, signature, cc, pdf);
                    return [2 /*return*/];
            }
        });
    }); };
    var templateChanged = function (e) {
        setTemplateId(e.target.value);
    };
    useEffect(function () {
        var _a;
        var tmpl = getInvoiceTemplates();
        setForm(__assign(__assign({}, form), { templates: tmpl }));
        setTemplateId(((_a = Object.keys(tmpl)) === null || _a === void 0 ? void 0 : _a[0]) || '');
    }, []);
    var loadPreview = function () { return __awaiter(void 0, void 0, void 0, function () {
        var preview;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, httpGet("invoice/preview/" + id + "/" + templateId)];
                case 1:
                    preview = (_a.sent()).preview;
                    setPreview(preview);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (templateId) {
            loadPreview();
        }
    }, [templateId]);
    return (_jsxs("div", __assign({ className: "db w-full v-top" }, { children: [_jsxs("div", __assign({ className: "dib w-40 v-top p-2" }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(InputText, __assign({ value: "" + templateId, label: "Templates", size: "small", fullWidth: true, select: true, onChange: templateChanged }, { children: Object.keys((form === null || form === void 0 ? void 0 : form.templates) || {}).map(function (key) { return (_jsx(MenuItem, __assign({ value: key }, { children: key }), key)); }) }), void 0) }, void 0) }, void 0),
                    _jsx(EmailTemplateForm, { email: email, ref: emailRef }, void 0)] }), void 0),
            _jsx("div", __assign({ className: "dib w-60 v-top border-1" }, { children: _jsx("div", { className: 'db w-100', id: "preview", dangerouslySetInnerHTML: { __html: preview } }, void 0) }), void 0),
            _jsx("input", { type: "button", id: "send-invoice-button", className: 'hidden', onClick: sendInvoice }, void 0)] }), void 0));
};
