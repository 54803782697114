var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { format, getMonth, lastDayOfMonth, addMonths, addDays as fnsAddDays, parseISO, differenceInMonths, startOfQuarter, endOfQuarter, setQuarter, getQuarter } from 'date-fns';
export var formatDate = function (date, formatString) {
    if (formatString === void 0) { formatString = 'LLLL'; }
    if (date && typeof date === "string" && date.indexOf('T') !== -1) {
        return format(parseISO(date), formatString);
    }
    else {
        try {
            return !date ? '' : format(date, formatString);
        }
        catch (_a) {
            return !date ? '' : format(parseISO(date), formatString);
        }
    }
};
export var addToMonth = function (date, month) { return addMonths(date, month); };
export var formatDateTime = function (date, formatString) {
    if (formatString === void 0) { formatString = 'LLLL'; }
    return format(date, formatString);
};
export var getSeconds = function () { return "" + new Date().getTime(); };
export var getThisMonth = function () { return [getMonth(new Date()) + 1 + "/01/" + new Date().getFullYear(), getMonth(new Date()) + 1 + "/" + lastDayOfMonth(new Date()).getDate() + "/" + new Date().getFullYear()]; };
export var getThisMonthToDate = function () { return [getMonth(new Date()) + 1 + "/01/" + new Date().getFullYear(), getMonth(new Date()) + 1 + "/" + new Date().getDate() + "/" + new Date().getFullYear()]; };
export var getLast30Days = function () { return ["" + addDays(new Date(), -30, "MM/dd/yyyy"), getMonth(new Date()) + 1 + "/" + new Date().getDate() + "/" + new Date().getFullYear()]; };
export var getLastMonth = function () {
    var d = addToMonth(new Date(), -1);
    return [getMonth(d) + 1 + "/01/" + d.getFullYear(), getMonth(d) + 1 + "/" + lastDayOfMonth(d).getDate() + "/" + d.getFullYear()];
};
export var getThisYear = function () { return ["01/01/" + new Date().getFullYear(), "12/" + lastDayOfMonth(new Date()).getDate() + "/" + new Date().getFullYear()]; };
export var getThisYearToDate = function () { return ["01/01/" + new Date().getFullYear(), getMonth(new Date()) + 1 + "/" + new Date().getDate() + "/" + new Date().getFullYear()]; };
export var getLastYear = function () { return ["01/01/" + (new Date().getFullYear() - 1), "12/" + lastDayOfMonth(new Date(new Date().getFullYear() - 1, 12, 1)).getDate() + "/" + (new Date().getFullYear() - 1)]; };
export var getLastThreeMonth = function () {
    var q = getQuarter(new Date());
    var date = setQuarter(new Date(), q - 1);
    return ["" + formatDate(startOfQuarter(date), 'MM/dd/yyyy'), "" + formatDate(endOfQuarter(date), 'MM/dd/yyyy')];
};
var monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export var getMonthName = function (month) { return monthNames[month]; };
export var getThisQuarter = function () { return ["" + formatDate(startOfQuarter(new Date()), 'MM/dd/yyyy'), "" + formatDate(endOfQuarter(new Date()), 'MM/dd/yyyy')]; };
export var getThisQuarterToDate = function () { return ["" + formatDate(startOfQuarter(new Date()), 'MM/dd/yyyy'), getMonth(new Date()) + 1 + "/" + new Date().getDate() + "/" + new Date().getFullYear()]; };
export var addDays = function (date, days, format) {
    if (days === void 0) { days = 7; }
    if (format === void 0) { format = 'yyyy-MM-DD'; }
    return formatDate(fnsAddDays(new Date(date), days), format);
};
export var handleDateChanged = function (formik, key) { return function (date) {
    formik.setFieldValue(key, formatDate(date, 'yyyy-MM-dd'));
}; };
export var handleFormDateChanged = function (setForm, form, key) { return function (date) {
    var _a;
    setForm(__assign(__assign({}, form), (_a = {}, _a[key] = formatDate(date, 'yyyy-MM-dd'), _a)));
}; };
export var toDate = function (date) { return parseISO(date + 'T00:00:00'); };
export var getCurrentMonth = function (date) { return [getMonth(date) + 1 + "/01/" + date.getFullYear(), getMonth(date) + 1 + "/" + lastDayOfMonth(date).getDate() + "/" + date.getFullYear()]; };
export var detectSameDates = function (input) {
    var regex = /[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])-([1-2][0-9]|[0][1-9]|[3][0-1])/g;
    var found = input.match(regex);
    return (found === null || found === void 0 ? void 0 : found.length) && found.length === 2 && found[0] === found[1];
};
export var calcDifferenceInMonths = function (startDate, endDate) { return differenceInMonths(endDate, startDate); };
export var convertDateToYear = function (input) { return !input ? 0 : parseFloat(((+input.split('-')[0]) + ((+input.split('-')[1]) / 12)).toFixed(2)); };
