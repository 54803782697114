var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { routes } from "../routes";
import { canRenderRoute, getUserRole } from "../services/user-service";
import { Roles } from "@enums";
import { getCompanyMenu, getCompanyName } from "../utils/Storage.util";
import { useDispatch, useSelector } from "react-redux";
import { getAppModules, getSidebarLeft, getSidebarWidth } from "../stores/app.selector";
import { setSidebarLeft, toggleSidebarWidth } from "../stores/app.reducer";
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { isMobile } from 'react-device-detect';
export var Sidebar = function () {
    var _a = useState(''), role = _a[0], setRole = _a[1];
    var dispatch = useDispatch();
    var sidebarWidth = useSelector(getSidebarWidth);
    var sidebarLeft = useSelector(getSidebarLeft);
    var hideModule = useSelector(getAppModules);
    var company = getCompanyName();
    var menu = getCompanyMenu();
    var locationChanged = function () {
        if (isMobile) {
            dispatch(setSidebarLeft(''));
        }
    };
    var activeRoute = function (routeName) {
        return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    var toggleSidearWidth = function () {
        if (isMobile) {
            dispatch(setSidebarLeft(''));
        }
        else {
            dispatch(toggleSidebarWidth(sidebarWidth ? '' : '60px'));
        }
    };
    useEffect(function () {
        var role = getUserRole();
        setRole(role);
    }, []);
    useEffect(function () {
        if (!isMobile)
            return;
        if (sidebarLeft) {
            document.getElementById('sidebar-left').style.left = sidebarLeft + 'px';
            document.getElementById('sidebar-left').style.width = '200px';
        }
        else {
            document.getElementById('sidebar-left').style.left = 0;
        }
    }, [sidebarLeft, isMobile]);
    return (_jsxs("div", __assign({ id: "sidebar-left", className: "sidebar", style: sidebarWidth ? { width: sidebarWidth } : {} }, { children: [(!isMobile || sidebarLeft) && _jsx("div", __assign({ className: "sidebar-toggle-action", onClick: toggleSidearWidth }, { children: !sidebarWidth ? _jsx(NavigateBeforeOutlinedIcon, { style: { color: 'white' } }, void 0) : _jsx(NavigateNextOutlinedIcon, { style: { color: 'white' } }, void 0) }), void 0),
            _jsx("div", __assign({ className: "logo " + (role === Roles.CLIENT ? 'client-logo' : '') }, { children: _jsx("div", __assign({ title: company, className: "simple-text logo-normal company-name" }, { children: !sidebarWidth ? 'ICISIGMA' : 'ICI' }), void 0) }), void 0),
            _jsx("div", __assign({ className: "sidebar-wrapper " + (sidebarWidth ? 'sidebar-wrapper-small' : ''), style: sidebarWidth ? { width: sidebarWidth } : {} }, { children: _jsx(Nav, { children: routes.filter(function (r) { return !r.hidden; }).map(function (prop, key) {
                        if (!canRenderRoute(role, prop, hideModule === null || hideModule === void 0 ? void 0 : hideModule.inactive)) {
                            return null;
                        }
                        return (_jsx("li", __assign({ className: activeRoute(prop.path) }, { children: (prop.subs && role !== Roles.APPRAISER) ? (_jsxs(_Fragment, { children: [_jsxs("a", __assign({ "data-toggle": "collapse", href: "#" + prop.id + "\"", className: "", "aria-expanded": "true" }, { children: [_jsx("i", { className: "nc-icon nc-book-bookmark" }, void 0),
                                            _jsxs("p", { children: [(menu === null || menu === void 0 ? void 0 : menu[prop.id]) ? menu === null || menu === void 0 ? void 0 : menu[prop.id] : prop.name, " ", _jsx("b", { className: "caret" }, void 0)] }, void 0)] }), void 0),
                                    _jsx("div", __assign({ className: "collapse show", id: prop.id }, { children: _jsx("ul", __assign({ className: "nav pl-40" }, { children: prop.subs.map(function (sub) { return (_jsx("li", __assign({ className: activeRoute(sub.path) }, { children: _jsx(NavLink, __assign({ to: sub.layout + sub.path, className: "nav-link", activeClassName: "active" }, { children: !sidebarWidth && (_jsx("span", __assign({ className: "sidebar-normal" }, { children: (menu === null || menu === void 0 ? void 0 : menu[sub.id]) ? menu === null || menu === void 0 ? void 0 : menu[sub.id] : sub.name }), void 0)) }), void 0) }), sub.layout + sub.path)); }) }), void 0) }), void 0)] }, void 0)) : (_jsxs(NavLink, __assign({ onClick: locationChanged, to: prop.layout + prop.path, className: "nav-link", activeClassName: "active" }, { children: [_jsx("i", { className: prop.icon }, void 0),
                                    _jsx("p", __assign({ className: sidebarWidth ? 'color-sidebar' : '' }, { children: sidebarWidth ? '.' : ((menu === null || menu === void 0 ? void 0 : menu[prop.id]) ? menu === null || menu === void 0 ? void 0 : menu[prop.id] : prop.name) }), void 0),
                                    sidebarWidth && _jsx("div", __assign({ className: "hover-sidebar-item" }, { children: (menu === null || menu === void 0 ? void 0 : menu[prop.id]) ? menu === null || menu === void 0 ? void 0 : menu[prop.id] : prop.name }), void 0)] }), void 0)) }), key + "-" + prop.id));
                    }) }, void 0) }), void 0)] }), void 0));
};
