var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@material-ui/core";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { AppChart } from "../../ui-components/charts/Chart.component";
import { useMutation } from "@apollo/client";
import { GET_PAYMENTS } from "../../graphqls/dashboard/payments.query";
import { formatTotalAsCurrency } from "../../helpers/numbers-helper.helper";
import { orderBy, sumBy, groupBy, round } from "lodash";
import { ColumnType, StatusEnum } from "@enums";
import { isAppraiser } from "../../services/user-service";
import { CustomDatePicker } from "../../ui-components/date-picker/Custom-Date-Picker";
import { formatDate, getThisMonth, getMonthName } from "@utils/date";
import LinearProgress from '@material-ui/core/LinearProgress';
import { CUSTOMDATEPICKER } from "../../ui-components/date-picker/Date.enum";
import { formatNumbers } from "@utils/text";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { getDrivingDistance } from "../../services/invoice.service";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { isMobile } from 'react-device-detect';
import AppraiserDashboard from "./AppraiserDashboard.component";
import { CssGridContainer, CssGridItem } from "../../ui-components/css-grid/index";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { MyCalendar } from "../calendar";
import { ModalForm } from "../../ui-components/modals/Modal-Form.component";
import { Table } from "../../ui-components/table/table.component";
import { Income } from "../accounting/Income";
export var Dashboard = function () {
    var _a = useState({ start: getThisMonth()[0], end: getThisMonth()[1] }), date = _a[0], setDate = _a[1];
    var _b = useState(null), expenses = _b[0], setExpenses = _b[1];
    var _c = useState([]), acr = _c[0], setAcr = _c[1];
    var _d = useState(null), drivingDistance = _d[0], setDrivingDistance = _d[1];
    var _e = useState(null), allJobsIncome = _e[0], setAllJobsIncome = _e[1];
    var _f = useState(null), allJobsRevenue = _f[0], setAllJobsRevenue = _f[1];
    var _isAppraiser = isAppraiser();
    var apolloClient = useApolloQuery()[0];
    var _g = useState({
        quote: "0",
        appraisal: "0",
        total: "0",
        retainer: "0",
        pending: "0",
        progress: "0",
        approved: "0",
        review: '0',
        cancelled: '0',
    }), jobs = _g[0], setJobs = _g[1];
    var getPayments = useMutation(GET_PAYMENTS, {
        onCompleted: function (data) { return __awaiter(void 0, void 0, void 0, function () {
            var _acr, jobType, _allIncome, _allRevenue;
            var _a, _b, _c, _d, _e, _f, _g;
            return __generator(this, function (_h) {
                setExpenses(data.spDashboard.results.filter(function (c) { return c.datepart === 'EXPENSES'; }));
                _acr = orderBy(Object.entries(groupBy(data.spDashboard.results.filter(function (c) { return c.datepart === 'ACR' && c.total > 0; }), 'title')).map(function (_a) {
                    var _b;
                    var key = _a[0], value = _a[1];
                    return {
                        title: key,
                        total: sumBy(value, 'total') || 0,
                        id: (_b = value === null || value === void 0 ? void 0 : value[0]) === null || _b === void 0 ? void 0 : _b.refid,
                    };
                }), 'title');
                setAcr(_acr);
                jobType = data.spDashboard.results.filter(function (c) { return c.datepart === 'JOB_TYPE'; });
                setJobs({
                    quote: formatNumbers((((_a = jobType.find(function (t) { return t.title === 'Quote'; })) === null || _a === void 0 ? void 0 : _a.total) || 0) + (((_b = jobType.find(function (t) { return t.title === 'Appraisal'; })) === null || _b === void 0 ? void 0 : _b.total) || 0)),
                    appraisal: formatNumbers(((_c = jobType.find(function (t) { return t.title === 'Appraisal'; })) === null || _c === void 0 ? void 0 : _c.total) || 0),
                    total: formatNumbers(sumBy(jobType, 'total') || 0),
                    retainer: formatNumbers(((_d = data.spDashboard.results.find(function (t) { return t.datepart === 'JOB_STATUS' && +t.refid === StatusEnum.RETAINER_REQUIRED; })) === null || _d === void 0 ? void 0 : _d.total) || 0),
                    pending: formatNumbers(((_e = data.spDashboard.results.find(function (t) { return t.datepart === 'JOB_STATUS' && +t.refid === StatusEnum.QUOTE_PENDING; })) === null || _e === void 0 ? void 0 : _e.total) || 0),
                    progress: formatNumbers(sumBy(data.spDashboard.results.filter(function (t) { return t.datepart === 'JOB_STATUS' && [StatusEnum.RELIANCE, StatusEnum.DRAFT_SUBMITTED, StatusEnum.WORK_IN_PROGRESS, StatusEnum.IN_PROGRESS, StatusEnum.FINAL_REPORT_SUBMITTED, StatusEnum.INVOICE_SENT, StatusEnum.QUOTE_REJECTED, StatusEnum.QUOTE_APPROVED].includes(+t.refid); }), 'total')),
                    approved: formatNumbers(sumBy(data.spDashboard.results.filter(function (t) { return t.datepart === 'JOB_STATUS' && [StatusEnum.COMPLETED, StatusEnum.CLOSED, StatusEnum.APPROVED].includes(+t.refid); }) || 0, 'total')),
                    review: formatNumbers(((_f = data.spDashboard.results.find(function (t) { return t.datepart === 'JOB_STATUS' && +t.refid === StatusEnum.QUOTE_SUBMITTED; })) === null || _f === void 0 ? void 0 : _f.total) || 0),
                    cancelled: formatNumbers(((_g = data.spDashboard.results.find(function (t) { return t.datepart === 'JOB_STATUS' && +t.refid === StatusEnum.ARCHIVED; })) === null || _g === void 0 ? void 0 : _g.total) || 0),
                });
                _allIncome = orderBy(data.spDashboard.results.filter(function (c) { return c.datepart === 'BY_ALL_JOBS_INCOME'; }).map(function (j) { return (__assign(__assign({}, j), { order: (+j.refid) * 100 + (+j.title), title: getMonthName(+j.title) + " - " + j.refid })); }), 'order');
                setAllJobsIncome(_allIncome);
                _allRevenue = orderBy(data.spDashboard.results.filter(function (c) { return c.datepart === 'BY_ALL_JOBS_REVENUE'; }).map(function (j) { return (__assign(__assign({}, j), { order: (+j.refid) * 100 + (+j.title), title: getMonthName(+j.title) + " - " + j.refid })); }), 'order');
                setAllJobsRevenue(_allRevenue);
                Spinner.hide();
                return [2 /*return*/];
            });
        }); }
    })[0];
    var onDateChanged = function (ev) {
        setDate({
            start: ev[0],
            end: ev[1]
        });
    };
    useEffect(function () {
        //if(_isAppraiser) return;
        // setJobByClient(null);
        // setAppSummary({
        //     billed:0,
        //     revenue:0,
        //     unpaid:0,
        //     paid:0,
        // });
        Spinner.show();
        getPayments({
            variables: {
                startDate: date.start,
                endDate: date.end + "T23:59:59",
            }
        });
    }, [date, _isAppraiser]);
    var drivingDistanceTotal = useMemo(function () {
        return orderBy(Object.entries(groupBy(drivingDistance, 'title')).map(function (_a) {
            var _b;
            var key = _a[0], value = _a[1];
            return {
                title: key,
                total: round(sumBy(value, 'distance') || 0, 2),
                appraiser: (_b = value === null || value === void 0 ? void 0 : value[0]) === null || _b === void 0 ? void 0 : _b.appraiser,
            };
        }), 'title');
    }, [drivingDistance]);
    var loadDrivingDistance = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (_isAppraiser)
                        return [2 /*return*/];
                    return [4 /*yield*/, getDrivingDistance(-1, date.start, date.end + "T23:59:59", apolloClient)];
                case 1:
                    _data = _a.sent();
                    setDrivingDistance(_data);
                    return [2 /*return*/];
            }
        });
    }); };
    var showAccountR = useCallback(function (index, label) {
        ModalForm.show({
            title: 'Accounts Receivable',
            large: true,
            footer: false,
            body: (_jsx(Income, { modal: true, header: false, userId: -1, clientId: +acr[index].id, duePayments: true, classContent: '' }, void 0))
        });
    }, [acr]);
    var showJobsByAppraiser = useCallback(function (index, label) {
        var listData = drivingDistance === null || drivingDistance === void 0 ? void 0 : drivingDistance.filter(function (d) { return +d.appraiser === +drivingDistanceTotal[index].appraiser; });
        showModal('DRIVING DISTANCE', listData, [{ caption: 'Address', fieldName: 'address' }, { caption: 'Total driving distance(KM)', columnType: ColumnType.NUMBER, totalInFooter: true, separator: true, fieldName: 'distance' }, { caption: 'Address', fieldName: 'id', isPrimaryKey: true, isHidden: true }]);
    }, [drivingDistance]);
    var showModal = function (title, data, columns) {
        ModalForm.show({
            title: title,
            md: true,
            footer: false,
            body: (_jsx(Table, { offlineData: data, showAllData: true, exportToExcel: true, showFilter: false, headers: columns }, void 0))
        });
    };
    useEffect(function () {
        loadDrivingDistance();
    }, [date]);
    var revenuePercentage = useMemo(function () { return (sumBy(allJobsRevenue, 'total') / sumBy(allJobsIncome, 'total')) * 100; }, [allJobsRevenue, allJobsIncome]);
    var unPaidPercentage = useMemo(function () { return ((sumBy(allJobsIncome, 'total') - sumBy(allJobsRevenue, 'total')) / sumBy(allJobsIncome, 'total')) * 100; }, [allJobsRevenue, allJobsIncome]);
    return (_jsxs("div", __assign({ className: "content" }, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, { title: "Dashboard", href: "#" }, void 0) }, void 0) }, void 0),
            _jsx(Row, __assign({ className: "mt-16" }, { children: _jsxs(Col, __assign({ xs: 12, className: "align-items-end flex-gap-24 p-t-b-0" }, { children: [_jsx(CustomDatePicker, { defaultValue: CUSTOMDATEPICKER.THIS_MONTH, label: 'Date Range', onChange: onDateChanged }, void 0),
                        _jsxs(Typography, __assign({ variant: isMobile ? "caption" : "subtitle2" }, { children: [formatDate(new Date(date.start), 'MMM dd yy'), " - ", formatDate(new Date(date.end), 'MMM dd yy')] }), void 0)] }), void 0) }), void 0),
            _jsxs(CssGridContainer, { children: [_jsx(CssGridItem, __assign({ pan: 2 }, { children: _jsxs(Card, __assign({ className: "top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Cash Flow" }), void 0) }), void 0),
                                _jsx(CardBody, { children: _isAppraiser ? _jsx(AppraiserDashboard, { date: date }, void 0) :
                                        _jsx(AppChart, { label: ['Billed', 'Revenue'], datalabelPrefix: '$', chartData: [allJobsIncome, allJobsRevenue], chartType: 'column', defaultColor: ['rgb(44,175,254)', '#544fc5'] }, void 0) }, void 0)] }), void 0) }), void 0),
                    _jsx(CssGridItem, { children: _jsxs(Card, __assign({ className: "dashboard-calendar top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Calendar" }), void 0) }), void 0),
                                _jsx(CardBody, { children: _jsx(MyCalendar, { withWrapper: false }, void 0) }, void 0)] }), void 0) }, void 0),
                    _jsx(CssGridItem, { children: _jsxs(Card, __assign({ className: "top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Jobs Overview" }), void 0) }), void 0),
                                _jsxs(CardBody, { children: [_jsxs("div", __assign({ className: "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Total jobs" }), void 0),
                                                _jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: jobs.quote || 0 }), void 0)] }), void 0),
                                        _jsx(AppChart, { paddingTop: 0, chartType: 'pie', datalabel: false, legend: true, legendPosition: 'left', chartData: [
                                                [
                                                    { title: 'Pending', total: jobs.pending || 0 },
                                                    { title: 'Work in Progress', total: jobs.progress || 0 },
                                                    { title: 'Submitted', total: jobs.review || 0 },
                                                    { title: 'Retainer', total: jobs.retainer || 0 },
                                                    { title: 'Completed', total: jobs.approved || 0 },
                                                    { title: 'Cancelled', total: jobs.cancelled || 0 },
                                                ]
                                            ] }, void 0)] }, void 0)] }), void 0) }, void 0),
                    !_isAppraiser && _jsx(CssGridItem, { children: _jsxs(Card, __assign({ className: "top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Invoices" }), void 0) }), void 0),
                                _jsxs(CardBody, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Total invoices" }), void 0),
                                        _jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: formatTotalAsCurrency(sumBy(allJobsIncome, 'total'), true) }), void 0),
                                        _jsx(AppChart, { datalabel: false, datalabelPrefix: '$', chartData: [allJobsIncome], chartType: 'line' }, void 0),
                                        _jsx("div", __assign({ className: "db mt-24" }, { children: _jsxs("div", __assign({ className: "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: formatTotalAsCurrency(Math.max(sumBy(allJobsIncome, 'total') - sumBy(allJobsRevenue, 'total'), 0), true) }), void 0),
                                                    _jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: "Unpaid" }), void 0)] }), void 0) }), void 0),
                                        _jsx("div", __assign({ className: "db" }, { children: _jsx(LinearProgress, { variant: "determinate", className: "dashboard-progress", value: unPaidPercentage }, void 0) }), void 0),
                                        _jsx("div", __assign({ className: "db  mt-32" }, { children: _jsxs("div", __assign({ className: "flex gap-8" }, { children: [_jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: formatTotalAsCurrency(sumBy(allJobsRevenue, 'total'), true) }), void 0),
                                                    _jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: "Paid" }), void 0)] }), void 0) }), void 0),
                                        _jsx("div", __assign({ className: "db" }, { children: _jsx(LinearProgress, { variant: "determinate", className: "dashboard-progress dashboard-progress-green", value: revenuePercentage }, void 0) }), void 0)] }, void 0)] }), void 0) }, void 0),
                    _jsx(CssGridItem, { children: _jsxs(Card, __assign({ className: "top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Expenses" }), void 0) }), void 0),
                                _jsxs(CardBody, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Total expenses" }), void 0),
                                        _jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: formatTotalAsCurrency(sumBy(expenses, 'total'), true) }), void 0),
                                        _jsx(AppChart, { datalabelPrefix: '$', paddingTop: 0, chartType: 'pie', datalabel: false, legend: true, legendPosition: 'bottom', chartData: [expenses] }, void 0)] }, void 0)] }), void 0) }, void 0),
                    !_isAppraiser &&
                        _jsx(CssGridItem, { children: _jsxs(Card, __assign({ className: "top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "ACCOUNTS RECEIVABLE" }), void 0) }), void 0),
                                    _jsxs(CardBody, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Total A/R amount" }), void 0),
                                            _jsx(Typography, __assign({ variant: "h6", className: "black" }, { children: formatTotalAsCurrency(sumBy(acr, 'total'), true) }), void 0),
                                            _jsx(AppChart, { onClick: showAccountR, datalabelPrefix: '$', paddingTop: 0, chartType: 'pie', datalabel: false, legendPosition: 'bottom', chartData: [acr] }, void 0)] }, void 0)] }), void 0) }, void 0),
                    !_isAppraiser &&
                        _jsx(CssGridItem, __assign({ pan: 2 }, { children: _jsxs(Card, __assign({ className: "top-radius-0" }, { children: [_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Driving Distance" }), void 0) }), void 0),
                                    _jsxs(CardBody, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Total driving distance" }), void 0),
                                            _jsxs(Typography, __assign({ variant: "h6", className: "black" }, { children: [formatTotalAsCurrency(sumBy(drivingDistanceTotal, 'total'), false), " KM"] }), void 0),
                                            _jsx(AppChart, { onClick: showJobsByAppraiser, chartType: 'column', datalabelPrefix: "KM", chartData: [drivingDistanceTotal] }, void 0)] }, void 0)] }), void 0) }), void 0)] }, void 0)] }), void 0));
};
