var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getOptions } from "../../stores/client.selector";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useMemo, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { useMutation } from "@apollo/client";
import { UPDATE_OPTION, DELETE_OPTION, ADD_FIELD_SELECT, UPDATE_FIELD_SELECT, REMOVE_FIELD_SELECT } from "../../graphqls/type-tables/type-table.query";
import { removeOption } from "../../stores/client.reducer";
import { getAllFieldSelectOption } from "../../stores/app.selector";
import { InputText } from "../../ui-components/input/Input-Field.component";
import { Button, Table } from "reactstrap";
import { addFieldSelect, removeFieldSelectStore, updateFieldSelectStore } from "../../stores/app.reducer";
export var OptionManager = function (_a) {
    var _b;
    var _c = _a.optionType, optionType = _c === void 0 ? null : _c, _d = _a.fieldId, fieldId = _d === void 0 ? -1 : _d;
    var quoteOptions = useSelector(getOptions);
    var fieldSelectOption = useSelector(getAllFieldSelectOption);
    var _e = useState(''), optionTitle = _e[0], setOptionTitle = _e[1];
    var _f = useState(null), edit = _f[0], setEdit = _f[1];
    var _g = useState(false), batch = _g[0], setBatch = _g[1];
    var dispatch = useDispatch();
    var deleteFieldSelect = useMutation(REMOVE_FIELD_SELECT, {
        onCompleted: function (data) {
            var _a, _b, _c;
            if ((_b = (_a = data === null || data === void 0 ? void 0 : data.deleteTblFieldSelectById) === null || _a === void 0 ? void 0 : _a.tblFieldSelect) === null || _b === void 0 ? void 0 : _b.id) {
                dispatch(removeFieldSelectStore((_c = data === null || data === void 0 ? void 0 : data.deleteTblFieldSelectById) === null || _c === void 0 ? void 0 : _c.tblFieldSelect));
                setEdit(null);
            }
        }
    })[0];
    var addNewFieldSelect = useMutation(ADD_FIELD_SELECT, {
        onCompleted: function (data) {
            var _a, _b;
            if ((_a = data === null || data === void 0 ? void 0 : data.createTblFieldSelect) === null || _a === void 0 ? void 0 : _a.tblFieldSelect) {
                dispatch(addFieldSelect((_b = data === null || data === void 0 ? void 0 : data.createTblFieldSelect) === null || _b === void 0 ? void 0 : _b.tblFieldSelect));
                setOptionTitle('');
            }
        }
    })[0];
    var updateFieldSelect = useMutation(UPDATE_FIELD_SELECT, {
        onCompleted: function (data) {
            if (data === null || data === void 0 ? void 0 : data.updateTblFieldSelectById) {
                dispatch(updateFieldSelectStore(edit));
                setEdit(null);
                setOptionTitle('');
            }
        }
    })[0];
    var updateAnOption = useMutation(UPDATE_OPTION, {
        onCompleted: function (data) {
            if (data === null || data === void 0 ? void 0 : data.updateTblOptionById) {
                dispatch(updateOption(edit));
                setEdit(null);
            }
        }
    })[0];
    var deleteAnOption = useMutation(DELETE_OPTION, {
        onCompleted: function (data) {
            var _a, _b, _c, _d;
            if ((_b = (_a = data === null || data === void 0 ? void 0 : data.deleteTblOptionById) === null || _a === void 0 ? void 0 : _a.tblOption) === null || _b === void 0 ? void 0 : _b.id) {
                dispatch(removeOption(+((_d = (_c = data === null || data === void 0 ? void 0 : data.deleteTblOptionById) === null || _c === void 0 ? void 0 : _c.tblOption) === null || _d === void 0 ? void 0 : _d.id)));
                setEdit(null);
            }
        }
    })[0];
    var deleteItem = function (op) {
        if (fieldId > -1) {
            deleteFieldSelect({
                variables: {
                    id: +(op === null || op === void 0 ? void 0 : op.id),
                }
            });
        }
        else {
            deleteAnOption({
                variables: {
                    id: +(op === null || op === void 0 ? void 0 : op.id),
                }
            });
        }
    };
    var saveChanges = function () {
        if (fieldId > -1) {
            updateFieldSelect({
                variables: {
                    input: {
                        option: edit.title,
                    },
                    id: +(edit === null || edit === void 0 ? void 0 : edit.id),
                }
            });
        }
        else {
            updateAnOption({
                variables: {
                    input: {
                        title: edit.title,
                        id: +edit.id,
                    },
                    id: +(edit === null || edit === void 0 ? void 0 : edit.id),
                }
            });
        }
    };
    var addSelect = function () {
        optionTitle.split('\n').forEach(function (element) {
            if (element) {
                addNewFieldSelect({
                    variables: {
                        input: {
                            option: element,
                            fieldId: fieldId,
                        },
                    }
                });
            }
        });
    };
    var updateOption = function (title) {
        setOptionTitle(title);
    };
    var options = useMemo(function () {
        if (fieldId > -1) {
            return fieldSelectOption === null || fieldSelectOption === void 0 ? void 0 : fieldSelectOption.filter(function (f) { return +f.fieldId === fieldId; });
        }
        if (optionType) {
            return quoteOptions === null || quoteOptions === void 0 ? void 0 : quoteOptions.filter(function (o) { return o.optionType === optionType; });
        }
        return [];
    }, [optionType, fieldId, fieldSelectOption, quoteOptions]);
    return (_jsxs("div", __assign({ className: "mt-16" }, { children: [fieldId > -1 && (_jsxs("div", __assign({ className: "db pb-16" }, { children: [_jsx("div", __assign({ className: "dib w-60" }, { children: _jsx(InputText, { onChange: function (e) { return updateOption(e.target.value); }, size: batch ? 'small' : 'sm', label: 'Option', multiline: batch, fullWidth: batch, rows: 5, value: optionTitle, placeholder: batch ? 'option1\noption2\noption3\noption4\n...' : '' }, void 0) }), void 0),
                    _jsx("div", __assign({ className: "dib w-10 pl-4" }, { children: _jsxs("div", __assign({ className: "flex min-w-200 gap-8" }, { children: [_jsx("input", { type: "checkbox", onClick: function (e) { return setBatch(e.target.checked); } }, void 0),
                                _jsx(Typography, __assign({ variant: "caption" }, { children: "Batch Entry" }), void 0)] }), void 0) }), void 0),
                    _jsx("div", __assign({ className: "dib w-30 right" }, { children: _jsx(Button, __assign({ onClick: addSelect, className: "btn btn-xs", color: "info" }, { children: "Save Changes" }), void 0) }), void 0)] }), void 0)),
            _jsxs(Table, { children: [_jsx(TableHead, __assign({ className: "text-primary" }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Title" }, void 0),
                                _jsx(TableCell, { children: "Action" }, void 0)] }, void 0) }), void 0),
                    _jsx(TableBody, { children: (_b = options === null || options === void 0 ? void 0 : options.filter(function (op) { return op.licenceId; })) === null || _b === void 0 ? void 0 : _b.map(function (op) { return (_jsx(TableRow, { children: (edit && op.id === (edit === null || edit === void 0 ? void 0 : edit.id)) ? (_jsxs(_Fragment, { children: [_jsx(TableCell, __assign({ className: "w-80" }, { children: _jsx(InputText, { size: "sm", onChange: function (e) { return setEdit(__assign(__assign({}, edit), { title: e.target.value })); }, value: edit.title || op.option }, void 0) }), void 0),
                                    _jsxs(TableCell, __assign({ className: "flex gap-8" }, { children: [_jsx(Tooltip, __assign({ title: "Save" }, { children: _jsx(SaveIcon, { className: "pointer", color: "primary", onClick: saveChanges }, void 0) }), void 0),
                                            _jsx(Tooltip, __assign({ title: "Cancel" }, { children: _jsx(CloseIcon, { className: "pointer", onClick: function () { return setEdit(null); } }, void 0) }), void 0)] }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(TableCell, __assign({ className: "w-80" }, { children: op.title || op.option }), void 0),
                                    _jsxs(TableCell, __assign({ className: "flex table-action-column gap-8" }, { children: [_jsx(Tooltip, __assign({ title: "Edit" }, { children: _jsx(EditIcon, { className: "pointer", color: "primary", fontSize: "small", onClick: function () { return setEdit(op); } }, void 0) }), void 0),
                                            _jsx(Tooltip, __assign({ title: "Delete" }, { children: _jsx(DeleteIcon, { className: "pointer", color: "primary", onClick: function () { return deleteItem(op); }, fontSize: "small" }, void 0) }), void 0)] }), void 0)] }, void 0)) }, "item-option-" + op.id)); }) }, void 0)] }, void 0)] }), void 0));
};
