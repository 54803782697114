var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Paper, Typography } from '@material-ui/core';
import { GETJOBHISTORY } from "../../graphqls/jobs/job.query";
import { useLazyQuery } from '@apollo/client';
import { map } from 'lodash';
import { formatDate } from "@utils/date";
import { formatInsertUpdate } from '@utils/text';
import HistoryIcon from '@material-ui/icons/History';
import CheckIcon from '@material-ui/icons/Check';
import { StatusEnum } from "@enums";
import MailIcon from '@material-ui/icons/Mail';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Card, CardBody, CardHeader } from 'reactstrap';
export var TimelineComponent = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.reload, reload = _c === void 0 ? '' : _c;
    var _d = useState([]), timelines = _d[0], setTimelines = _d[1];
    var getHistory = useLazyQuery(GETJOBHISTORY, {
        onCompleted: function (data) {
            setTimelines(data.allTblJobHistories.nodes);
        }
    })[0];
    var setTimelineIcon = function (status) {
        switch (status) {
            case StatusEnum.QUOTE_APPROVED:
                return _jsx(CheckIcon, { style: { fontSize: 14 } }, void 0);
            case StatusEnum.QUOTE_SUBMITTED:
                return _jsx(MailIcon, { style: { fontSize: 14 } }, void 0);
            case StatusEnum.INVOICE_SENT:
                return _jsx(SendIcon, { style: { fontSize: 14 } }, void 0);
            case StatusEnum.QUOTE_PENDING:
                return _jsx(AddIcon, { style: { fontSize: 14 } }, void 0);
            case StatusEnum.PAYMENT_RECEIVED:
                return _jsx(AttachMoneyIcon, { style: { fontSize: 14 } }, void 0);
            case StatusEnum.QUOTE_REJECTED:
                return _jsx(ThumbDownIcon, { style: { fontSize: 14 } }, void 0);
            default:
                return _jsx(HistoryIcon, { style: { fontSize: 14 } }, void 0);
        }
    };
    useEffect(function () {
        if (id) {
            getHistory({
                variables: {
                    id: +id,
                }
            });
        }
    }, [id, getHistory]);
    useEffect(function () {
        if (reload) {
            getHistory({
                variables: {
                    id: +id,
                }
            });
        }
    }, [reload]);
    return (_jsxs(Card, { children: [_jsx(CardHeader, __assign({ className: 'p-2' }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: "Activity Feed" }), void 0) }), void 0),
            _jsx(CardBody, { children: _jsx(Timeline, __assign({ align: "alternate" }, { children: map(timelines, function (item, index) { return (_jsxs(TimelineItem, { children: [_jsx(TimelineOppositeContent, { children: _jsx(Typography, __assign({ variant: "caption", color: "textSecondary" }, { children: formatDate(item.zzCreatedDate, "MMM dd,yyyy") }), void 0) }, void 0),
                            _jsxs(TimelineSeparator, { children: [_jsx(TimelineDot, __assign({ color: "primary" }, { children: setTimelineIcon(item.statusId) }), void 0),
                                    _jsx(TimelineConnector, {}, void 0)] }, void 0),
                            _jsx(TimelineContent, { children: _jsxs(Paper, __assign({ elevation: 3, className: "pa-8" }, { children: [_jsxs(Typography, __assign({ variant: "caption" }, { children: [item.statusId === -1 ? item.editAction : formatInsertUpdate(item.editAction), " by ", _jsx("strong", { children: item.zzCreatedBy }, void 0)] }), void 0),
                                        _jsxs(Typography, __assign({ variant: "caption" }, { children: [" ", item.tblStatusByStatusId.statusTitle] }), void 0)] }), void 0) }, void 0)] }, "timeline_row_" + index)); }) }), void 0) }, void 0)] }, void 0));
};
