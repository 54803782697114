var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { calculateFormula } from "../../utils/Formula.utility";
import { formatTotalAsCurrency } from "../../helpers/numbers-helper.helper";
import { sumBy } from "lodash";
export var TableTotalFooter = function (_a) {
    var _b = _a.headers, headers = _b === void 0 ? [] : _b, _c = _a.selection, selection = _c === void 0 ? false : _c, _d = _a.dataRow, dataRow = _d === void 0 ? [] : _d, _e = _a.totalInFooter, totalInFooter = _e === void 0 ? true : _e;
    return (totalInFooter ? (_jsx("tfoot", { children: _jsxs("tr", __assign({ className: "total-footer" }, { children: [selection && (_jsx("td", {}, void 0)),
                headers.map(function (d) { return (_jsx("td", __assign({ className: "total-footer-td" }, { children: d.totalInFooter ? formatTotalAsCurrency(sumBy(dataRow, function (r) { return d.formula ? calculateFormula(d, r) : +r[d.fieldName]; })) : '' }), "totla_footer_" + d.fieldName)); })] }), void 0) }, void 0)) : (_jsxs("tr", __assign({ className: "total-footer" }, { children: [selection && (_jsx("td", {}, void 0)),
            headers.map(function (d) { return (_jsx("td", __assign({ className: "total-footer-td" }, { children: d.totalInFooter ? formatTotalAsCurrency(sumBy(dataRow, function (r) { return d.formula ? calculateFormula(d, r) : +r[d.fieldName]; })) : '' }), "totla_footer_" + d.fieldName)); })] }), void 0)));
};
