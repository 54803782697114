var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { createAction, createReducer } from '@reduxjs/toolkit';
export var setTableFilter = createAction('TABLE-SET-TABLE-FILTER');
export var setUpdatedData = createAction('TABLE-SET-UPDATED-DATA');
export var clearTableData = createAction('TABLE-CLEAR-TABLE-DATA');
export var updateTableDataRow = createAction('updateTableDataRow');
export var tableDataRefreshRequired = createAction('tableDataRefreshRequired');
export var tableDataRefreshDone = createAction('tableDataRefreshDone');
export var tableReducer = createReducer(null, function (builder) {
    builder
        .addCase(setTableFilter, function (state, action) {
        return __assign(__assign({}, state), { tableFIlter: action.payload });
    }).addCase(tableDataRefreshRequired, function (state, action) {
        return __assign(__assign({}, state), { tableDataRefreshRequired: 'YES' });
    }).addCase(tableDataRefreshDone, function (state, action) {
        return __assign(__assign({}, state), { tableDataRefreshRequired: '' });
    }).addCase(updateTableDataRow, function (state, action) {
        var stateData = __spreadArray([], ((state === null || state === void 0 ? void 0 : state.tableData) || []));
        var data = __spreadArray([], (action.payload || []));
        stateData = stateData.reduce(function (res, curr) {
            var updated = data.find(function (d) { return +curr.jobid === +d.jobid && +curr.appid === +d.appid; });
            if (updated) {
                res.push(__assign(__assign({}, curr), { hst: updated.hst, saleprice: updated.saleprice, appfee: updated.appfee, feetype: updated.feetype, payments: updated.payments, totalJobExpenses: updated.totalJobExpenses, expportion: updated.expportion, appportion: updated.appportion }));
            }
            else {
                res.push(curr);
            }
            return res;
        }, []);
        return __assign(__assign({}, state), { tableData: stateData });
    }).addCase(setUpdatedData, function (state, action) {
        var result = __spreadArray([], ((state === null || state === void 0 ? void 0 : state.tableData) || []));
        var data = __spreadArray([], (action.payload || []));
        data.forEach(function (item) {
            var i = result.findIndex(function (c) { return +(c.id || c.jobid) === +(item.id || item.jobid) && +c.appid === +item.appid; });
            if (i === -1) {
                result.push(item);
            }
            else {
                result.splice(i, 1, item);
            }
        });
        return __assign(__assign({}, state), { tableData: result });
    }).addCase(clearTableData, function (state, action) {
        return __assign(__assign({}, state), { tableData: [] });
    });
});
