var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
var Spinner = /** @class */ (function (_super) {
    __extends(Spinner, _super);
    function Spinner(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClose = function () {
            _this.setState({ open: false });
        };
        _this.state = {
            open: false,
        };
        Spinner.Instance = _this;
        return _this;
    }
    Spinner.hide = function () {
        Spinner.Instance.setState({
            open: false,
        });
    };
    Spinner.show = function () {
        if (Spinner.Instance) {
            Spinner.Instance.setState({
                open: true,
            });
        }
        else {
            console.warn('No modal found');
        }
    };
    Spinner.prototype.render = function () {
        var open = this.state.open;
        return _jsx(Backdrop, __assign({ style: { zIndex: 99999, color: '#fff' }, open: open }, { children: _jsx(CircularProgress, { color: "inherit" }, void 0) }), void 0);
    };
    return Spinner;
}(React.Component));
export { Spinner };
