var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { List, ListItem, ListItemText, Typography, Divider, MenuItem, Avatar, Tooltip } from "@material-ui/core";
import { map } from "lodash";
import { Card, CardBody, Row, Col, Button, CardHeader } from "reactstrap";
import { GET_DOCUMENTS, GET_DOCUMENTS_BY_TYPE } from "../../graphqls/jobs/job.query";
import { formatDate } from "@utils/date";
import { FileExtension } from "../../ui-components/extens/File-Extension.component";
import { DropzoneDialog } from 'material-ui-dropzone';
import { InputText } from "../../ui-components/input/Input-Field.component";
import { httpPost, httpPostUpload } from "../../helpers/http.helper";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { GetUserId, canEditRequest, getJwtToken, getUserName } from "../../services/user-service";
import { DOCUMENT_TYPE, INLINE_EDITOR, QuoteOptionType, SelectorName } from "@enums";
import { useSelector } from "react-redux";
import { getOptions } from "../../stores/client.selector";
import EditIcon from '@material-ui/icons/Edit';
import { ModalForm } from "../../ui-components/modals/Modal-Form.component";
import { FormDynamic } from "../../ui-components/forms/Form-Dynamic";
import { DeleteButton } from "../../ui-components/button/Delete-Button";
import { PhotoGallery } from "../appraisals/view/photoGallery";
import { getAppModules } from "../../stores/app.selector";
export var Note = function (_a) {
    var _b = _a.jobId, jobId = _b === void 0 ? 0 : _b, _c = _a.modal, modal = _c === void 0 ? false : _c, _d = _a.onNoteAdded, onNoteAdded = _d === void 0 ? null : _d, _e = _a.documentType, documentType = _e === void 0 ? DOCUMENT_TYPE.JOBS : _e, _f = _a.header, header = _f === void 0 ? 'Attachments & Notes' : _f, _g = _a.btn, btn = _g === void 0 ? 'Add Attachment' : _g;
    var _h = useState([]), notes = _h[0], setNotes = _h[1];
    var _j = useState([]), attachment = _j[0], setAttachment = _j[1];
    var _k = useState(false), open = _k[0], setOpen = _k[1];
    var _l = useState(''), extraParams = _l[0], setExtraParams = _l[1];
    var editable = canEditRequest();
    var userId = +GetUserId();
    var appModule = useSelector(getAppModules);
    var options = useSelector(getOptions);
    var decription = useRef();
    var deleteComment = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var removed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpPost("document/removeComment", {
                            id: +id,
                        })];
                case 1:
                    removed = _a.sent();
                    if (removed) {
                        setNotes(notes.filter(function (n) { return +n.id !== +id; }));
                    }
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var upsertComment = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpPost("document/updateComment", {
                            id: +data.id,
                            description: data.description,
                            type: documentType === DOCUMENT_TYPE.JOB_REPORT_TYPE ? data.fileName : 'SKIP_TYPE',
                        })];
                case 1:
                    _a.sent();
                    setNotes(notes.reduce(function (result, curr) {
                        if (+curr.id === +data.id) {
                            result.push(__assign(__assign({}, curr), { description: data.description, zzCreatedDate: new Date(), fileName: documentType === DOCUMENT_TYPE.JOB_REPORT_TYPE ? data.fileName : curr.fileName }));
                        }
                        else {
                            result.push(curr);
                        }
                        return result;
                    }, []));
                    ModalForm.close();
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var editComment = function (doc) { return function () {
        var formFields = [
            { fieldName: 'description', title: 'Comment', defaultValue: doc.description, required: true, type: 'textArea' },
            { fieldName: 'id', title: 'ID', defaultValue: doc.id, required: true, type: 'number', hidden: true }
        ];
        if (documentType === DOCUMENT_TYPE.JOB_REPORT_TYPE) {
            formFields.push({
                fieldName: 'fileName', title: 'Report Type', defaultValue: doc.fileName, required: true, type: 'select',
                options: options === null || options === void 0 ? void 0 : options.filter(function (c) { return +c.optionType === SelectorName.REPORT_TYPE; }).map(function (o) { return ({ id: o.title, title: o.title }); })
            });
        }
        ModalForm.show({
            title: 'Edit',
            footer: false,
            md: true,
            body: (_jsx(FormDynamic, { formFields: formFields, onSubmit: upsertComment }, void 0))
        });
    }; };
    var setExtra = function (e) {
        setExtraParams(e.target.value);
    };
    var deleteAttachments = function () {
        setAttachment([]);
    };
    var handleUpload = function (files) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAttachment(files);
                    if (!uploadOnly) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveComment(files)()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var getDocuemtns = useLazyQuery(documentType === DOCUMENT_TYPE.JOBS ? GET_DOCUMENTS : GET_DOCUMENTS_BY_TYPE, {
        onCompleted: function (data) {
            setNotes(data.allTblDocuments.nodes);
        }
    })[0];
    var saveComment = function (files) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, nodes;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    Spinner.show();
                    return [4 /*yield*/, httpPostUpload("document/upload/" + jobId, (files === null || files === void 0 ? void 0 : files.length) ? files : attachment, { type: extraParams, description: ((_a = decription.current) === null || _a === void 0 ? void 0 : _a.value) || "", documentType: documentType })];
                case 1:
                    result = _c.sent();
                    Spinner.hide();
                    nodes = result.map(function (r) { return ({
                        fileName: r.originalname,
                        fileSize: r.size,
                        fileKey: r.fileKey,
                    }); });
                    setNotes([{
                            description: ((_b = decription.current) === null || _b === void 0 ? void 0 : _b.value) || "",
                            zzCreatedDate: new Date(),
                            zzCreatedBy: getUserName(),
                            fileName: extraParams,
                            tblFilesByDocumentId: {
                                nodes: nodes,
                            },
                        }].concat(notes));
                    setAttachment([]);
                    decription.current.value = '';
                    onNoteAdded && onNoteAdded();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var showFile = function (doc) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            window.open(process.env.REACT_APP_EXTERNAL_API_URL + "document/document/" + doc.fileKey + "/" + getJwtToken());
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (!jobId)
            return;
        getDocuemtns({
            variables: {
                jobId: jobId,
                refId: "" + jobId,
                type: documentType,
            }
        });
    }, [getDocuemtns, jobId]);
    var uploadOnly = useMemo(function () { return documentType === DOCUMENT_TYPE.JOB_PHOTOS; }, [documentType]);
    var images = useMemo(function () {
        var img = [];
        (notes || []).forEach(function (n) {
            var _a;
            (((_a = n.tblFilesByDocumentId) === null || _a === void 0 ? void 0 : _a.nodes) || []).forEach(function (i) {
                img.push(i);
            });
        });
        return img;
    }, [notes]);
    return (_jsxs(Card, { children: [header && (_jsx(CardHeader, __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "db" }, { children: [_jsxs("div", __assign({ className: "dib w-50" }, { children: [" ", _jsx(Typography, __assign({ className: "card-header-h5" }, { children: header }), void 0)] }), void 0),
                        uploadOnly && editable && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsx("div", __assign({ className: "dib right w-50" }, { children: _jsxs(Button, __assign({ onClick: function () { return setOpen(true); }, color: "default", className: "btn-xss" }, { children: [_jsx("i", { className: "fa fa-upload" }, void 0), " Select photos"] }), void 0) }), void 0))] }), void 0) }), void 0)),
            _jsxs(CardBody, { children: [uploadOnly && (_jsx(PhotoGallery, { images: images }, void 0)),
                    !modal && (_jsxs(_Fragment, { children: [!uploadOnly && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsx(Row, { children: _jsx(Col, __assign({ xs: 12 }, { children: _jsxs("div", __assign({ className: "flex gap-16" }, { children: [_jsx("div", __assign({ className: 'flex-1' }, { children: _jsx(InputText, { inputRef: decription, fullWidth: true, size: "small", label: "Comment:" }, void 0) }), void 0),
                                            documentType === DOCUMENT_TYPE.JOB_REPORT_TYPE && (_jsxs(InputText, __assign({ select: true, label: "Report Type:", className: "max-w-200 min-w-150", value: extraParams, onChange: setExtra, selectorName: QuoteOptionType.NOTE_REPORT_TYPE, useId: false }, { children: [_jsx(MenuItem, __assign({ value: "-10" }, { children: INLINE_EDITOR.ADD_OR_EDIT }), void 0),
                                                    options === null || options === void 0 ? void 0 : options.filter(function (c) { return +c.optionType === SelectorName.REPORT_TYPE; }).map(function (o) { return (_jsx(MenuItem, __assign({ value: o.title }, { children: o.title }), o.title)); })] }), void 0))] }), void 0) }), void 0) }, void 0)),
                            _jsx(DropzoneDialog, { filesLimit: 5, onClose: function () { return setOpen(false); }, open: open, showFileNamesInPreview: true, showFileNames: true, submitButtonText: "Select", maxFileSize: +(process.env.REACT_APP_UPLOAD_SIZE || '20'), onSave: function (files) {
                                    handleUpload(files);
                                    setOpen(false);
                                }, showAlerts: ['error'] }, void 0),
                            _jsxs(Row, __assign({ className: "mt-8 mb-8" }, { children: [!uploadOnly && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsxs(Col, __assign({ xs: 6, className: "flex gap-16" }, { children: [_jsxs(Button, __assign({ onClick: function () { return setOpen(true); }, color: "secondary", className: "btn btn-xxs" }, { children: [_jsx("i", { className: "fa fa-paperclip" }, void 0), " ", btn] }), void 0),
                                            ((attachment === null || attachment === void 0 ? void 0 : attachment.length) > 0) && (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ variant: "caption" }, { children: [attachment.length, " file(s)"] }), void 0),
                                                    !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && _jsx(DeleteButton, { onDelete: deleteAttachments, size: 14 }, void 0)] }, void 0))] }), void 0)),
                                    !uploadOnly && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsx(Col, __assign({ align: "right" }, { children: _jsxs(Button, __assign({ onClick: saveComment([]), className: "right-align btn btn-xxs", color: "info" }, { children: [_jsx("i", { className: "fa fa-save" }, void 0), " ", uploadOnly ? 'Upload' : 'Submit'] }), void 0) }), void 0))] }), void 0),
                            !uploadOnly && (_jsx(Divider, {}, void 0))] }, void 0)),
                    !uploadOnly && (_jsx(List, { children: map(notes, function (doc, index) {
                            var _a, _b, _c;
                            return (_jsxs(React.Fragment, { children: [_jsx(ListItem, { children: _jsx(ListItemText, { secondary: _jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsxs(Col, __assign({ className: "flex gap-8" }, { children: [_jsx(Avatar, __assign({ className: "wh-30" }, { children: (_b = (_a = doc.zzCreatedBy) === null || _a === void 0 ? void 0 : _a.charAt(0)) === null || _b === void 0 ? void 0 : _b.toUpperCase() }), void 0),
                                                                    _jsx(Typography, __assign({ variant: "subtitle2", className: "black" }, { children: doc.zzCreatedBy }), void 0)] }), void 0),
                                                            _jsx(Col, __assign({ className: "flex flex-1 flex-end" }, { children: _jsx(Typography, __assign({ variant: "caption", className: "db" }, { children: formatDate(doc.zzCreatedDate, "MMM dd,yyyy HH:mm:ss") }), void 0) }), void 0)] }, void 0),
                                                    _jsxs("div", __assign({ className: "flex mt-8" }, { children: [_jsxs(Typography, __assign({ variant: "caption", className: "black" }, { children: [documentType === DOCUMENT_TYPE.JOB_REPORT_TYPE ? "[Type : " + doc.fileName + "] " : '', " ", doc.description] }), void 0),
                                                            +doc.userId === userId && !(appModule === null || appModule === void 0 ? void 0 : appModule.read_only) && (_jsxs("div", __assign({ className: "flex-end flex-1" }, { children: [_jsx(DeleteButton, { id: doc.id, onDelete: deleteComment, size: 14 }, void 0),
                                                                    _jsx(Tooltip, __assign({ title: "Edit" }, { children: _jsx(EditIcon, { onClick: editComment(doc), className: "pointer ml-8", style: { fontSize: '14px' } }, void 0) }), void 0)] }), void 0))] }), void 0),
                                                    _jsx("div", __assign({ className: "db" }, { children: map(((_c = doc.tblFilesByDocumentId) === null || _c === void 0 ? void 0 : _c.nodes) || [], function (file, index) { return (_jsx(FileExtension, { className: "dib ml-8", fileName: file.fileName, onClick: function () { return showFile(file); } }, "file_" + index)); }) }), void 0)] }, void 0) }, void 0) }, void 0),
                                    _jsx(Divider, {}, void 0)] }, index));
                        }) }, void 0))] }, void 0)] }, void 0));
};
