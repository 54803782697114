var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Row } from "reactstrap";
import { TBLAPPRAISOR, Columns } from "../../graphqls/appraiser/appraisor.query";
import { Table } from "../../ui-components/table/table.component";
import { ListHeader } from "../../ui-components/lists/Header.component";
import { ModalForm } from "../../ui-components/modals/Modal-Form.component";
import { tableRowClicked } from "../../helpers/form.helper";
import { useDispatch } from "react-redux";
import { setOpenDrawer } from "../../stores/app.reducer";
import { DRAWERCOMPONENT } from '@enums';
export var Appraiser = function (_a) {
    var _b = _a.header, header = _b === void 0 ? true : _b;
    var dispatch = useDispatch();
    var selectClick = function (evt) {
        var _a, _b, _c;
        var appId = (_c = (_b = (_a = evt.target) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.attributes['data-id']) === null || _c === void 0 ? void 0 : _c.nodeValue;
        ModalForm.hide({ id: appId });
    };
    var rowClicked = function (e, id) {
        if (tableRowClicked(e)) {
            dispatch(setOpenDrawer({ status: 'OPEN', id: id, component: DRAWERCOMPONENT.APPRAISER }));
        }
    };
    return (_jsxs("div", __assign({ className: "content" }, { children: [header && (_jsx(Row, { children: _jsx(Col, { children: _jsx(ListHeader, { title: "Appraisers" }, void 0) }, void 0) }, void 0)),
            _jsx(Table, { addLink: header ? "/admin/appraisers/edit" : '', addLinkTitle: "New Appraiser", rowClicked: rowClicked, editRoute: !header ? "" : "/admin/appraisers/edit/", tableName: "allTblAppraisers", query: TBLAPPRAISOR, headers: Columns, action: !header ? (_jsx(Button, __assign({ color: "info", className: "btn btn-xs", onClick: selectClick }, { children: "Select" }), void 0)) : null }, void 0)] }), void 0));
};
