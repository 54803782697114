export var PROPERTY_SECTION;
(function (PROPERTY_SECTION) {
    PROPERTY_SECTION[PROPERTY_SECTION["SITE"] = 1] = "SITE";
    PROPERTY_SECTION[PROPERTY_SECTION["GEOWARE"] = 2] = "GEOWARE";
    PROPERTY_SECTION[PROPERTY_SECTION["BUILDING"] = 3] = "BUILDING";
    PROPERTY_SECTION[PROPERTY_SECTION["FINANCIAL"] = 4] = "FINANCIAL";
    PROPERTY_SECTION[PROPERTY_SECTION["JOB_DETAILS"] = 6] = "JOB_DETAILS";
})(PROPERTY_SECTION || (PROPERTY_SECTION = {}));
export var SYSTEM_TABLES;
(function (SYSTEM_TABLES) {
    SYSTEM_TABLES["JOB"] = "JOB";
    SYSTEM_TABLES["SALES"] = "SALES";
    SYSTEM_TABLES["JOB_DETAILS"] = "JOB_DETAILS";
})(SYSTEM_TABLES || (SYSTEM_TABLES = {}));
export var COLUMN_TYPE_NUMBER;
(function (COLUMN_TYPE_NUMBER) {
    COLUMN_TYPE_NUMBER[COLUMN_TYPE_NUMBER["NUMBER"] = 1] = "NUMBER";
    COLUMN_TYPE_NUMBER[COLUMN_TYPE_NUMBER["TEXT"] = 2] = "TEXT";
    COLUMN_TYPE_NUMBER[COLUMN_TYPE_NUMBER["SELECT"] = 3] = "SELECT";
})(COLUMN_TYPE_NUMBER || (COLUMN_TYPE_NUMBER = {}));
