var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Typography from "@material-ui/core/Typography";
export var ReactDatePicker = function (_a) {
    var props = __rest(_a, []);
    return (_jsx("div", __assign({ className: props.className }, { children: props.block ? (_jsxs("div", { children: [_jsx("div", __assign({ className: 'db' }, { children: _jsx("label", __assign({ className: "input-text-label" }, { children: props.label }), void 0) }), void 0),
                _jsx("div", __assign({ className: 'db' }, { children: _jsx(DatePicker, __assign({ showIcon: true, dateFormat: "MM/dd/yyyy" }, props), void 0) }), void 0)] }, void 0)) : (_jsxs("div", __assign({ className: props.label ? 'flex' : '' }, { children: [props.label && (_jsx(Typography, __assign({ variant: "caption", className: "min-w-" + (props.width || '150 bold') }, { children: props.label }), void 0)),
                _jsx(DatePicker, __assign({ showIcon: true, dateFormat: "MM/dd/yyyy" }, props, { className: props.inputWidth || props.className }), void 0)] }), void 0)) }), void 0));
};
