var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { InvoiceStatus } from '@enums';
export var INSERT_BATCH_PAYMENT = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["mutation createTblBatchPayment($input: TblBatchPaymentInput!) {\n  createTblBatchPayment(input: { tblBatchPayment: $input }) {\n    clientMutationId\n  }\n}\n"], ["mutation createTblBatchPayment($input: TblBatchPaymentInput!) {\n  createTblBatchPayment(input: { tblBatchPayment: $input }) {\n    clientMutationId\n  }\n}\n"])));
export var GET_INVOICES_BY_JOBS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery getJobInvoices($id: [BigInt!]) {\n  allTblJobs(filter: {id: {in: $id}}) {\n    nodes {\n      tblInvoicesByJobId(filter: {status: {notEqualTo: ", "}}) {\n        nodes {\n          rate\n        }\n      }\n      tblPaymentsByJobId {\n        nodes {\n          amount\n        }\n      }\n      id\n      jobNumber\n      city\n      county\n      fileNumber\n      postalCode\n      province\n      streetNo\n      streetName\n      unit\n      zzFullAddress\n    }\n  }\n}"], ["\nquery getJobInvoices($id: [BigInt!]) {\n  allTblJobs(filter: {id: {in: $id}}) {\n    nodes {\n      tblInvoicesByJobId(filter: {status: {notEqualTo: ", "}}) {\n        nodes {\n          rate\n        }\n      }\n      tblPaymentsByJobId {\n        nodes {\n          amount\n        }\n      }\n      id\n      jobNumber\n      city\n      county\n      fileNumber\n      postalCode\n      province\n      streetNo\n      streetName\n      unit\n      zzFullAddress\n    }\n  }\n}"])), InvoiceStatus.VOID);
export var GETBYJOBID = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["query getByJobId($id: BigInt!) {\n    allTblInvoices(condition: {jobId: $id}) {\n      nodes {\n        billClient\n        comission\n        description\n        id\n        invoiceDate\n        rate\n        zzCreatedBy\n        zzCreatedDate\n        comissionType\n        isSent\n        sentDate\n        dueDate\n        status\n        hst\n        autoGenerated\n        tblJobByJobId {\n          tblClientByClientId {\n            email\n          }\n          tblExpensesByJobId {\n            nodes {\n              value\n              tblOptionByOptionId {\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }  \n"], ["query getByJobId($id: BigInt!) {\n    allTblInvoices(condition: {jobId: $id}) {\n      nodes {\n        billClient\n        comission\n        description\n        id\n        invoiceDate\n        rate\n        zzCreatedBy\n        zzCreatedDate\n        comissionType\n        isSent\n        sentDate\n        dueDate\n        status\n        hst\n        autoGenerated\n        tblJobByJobId {\n          tblClientByClientId {\n            email\n          }\n          tblExpensesByJobId {\n            nodes {\n              value\n              tblOptionByOptionId {\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }  \n"])));
export var DELETE_INVOICE = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation voidInvoice($id: BigInt!, $status: Int!) {\n  updateTblInvoiceById(\n    input: { tblInvoicePatch: { status: $status }, id: $id }\n  ) {\n    tblInvoice {\n      id\n      qbId\n    }\n  }\n}"], ["\nmutation voidInvoice($id: BigInt!, $status: Int!) {\n  updateTblInvoiceById(\n    input: { tblInvoicePatch: { status: $status }, id: $id }\n  ) {\n    tblInvoice {\n      id\n      qbId\n    }\n  }\n}"])));
export var ADDNEWINVOICE = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nmutation addinvoice($input: TblInvoiceInput!) {\n  createTblInvoice(input: {tblInvoice: $input}) {\n    tblInvoice {\n      id\n      billClient\n      comission\n      comissionType\n      description\n      dueDate\n      invoiceDate\n      isSent\n      rate\n      zzCreatedBy\n      sentDate\n      status\n      hst\n      tblJobByJobId {\n        tblClientByClientId {\n          qbId\n        }\n      }\n    }\n  }\n}"], ["\nmutation addinvoice($input: TblInvoiceInput!) {\n  createTblInvoice(input: {tblInvoice: $input}) {\n    tblInvoice {\n      id\n      billClient\n      comission\n      comissionType\n      description\n      dueDate\n      invoiceDate\n      isSent\n      rate\n      zzCreatedBy\n      sentDate\n      status\n      hst\n      tblJobByJobId {\n        tblClientByClientId {\n          qbId\n        }\n      }\n    }\n  }\n}"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
