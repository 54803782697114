var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { TextField, Typography, MenuItem } from "@material-ui/core";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import { formatTotalAsCurrency } from "../../helpers/numbers-helper.helper";
import { uuid } from "@utils/text";
import { useApolloQuery } from "../../hooks/use-appolo.hooks";
import { addNewOptions } from "../../services/options.service";
import { useDispatch } from "react-redux";
import { INLINE_EDITOR } from "@enums";
export var InputText = function (_a) {
    var _b = _a.flex, flex = _b === void 0 ? false : _b, _c = _a.editMode, editMode = _c === void 0 ? true : _c, _d = _a.flexWidth, flexWidth = _d === void 0 ? '100' : _d, _e = _a.mt, mt = _e === void 0 ? '' : _e, _f = _a.separator, separator = _f === void 0 ? '' : _f, _g = _a.selectorName, selectorName = _g === void 0 ? 0 : _g, _h = _a.useId, useId = _h === void 0 ? true : _h, _j = _a.displayText, displayText = _j === void 0 ? 'option' : _j, _k = _a.displayValue, displayValue = _k === void 0 ? "id" : _k, _l = _a.menuItems, menuItems = _l === void 0 ? null : _l, _m = _a.removeItem, removeItem = _m === void 0 ? false : _m, _o = _a.extendMenu, extendMenu = _o === void 0 ? true : _o, props = __rest(_a, ["flex", "editMode", "flexWidth", "mt", "separator", "selectorName", "useId", "displayText", "displayValue", "menuItems", "removeItem", "extendMenu"]);
    var inputId = uuid();
    var _p = useState(false), addOption = _p[0], setAddOption = _p[1];
    var _q = useState(''), selectTitle = _q[0], setSelectTitle = _q[1];
    var apolloClient = useApolloQuery()[0];
    var dispatch = useDispatch();
    var saveOption = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addNewOptions(e.target.value, selectorName)(apolloClient, dispatch)];
                case 1:
                    data = _a.sent();
                    setAddOption(false);
                    if ((data === null || data === void 0 ? void 0 : data.id) && props.onChange) {
                        props.onChange({
                            target: {
                                value: useId ? +data.id : data.title,
                            }
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var selectChanged = function (e) {
        var _a, _b;
        if (selectorName !== 0 && e.target.value === "-10") {
            setAddOption(true);
        }
        else {
            setSelectTitle(((_b = (_a = e === null || e === void 0 ? void 0 : e.nativeEvent) === null || _a === void 0 ? void 0 : _a.srcElement) === null || _b === void 0 ? void 0 : _b.innerText) || '');
            props.onChange && props.onChange(e);
        }
    };
    var onBlur = function (e) {
        props.onBlur && props.onBlur(__assign({}, e));
    };
    var formatDefaultValue = function () {
        var _a, _b;
        if ((props === null || props === void 0 ? void 0 : props.type) === "number") {
            if (props.defaultValue) {
                return (_a = formatTotalAsCurrency((("" + props.defaultValue).replace(/,/g, '')), false)) === null || _a === void 0 ? void 0 : _a.replace('$', '');
            }
            else if (props.value) {
                return (_b = formatTotalAsCurrency((("" + props.value).replace(/,/g, '')), false)) === null || _b === void 0 ? void 0 : _b.replace('$', '');
            }
            else {
                return '';
            }
        }
    };
    var handleNumbers = function (e) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.type) === 'tel') {
            e.target.value = !e.target.value ? '' : (_d = formatTotalAsCurrency((_c = (_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.replace(/,/g, ''), false)) === null || _d === void 0 ? void 0 : _d.replace('$', '');
            if (!props.onChange)
                return;
            if (props.id) {
                props.onChange(__assign(__assign({}, e), { target: __assign(__assign({}, e.target), { value: !e.target.value ? null : +((_f = (_e = e === null || e === void 0 ? void 0 : e.target) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.replace(/,/g, '')), id: props.id, name: props.id }) }));
            }
            else {
                props.onChange(__assign(__assign({}, e), { target: __assign(__assign({}, e.target), { value: !e.target.value ? null : +((_h = (_g = e === null || e === void 0 ? void 0 : e.target) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.replace(/,/g, '')) }) }));
            }
        }
    };
    useEffect(function () {
        var _a, _b;
        if (document.getElementById(inputId) && props.size === "sm" && (props === null || props === void 0 ? void 0 : props.type) === "number" && editMode && props.value !== undefined) {
            document.getElementById(inputId).value = (_a = formatTotalAsCurrency((("" + (props.value || 0)).replace(/,/g, '')), false)) === null || _a === void 0 ? void 0 : _a.replace('$', '');
        }
        if (props.value && (menuItems === null || menuItems === void 0 ? void 0 : menuItems.length)) {
            setSelectTitle(((_b = menuItems.find(function (c) { return c[displayValue] === props.value; })) === null || _b === void 0 ? void 0 : _b[displayText]) || '');
        }
    }, [props.value]);
    return (_jsxs("div", __assign({ className: "db " + mt, title: selectTitle }, { children: [(props.label && !flex) && (_jsx("label", __assign({ className: "input-text-label" }, { children: props.label }), void 0)),
            props.size === "sm" ? (_jsxs("div", __assign({ className: flex && props.text ? 'flex' : '' }, { children: [(flex && props.text) && (_jsx(Typography, __assign({ variant: "caption", className: "min-w-" + flexWidth + " input-text-label" }, { children: props.text }), void 0)),
                    props.InputProps ? (_jsxs(InputGroup, __assign({ style: { marginBottom: 0 } }, { children: [_jsx(InputGroupAddon, __assign({ addonType: "prepend", style: props.helperText ? { borderBottom: 'solid 2px #c92f54' } : {} }, { children: props.prepend }), void 0),
                            _jsx(Input, __assign({}, props, { bgSize: "sm", autoComplete: "off", style: (props === null || props === void 0 ? void 0 : props.type) === 'number' ? { visibility: 'hidden', position: 'absolute' } : {} }), void 0),
                            (props === null || props === void 0 ? void 0 : props.type) === 'number' && (_jsx(Input, { defaultValue: formatDefaultValue(), onInput: handleNumbers, type: (props === null || props === void 0 ? void 0 : props.type) === 'number' ? 'tel' : props === null || props === void 0 ? void 0 : props.type, className: props.className, bgSize: "sm", id: inputId, autoComplete: "off", onBlur: onBlur, readOnly: props.readOnly || false, step: (props === null || props === void 0 ? void 0 : props.type) === 'number' ? '.0' : "", style: props.helperText ? { borderBottom: 'solid 2px #c92f54' } : {} }, void 0)),
                            props.helperText && (_jsx("div", __assign({ className: "form-error flex h-100" }, { children: "required*" }), void 0))] }), void 0)) : (_jsxs("div", __assign({ className: 'relative flex' }, { children: [flex && (_jsx(Typography, __assign({ variant: "caption", className: "min-w-" + flexWidth + " input-text-label" }, { children: props.label }), void 0)),
                            !editMode ? (_jsx(Typography, __assign({ variant: "caption" }, { children: props.value }), void 0)) : (_jsxs(_Fragment, { children: [_jsx(Input, __assign({}, props, { autoComplete: "off", bgSize: "sm", InputProps: __assign(__assign({}, props.InputProps), { classes: {
                                                focused: "notchedOutline",
                                                notchedOutline: "notchedOutline"
                                            } }), style: props.helperText ? { borderBottom: 'solid 2px #c92f54' } : (separator ? { visibility: 'hidden', position: 'absolute' } : {}) }), void 0),
                                    separator && (_jsx(Input, { id: inputId, defaultValue: formatDefaultValue(), onInput: handleNumbers, type: 'tel', className: props.className, bgSize: "sm", autoComplete: "off", onBlur: onBlur, step: '.0', readOnly: props.readOnly || false }, void 0)),
                                    props.helperText && (_jsx("div", __assign({ className: "form-error" }, { children: "required*" }), void 0))] }, void 0))] }), void 0))] }), void 0)) : (_jsxs("div", __assign({ className: flex ? 'flex' : '' }, { children: [flex && (_jsx(Typography, __assign({ variant: "caption", className: "min-w-" + flexWidth + " input-text-label" }, { children: props.label }), void 0)),
                    _jsxs(TextField, __assign({ size: "small", variant: "outlined", InputLabelProps: {
                            shrink: true,
                        } }, props, { onChange: selectChanged, label: "", className: props.className + " " + (addOption ? ' none' : ''), autoComplete: "off", style: props.helperText ? __assign(__assign({}, props.style), { borderBottom: 'solid 2px #c92f54' }) : props.style, InputProps: __assign(__assign({}, props.InputProps), { classes: {
                                focused: "notchedOutline",
                                notchedOutline: "notchedOutline"
                            } }) }, { children: [extendMenu && menuItems && (menuItems === null || menuItems === void 0 ? void 0 : menuItems.length) > 0 && _jsx(MenuItem, __assign({ value: "-10" }, { children: INLINE_EDITOR.ADD_OR_EDIT }), void 0),
                            extendMenu && menuItems && (menuItems === null || menuItems === void 0 ? void 0 : menuItems.length) > 0 && removeItem && _jsx(MenuItem, __assign({ value: "-11" }, { children: "Remove..." }), void 0),
                            extendMenu && menuItems && (menuItems === null || menuItems === void 0 ? void 0 : menuItems.length) > 0 && _jsx(MenuItem, { children: _jsx("em", { children: "None" }, void 0) }, void 0),
                            menuItems && (menuItems === null || menuItems === void 0 ? void 0 : menuItems.length) > 0 && menuItems.map(function (item) { return (_jsx(MenuItem, __assign({ value: item[displayValue] }, { children: item[displayText] }), item[displayValue])); }), props.children] }), void 0),
                    addOption && (_jsx(TextField, { size: "small", label: "", type: "text", className: props.className, variant: "outlined", onBlur: saveOption, placeholder: "type here ..." }, void 0))] }), void 0))] }), void 0));
};
