var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Chip, Grid, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpGet, httpPost } from "../../helpers/http.helper";
import { excludeHst, formatTotalAsCurrency } from "../../helpers/numbers-helper.helper";
import { formatDate } from "@utils/date";
import "./Job-Status.component.css";
import { Button, Card, CardBody, Table } from "reactstrap";
import { Spinner } from "../../ui-components/loading/Spinner.component";
import { GoogleMap } from "../../ui-components/google-map/googleMap";
import { InputText } from "../../ui-components/input/Input-Field.component";
export var ClientJobStatus = function () {
    var _a;
    var _b = useParams(), id = _b.id, uuid = _b.uuid;
    var _c = useState({}), job = _c[0], setJob = _c[1];
    var _d = useState([]), invoices = _d[0], setInvoices = _d[1];
    var _e = useState([]), payments = _e[0], setPayments = _e[1];
    var _f = useState(true), loading = _f[0], setIsLoading = _f[1];
    var _g = useState(false), approvalRequired = _g[0], setApprovalRequired = _g[1];
    var _h = useState(false), submitted = _h[0], setSubmitted = _h[1];
    var _j = useState(''), contactName = _j[0], setContactName = _j[1];
    var _k = useState(''), note = _k[0], setNotes = _k[1];
    var approval = function (approved) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSubmitted(true);
                    if (!contactName) {
                        return [2 /*return*/];
                    }
                    Spinner.show();
                    return [4 /*yield*/, httpPost("quote/approval", {
                            approved: approved,
                            id: id,
                            contact: contactName,
                            uuid: uuid,
                            note: note,
                        })
                            .catch(function (e) {
                            return null;
                        })];
                case 1:
                    result = _a.sent();
                    if (result) {
                        setApprovalRequired(false);
                    }
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var loadJob = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, job, invoices, payments, quote;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, httpGet("job/jobstatus/" + id + "/" + uuid)];
                case 1:
                    _a = _b.sent(), job = _a.job, invoices = _a.invoices, payments = _a.payments, quote = _a.quote;
                    setJob(job);
                    setInvoices(invoices);
                    setPayments(payments);
                    setIsLoading(false);
                    setApprovalRequired(quote);
                    document.title = (job === null || job === void 0 ? void 0 : job.name) || '';
                    Spinner.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var setNote = function (e) {
        setNotes(e.target.value);
    };
    var setContact = function (e) {
        setContactName(e.target.value);
    };
    useEffect(function () {
        Spinner.show();
        if (id)
            loadJob(id);
    }, [id]);
    if (loading) {
        return null;
    }
    return (_jsxs("div", __assign({ className: "db h-full w-full" }, { children: [_jsxs("div", __assign({ className: "dib w-60 h-full v-top overflow-auto" }, { children: [_jsxs("div", __assign({ className: "db p-8 sticky-top bg-darkblue" }, { children: [_jsx("div", __assign({ className: "dib w-70" }, { children: _jsx(Typography, __assign({ className: "card-header-h5" }, { children: job === null || job === void 0 ? void 0 : job.zzFullAddress }), void 0) }), void 0),
                            _jsx("div", __assign({ className: "dib w-30 text-right" }, { children: _jsx("div", __assign({ className: "flex items-center" }, { children: _jsx(Chip, { color: "primary", label: (_a = job === null || job === void 0 ? void 0 : job.statusTitle) === null || _a === void 0 ? void 0 : _a.toUpperCase() }, void 0) }), void 0) }), void 0)] }), void 0),
                    _jsx(Typography, __assign({ variant: "body1", className: "card-header-h5 border-b p-8" }, { children: "Order Details" }), void 0),
                    _jsx(Card, __assign({ className: "db border-0" }, { children: _jsx(CardBody, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Reference#" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: job === null || job === void 0 ? void 0 : job.jobNumber }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "File#" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: job === null || job === void 0 ? void 0 : job.fileNumber }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Created Date" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 10 }, { children: formatDate(job === null || job === void 0 ? void 0 : job.zzCreatedDate, 'dd LLL yyyy') }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Client Name" }), void 0) }), void 0),
                                    _jsxs(Grid, __assign({ item: true, xs: 4 }, { children: [job && (job === null || job === void 0 ? void 0 : job.name),
                                            _jsx("span", __assign({ className: "caption" }, { children: (job === null || job === void 0 ? void 0 : job.retainerRequired) ? ' (Retainer Required)' : '' }), void 0)] }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Property Type" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: ((job === null || job === void 0 ? void 0 : job.propertyType) || '') + " -> " + ((job === null || job === void 0 ? void 0 : job.propertySubType) || '') }), void 0),
                                    (job === null || job === void 0 ? void 0 : job.retainerRequired) && (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Retainer Required" }), void 0) }), void 0),
                                            _jsx(Grid, __assign({ item: true, xs: 10 }, { children: formatTotalAsCurrency(job === null || job === void 0 ? void 0 : job.retainerAmount) }), void 0)] }, void 0)),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Portfolio Name" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: (job === null || job === void 0 ? void 0 : job.portfolioName) || 'N/A' }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Owner" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: (job === null || job === void 0 ? void 0 : job.owner) || 'N/A' }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Delivery Timeline" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: (job === null || job === void 0 ? void 0 : job.delivery) || 'N/A' }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 2, className: "text-right" }, { children: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: "Due Date" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 4 }, { children: (job === null || job === void 0 ? void 0 : job.jobDueDate) ? formatDate(job === null || job === void 0 ? void 0 : job.jobDueDate, 'dd LLL yyyy') : 'N/A' }), void 0)] }), void 0) }, void 0) }), void 0),
                    _jsx(Typography, __assign({ variant: "body1", className: "card-header-h5 border-b p-8" }, { children: "Invoices" }), void 0),
                    _jsx(Card, __assign({ className: "db border-0" }, { children: _jsx(CardBody, __assign({ className: "p-0" }, { children: _jsxs(Table, { children: [_jsx(TableHead, __assign({ className: 'text-primary' }, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Description" }, void 0),
                                                _jsx(TableCell, { children: "Rate" }, void 0),
                                                _jsx(TableCell, { children: "HST" }, void 0),
                                                _jsx(TableCell, { children: "Total" }, void 0),
                                                _jsx(TableCell, { children: "Invoice Date" }, void 0),
                                                _jsx(TableCell, { children: "Due Date" }, void 0)] }, void 0) }), void 0),
                                    _jsx(TableBody, { children: invoices.map(function (pay, index) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: pay.description }, void 0),
                                                _jsx(TableCell, { children: formatTotalAsCurrency(excludeHst(+pay.rate, +pay.hst)) }, void 0),
                                                _jsxs(TableCell, { children: [+pay.hst * 100, "%"] }, void 0),
                                                _jsx(TableCell, { children: formatTotalAsCurrency(+pay.rate) }, void 0),
                                                _jsx(TableCell, { children: formatDate(pay.invoiceDate, 'dd LLL yyyy') }, void 0),
                                                _jsx(TableCell, { children: formatDate(pay.dueDate, 'dd LLL yyyy') }, void 0)] }, index)); }) }, void 0)] }, void 0) }), void 0) }), void 0),
                    approvalRequired && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "body1", className: "card-header-h5 border-b p-8" }, { children: "Quote Approval" }), void 0),
                            _jsxs(Grid, __assign({ container: true, className: "mt-32 approval-form" }, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(InputText, { helperText: submitted && !contactName ? 'required' : '', onChange: setContact, size: "sm", label: "Contact Name" }, void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 12, className: "mt-8" }, { children: _jsx(InputText, { onChange: setNote, size: "sm", label: "Note" }, void 0) }), void 0),
                                    _jsx(Grid, { item: true, xs: 12, className: "mt-16" }, void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 6, className: "text-right pr-5" }, { children: _jsx(Button, __assign({ onClick: function () { return approval(true); }, color: "info", className: "btn btn-md" }, { children: "Approve" }), void 0) }), void 0),
                                    _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Button, __assign({ onClick: function () { return approval(false); }, className: "btn btn-md" }, { children: "Reject" }), void 0) }), void 0)] }), void 0)] }, void 0))] }), void 0),
            _jsx("div", __assign({ className: "dib w-40 h-99" }, { children: _jsx(GoogleMap, { displayInfo: false, mapTypeControl: false, width: "100%", height: "100%", data: [job], zoom: 14 }, void 0) }), void 0)] }), void 0));
};
