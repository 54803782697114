var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, } from "reactstrap";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { openJobInViewMode } from "../../helpers/form.helper";
import { formatFileNumber } from "../../services/job.service";
import { isMobile } from "react-device-detect";
export var SmallJobList = function (_a) {
    var _b = _a.jobs, jobs = _b === void 0 ? [] : _b, _c = _a.showAddress, showAddress = _c === void 0 ? true : _c, _d = _a.onSelect, onSelect = _d === void 0 ? null : _d, _e = _a.className, className = _e === void 0 ? 'mt-24' : _e;
    var openJob = function (job) {
        if (onSelect) {
            onSelect(job.id);
        }
        else {
            openJobInViewMode(job.id);
        }
    };
    return (_jsxs(Table, __assign({ className: className }, { children: [_jsx(TableHead, __assign({ className: "text-primary" }, { children: _jsxs(TableRow, { children: [!isMobile && _jsx(TableCell, { children: "#" }, void 0),
                        _jsx(TableCell, { children: "Status" }, void 0),
                        _jsx(TableCell, { children: "File#" }, void 0),
                        _jsx(TableCell, { children: "Reference#" }, void 0),
                        showAddress && (_jsx(TableCell, { children: "Address" }, void 0)),
                        _jsx(TableCell, { children: "Client" }, void 0)] }, void 0) }), void 0),
            _jsx(TableBody, { children: (jobs || []).map(function (job, index) {
                    var _a;
                    return (_jsxs(TableRow, __assign({ onClick: function () { return openJob(job); }, className: "pointer" }, { children: [!isMobile && _jsx(TableCell, { children: index + 1 }, void 0),
                            _jsx(TableCell, { children: job.statusname || job.tblStatusByStatusId.statusTitle }, void 0),
                            _jsx(TableCell, { children: formatFileNumber(job.filenumber || job.fileNumber) }, void 0),
                            _jsx(TableCell, { children: job.jobnumber || job.jobNumber }, void 0),
                            showAddress && (_jsx(TableCell, { children: job.zzFullAddress || job.zzfulladdress }, void 0)),
                            _jsx(TableCell, { children: job.clientname || ((_a = job.tblClientByClientId) === null || _a === void 0 ? void 0 : _a.name) }, void 0)] }), job.id));
                }) }, void 0)] }), void 0));
};
