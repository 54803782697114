export var FeeTypeEnum;
(function (FeeTypeEnum) {
    FeeTypeEnum[FeeTypeEnum["PERCENTAGE"] = 0] = "PERCENTAGE";
    FeeTypeEnum[FeeTypeEnum["DOLLAR"] = 1] = "DOLLAR";
})(FeeTypeEnum || (FeeTypeEnum = {}));
export var JobTypes;
(function (JobTypes) {
    JobTypes[JobTypes["APPRAISAL"] = 1] = "APPRAISAL";
    JobTypes[JobTypes["QUOTE"] = 2] = "QUOTE";
})(JobTypes || (JobTypes = {}));
