var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Button } from 'reactstrap';
var StyledBreadcrumb = withStyles(function (theme) { return ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}); })(Chip);
export var ListHeader = function (_a) {
    var _b = _a.title, title = _b === void 0 ? "" : _b, _c = _a.href, href = _c === void 0 ? "#" : _c, _d = _a.mainClick, mainClick = _d === void 0 ? null : _d, _e = _a.others, others = _e === void 0 ? [] : _e, _f = _a.cancel, cancel = _f === void 0 ? true : _f, props = __rest(_a, ["title", "href", "mainClick", "others", "cancel"]);
    var history = useHistory();
    return (_jsxs(React.Fragment, { children: [_jsx("div", __assign({ className: 'db w-full v-top breadcrumb-top pb-10 ' + (isMobile ? 'breadcrumb-top-mobile' : '') }, { children: _jsxs(Breadcrumbs, __assign({ "aria-label": "breadcrumb", separator: ">" }, { children: [!props.noHome && (_jsx(Link, __assign({ to: '/admin/dashboard' }, { children: isMobile ? _jsx(HomeIcon, { fontSize: "small" }, void 0) : _jsx(StyledBreadcrumb, { label: 'Home', icon: _jsx(HomeIcon, { fontSize: "small" }, void 0) }, void 0) }), void 0)),
                        _jsx(Link, __assign({ to: href, onClick: function () { return mainClick && mainClick(); } }, { children: _jsx(StyledBreadcrumb, { label: title }, void 0) }), void 0),
                        others.map(function (link, index) { return (_jsx(StyledBreadcrumb, { component: "a", href: "#", label: link }, "list_item_row_" + index)); })] }), void 0) }), void 0),
            props.children &&
                _jsxs("div", __assign({ className: 'form-save-button' }, { children: [props.children, cancel && _jsx(Button, __assign({ color: "secondary", className: "btn btn-md", onClick: function () { return history.goBack(); } }, { children: "Cancel" }), void 0)] }), void 0)] }, void 0));
};
