import { v4 as uuidv4 } from 'uuid';
import { kebabCase, toUpper } from "lodash";
import { formatDate as formatDateFns } from "../date";
import { sortBy } from 'lodash';
import { std } from "mathjs";
export var uuid = function () { return uuidv4(); };
export var newUUId = function () { return uuidv4(); };
export var getDefaultLocale = function () { return 'en-CA'; };
export var tobeLocal = function (locale) { return kebabCase(locale || getDefaultLocale()); };
export var formatDate = function (date, today) {
    if (today === void 0) { today = true; }
    if (!date) {
        return !today ? "" : formatDateFns(new Date(), "LLL");
    }
    return formatDateFns(new Date(date), "LLL");
};
export var formatInsertUpdate = function (action) {
    return action === "INSERT" ? "Created" : "Updated";
};
export var formatTotalAsCurrency = function (val, currency, locale) {
    if (currency === void 0) { currency = "CAD"; }
    var total = 0;
    if (val) {
        total = parseFloat("" + val);
    }
    return total.toLocaleString(tobeLocal(locale || getDefaultLocale()), {
        currency: toUpper(currency || 'CAD'),
        style: 'currency',
    });
};
export var getSplitNames = function (input, index) {
    var names = (input || "").split(' ');
    if (index === 0) {
        return names[0];
    }
    return names.length > 2 ? names[1] + ' ' + names[2] : (names.length > 1 ? names[1] : '');
};
export var formatNumbers = function (input) {
    var _a, _b;
    if (input && ((_b = (_a = ("" + input).split('.')) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.length) > 2)
        return input.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return !input ? '0' : input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export var generateRandomNumber = function () {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math
        .random() * (maxm - minm + 1)) + minm;
};
export var excludeHst = function (rate, hst) { return rate / (1 + hst); };
export var includeHst = function (rate, hst) { return rate + (rate * (hst / 100)); };
export var sqFeetToAcres = function (sq) { return parseFloat((sq / 43560).toFixed(2)); };
export var acresTosf = function (acres) { return parseFloat((acres * 43560).toFixed(2)); };
export var meterTofoot = function (meter) { return parseFloat((meter * 3.281).toFixed(2)); };
export var correctMeasure = function (input, type) { return type.toLowerCase() === "m" ? meterTofoot(input) : input; };
export var formatAsDecimalPlaces = function (input, decimal) {
    if (decimal === void 0) { decimal = 2; }
    return decimal === 0 ? Math.round(input || 0) : parseFloat((input || 0).toFixed(decimal));
};
export var median = function (array) {
    if (array.length === 0)
        return 0;
    var sorted = sortBy(array);
    var half = Math.floor(array.length / 2);
    if (array.length % 2)
        return sorted[half];
    return (sorted[half - 1] + sorted[half]) / 2.0;
};
export var stdev = function (array) {
    return !array.length ? 0 : std(array);
};
export var trunc = function (x, posiciones) {
    if (posiciones === void 0) { posiciones = 0; }
    var s = x.toString();
    var l = s.length;
    var decimalLength = s.indexOf('.') + 1;
    var numStr = s.substr(0, decimalLength + posiciones);
    return Number(numStr);
};
export var conv_number = function (expr, decplaces) {
    var str = "" + Math.round(eval(expr) * Math.pow(10, decplaces));
    while (str.length <= decplaces) {
        str = "0" + str;
    }
    var decpoint = str.length - decplaces;
    return (str.substring(0, decpoint) + "." + str.substring(decpoint, str.length));
};
export var pv = function (interestRate, nper, pmt, fv, type) {
    if (fv === void 0) { fv = 0; }
    if (type === void 0) { type = 0; }
    var pv_value = 0;
    var rate = interestRate / 100;
    if (rate == 0) {
        pv_value = -(fv + (pmt * nper));
    }
    else {
        var x = Math.pow(1 + rate, -nper);
        var y = Math.pow(1 + rate, nper);
        pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate;
    }
    pv_value = conv_number(pv_value, 2);
    return (trunc(pv_value * -1, 2));
};
